import React, { memo } from 'react';
import classNames from 'classnames';
import { FindSearchItem } from './FindSearchItem';
import { HasMoreFlag, SearchTOCItem } from '../type';
import Styles from '../search.module.scss';

type Props = {
  selectedItemId: string | null;
  hasMore: HasMoreFlag;
  findResult: SearchTOCItem[];
  searchMenuRef: React.RefObject<HTMLDivElement>;
  clickHandler(item: SearchTOCItem): void;
};

export const List: React.FC<Props> = memo(
  ({ findResult, selectedItemId, hasMore, searchMenuRef, clickHandler }) => {
    const isFirstElementWasFound = React.useRef(false);

    const setIsFirstElementWasFound = (value: boolean) => {
      isFirstElementWasFound.current = value;
    };
    if (!findResult.length) return null;

    return (
      <div className={Styles['list-wrapper']}>
        <div className={Styles['list-title']}>Results</div>
        <div className={Styles.list} ref={searchMenuRef}>
          <div
            className={classNames('start-of-list', {
              [Styles['is-hidden']]: !hasMore.upper,
            })}
          >
            {hasMore.upper ? 'Loading more items...' : ''}
          </div>
          {findResult.map((resultItem) => (
            <FindSearchItem
              isFirstElementWasFound={isFirstElementWasFound}
              setIsFirstElementWasFound={setIsFirstElementWasFound}
              selectedItemId={selectedItemId}
              searchMenuRef={searchMenuRef}
              key={resultItem.id}
              resultItem={resultItem}
              onClick={clickHandler}
            />
          ))}
          <div
            className={classNames('end-of-list', {
              [Styles['is-hidden']]: !hasMore.lower,
            })}
          >
            {hasMore.lower ? 'Loading more items...' : ''}
          </div>
        </div>
      </div>
    );
  }
);
