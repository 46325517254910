import { FC } from 'react';
import { flexRender, RowModel } from '@tanstack/react-table';
import Loader from 'modules/common/components/Loader/Loader';
import Styles from './table.module.scss';
import classNames from 'classnames';

interface Props {
  data: RowModel<any> | null;
  emptyMessage: string;
  isLoading?: boolean;
  selectedRowId?: any;
  onRowClick?: (row: any) => void;
  getRowDisabled?: (row: any) => void;
}

const TableBody: FC<Props> = ({
  data,
  emptyMessage,
  isLoading,
  selectedRowId,
  onRowClick,
  getRowDisabled,
}) => {
  return (
    <tbody className={Styles['tbody']}>
      {isLoading && (
        <tr>
          <td colSpan={100} className={Styles['loading']}>
            <Loader
              loadingPartColor="#031E50"
              loadingBackSet="#ffffff"
              size="40px"
              loaderWidth="3px"
            />
          </td>
        </tr>
      )}
      {data?.rows.length === 0 && (
        <tr>
          <td colSpan={100} className={Styles['table-empty-message']}>
            {emptyMessage}
          </td>
        </tr>
      )}
      {data?.rows.map((row) => {
        return (
          <tr
            onClick={
              onRowClick && !getRowDisabled?.(row.original)
                ? () => onRowClick(row)
                : undefined
            }
            key={row.id}
            className={classNames({
              [Styles.active]: row.id === selectedRowId,
              [Styles.disabled]: getRowDisabled?.(row.original),
            })}
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
