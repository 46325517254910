import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetVoicesQuery } from 'redux/api/app.api';
import { TTSEngine } from 'modules/common/types';
import { ProjectIcon } from 'modules/common/components/ProjectIcon/ProjectIcon';
import { Stack } from 'modules/common/components/Stack/Stack';
import Styles from './namecelllogo.module.scss';
import { Icon } from 'icons';
import classnames from 'classnames';

interface Props {
  id: string;
  isV2: boolean;
  ttsEngine: TTSEngine;
  voice_name: string;
  value: string;
  isPreprocessAvailable: boolean;
  elevenLabsModel?: string;
}

const NameCellLogo: FC<Props> = ({
  id,
  isV2,
  ttsEngine,
  voice_name,
  value,
  isPreprocessAvailable,
  elevenLabsModel,
}) => {
  const { data: voicesData } = useGetVoicesQuery();
  const [showTooltip, setShowTooltip] = useState(false);

  const elevenLabsModelLabel = elevenLabsModel
    ? voicesData?.models.elevenlabs.find(
        ({ value }) => value === elevenLabsModel
      )?.label || ''
    : undefined;

  const linkUrl = isV2 ? `/editor/v2/${id}` : `/editor/${id}`;

  return (
    <Stack justifyContent="flex-start" alignItems="center" spacing="1">
      <div
        className={Styles['logo-container']}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <ProjectIcon ttsEngine={ttsEngine} />
        {isPreprocessAvailable && (
          <Icon
            name="preprocessing"
            size={10}
            style={{ color: '#FF5C35' }}
            className={Styles['preprocessing-icon']}
          />
        )}
        {showTooltip && (
          <Stack
            className={Styles['tooltip']}
            alignItems="center"
            justifyContent="center"
          >
            <div>
              <Icon
                name="volume_mid"
                className="m-r-1"
                color={'#D7DAE1'}
                size={20}
              />
              {voice_name}
            </div>
            {elevenLabsModelLabel && (
              <div>
                <Icon
                  name="info"
                  className="m-r-1"
                  color={'#D7DAE1'}
                  size={20}
                />
                {elevenLabsModelLabel}
              </div>
            )}
          </Stack>
        )}
      </div>
      <div className={classnames(Styles['text-container'], 't-truncate')}>
        {id ? (
          <Link className="color-primary" to={linkUrl}>
            {value}
          </Link>
        ) : (
          value
        )}
      </div>
    </Stack>
  );
};

export default NameCellLogo;
