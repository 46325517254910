import axiosInstance from 'utils/axios';
import {
  ChapterModel,
  ChapterDetailedModel,
  PreProcessChapter,
  ChapterReport,
} from 'modules/common/models/Chapter';
import { ProjectModel } from 'modules/common/models/Project';
import { Dict } from 'modules/common/types';
import { convertArrayToStrWithCommas } from 'utils';
import { AxiosResponse } from 'axios';

interface IChaptersApi {
  getAll(projectId: string): Promise<ChapterModel[]>;
  getAllPreAssignedUrls(
    projectId: string,
    chapterIds: string[]
  ): Promise<Dict<ChapterDetailedModel>>;
  getChapterInfo(
    chapterId: string,
    projectId: string
  ): Promise<ChapterDetailedModel>;
  getChapterText(preAssignedUrl: string): Promise<string>;
  saveChapterInS3(
    upload: ProjectModel['uploadUrl'],
    text: string
  ): Promise<string>;
}

class ChaptersApi implements IChaptersApi {
  static action = '/chapter';
  static actionPreAssigned = `${this.action}/presigned_url`;
  static chapterReport = '/report';

  async getAll(projectId: string): Promise<ChapterModel[]> {
    const res = await axiosInstance.get(ChaptersApi.action, {
      params: {
        projectId,
      },
    });

    let chapters: ChapterModel[] = [];
    // to keep the object key order
    res.data.order.forEach((id: string) => {
      chapters.push({ id, ...res.data.chapters[id] });
    });
    return chapters;
  }

  async getAllPreAssignedUrls(
    projectId: string,
    chapterIdsArr: string[]
  ): Promise<Dict<ChapterDetailedModel>> {
    const chapterId = convertArrayToStrWithCommas(chapterIdsArr);

    const res = await axiosInstance.get(ChaptersApi.actionPreAssigned, {
      params: {
        projectId,
        chapterId,
      },
    });

    return res.data;
  }

  async getPreProcessReport({
    projectId,
    chapterId,
  }: PreProcessChapter): Promise<ChapterReport | Error> {
    try {
      const res = (await axiosInstance.get(ChaptersApi.chapterReport, {
        params: {
          projectId,
          chapterId,
        },
      })) as AxiosResponse<ChapterReport>;

      return res.data;
    } catch {
      return new Error();
    }
  }

  async getChapterInfo(
    chapterId: string,
    projectId: string
  ): Promise<ChapterDetailedModel> {
    const res = await axiosInstance.get(`${ChaptersApi.action}/${chapterId}`, {
      params: {
        projectId,
      },
    });
    return res.data;
  }

  async getChapterText(preAssignedUrl: string): Promise<string> {
    const res = await axiosInstance.get(preAssignedUrl, {
      baseURL: preAssignedUrl,
      responseType: 'text',
    });
    return res.data;
  }

  async saveChapterInS3(
    upload: ProjectModel['uploadUrl'],
    text: string
  ): Promise<any> {
    if (!upload) {
      return;
    }

    const bodyFormData = new FormData();
    Object.entries(upload.fields).forEach(([k, v]) => {
      bodyFormData.append(k, v);
    });
    bodyFormData.append('file', text);
    const res = await axiosInstance.post(upload.url, bodyFormData, {
      baseURL: upload.url,
    });
    return res.data;
  }

  async cacheChapterTrigger(
    projectId: string,
    chapterId: string
  ): Promise<any> {
    return axiosInstance.post(
      `v2/projects/${projectId}/chapters/${chapterId}/cache`
    );
  }
}

const ChaptersApiInstance = new ChaptersApi();

export default ChaptersApiInstance;
