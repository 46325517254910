import { Quill } from 'react-quill';
import QUtil from 'utils/QuillUtil';

const Inline = Quill.import('blots/inline');

export class SelectionBlot extends Inline {
  optimize(): void {
    if (QUtil.areTagsSSMLEqual(this.prev?.domNode, this.next?.domNode)) {
      let currentSSMLTag = this.prev.domNode;

      if (this.children && this.children.length === 1) {
        let child = this.children.tail;

        if (QUtil.areTagsSSMLEqual(child.domNode, currentSSMLTag)) {
          // moving the rouge tag into prev tag
          child.unwrap(); // if the other case then child child unwrap problem solve maybe check the this ;)
          this.prev.appendChild(this);

          let outerTag = this.prev.parent;

          outerTag.next.moveChildren(outerTag);
          outerTag.next.remove();
        }
      }
      return;
    }

    let child = this.children.tail;
    if (
      QUtil.isFullyEqual(child, this?.next) &&
      !QUtil.isFullyEqual(child, this?.prev)
    ) {
      const value = QUtil.getValueFromTag(child.domNode);
      child.unwrap();
      let next = this.next;
      this.wrap(child.statics.blotName, value);
      next.moveChildren(this.parent);
      next.remove();
      return;
    }

    if (
      QUtil.isFullyEqual(child, this?.prev) &&
      !QUtil.isFullyEqual(child, this?.next)
    ) {
      child.unwrap();
      this.prev.appendChild(this);
      return;
    }
  }
}

SelectionBlot.blotName = 'selection';
SelectionBlot.className = 'rb-selection';
SelectionBlot.tagName = 'selection';
