import { FC, useState } from 'react';
import { Card } from 'modules/common/components/Card/Card';

import useModal from 'modules/common/hooks/useModal';
import { SelectEditorModal } from './SelectEditorModal';
import { AdminProjects } from 'modules/dashboard/ProjectsTableConfig';

import Styles from './unassigned-projects.module.scss';
import classnames from 'classnames';
import DeclineViewToggled from './DeclineViewToggled';
import NormalProjectView from './NormalProjectView';

export const ProjectItem: FC<{ data: AdminProjects; isFetching?: boolean }> = ({
  data,
  isFetching,
}) => {
  const { isOpen, setOpen, setClose } = useModal();
  const [isDeclineReasonView, setDeclineReasonView] = useState(false);

  return (
    <>
      <Card className={classnames([Styles.card])}>
        {isDeclineReasonView ? (
          <DeclineViewToggled
            setDeclineReasonView={setDeclineReasonView}
            setOpen={setOpen}
            data={data}
            isFetching={isFetching}
          />
        ) : (
          <NormalProjectView
            setDeclineReasonView={setDeclineReasonView}
            setOpen={setOpen}
            data={data}
            isFetching={isFetching}
          />
        )}
      </Card>
      {isOpen && (
        <SelectEditorModal
          projectId={data.id}
          isOpen={isOpen}
          setClose={setClose}
        />
      )}
    </>
  );
};
