import classNames from 'classnames';
import { useSelectionWithLength } from 'modules/common/hooks/useSelectionWithLength';
import { Button } from 'modules/common/components/Button/Button';
import useQuill from 'modules/common/hooks/useQuill';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Divider } from 'modules/common/components/Divider/Divider';
import { getQuillHtml, prepareSSMLText } from 'utils/SsmlUtils';
import { error, success } from 'modules/common/components/Notify';

export const CopySelectedFragment = () => {
  const showCopy = useSelectionWithLength();
  const quillInstance = useQuill();

  const onClickHandler = async () => {
    const editor = quillInstance?.editor;
    if (editor) {
      const range = editor.getSelection();
      if (range && range.length) {
        const contents = editor.getContents(range.index, range.length);

        const tempQuill = getQuillHtml(contents);

        try {
          await navigator.clipboard.writeText(
            prepareSSMLText(tempQuill).replaceAll(/<p>|<\/p>/g, '')
          );
          success({ children: 'Selected text is copied to clipboard' });
        } catch {
          error({ children: 'Something went Wrong with the clipboard' });
        }
      }
    }
  };

  return showCopy ? (
    <>
      <Stack justifyContent="center" className={classNames('p-x-3', 'p-y-3')}>
        <Button large onClick={onClickHandler}>
          Copy Fragment with tags
        </Button>
      </Stack>
      <Divider />
    </>
  ) : null;
};
