import { FC, useCallback, useEffect, useState } from 'react';
import Quill from 'quill';
import classNames from 'classnames';

import { Stack } from 'modules/common/components/Stack/Stack';
import { Checkbox } from 'modules/common/components/Checkbox/Checkbox';
import useQuill from 'modules/common/hooks/useQuill';
import { Divider } from 'modules/common/components/Divider/Divider';
import QuillUtil from 'utils/QuillUtil';

import { PresetBlot } from 'modules/edit/Editor/Blots/PresetBlot';
import { SkipBlot } from 'modules/edit/Editor/Blots/SkipBlot';
import { SelectionBlot } from 'modules/edit/Editor/Blots/SelectionBlot';
import { useSelectionWithLength } from 'modules/common/hooks/useSelectionWithLength';
import { isSelectedSentenceTagAreaFitsTheSkipTagUsage } from './lib';

export const Skip: FC = () => {
  const [selected, setSelected] = useState<boolean>(false);
  const showSkip = useSelectionWithLength();
  const [disabled, setDisabled] = useState<boolean>(false);
  const quillInstance = useQuill();

  useEffect(() => {
    const editor = quillInstance?.getEditor();
    if (editor) {
      const selectionChangeHandler = (range: {
        index: number;
        length: number;
      }) => {
        if (range && range.length) {
          const formats = editor.getFormat(range);
          if (formats.hasOwnProperty(SkipBlot.blotName)) {
            setSelected(true);
          } else {
            setSelected(false);
          }
        } else if (editor.hasFocus()) {
          setSelected(false);
        }
      };

      editor.on('selection-change', selectionChangeHandler);
      return () => {
        editor.off('selection-change', selectionChangeHandler);
      };
    }
  }, [quillInstance]);

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      const quill = quillInstance!.getEditor();
      const scrollPosition = quill.root.scrollTop;
      const selection = quill.getSelection(true);
      if (selection && selection.length) {
        quill.formatText(
          0,
          quill.getText().length,
          SelectionBlot.blotName,
          false
        );
        quill.formatText(selection, SkipBlot.blotName, checked, 'user');
        setSelected(checked);
      }
      quill.root.scrollTo({
        top: scrollPosition,
        behavior: 'auto'
      });
    },
    [quillInstance]
  );

  const handleDisabledHandler = useCallback(
    (
      editor: Quill,
      range: {
        index: number;
        length: number;
      }
    ) => {
      if (range) {
        const newRange = { ...range };
        if (newRange.length === 0) {
          newRange.length = 1;
        }
        const blots = QuillUtil.getRBChildren(editor, newRange);

        if (!isSelectedSentenceTagAreaFitsTheSkipTagUsage(editor, range)) {
          return setDisabled(true);
        }

        if (blots.length) {
          if (blots.some((b) => b.statics.blotName === PresetBlot.blotName)) {
            return setDisabled(true);
          }

          const firstBlotLength = blots[0].length();
          if (firstBlotLength > newRange.length) {
            // Inner
            const parent = blots[0];
            const parentAllowedTags = parent.statics.allowedTags || [] as string[];
            if (
              parentAllowedTags.includes('*') ||
              parentAllowedTags.includes(SkipBlot.tagName.toLowerCase())
            ) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          } else {
            if (
              firstBlotLength === newRange.length &&
              blots.some((b) => b.statics.blotName === SkipBlot.blotName)
            ) {
              setDisabled(false);
            } else {
              // Outer
              setDisabled(
                !blots.every((b) => {
                  return b.statics.blotName === SkipBlot.blotName;
                })
              );
            }
          }
        } else {
          setDisabled(false);
        }
      }
    },
    []
  );

  useEffect(() => {
    const editor = quillInstance?.getEditor();
    let mounted = true;
    if (editor) {
      const selectionChangeHandler = (range: {
        index: number;
        length: number;
      }) => {
        setTimeout(() => {
          if (mounted) {
            handleDisabledHandler(editor, range);
          }
        });
      };

      editor.on('selection-change', selectionChangeHandler);
      return () => {
        mounted = false;
        editor.off('selection-change', selectionChangeHandler);
      };
    }
  }, [handleDisabledHandler, quillInstance]);

  useEffect(() => {
    const editor = quillInstance?.getEditor();
    const range = editor?.getSelection();
    if (editor) {
      if (range) {
        handleDisabledHandler(editor, range);
      }
    }
  }, [handleDisabledHandler, quillInstance]);

  return showSkip ? (
    <>
      <div className={classNames('p-x-3', 'p-y-3')}>
        <Stack alignItems="center" justifyContent="space-between">
          <Stack.Item>
            <p
              className={classNames('fw-600 font-size-sm', {
                'opacity-50': disabled,
              })}
            >
              Skip selected fragment
            </p>
          </Stack.Item>
          <Stack.Item>
            <Checkbox
              disabled={disabled}
              onChange={handleCheckboxChange}
              checked={selected}
            />
          </Stack.Item>
        </Stack>
      </div>
      <Divider />
    </>
  ) : null;
};
