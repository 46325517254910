import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Quill from 'quill';

import { v4 as uuid } from 'uuid';
import { findIndices } from 'utils';
import QuillUtil from 'utils/QuillUtil';
import useQuill from 'modules/common/hooks/useQuill';
import { ProjectTOCPage } from 'modules/common/models/Project';
import {
  FoundTextTOCBlot,
  FoundTextTOCCurrentBlot,
} from 'modules/edit/Editor/Blots/FoundTOCTextBlot';
import { SearchTOCItem } from './type';

const getCurrentPageId = () =>
  new URLSearchParams(window.location.search).get('pageId');

function isScrolledIntoView(el: HTMLElement, container: HTMLElement) {
  const rect = el.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  const isVisible =
    elemTop >= containerRect.top && elemBottom <= containerRect.bottom;
  return isVisible;
}

const getCurrentPageResults = ({
  valueToFind,
  editor,
  results,
}: {
  valueToFind: string;
  editor: Quill;
  results: SearchTOCItem[];
}) =>
  results.filter((item) => {
    const pageIdSearchParam = getCurrentPageId();
    return (
      item.pageId === pageIdSearchParam &&
      !QuillUtil.getRBChildren(editor, {
        index: item.index,
        length: valueToFind.length,
      }).some((p) => p.domNode.dataset.id !== item.id)
    );
  });

const findResultsWithinThePage = ({
  indices,
  valueToFind,
  pageText,
  pageId,
}: {
  indices: number[];
  valueToFind: string;
  pageText: string;
  pageId: string;
}): SearchTOCItem[] => {
  let max = 35;
  return indices.map((index) => {
    const diff = Math.round((max - valueToFind.length) / 2);
    let start = index - diff;
    if (start < 0) {
      start = 0;
    }
    let end = index + valueToFind.length + diff;
    if (end > pageText.length) {
      end = pageText.length - 1;
    }
    let prevText = start > 0 ? '...' : '';
    prevText += pageText.slice(start, start === 0 ? index : start + diff);

    let nextText = pageText.slice(
      index + valueToFind.length,
      index + valueToFind.length + diff
    );
    nextText += end < pageText.length - 1 ? '...' : '';
    const textToPass = pageText.slice(index, index + valueToFind.length);

    const textToShow = `${prevText}<strong class="found-toc-item">${textToPass}</strong>${nextText}`;

    return {
      index,
      id: uuid(),
      text: textToShow,
      pageId,
    };
  });
};

// The usePageChangeDetector hook will be triggered after the pageId search param change.
const useOpenAnotherTOCPage = () => {
  const [, setSearchParams] = useSearchParams();

  return ({ itemNavigateTo }: { itemNavigateTo: SearchTOCItem }) => {
    const pageIdSearchParam = getCurrentPageId();
    if (itemNavigateTo.pageId === pageIdSearchParam) return;
    setSearchParams({
      // The decreasing with one is necessary because the page index starts with a zero value.
      pageId: itemNavigateTo.pageId,
    });
  };
};

const scrollEditorHandler = ({
  id,
  quillEditor,
}: {
  id: string;
  quillEditor: Quill;
}) => {
  const foundEl = document.querySelector(
    `${FoundTextTOCCurrentBlot.tagName}[${FoundTextTOCCurrentBlot.dataAttrName}="${id}"]`
  )! as HTMLElement;
  if (!foundEl) return;

  const scrollEl = quillEditor.scroll.domNode as HTMLDivElement;
  const isNeedToScroll = !isScrolledIntoView(foundEl, scrollEl);
  if (!isNeedToScroll) return;

  scrollEl.scrollTo({
    top: foundEl.offsetTop,
  });
};

// The cached selected item ID needed to prevent auto-scrolling back to that element
// if the list of items rewrites while scrolling it manually.
let cachedSelectedItemIdInside: string | null = null;

export const useScrollMenuItemEffect = ({
  selected,
  menuRef,
  menuItemRef,
  isFirstElementWasFound,
  selectedItemId,
  setIsFirstElementWasFound,
}: {
  selected: boolean;
  menuRef: React.RefObject<HTMLDivElement>;
  menuItemRef: React.RefObject<HTMLDivElement>;
  isFirstElementWasFound: React.MutableRefObject<boolean>;
  selectedItemId: string | null;
  setIsFirstElementWasFound(value: boolean): void;
}) => {
  React.useEffect(() => {
    if (cachedSelectedItemIdInside === null) {
      cachedSelectedItemIdInside = selectedItemId;
    }

    if (!selected || !menuItemRef.current || !menuRef.current) return;

    const isVisible = isScrolledIntoView(menuItemRef.current, menuRef.current);

    if (isVisible || selectedItemId === cachedSelectedItemIdInside) return;

    cachedSelectedItemIdInside = selectedItemId;

    const itemRect = menuItemRef.current.getBoundingClientRect();
    const menuRect = menuRef.current.getBoundingClientRect();

    let scrollTop = 0;
    if (!isFirstElementWasFound.current) {
      setIsFirstElementWasFound(true);
      scrollTop =
        menuItemRef.current.offsetTop - menuItemRef.current.clientHeight * 2;
    } else if (itemRect.top < menuRect.top) {
      scrollTop =
        menuItemRef.current.offsetTop - menuItemRef.current.clientHeight * 2;
    } else {
      scrollTop =
        menuItemRef.current.offsetTop - menuRect.bottom + itemRect.height * 4;
    }

    menuRef.current?.scrollTo({ top: scrollTop });
  }, [
    isFirstElementWasFound,
    menuItemRef,
    menuRef,
    selected,
    selectedItemId,
    setIsFirstElementWasFound,
  ]);
};

// The usePageChangeDetector hook checks whether the quill text was updated after TOC page navigation
// (literally navigating/switching between pages).
const usePageChangeDetector = () => {
  const quillInstance = useQuill();
  const editor = quillInstance?.getEditor();
  const cachedPageValue = React.useRef<string | null>(getCurrentPageId());
  const [isChanged, setIsChanged] = React.useState(false);

  React.useEffect(() => {
    if (!editor) return;
    const editorChangeHandler = () => {
      const pageIdSearchParam = getCurrentPageId();
      if (pageIdSearchParam === cachedPageValue.current) return;
      cachedPageValue.current = pageIdSearchParam;
      setIsChanged(true);
    };

    editor.on('text-change', editorChangeHandler);

    return () => {
      editor.off('text-change', editorChangeHandler);
    };
  }, [editor]);

  return React.useMemo(
    () => ({
      isChanged,
      cachedPageValue: cachedPageValue.current,
      setIsChanged,
    }),
    [isChanged]
  );
};

// The useUpdateQuillPageForSearch hook gets the isChanged flag provided by usePageChangeDetector hook
// and enriches a new page with search results.
export const useUpdateQuillPageForSearch = ({
  currentResultIndex,
  valueToFind,
  overallFoundResult,
}: {
  currentResultIndex: number;
  valueToFind: React.MutableRefObject<string>;
  overallFoundResult: SearchTOCItem[];
}) => {
  const quillInstance = useQuill();
  const editor = quillInstance?.getEditor();
  const { isChanged, cachedPageValue, setIsChanged } = usePageChangeDetector();

  React.useEffect(() => {
    if (!editor || !isChanged) return;

    const editorChangeHandler = () => {
      const currentPageResults = getCurrentPageResults({
        editor,
        valueToFind: valueToFind.current,
        results: overallFoundResult,
      });

      if (!currentPageResults.length) return;

      const currentlyActiveElement = overallFoundResult[currentResultIndex];

      currentPageResults.forEach((item) => {
        editor.formatText(
          item.index,
          valueToFind.current.length,
          FoundTextTOCBlot.blotName,
          item.id
        );
      });
      const isActiveElementOnThePage = !!currentPageResults.find(
        (item) => item.id === currentlyActiveElement.id
      );
      if (!isActiveElementOnThePage) return;

      editor.formatText(
        currentlyActiveElement.index,
        valueToFind.current.length,
        FoundTextTOCCurrentBlot.blotName,
        currentlyActiveElement.id
      );
      scrollEditorHandler({
        quillEditor: editor,
        id: currentlyActiveElement.id,
      });
    };

    editorChangeHandler();
    setIsChanged(false);
  }, [
    cachedPageValue,
    currentResultIndex,
    editor,
    overallFoundResult,
    isChanged,
    setIsChanged,
    valueToFind,
  ]);
};

const useHighlightCurrent = () => {
  const quillInstance = useQuill();
  const editor = quillInstance!.getEditor();

  return ({
    prevId,
    currentId,
    valueToFind,
    overallFoundResult,
  }: {
    prevId?: string;
    currentId: string;
    valueToFind: string;
    overallFoundResult: SearchTOCItem[];
  }) => {
    const prevElement =
      !!prevId && overallFoundResult.find((item) => item.id === prevId);
    const currentElement = overallFoundResult.find(
      (item) => item.id === currentId
    );
    if (!!prevElement) {
      editor.formatText(
        prevElement.index,
        valueToFind.length,
        FoundTextTOCCurrentBlot.blotName,
        false
      );
      editor.formatText(
        prevElement.index,
        valueToFind.length,
        FoundTextTOCBlot.blotName,
        true
      );
    }
    if (!currentElement) return;

    editor.formatText(
      currentElement.index,
      valueToFind.length,
      FoundTextTOCBlot.blotName,
      false
    );
    editor.formatText(
      currentElement.index,
      valueToFind.length,
      FoundTextTOCCurrentBlot.blotName,
      currentElement.id
    );
    scrollEditorHandler({ id: currentElement.id, quillEditor: editor });
  };
};

export const useSearchNavigationBySelectingItem = () => {
  const openAnotherTOCPage = useOpenAnotherTOCPage();
  const highlightCurrent = useHighlightCurrent();

  return ({
    item,
    valueToFind,
    prevId,
    overallFoundResult,
  }: {
    item: SearchTOCItem;
    valueToFind: string;
    prevId: string;
    overallFoundResult: SearchTOCItem[];
  }) => {
    const pageIdSearchParam = getCurrentPageId();
    if (item.pageId !== pageIdSearchParam) {
      openAnotherTOCPage({ itemNavigateTo: item });
      return;
    }
    if (!prevId) return;

    highlightCurrent({
      prevId,
      currentId: item.id,
      valueToFind,
      overallFoundResult,
    });
  };
};

export const useNavigateToTheNextElementInTheSequence = () => {
  const openAnotherTOCPage = useOpenAnotherTOCPage();
  const highlightCurrent = useHighlightCurrent();

  return ({
    prevId,
    itemNavigateTo,
    valueToFind,
    overallFoundResult,
  }: {
    prevId: string;
    itemNavigateTo: SearchTOCItem;
    valueToFind: string;
    overallFoundResult: SearchTOCItem[];
  }) => {
    const pageIdSearchParam = getCurrentPageId();
    if (itemNavigateTo.pageId !== pageIdSearchParam) {
      openAnotherTOCPage({ itemNavigateTo });
      return;
    }

    if (!prevId) return;

    highlightCurrent({
      prevId,
      currentId: itemNavigateTo.id,
      valueToFind,
      overallFoundResult: overallFoundResult,
    });
  };
};

export const applySearch = ({
  editor,
  valueToFind,
  projectTOCPages,
  removeStyle,
  setOverallFoundResult,
  setCurrentResultIndex,
  setCurrentResultId,
}: {
  editor: Quill;
  valueToFind: string;
  projectTOCPages: ProjectTOCPage[];
  removeStyle(): void;
  setOverallFoundResult(value: SearchTOCItem[]): void;
  setCurrentResultIndex(value: number): void;
  setCurrentResultId(value: string | null): void;
}) => {
  removeStyle();
  if (!valueToFind.length) {
    setOverallFoundResult([]);
    setCurrentResultIndex(0);
    setCurrentResultId(null);
    return;
  }

  let regexp = new RegExp(valueToFind, 'gi');
  let results: SearchTOCItem[] = [];
  projectTOCPages.forEach((page) => {
    const text = page.id === '0' ? ' ' + page.textContent : page.textContent;
    const indices = findIndices(regexp, text);
    results.push(
      ...findResultsWithinThePage({
        indices,
        pageText: text,
        valueToFind: valueToFind,
        pageId: page.id,
      })
    );
  });

  setOverallFoundResult(results);

  const currentPageResults = getCurrentPageResults({
    editor,
    valueToFind: valueToFind,
    results,
  });

  if (!currentPageResults.length) {
    setCurrentResultIndex(0);
    return;
  }

  const [first, ...rest] = currentPageResults;

  const newCurrentResultIndex = results.findIndex(
    (item) => item.id === first.id
  );
  if (newCurrentResultIndex === -1) return;

  editor.formatText(
    first.index,
    valueToFind.length,
    FoundTextTOCCurrentBlot.blotName,
    first.id
  );
  rest.forEach((item) => {
    editor.formatText(
      item.index,
      valueToFind.length,
      FoundTextTOCBlot.blotName,
      item.id
    );
  });
  scrollEditorHandler({ quillEditor: editor, id: first.id });

  setCurrentResultIndex(newCurrentResultIndex);
  setCurrentResultId(results[newCurrentResultIndex].id);
};
