import { FC, useCallback, MouseEvent } from 'react';

import { Stack } from 'modules/common/components/Stack/Stack';
import { CommentItemModel } from 'modules/common/models/Comment';
import { getTimeSince } from 'utils';
import { useActions } from 'modules/common/hooks/useActions';
import { ActionMenu } from 'modules/common/components/ActionMenu/ActionMenu';
import { Icon } from 'icons';
import { useUserId } from 'modules/common/hooks/useUserId';
import Styles from './tooltip.module.scss';

interface TooltipCommentItemProps {
  data: CommentItemModel;
  onDelete: (commentId: string) => void;
  onEdit: (commentItem: CommentItemModel) => void;
}

export const TooltipCommentItem: FC<TooltipCommentItemProps> = ({
  data,
  onEdit,
  onDelete,
}) => {
  const {
    actionMenuOpen,
    handleCloseActions,
    handleOpenActions,
    setActionMenuOpen,
    hovered,
    handleMouseEnter,
    handleMouseLeave,
  } = useActions();
  const userId = useUserId();

  const handleDelete = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onDelete(data.commentId);
      setActionMenuOpen(false);
    },
    [data.commentId, onDelete, setActionMenuOpen]
  );

  const handleEditMode = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setActionMenuOpen(false);
      onEdit(data);
    },
    [data, onEdit, setActionMenuOpen]
  );

  return (
    <div
      className={Styles['comment-item']}
      onMouseEnter={
        userId && userId === data.authorId ? handleMouseEnter : undefined
      }
      onMouseLeave={
        userId && userId === data.authorId ? handleMouseLeave : undefined
      }
    >
      <Stack spacing={2} className="m-b-1-5">
        <Stack.Item>
          <strong>{data.author}</strong>
        </Stack.Item>
        <Stack.Item>
          <span className={Styles['comment-date']}>
            {getTimeSince(data.dateAdded)}
          </span>
        </Stack.Item>
        {hovered && userId && userId === data.authorId && (
          <Stack.Item className="m-l-auto">
            <ActionMenu
              direction="bl"
              open={actionMenuOpen}
              width="xs"
              trigger={
                <Icon onClick={handleOpenActions} name="more_horiz" size={20} />
              }
              onClickOutside={handleCloseActions}
            >
              <ActionMenu.Item onClick={handleEditMode}>Edit</ActionMenu.Item>
              <ActionMenu.Item onClick={handleDelete}>Delete</ActionMenu.Item>
            </ActionMenu>
          </Stack.Item>
        )}
      </Stack>
      <p className={Styles['comment-text']}>{data.comment}</p>
    </div>
  );
};
