import { FC } from 'react';
import { flexRender, HeaderGroup } from '@tanstack/react-table';
import Styles from './table.module.scss';

interface Props {
  data: HeaderGroup<any>[];
}

const TableFooter: FC<Props> = ({ data }) => {
  return (
    <tfoot className={Styles['tfoot']}>
      {data.map((footerGroup) => (
        <tr key={footerGroup.id}>
          {footerGroup.headers.map((header) => (
            <th key={header.id}>
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.footer,
                    header.getContext()
                  )}
            </th>
          ))}
        </tr>
      ))}
    </tfoot>
  );
};

export default TableFooter;
