import { DEFAULT_CIRCLE_SIZE, DEFAULT_CIRCLE_WIDTH } from './lib';

export const Circle = ({
  colour,
  percentage = 100,
  circleSize = DEFAULT_CIRCLE_SIZE,
  strokeWidth = DEFAULT_CIRCLE_WIDTH,
}: {
  colour: string;
  percentage?: number;
  circleSize?: number;
  strokeWidth?: number;
}) => {
  const r = circleSize / 2 - strokeWidth / 2;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100; // where stroke will start, e.g. from 15% to 100%.
  return (
    <circle
      r={r}
      cx={circleSize / 2}
      cy={circleSize / 2}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ''} // remove colour as 0% sets full circumference
      strokeWidth={strokeWidth}
      strokeDasharray={circ}
      strokeDashoffset={percentage ? strokePct : 0}
    ></circle>
  );
};

export const Text = ({ percentage }: { percentage: number }) => (
  <text
    x="50%"
    y="50%"
    dominantBaseline="central"
    textAnchor="middle"
    fontSize={'18px'}
  >
    {percentage.toFixed(0)}%
  </text>
);
