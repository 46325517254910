import Styles from './helpcenter.module.scss';

const SaveDownloadView = () => {
  return (
    <>
      <p className={Styles['main-header']} id="save_download_view">
        Save and download audio
      </p>
      <p className={Styles['alert']}>
        Before you download a chapter or the whole book, make sure that you have
        saved the changes
      </p>
      <p className={Styles['text']}>To download a chapter or audio book:</p>
      <ol className={Styles['list']}>
        <li>
          At the upper right, click on <b>Save</b>.
        </li>
        <li>
          At the upper right, click on <b>Export</b>.
        </li>
        <li>
          Select one or several chapters. To download the whole book, select{' '}
          <b>All chapters</b>.
        </li>
        <li>
          Click on <b>Export audiobook</b>.{' '}
        </li>
      </ol>

      <p className={Styles['text']}>
        Also you may see a list of downloaded chapters. It contains the newest
        versions of audios: if you download an audio and then edit it, you may
        download the updated one:
      </p>

      <ol className={Styles['list']}>
        <li>
          At the upper right, click on <b>Save</b>.
        </li>
        <li>
          Go to the <b>Audio</b> tab
        </li>
        <li>Select one or several chapters.</li>
        <li>
          Click on <b>Download</b>.
        </li>
      </ol>
    </>
  );
};

export default SaveDownloadView;
