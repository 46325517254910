import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { Range } from 'react-quill';

import { PresetsModel } from 'modules/common/models/Presets';
import Styles from './preset.module.scss';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Icon } from 'icons';
import { Button } from 'modules/common/components/Button/Button';
import { useAppSelector } from 'modules/common/hooks/redux';
import { selectEntities } from 'redux/reducers/tags/selectors';
import useQuill from 'modules/common/hooks/useQuill';
import { PresetBlot } from '../Editor/Blots/PresetBlot';
import { AttributeTypes, TagModel } from 'modules/common/models/Tags';
import QuillUtil from 'utils/QuillUtil';
import { SelectionBlot } from '../Editor/Blots/SelectionBlot';
import { SelectValue } from 'modules/common/types';

interface PresetProps {
  preset: PresetsModel;
  onEdit?: (preset: PresetsModel, e: MouseEvent<HTMLButtonElement>) => void;
  onSelect?: (value: SelectValue) => void;
  isSelectable?: boolean;
}

export const Preset: FC<PresetProps> = ({
  preset,
  onEdit,
  onSelect,
  isSelectable = true,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const tagEntities = useAppSelector(selectEntities);
  const quillInstance = useQuill();
  const [disabled, setDisabled] = useState(false);
  const [applied, setApplied] = useState(false);

  const tags: TagModel[] = useMemo(() => {
    return preset.tags.map((t) => {
      const tag = { ...tagEntities[t.id] } as TagModel;
      tag.selectedAttrs = t.attributes
        .map((a) => {
          const attr = tag.attributes.find((attr) => attr.id === a.id);
          if (attr && attr.type === AttributeTypes.Enum) {
            return attr.values?.find((v) => v.value === a.value)!;
          }
          return {
            name: a.value,
            value: a.value,
          };
        })
        .filter((v) => v !== undefined);
      return tag;
    });
  }, [preset.tags, tagEntities]);

  const onDetach = useCallback(() => {
    const editor = quillInstance?.getEditor();
    if (editor) {
      const selection = editor.getSelection(true);
      editor.formatText(selection, PresetBlot.blotName, false, 'user');
    }
  }, [quillInstance]);

  const onApplyPreset = useCallback(() => {
    const quill = quillInstance!.getEditor();
    const scrollPosition = quill.root.scrollTop;
    const selection = quill.getSelection(true);

    if (selection && selection.length) {
      quill.formatText(selection, SelectionBlot.blotName, false);
      const presetToApply = { ...preset };
      presetToApply.tags = preset.tags.map((t) => {
        const tagStored = tagEntities[t.id];
        let tag = { ...t };
        tag.attributes = tag.attributes.map((a) => {
          let attr = { ...a };
          attr.name = tagStored?.attributes.find((a) => a.id === attr.id)?.name;
          return attr;
        });

        tag.name = tagStored?.name;
        return tag;
      });
      quill.formatText(
        selection,
        PresetBlot.blotName,
        JSON.stringify(presetToApply),
        'user'
      );
      setApplied(true);
    }
    quill.root.scrollTo({
      top: scrollPosition,
      behavior: 'auto'
    });
  }, [preset, quillInstance, tagEntities]);

  useEffect(() => {
    const editor = quillInstance?.getEditor();
    let mounted = true;
    if (editor) {
      const selectionChangeHandlerForApply = (
        newRange: Range,
        prevRange?: Range
      ) => {
        const range = newRange || prevRange;
        if (range) {
          setTimeout(() => {
            if (!mounted) {
              return;
            }
            const formats = editor.getFormat(range!);
            const presetFormat = formats['preset'];
            if (presetFormat) {
              const presetParsed = JSON.parse(presetFormat);
              setApplied(presetParsed.id === preset.id);
            } else {
              setApplied(false);
            }
          });
        } else {
          setApplied(false);
        }
      };

      const selectionChangeHandlerForDisabled = async (range: Range) => {
        if (range) {
          setTimeout(() => {
            if (!mounted) {
              return;
            }
            let disabled = false;
            if (
              QuillUtil.getRBChildren(editor, range).some((p) => {
                return p.domNode.dataset.id !== preset.id;
              })
            ) {
              disabled = true;
            }
            setDisabled(disabled);
          });
        }
      };
      editor.on('selection-change', selectionChangeHandlerForApply);
      editor.on('selection-change', selectionChangeHandlerForDisabled);
      const range = editor?.getSelection();
      selectionChangeHandlerForDisabled(range);
      selectionChangeHandlerForApply(range);
      return () => {
        mounted = false;
        editor.off('selection-change', selectionChangeHandlerForApply);
        editor.off('selection-change', selectionChangeHandlerForDisabled);
      };
    }
  }, [preset.id, quillInstance]);

  const handleClick = () => {
    if (onSelect) {
      onSelect({
        label: preset.name,
        value: preset.id,
      });
      return;
    }
    setOpen((prv) => !prv);
  };

  const handleClickOnArrow = (e: MouseEvent) => {
    e.stopPropagation();
    if (!isSelectable) {
      return;
    }
    if (onSelect) {
      setOpen((prv) => !prv);
    }
  };
  return (
    <div
      className={classNames(Styles['preset'], { [Styles.disabled]: disabled })}
      onClick={handleClick}
    >
      <Stack
        justifyContent="space-between"
        alignItems="center"
        className={classNames(Styles['label'], open && Styles['open'])}
      >
        <Stack.Item className={Styles['label-name']}>
          {applied && <Icon name="done" size={16} className="m-r-1" />}{' '}
          {preset.name}
        </Stack.Item>

        <Stack.Item className="font-size-0">
          {tags.map((tag) => (
            <Icon
              style={{ backgroundColor: tag.iconBgColor }}
              color={tag.iconColor}
              size={16}
              name={tag.iconName}
              className={Styles['tag-icon']}
              key={tag.id}
            />
          ))}
          <Icon
            name={open ? 'arrow_drop_up' : 'arrow_drop_down'}
            className="m-l-1"
            size={16}
            onClick={handleClickOnArrow}
          />
        </Stack.Item>
      </Stack>
      {open && (
        <div className={Styles['open-container']}>
          <div className={Styles['tags-description']}>
            {tags.map((tag) => {
              return (
                <Stack
                  alignItems="center"
                  className={Styles['tag-description']}
                  key={tag.id}
                >
                  <Stack.Item>
                    <Icon
                      style={{ backgroundColor: tag.iconBgColor }}
                      color={tag.iconColor}
                      size={16}
                      name={tag.iconName}
                      className={Styles['tag-icon']}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <p className="font-size-xs fw-600 m-l-1">
                      {tag.displayName}
                    </p>
                  </Stack.Item>
                  <Stack.Item fill>
                    <Stack justifyContent="flex-end" spacing={1}>
                      {tag.selectedAttrs?.map((a) => (
                        <Stack.Item key={a.value}>
                          <span className="fw-600 font-size-xs">{a.name}</span>
                        </Stack.Item>
                      ))}
                    </Stack>
                  </Stack.Item>
                </Stack>
              );
            })}
          </div>
          <Stack
            justifyContent="space-between"
            className={Styles['btn-actions']}
          >
            <Button small secondary onClick={(e) => onEdit?.(preset, e)}>
              Edit
            </Button>
            {applied ? (
              <Button secondary small onClick={onDetach}>
                Detach
              </Button>
            ) : (
              <Button small onClick={onApplyPreset}>
                Apply
              </Button>
            )}
          </Stack>
        </div>
      )}
    </div>
  );
};
