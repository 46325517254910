import { RootState } from 'redux/store';
import {
  addBookmark,
  BookmarksAdapter,
  editBookmark,
  fetchBookmarks,
} from 'redux/reducers/bookmarks/bookmarksSlice';
import { createSelector } from '@reduxjs/toolkit';
import { getFetchingSelector } from 'redux/reducers/loader/selectors';
import { AsyncRequestStatus } from 'redux/interfaces/loader';

export const { selectAll, selectById, selectEntities } =
  BookmarksAdapter.getSelectors<RootState>(({ bookmarks }) => bookmarks);

export const bookMarksIsFetchingSelector = createSelector(
  getFetchingSelector(fetchBookmarks.typePrefix),
  (status) => status === AsyncRequestStatus.pending
);

export const bookmarksFetchedSelector = createSelector(
  getFetchingSelector(fetchBookmarks.typePrefix),
  (status) => status === AsyncRequestStatus.fulfilled
);

export const bookMarkIsAddingSelector = createSelector(
  getFetchingSelector(addBookmark.typePrefix),
  (status) => status === AsyncRequestStatus.pending
);

export const bookMarkIsEditingSelector = createSelector(
  getFetchingSelector(editBookmark.typePrefix),
  (status) => status === AsyncRequestStatus.pending
);
