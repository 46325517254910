import React, { FC, useCallback } from 'react';
import { Stack } from 'modules/common/components/Stack/Stack';
import Styles from './find-and-apply.module.scss';
import { Checkbox } from 'modules/common/components/Checkbox/Checkbox';
import { FindResultItemModel } from 'modules/common/models/FindResult';
import classNames from "classnames";

interface FindResultItemProps {
  resultItem: FindResultItemModel;
  selected: boolean;
  onSelect: (index: number, checked: boolean) => void;
  showDisabledResults:boolean
}

const FindResultItem: FC<FindResultItemProps> = ({
  resultItem,
  selected,
  onSelect, showDisabledResults
}) => {
  const handleChange = useCallback(
    (checked) => {
      onSelect(resultItem.index, checked);
    },
    [onSelect, resultItem.index]
  );

  return (
    <Stack
      className={classNames(Styles['result-item'])}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack.Item>
        <span dangerouslySetInnerHTML={{ __html: resultItem.text }} />
      </Stack.Item>
      <Stack.Item>
        <>
          {showDisabledResults ? "" :
        <Checkbox onChange={handleChange} checked={selected} />
        }
        </>
      </Stack.Item>
    </Stack>
  );
};

export default FindResultItem;
