import {
  useChangeNotificationStatusMutation,
  useGetNotificationQuery,
} from 'redux/api/app.api';
import { useCallback, useEffect, useState } from 'react';
import {
  Notification,
  SocketEventType,
} from 'modules/common/models/Notifications';
import { error } from 'modules/common/components/Notify';
import { activeProjectSelectorId } from 'redux/reducers/project/selectors';
import { useAppSelector } from 'modules/common/hooks/redux';
import { useWebSocket } from './useWebSocket';

export function useNotification() {
  const [page, setPage] = useState(5);
  const [hasMore, setHasMore] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);
  const [animate, setAnimate] = useState(false);
  const projectId = useAppSelector(activeProjectSelectorId);
  const { data, isFetching } = useGetNotificationQuery(
    { index: page, projectId },
    {
      skip: !hasMore,
    }
  );
  const [changeNotificationStatus] = useChangeNotificationStatusMutation();

  const [notifications, setNotifications] = useState<Notification<any>[]>([]);

  useEffect(() => {
    if (data?.data.length) {
      setNotifications((prev) => {
        let arr = [...data.data, ...prev];

        arr.sort(function (one, another) {
          return (
            new Date(another.createdAt).getTime() -
            new Date(one.createdAt).getTime()
          );
        });

        let count = arr.filter((item) => !item.isRead);

        setUnreadCount(count.length);

        return arr;
      });
    }

    if (data?.totalCount) {
      setHasMore(data.totalCount > page);
    }
  }, [data, page]);

  useWebSocket(SocketEventType.All, function (socketInfo) {
    // both will be batched no need to worry
    if (!socketInfo.value.isRead) {
      setUnreadCount((prev) => ++prev);
    }
    setNotifications([socketInfo.value, ...notifications]);

    setAnimate(true);
    setTimeout(function () {
      setAnimate(false);
    }, 5000);
  });

  useWebSocket(SocketEventType.Error, function () {
    // refresh the page
    error({
      children:
        'Something is wrong with the notification connection refresh the page',
    });
  });

  const setNextPage = useCallback(() => {
    if (hasMore) {
      setPage((prev) => prev + 5);
    }
  }, [hasMore]);

  const readANotification = useCallback(
    async (id: string) => {
      await changeNotificationStatus({ id, isRead: true });
      setUnreadCount((prev) => --prev);
    },
    [changeNotificationStatus]
  );

  return {
    notifications,
    isFetching,
    setNextPage,
    hasMore,
    unreadCount,
    readANotification,
    animate,
  };
}
