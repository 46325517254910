import { FC, useCallback, useEffect, useState } from 'react';
import { DeltaStatic, Sources } from 'quill';

import { Icon } from 'icons';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Tab } from 'modules/common/components/Tab/Tab';
import { TabGroup } from 'modules/common/components/Tab/TabGroup';
import useQuill from 'modules/common/hooks/useQuill';
import { EditorSave } from 'utils/EditorSave';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import {
  activeChapterIdSelector,
  isCurrentChapterDirtySelector,
} from 'redux/reducers/chapters/selectors';
import { setChapterDirty } from 'redux/reducers/chapters/chaptersSlice';
import { useScreenSize } from 'modules/common/hooks/useScreenSize';
import { putClickOnTags } from 'utils';
import { useIsAdminRole } from 'modules/common/hooks/useIsAdminRole';
import { CommentBlot } from '../Editor/Blots/CommentBlot';
import { Button } from 'modules/common/components/Button/Button';
import HelpCenter from 'modules/edit/Helpcenter/HelpCenter';
import { useInitializeUndoRedoCanvas } from 'modules/common/hooks/useInitializeUndoRedoCanvas';
import { initialiseAnimation, invokeActionOnScrollEnd } from './util';
import { UNDO_REDO_ACTION_DELAY } from './config';
import { isInTheViewport } from 'utils';
import Styles from './styles.module.scss';

export enum EditorViews {
  Text = 0,
  SSML,
}

interface ActionsProps {
  editorView: EditorViews;
  onEditorViewChange: (editorView: EditorViews) => void;
}

export const Actions: FC<ActionsProps> = ({
  editorView,
  onEditorViewChange,
}) => {
  const quillInstance = useQuill();
  const canvasRef = useInitializeUndoRedoCanvas({ quillInstance });
  const dispatch = useAppDispatch();
  const isCurrentChapterDirty = useAppSelector(isCurrentChapterDirtySelector);
  const currentChapterId = useAppSelector(activeChapterIdSelector);
  const editorSaveInstance = EditorSave.getInstance();
  const { isTabletOrMobile } = useScreenSize();
  const isAdminRole = useIsAdminRole();
  const [isHelpCenterOpen, setHelpCenter] = useState(false);

  const [undoDisabled, setUndoDisabled] = useState(true);
  const [redoDisabled, setRedoDisabled] = useState(true);

  const handleUndo = useCallback(() => {
    if (!undoDisabled) {
      const quill = quillInstance!.getEditor();
      if (
        quill.history.stack.undo
          .at(-1)
          ?.redo.ops?.some(
            (o) =>
              o.attributes?.['bookmark-icon'] ||
              o.attributes?.[CommentBlot.blotName]
          )
      ) {
        const item = quill.history.stack.undo.pop()!;
        quill.history.stack.redo.push(item);
        handleUndo();
      } else {
        const item = quill.history.stack.undo.slice(-1);
        const index = item[0].redo.ops?.[0].retain || 0;
        const length = item[0].redo.ops?.[1].retain || 0;
        const { top, bottom, left, width, height } = quill.getBounds(
          index,
          length
        );
        const { offsetHeight, scrollTop } = quill.root;
        // If an element not in the viewport, scroll to it first.
        // Otherwise, do an action itself.
        if (!isInTheViewport({ top, bottom, offsetHeight })) {
          quill.root.scrollBy({
            top: top < 0 || scrollTop < top || top > bottom ? top : bottom,
          });
          invokeActionOnScrollEnd(quill.root, () => {
            // The animation goes first and with delay equal to UNDO_REDO_ACTION_DELAY the undo action calls.
            initialiseAnimation(
              quill,
              canvasRef,
              scrollTop + top,
              left,
              width,
              height
            );
            setTimeout(() => {
              quill.history.undo();
            }, UNDO_REDO_ACTION_DELAY);
          });
        } else {
          setTimeout(() => {
            // The resetting of marginTop is necessary to align the top point without any deviation
            // that might occur during scrolling.
            if (canvasRef.current) canvasRef.current.style.marginTop = '-1px';
            // The animation goes first(!) and with delay equal to UNDO_REDO_ACTION_DELAY the undo action calls.
            initialiseAnimation(quill, canvasRef, top, left, width, height);
            setTimeout(() => {
              quill.history.undo();
            }, UNDO_REDO_ACTION_DELAY);
            // quill.history.undo();
          }, UNDO_REDO_ACTION_DELAY);
        }
      }
      if (quill.hasFocus()) {
        quill.blur();
      } else {
        quill.focus();
      }
    }
  }, [quillInstance, undoDisabled, canvasRef]);

  const handleRedo = useCallback(() => {
    if (!redoDisabled) {
      const quill = quillInstance!.getEditor();
      if (
        quill.history.stack.redo
          .at(-1)
          ?.redo.ops?.some(
            (o) =>
              o.attributes?.['bookmark-icon'] ||
              o.attributes?.[CommentBlot.blotName]
          )
      ) {
        const item = quill.history.stack.redo.pop()!;
        quill.history.stack.undo.push(item);
        handleRedo();
      } else {
        const item = quill.history.stack.redo.slice(-1);
        const index = item[0].undo.ops?.[0].retain || 0;
        const length = item[0].undo.ops?.[1].retain || 0;
        const { top, bottom, left, width, height } = quill.getBounds(
          index,
          length
        );
        const { offsetHeight, scrollTop } = quill.root;
        // If an element not in the viewport, scroll to it first.
        // Otherwise, do an action itself.
        if (!isInTheViewport({ top, bottom, offsetHeight })) {
          quill.root.scrollBy({
            top: top < 0 || scrollTop < top || top > bottom ? top : bottom,
          });
          // The animation goes first and with delay equal to UNDO_REDO_ACTION_DELAY the redo action calls.
          invokeActionOnScrollEnd(quill.root, () => {
            initialiseAnimation(
              quill,
              canvasRef,
              scrollTop + top,
              left,
              width,
              height
            );
            setTimeout(() => {
              quill.history.redo();
            }, UNDO_REDO_ACTION_DELAY);
            // quill.history.redo();
          });
        } else {
          setTimeout(() => {
            // The resetting of marginTop is necessary to align the top point without any deviation
            // that might occur during scrolling.
            if (canvasRef.current) canvasRef.current.style.marginTop = '-1px';
            // The animation goes first and with delay equal to UNDO_REDO_ACTION_DELAY the redo action calls.
            initialiseAnimation(quill, canvasRef, top, left, width, height);
            setTimeout(() => {
              quill.history.redo();
            }, UNDO_REDO_ACTION_DELAY);
            // quill.history.redo();
          }, UNDO_REDO_ACTION_DELAY);
        }
      }

      if (quill.hasFocus()) {
        quill.blur();
      } else {
        quill.focus();
      }
    }
  }, [quillInstance, redoDisabled, canvasRef]);

  useEffect(() => {
    const editor = quillInstance?.getEditor();
    if (editor) {
      const textChangeHandler = (
        delta: DeltaStatic,
        oldDelta: DeltaStatic,
        source: Sources
      ) => {
        // second is not change the text before doing anything thus unnecessary requests
        if (source !== 'api' && editor.history.isStackHistoryLoaded()) {
          editorSaveInstance.saveText(JSON.stringify(editor.history.stack));
          putClickOnTags(editor);
          if (!isCurrentChapterDirty && currentChapterId) {
            dispatch(setChapterDirty(currentChapterId));
          }
        }

        const undoLength = editor.history.stack.undo.filter((u) => {
          return !u.redo.ops?.some(
            (o) =>
              o.attributes?.['bookmark-icon'] ||
              o.attributes?.[CommentBlot.blotName]
          );
        }).length;

        const redoLength = editor.history.stack.redo.filter((u) => {
          return !u.undo.ops?.some(
            (o) =>
              o.attributes?.['bookmark-icon'] ||
              o.attributes?.[CommentBlot.blotName]
          );
        }).length;

        setRedoDisabled(!redoLength);
        setUndoDisabled(!undoLength);
      };

      editor?.on('text-change', textChangeHandler);
      return () => {
        editor?.off('text-change', textChangeHandler);
      };
    }
  }, [
    currentChapterId,
    quillInstance,
    editorSaveInstance,
    dispatch,
    isCurrentChapterDirty,
  ]);

  return isTabletOrMobile ? null : (
    <div className="container">
      <Stack justifyContent="space-between" alignItems="center">
        <Stack.Item>
          {!isAdminRole && (
            <Stack spacing={2}>
              <Stack.Item>
                <Icon
                  hoverable
                  disabled={undoDisabled}
                  onClick={handleUndo}
                  size={20}
                  name="undo"
                />
              </Stack.Item>
              <Stack.Item>
                <Icon
                  hoverable
                  onClick={handleRedo}
                  size={20}
                  name="redo"
                  disabled={redoDisabled}
                />
              </Stack.Item>
            </Stack>
          )}
        </Stack.Item>
        <Stack.Item>
          <Stack>
            <Button
              secondary
              small
              fill="subtle"
              onClick={() => {
                setHelpCenter((prev) => !prev);
              }}
              className="m-r-2"
            >
              <Stack alignItems="center">
                <Icon className="m-r-1" name="help" size="20px" /> Help Center
              </Stack>
            </Button>
            <TabGroup>
              <Tab
                className={Styles.ActionsMenuTab}
                onClick={() => onEditorViewChange(EditorViews.Text)}
                active={editorView === EditorViews.Text}
              >
                Visual
              </Tab>
              <Tab
                className={Styles.ActionsMenuTab}
                onClick={() => onEditorViewChange(EditorViews.SSML)}
                active={editorView === EditorViews.SSML}
              >
                SSML
              </Tab>
            </TabGroup>
          </Stack>
        </Stack.Item>
      </Stack>
      {isHelpCenterOpen && <HelpCenter setHelpCenter={setHelpCenter} />}
    </div>
  );
};
