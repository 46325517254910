import axiosInstance from 'utils/axios';
import { convertArrayToStrWithCommas } from 'utils';

export type SaveCacheResponse = {
  value: string;
  createdAt: string;
  chapterId: string;
  projectId: string;
};

interface IGetCache {
  chapterId: string;
  projectId: string;
}

interface ISaveChapterApi {
  get(props: IGetCache): Promise<SaveCacheResponse>;
  getAll(projectId: string): Promise<SaveCacheResponse[]>;
  set(projectId: string, chapterId: string, text: string): Promise<any>;
  delete(projectId: string, chapterId: string): Promise<{}>;
  deleteAll(projectId: string): Promise<{}>;
  deleteMany(projectId: string, chapterIds: string[]): Promise<{}>;
}

class SaveCacheApi implements ISaveChapterApi {
  static actionCache = '/editor_changes';

  async get({
    chapterId,
    projectId,
  }: {
    chapterId: string;
    projectId: string;
  }): Promise<SaveCacheResponse> {
    const res = await axiosInstance.get(SaveCacheApi.actionCache, {
      params: {
        chapterId,
        projectId,
      },
    });
    return res.data;
  }

  async getAll(projectId: string): Promise<SaveCacheResponse[]> {
    const res = await axiosInstance.get(SaveCacheApi.actionCache, {
      params: {
        projectId,
      },
    });
    return res.data;
  }

  async set(projectId: string, chapterId: string, value: string): Promise<{}> {
    const res = await axiosInstance.post(SaveCacheApi.actionCache, {
      value,
      chapterId,
      projectId,
    });
    return res.data;
  }

  async delete(projectId: string, chapterId: string): Promise<{}> {
    const res = await axiosInstance.delete(SaveCacheApi.actionCache, {
      params: {
        projectId,
        chapterId,
      },
    });
    return res.data;
  }

  async deleteAll(projectId: string): Promise<{}> {
    const res = await axiosInstance.delete(SaveCacheApi.actionCache, {
      params: {
        projectId,
      },
    });
    return res.data;
  }

  async deleteMany(projectId: string, chapterIdsArr: string[]): Promise<{}> {
    const chapterId = convertArrayToStrWithCommas(chapterIdsArr);

    const res = await axiosInstance.delete(SaveCacheApi.actionCache, {
      params: {
        projectId,
        chapterId,
      },
    });
    return res.data;
  }
}

const SaveCacheApiInstance = new SaveCacheApi();

export default SaveCacheApiInstance;
