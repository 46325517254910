import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Stack } from 'modules/common/components/Stack/Stack';
import useEventListener from 'modules/common/hooks/useEventListener';
import { useScreenSize } from 'modules/common/hooks/useScreenSize';
import useStopwatch from 'modules/common/hooks/audioPlayer/useStopwatch';
import AudioPlayerVolume from './AudioPlayerVolume';
import AudioControls from './AudioControls';
import Styles from './audioplayer.module.scss';
import { AudioPlayerProjectProps } from 'modules/common/hooks/useAudioPlayerProject';
import { AudioSlider } from './AudioSlider';

export const AudioPlayerProject: FC<AudioPlayerProjectProps> = ({
  isPlaying,
  shouldTogglePlayPause,
  setShouldTogglePlayPause,
  isLoading,
  playingText,
  audioData,
  setIsPlaying,
  voiceIdRef,
}) => {
  const { getTime, handleStart, handleReset, handlePauseResume } =
    useStopwatch();

  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const audioPlayerContainerRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useScreenSize();

  // Refs
  const audioPlayer = useRef<HTMLAudioElement>(new Audio());
  const progressBar = useRef<HTMLInputElement>(null); // reference our progress bar
  const animationRef = useRef<number>(); // reference the animation

  const changePlayerCurrentTime = useCallback(() => {
    if (!progressBar.current) {
      return;
    }
    let value = parseFloat(progressBar.current.value);
    progressBar.current.style.setProperty(
      '--seek-before-width',
      `${(value / parseFloat(progressBar.current.max)) * 100}%`
    );
    setCurrentTime(value);
  }, [progressBar]);

  const whilePlaying = useCallback(async () => {
    if (!progressBar.current) {
      return;
    }

    progressBar.current.value = audioPlayer.current.currentTime.toString();
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  }, [changePlayerCurrentTime]);

  const togglePlayPause = useCallback(async () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      if (!getTime()) {
        handleStart();
      } else {
        handlePauseResume();
      }
      await audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    }
  }, [
    getTime,
    handlePauseResume,
    handleStart,
    isPlaying,
    setIsPlaying,
    whilePlaying,
  ]);

  const changeRange = useCallback(() => {
    audioPlayer.current.currentTime = parseFloat(
      (progressBar.current as HTMLInputElement).value
    );
    changePlayerCurrentTime();
  }, [changePlayerCurrentTime, audioPlayer]);

  const resetProgress = useCallback(() => {
    setIsPlaying(false);
    audioPlayer.current.pause();
    (progressBar.current as HTMLInputElement).value = '0';
    changeRange();
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
  }, [changeRange, setIsPlaying]);

  // Events
  useEventListener(
    'loadeddata',
    function () {
      const seconds = Math.floor(audioPlayer.current.duration);
      setDuration(seconds);
      if (progressBar.current) {
        progressBar.current.max = seconds.toString();
      }
    },
    audioPlayer
  );

  useEventListener('ended', resetProgress, audioPlayer);

  // support for pause and play events outside of the component (e.g. media keys)
  useEventListener(
    'pause',
    () => {
      setIsPlaying(false);
      handlePauseResume();
    },
    audioPlayer
  );
  useEventListener(
    'play',
    () => {
      setIsPlaying(true);
      handlePauseResume();
    },
    audioPlayer
  );

  useEffect(() => {
    if (shouldTogglePlayPause) {
      togglePlayPause();
      setShouldTogglePlayPause(false);
    }
  }, [
    isPlaying,
    setShouldTogglePlayPause,
    shouldTogglePlayPause,
    togglePlayPause,
  ]);

  useEffect(() => {
    audioPlayer.current.src = `data:audio/mp3;base64, ${audioData}`;
    handleReset();
  }, [audioData, handleReset]);

  const isControlsDisabled = isLoading || !audioData;

  return (
    <div className={Styles.audioplayer} ref={audioPlayerContainerRef}>
      <Stack
        direction={isMobile ? 'row-reverse' : 'row'}
        alignItems="center"
        spacing={3}
      >
        <Stack.Item shrink={0}>
          <AudioControls
            isPlaying={isPlaying}
            onPlayPauseClick={() => togglePlayPause()}
            isDisabled={isControlsDisabled}
            isLoading={isLoading}
          />
        </Stack.Item>
        <Stack.Item fill>
          <AudioSlider
            text={playingText ? `${voiceIdRef.current} - ${playingText}` : ''}
            currentTime={currentTime}
            duration={duration}
            changeRange={changeRange}
            progressBarRef={progressBar}
          />
        </Stack.Item>
        {!isMobile && (
          <Stack.Item shrink={0} flex>
            <AudioPlayerVolume audioPlayerRef={audioPlayer} />
          </Stack.Item>
        )}
      </Stack>
    </div>
  );
};
