import {
  VOICE_LABELS_TO_DISPLAY,
  VoiceLabelsToDisplayKey,
} from 'modules/common/types';

const VoiceLabelsTypesToDisplayCorrectOrder = Object.keys(
  VOICE_LABELS_TO_DISPLAY
);

export const filterAndSortLabelTypes = (labelsTypes: string[]) =>
  labelsTypes
    .filter((type) => VOICE_LABELS_TO_DISPLAY[type as VoiceLabelsToDisplayKey])
    .sort(
      (a, b) =>
        VoiceLabelsTypesToDisplayCorrectOrder.indexOf(a) -
        VoiceLabelsTypesToDisplayCorrectOrder.indexOf(b)
    );
