import React from 'react';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import { LabelValue, ParsedVoiceValue } from 'modules/common/types';
import { AudioPlayerProjectProps } from 'modules/common/hooks/useAudioPlayerProject';
import Styles from './voiceselect.module.scss';
import { OptionVoiceControls } from './OptionVoiceControls';
import { OptionLabelTypes } from './OptionLabelTypes';

interface VoiceSelectOptionLabelProps {
  playerProps?: AudioPlayerProjectProps;
  previewText?: string;
  data: ParsedVoiceValue;
  formatOptionLabelMeta: FormatOptionLabelMeta<LabelValue>;
}

export const VoiceSelectOptionLabel: React.FC<VoiceSelectOptionLabelProps> = ({
  playerProps,
  previewText,
  data,
  formatOptionLabelMeta,
}) => {
  if (formatOptionLabelMeta.context !== 'menu') {
    return <div>{data.label}</div>;
  }

  return (
    <div className={Styles['Container']}>
      {playerProps && !!previewText && !!data.voiceLabels && (
        <OptionVoiceControls
          playerProps={playerProps}
          previewText={previewText}
          voiceId={data.value}
        />
      )}
      <div className={Styles['Text-container']}>
        <div>{data.label}</div>
        <OptionLabelTypes voiceLabels={data.voiceLabels} />
      </div>
    </div>
  );
};
