enum OS_NAMES {
  WINDOWS = 'Windows',
  MAC_OS = 'MacOS',
  UNIX = 'UNIX',
  LINUX = 'Linux',
}

export const getOSName = () => {
  let OSName = 'Unknown OS';
  const { userAgent } = navigator;
  if (userAgent.indexOf('Win') !== -1) OSName = OS_NAMES.WINDOWS;
  if (userAgent.indexOf('Mac') !== -1) OSName = OS_NAMES.MAC_OS;
  if (userAgent.indexOf('X11') !== -1) OSName = OS_NAMES.UNIX;
  if (userAgent.indexOf('Linux') !== -1) OSName = OS_NAMES.LINUX;

  return OSName;
};

export const isMacOSRunning = () => getOSName() === OS_NAMES.MAC_OS;
