import { Quill } from 'react-quill';

const Inline = Quill.import('blots/inline');

export class RBBlot extends Inline {
  formatAt(index: number, length: number, name: string, value: any): void {
    // when removing tag inside a sentence tag
    if (this.parent.statics.blotName === 'sentence' && !value) {
      this.unwrap();
      this.parent.children.forEach(function (child: any) {
        if (child.statics.blotName === 'text') {
          // merge text blots together
          child.optimize();
        }
      });
      return;
    }

    super.formatAt(index, length, name, value);
  }
}
