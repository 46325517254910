import { Children, ComponentType, ReactElement, ReactNode } from 'react';
import { isFragment } from 'react-is';

import { wrapWithComponent } from './wrap-with-component';

export const wrapChildren = (
  element: ReactNode,
  Component: ComponentType,
  props?: any
) => {
  const childArray = isFragment(element) ? element.props.chidlren : element;

  return Children.map(childArray, (child: ReactElement) => {
    if (child === null) return;
    return wrapWithComponent(child, Component, props);
  });
};
