import Quill, { RangeStatic } from 'quill';
import ReactQuill from 'react-quill';
import { selectTagsApplyAll } from 'redux/reducers/tags/selectors';
import { isMacOSRunning } from 'utils';
import { useHandleCheckboxChange } from 'modules/edit/Tags/lib';
import { useAppSelector } from './redux';
import useEventListener from './useEventListener';

export function useAddPauseHotKeyListener({
  quillInstance,
}: {
  quillInstance: ReactQuill | null;
}) {
  const editor = quillInstance?.getEditor();
  const range = editor?.getSelection();
  const tagsApply = useAppSelector(selectTagsApplyAll);

  const handleCheckboxChange = useHandleCheckboxChange({});

  const pauseTagHotkeyHandler = ({
    quillEditor,
    selectedRange,
  }: {
    quillEditor: Quill;
    selectedRange: RangeStatic;
  }) => {
    const pauseTag = tagsApply.find(
      (tag) => tag.name === 'break' || tag.name === 'customPause'
    );

    if (!pauseTag) return;

    let format = quillEditor.getFormat(selectedRange);

    // filter out sentence tags from the format
    format = Object.fromEntries(
      Object.entries(format).filter(([key]) => key !== 'sentence')
    );

    // Check if the pause tag already exists within the current range.
    const isAnyTagWithinSelection = Object.keys(format).length !== 0;
    const isOtherThanPauseTagExistsWithinSelection = !isAnyTagWithinSelection
      ? false
      : JSON.parse(Object.values(format)?.[0])?.['name'] !== 'break' &&
        JSON.parse(Object.values(format)?.[0])?.['name'] !== 'customPause';
    if (isOtherThanPauseTagExistsWithinSelection) return;

    handleCheckboxChange(pauseTag, !isAnyTagWithinSelection);

    // After adding or removing a pause tag,
    // change the selected range to add the correct checkbox state to the right-placed panel.
    quillEditor.setSelection({
      ...selectedRange,
      index: selectedRange.index + (!isAnyTagWithinSelection ? 1 : -2),
    });
  };

  const onKeydown = (event: globalThis.KeyboardEvent) => {
    const isMacOS = isMacOSRunning();
    const isCmdP =
      (isMacOS ? event.metaKey : event.ctrlKey) && event.code === 'KeyP';
    if (isCmdP && editor?.hasFocus() && range) {
      event.preventDefault();
      pauseTagHotkeyHandler({ quillEditor: editor, selectedRange: range });
    }
  };

  useEventListener('keydown', onKeydown);
}
