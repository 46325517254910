import React from 'react';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Icon } from 'icons';
import Loader from 'modules/common/components/Loader/Loader';
import { Card } from 'modules/common/components/Card/Card';
import Styles from './project-view.module.scss';

interface Props<T extends { name: string }> {
  viewProjects: boolean;
  setViewProjects: React.Dispatch<React.SetStateAction<boolean>>;
  data?: T[];
  isFetching: boolean;
  render: (T: T, isFetching?: boolean) => React.ReactNode;
  header: string;
}

function ProjectsView<T extends { name: string }>({
  data,
  isFetching,
  viewProjects,
  setViewProjects,
  render,
  header,
}: Props<T>) {
  const dataToShow = React.useMemo(() => {
    if (!data) {
      return [];
    }

    return viewProjects ? data : data.slice(0, 3);
  }, [data, viewProjects]);

  const handleViewAll = () => {
    setViewProjects(true);
  };

  return (
    <div className="m-b-7">
      <Stack spacing={2}>
        {viewProjects && (
          <Stack.Item>
            <Icon
              hoverable
              size={20}
              name="arrow_backward"
              onClick={() => setViewProjects(false)}
            />
          </Stack.Item>
        )}
        <Stack.Item>
          <h3 className="m-b-2 font-size-lg">
            {data?.length} {header}
          </h3>
        </Stack.Item>
      </Stack>

      {isFetching && (
        <Loader
          loadingPartColor="#031E50"
          loadingBackSet="#ffffff"
          size="40px"
          loaderWidth="3px"
        />
      )}
      {!!dataToShow.length && (
        <Stack spacing={3} wrap="wrap">
          {dataToShow.map((project, index) => (
            <Stack.Item className="m-b-3" key={project.name + index}>
              {render(project, isFetching)}
            </Stack.Item>
          ))}
          {!viewProjects && dataToShow.length !== data?.length && (
            <Stack.Item grow={1} className="m-b-3">
              <Card
                hoverable
                light
                className={Styles.card}
                onClick={handleViewAll}
              >
                <Stack
                  className="h-100"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Stack.Item>
                    View {data!.length - dataToShow.length} more requests
                  </Stack.Item>
                </Stack>
              </Card>
            </Stack.Item>
          )}
        </Stack>
      )}
    </div>
  );
}

export default ProjectsView;
