import { FC } from 'react';

const ElevenLabsIcon: FC = () => {
  return (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4769_24047)">
          <path d="M0 12.1323C0.634361 12.1273 1.26872 12.1323 1.90308 12.124C1.89482 20.0577 1.9196 27.9898 1.89152 35.9235C1.7924 35.9036 1.59416 35.8673 1.49504 35.8491C1.00441 35.8855 0.512115 35.9053 0.0198242 35.902C-0.0148674 27.9798 0.0247797 20.0561 0 12.1323Z" fill="#010101"/>
          <path d="M3.74341 12.1256C4.38438 12.1323 5.02369 12.1306 5.66466 12.1256C5.65971 20.0461 5.66962 27.9666 5.65971 35.8871C5.02204 35.8425 4.38273 35.8574 3.74506 35.8623C3.74341 27.9501 3.74506 20.0379 3.74341 12.1256Z" fill="#010101"/>
          <path d="M7.53467 12.1323C9.98621 12.1389 12.4394 12.129 14.8909 12.1389C14.8893 12.6894 14.8893 13.2416 14.8909 13.7937C13.0804 13.797 11.2681 13.797 9.45758 13.7937C9.46253 14.6088 9.45758 15.4254 9.46253 16.2405C10.6668 16.2388 11.8711 16.2355 13.0754 16.2438C13.0738 16.7959 13.0738 17.3465 13.0738 17.8986C11.8695 17.8986 10.6652 17.9052 9.46088 17.8937C9.45757 18.798 9.46253 19.7023 9.45592 20.6066C11.3243 20.6016 13.1911 20.6016 15.0594 20.6066C15.0561 21.1571 15.0561 21.7076 15.0594 22.2581C12.5517 22.2697 10.0424 22.2581 7.53467 22.2647C7.53632 18.8872 7.53632 15.5098 7.53467 12.1323Z" fill="#010101"/>
          <path d="M16.1152 12C16.7579 12.0017 17.4021 12 18.0447 12.0017C18.0414 15.4205 18.0414 18.8376 18.0447 22.2565C17.4021 22.2598 16.7579 22.2581 16.1152 22.2598C16.1185 18.8393 16.1202 15.4205 16.1152 12Z" fill="#010101"/>
          <path d="M21.1537 15.2386C22.5347 14.7162 24.3205 14.7807 25.3894 15.9148C26.2517 16.8026 26.3838 18.1135 26.3392 19.2923C24.5287 19.2923 22.7181 19.284 20.9075 19.2972C21.0529 19.7403 21.1801 20.2429 21.5815 20.5322C22.2737 20.8959 23.1327 21.0645 23.8695 20.7256C24.2759 20.5504 24.4973 20.1503 24.7236 19.7948C25.2622 20.0031 25.8106 20.1867 26.3541 20.3801C25.8965 21.3786 24.9615 22.1275 23.8695 22.2945C22.5727 22.5044 21.0579 22.3755 20.0799 21.4001C18.4048 19.656 18.8607 16.2355 21.1537 15.2386ZM21.6476 16.662C21.2379 16.9943 21.0232 17.4754 20.8778 17.9714C22.0656 17.9978 23.2533 17.9879 24.4428 17.9763C24.3552 17.4589 24.195 16.8885 23.7109 16.6058C23.0716 16.2223 22.3018 16.4422 21.6476 16.662Z" fill="#010101"/>
          <path d="M35.2154 15.3709C36.6444 14.5972 38.6829 14.7989 39.727 16.1214C40.439 17.0059 40.5265 18.1946 40.5067 19.2857C38.749 19.2989 36.9913 19.2807 35.2336 19.2956C35.3723 19.7205 35.4847 20.1966 35.8498 20.4909C36.7782 21.2563 38.4202 20.9785 38.8712 19.7982C39.4147 19.9965 39.9615 20.1834 40.505 20.3784C40.0904 21.2844 39.2842 22.0134 38.2997 22.2317C36.8525 22.6036 35.0667 22.3408 34.1251 21.0761C32.8663 19.3336 33.2611 16.4438 35.2154 15.3709ZM36.4131 16.4653C35.7308 16.6554 35.3558 17.3283 35.2104 17.9763C36.3388 17.9962 37.4704 17.9796 38.6003 17.9846C38.5095 17.5531 38.4335 17.0737 38.0799 16.7695C37.6455 16.3446 36.9698 16.3446 36.4131 16.4653Z" fill="#010101"/>
          <path d="M43.3778 16.1776C44.0121 14.8237 45.9102 14.5178 47.0468 15.394C47.7258 15.9445 47.9686 16.8588 47.9917 17.7002C48.005 19.2195 47.9868 20.7405 48 22.2598C47.3706 22.2581 46.7412 22.2598 46.1118 22.2565C46.1283 20.9008 46.1167 19.5435 46.1217 18.1879C46.1068 17.6705 46.0705 17.0423 45.6013 16.7166C45.0578 16.376 44.2583 16.457 43.8469 16.9679C43.4637 17.4456 43.391 18.092 43.3893 18.6839C43.391 19.8742 43.3827 21.0661 43.3959 22.2581C42.7632 22.2581 42.1305 22.2598 41.4978 22.2565C41.5143 19.861 41.4995 17.4655 41.5061 15.0683C42.1338 15.0733 42.7616 15.0749 43.3893 15.0667C43.3943 15.437 43.3794 15.8073 43.3778 16.1776Z" fill="#010101"/>
          <path d="M26.3557 15.0683C27.0066 15.075 27.6575 15.0766 28.31 15.065C28.9014 16.7282 29.4234 18.4161 30.0363 20.0709C30.5121 18.3913 31.0771 16.7381 31.5991 15.0733C32.2269 15.0733 32.8563 15.075 33.4857 15.065C32.6151 17.4622 31.7544 19.8626 30.8739 22.2565C30.2874 22.2598 29.701 22.2581 29.1162 22.2581C28.1894 19.8643 27.2742 17.4655 26.3557 15.0683Z" fill="#010101"/>
          <path d="M22.9196 25.5844C23.5457 25.586 24.1718 25.5844 24.7995 25.5893C24.6922 26.9069 24.864 28.2279 24.745 29.5438C25.1167 29.1884 25.5082 28.823 26.0121 28.6693C27.299 28.2146 28.8601 28.7453 29.5639 29.9257C30.3139 31.1854 30.3287 32.8155 29.7803 34.1529C29.326 35.2854 28.1547 36.0954 26.9223 36.021C26.0699 36.0673 25.2951 35.611 24.745 34.9894C24.7434 35.2936 24.7434 35.5995 24.7434 35.9037C24.142 35.7962 23.5176 35.9483 22.9245 35.826C22.9113 32.4121 22.9245 28.9982 22.9196 25.5844ZM25.69 30.2249C24.6476 30.7953 24.5286 32.1922 24.7979 33.2304C24.9813 34.0256 25.7825 34.5993 26.5985 34.4869C27.4427 34.4488 28.0424 33.6685 28.1431 32.8767C28.2357 32.1509 28.229 31.3441 27.8045 30.7159C27.3518 30.053 26.3904 29.8811 25.69 30.2249Z" fill="#010101"/>
          <path d="M7.53467 25.7431C8.18555 25.7447 8.83643 25.7447 9.48731 25.7431C9.48235 28.5668 9.48235 31.3904 9.48731 34.2141C11.0104 34.1943 12.5352 34.2108 14.06 34.2058C14.0617 34.7712 14.0732 35.3399 14.0137 35.9053C12.9201 35.8937 11.8265 35.9004 10.7346 35.902C9.66738 35.8871 8.59854 35.9367 7.53467 35.8541C7.53797 32.4832 7.53632 29.114 7.53467 25.7431Z" fill="#010101"/>
          <path d="M31.9047 28.9718C33.5385 27.9881 35.8084 28.5766 36.9234 30.0811C36.4279 30.334 35.9372 30.5919 35.4565 30.8713C35.0121 30.3654 34.4058 29.9405 33.7004 29.9835C33.228 29.9389 32.5325 30.1852 32.587 30.7638C32.5953 31.116 33.0016 31.2135 33.2759 31.2962C34.3959 31.5789 35.7026 31.6318 36.5402 32.5344C37.3348 33.4222 36.9466 34.9034 35.9934 35.5168C34.348 36.6046 31.9279 36.1169 30.7285 34.5745C31.2258 34.2918 31.7214 34.0074 32.212 33.7165C32.7687 34.3728 33.6558 34.7762 34.5182 34.5629C34.9163 34.4802 35.2863 34.0587 35.0997 33.6421C34.7247 33.2288 34.1134 33.2205 33.6079 33.0899C32.6712 32.8965 31.5694 32.6749 31.0341 31.7905C30.4592 30.8085 30.9565 29.5107 31.9047 28.9718Z" fill="#010101"/>
          <path d="M15.6063 29.5206C16.3398 28.742 17.4664 28.494 18.5006 28.5353C19.3398 28.5601 20.25 28.7784 20.8233 29.4347C21.3007 29.967 21.4378 30.7093 21.4213 31.4036C21.4147 32.9031 21.4312 34.4025 21.4114 35.9037C20.81 35.9003 20.2071 35.9119 19.6074 35.864C19.6124 35.5515 19.619 35.2424 19.6289 34.9332C18.7682 36.145 16.9824 36.3599 15.7252 35.6838C14.7308 35.1399 14.4846 33.7148 15.0826 32.7973C16.1415 31.5194 18.005 31.7657 19.4158 31.1887C19.8469 30.9655 19.5661 30.3439 19.206 30.2001C18.6129 29.9406 17.8662 29.9273 17.2996 30.2613C16.9395 30.4646 16.842 30.8878 16.7412 31.2565C16.13 31.2267 15.5171 31.2697 14.9059 31.2664C14.962 30.6332 15.1487 29.9835 15.6063 29.5206ZM17.0997 33.1296C16.5298 33.4189 16.5182 34.3414 17.1327 34.5844C17.8646 34.8489 18.7946 34.6323 19.2737 33.9992C19.657 33.5263 19.6173 32.8899 19.6504 32.3195C18.8293 32.6832 17.8943 32.708 17.0997 33.1296Z" fill="#010101"/>
        </g>
        <defs>
          <clipPath id="clip0_4769_24047">
            <rect width="48" height="48" fill="white"/>
          </clipPath>
        </defs>
      </svg>
  );
};

export default ElevenLabsIcon;
