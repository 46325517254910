import React, { FC, useMemo, useState } from 'react';
import { ColumnFiltersState } from '@tanstack/react-table';
import { useGetEditorsQuery } from 'redux/api/app.api';
import Table from 'modules/common/components/Table/Table';
import useSorting from 'modules/common/hooks/useSorting';
import { getFiltersToSend } from 'modules/common/lib';
import { getEditorsTableColumnsConfig } from './EditorsTableConfig';

const EditorsTab: FC = () => {
  const [page, setPage] = useState(1);

  const { sortBy, sortType, changeSortBy } = useSorting();
  const [filters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const filtersToSend = React.useMemo(
    () => getFiltersToSend(filters),
    [filters]
  );

  const { data, isFetching } = useGetEditorsQuery({
    index: page * 10,
    sortBy,
    sortType,
    ...filtersToSend,
  });

  const columns = useMemo(() => {
    return getEditorsTableColumnsConfig({
      sortBy,
      sortType,
      changeSortBy,
    });
  }, [sortBy, sortType, changeSortBy]);

  return (
    <div className="p-t-3">
      <Table
        data={data && data.data ? data.data : []}
        columns={columns}
        isLoading={isFetching}
        getPaginationRowModel={undefined}
        totalDataCount={data ? data.totalCount : 0}
        page={setPage}
        onColumnFiltersChange={setColumnFilters}
        paginated
      />
    </div>
  );
};

export default EditorsTab;
