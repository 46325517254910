import React, { FC } from 'react';
import classnames from 'classnames';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Button } from 'modules/common/components/Button/Button';
import { AdminProjects } from 'modules/dashboard/ProjectsTableConfig';
import { prettifyDate } from 'utils';
import { Icon } from 'icons';

import Styles from './unassigned-projects.module.scss';

interface Props {
  setDeclineReasonView: (value: boolean) => void;
  setOpen: () => void;
  isFetching?: boolean;
  data: AdminProjects;
}

const DeclineViewToggled: FC<Props> = ({
  setDeclineReasonView,
  data,
  setOpen,
  isFetching,
}) => {
  return (
    <Stack direction="column" justifyContent="space-between" className="h-100">
      <Stack.Item>
        <Stack justifyContent="space-between">
          <Stack.Item className="font-size-xs">{data.editor}</Stack.Item>
          <Stack.Item className="font-size-xs">
            {prettifyDate(data.dateAdded)}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <p className={classnames(Styles['declined-view'], 'm-y-2')}>
          {data.declinedReason}
        </p>
        <Stack justifyContent="space-between">
          <Button onClick={() => setDeclineReasonView(false)} secondary>
            <Icon name="arrow_backward" />
          </Button>
          <Button onClick={setOpen} disabled={isFetching}>
            Select another editor
          </Button>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default DeclineViewToggled;
