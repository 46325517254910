import { useCallback, useRef, useState } from 'react';
import { error } from '../components/Notify';
import { KeyedResultForm, ResultForm } from 'modules/projects/Project/types';
import { useLazyTryVoiceQuery } from 'redux/api/app.api';
import { ELEVENLABS_DEFAULT_VOICE_PREVIEW_TEXT } from 'modules/projects/Project/Information/lib';
import { TryVoiceRequest } from 'redux/api/types';

export type AudioPlayerProjectProps = {
  isLoading: boolean;
  shouldTogglePlayPause: boolean;
  setShouldTogglePlayPause: React.Dispatch<React.SetStateAction<boolean>>;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  playingText: string;
  setPlayingText: React.Dispatch<React.SetStateAction<string>>;
  audioData?: string;
  setAudioData: React.Dispatch<React.SetStateAction<string>>;
  voiceIdRef: React.MutableRefObject<string>;
  handlePlayPause: () => Promise<void>;
};

interface UseAudioPlayerProjectProps {
  formData: ResultForm | null;
  previewText: string;
}

export function useAudioPlayerProject({
  formData,
  previewText,
}: UseAudioPlayerProjectProps): {
  playerProps: AudioPlayerProjectProps;
} {
  const [shouldTogglePlayPause, setShouldTogglePlayPause] =
    useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playingText, setPlayingText] = useState<string>('');
  const [audioData, setAudioData] = useState<string>('');
  const voiceIdRef = useRef<string>('');

  const [tryVoiceQueryTrigger, { isFetching }] = useLazyTryVoiceQuery();

  const createPayload = useCallback((): TryVoiceRequest => {
    if (!formData) {
      throw new Error('Form data is not available');
    }
    const text = previewText || ELEVENLABS_DEFAULT_VOICE_PREVIEW_TEXT;
    return {
      el_model_id: formData[KeyedResultForm.elevenLabsModel],
      voice_id: voiceIdRef.current,
      text,
      voice_settings: formData[KeyedResultForm.elevenLabsVoiceSettings]!,
      karaoke_enabled: false,
    };
  }, [formData, previewText]);

  const handlePlayPause = useCallback(async () => {
    if (isPlaying) {
      setShouldTogglePlayPause(true);
      return;
    }

    try {
      // use cached audio data if available
      const response = await tryVoiceQueryTrigger(
        createPayload(),
        true
      ).unwrap();
      setAudioData(response.audioStream);
      setPlayingText(previewText);
      setShouldTogglePlayPause(true);
      return;
    } catch (e) {
      error({ children: 'Failed to generate audio' });
    }
  }, [createPayload, isPlaying, previewText, tryVoiceQueryTrigger]);

  return {
    playerProps: {
      isLoading: isFetching,
      isPlaying,
      setIsPlaying,
      shouldTogglePlayPause,
      setShouldTogglePlayPause,
      playingText,
      setPlayingText,
      audioData,
      setAudioData,
      voiceIdRef,
      handlePlayPause,
    },
  };
}
