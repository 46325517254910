import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { TagModel } from 'modules/common/models/Tags';

export const TagsApplyAdapter = createEntityAdapter<TagModel>({
  selectId: (tag) => tag.id,
});

const initialState = {
  ...TagsApplyAdapter.getInitialState(),
};

const tagsApplySlice = createSlice({
  name: 'TagsApply',
  initialState,
  reducers: {
    filterTags(state, action) {
      TagsApplyAdapter.setAll(state, action.payload);
    },
  },
});

export const { filterTags } = tagsApplySlice.actions;
export default tagsApplySlice.reducer;
