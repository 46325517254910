import { Icon } from 'icons';
import { ChangeEvent, FC } from 'react';
import classnames from 'classnames';

import Styles from './checkbox.module.scss';

interface CheckboxPropsStrict {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  iconName?: 'done' | 'done_all';
  disabled?: boolean;
}

interface CheckboxProps extends CheckboxPropsStrict {
  [propName: string]: any;
}

export const Checkbox: FC<CheckboxProps> = ({
  checked = false,
  iconName = 'done',
  disabled = false,
  onChange,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.checked);
  };

  const classNames = classnames(Styles.checkbox, {
    [Styles.disabled]: disabled,
  });

  return (
    <label className={classNames}>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      {checked && <Icon color="#031E50" name={iconName} size={16} />}
    </label>
  );
};
