import React from 'react';
import Presets from '../../Presets/Presets';
import useQuill from '../../../common/hooks/useQuill';
import { SelectValue } from 'modules/common/types';

type PresetsViewProps = {
  title: string;
  handleSelectPreset: (value: SelectValue) => void;
};
export const PresetsView: React.FC<PresetsViewProps> = ({
  title,
  handleSelectPreset,
}) => {
  const quillInstance = useQuill();
  return (
    <>
      <Presets
        quillInstance={quillInstance}
        presetsAreVisible
        title={title}
        hideAddIcon
        onSelect={handleSelectPreset}
      />
    </>
  );
};
