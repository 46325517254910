import {
  FC,
  useCallback,
  KeyboardEvent,
  useState,
  useRef,
  useLayoutEffect,
  useContext,
} from 'react';

import Styles from './tooltip.module.scss';

import { Button } from 'modules/common/components/Button/Button';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Keys } from 'utils/keyCodes';
import { useKeyPressListener } from 'modules/common/hooks/useKeyPressListener';
import Textarea from 'modules/common/components/Textarea/Textarea';
import { TooltipCommentItem } from './TooltipCommentItem';
import QuillContext from 'modules/common/context/QuillContext';
import { CommentBlot } from 'modules/edit/Editor/Blots/CommentBlot';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import {
  addReply,
  deleteCommentReply,
  deleteMainComment,
  editComment,
} from 'redux/reducers/comments/commentsSlice';
import { selectEntities } from 'redux/reducers/comments/selectors';
import { useOnClickOutside } from 'modules/common/hooks/useOnClickOutside';
import { CommentItemModel } from 'modules/common/models/Comment';
import { Quill } from 'react-quill';
import { setChapterDirty } from 'redux/reducers/chapters/chaptersSlice';
import { activeChapterSelector } from 'redux/reducers/chapters/selectors';

export interface TooltipCoords {
  left?: string;
  top?: string;
  marginTop?: string;
}

interface EditorTooltipProps {
  tooltipComment: {
    commentId: string;
    blot: CommentBlot;
  };
  onExit: () => void;
}

export const CommentsTooltip: FC<EditorTooltipProps> = ({
  tooltipComment: { commentId, blot },
  onExit,
}) => {
  const { quillInstance } = useContext(QuillContext);
  const dispatch = useAppDispatch();
  const [commentText, setCommentText] = useState('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const commentsEntities = useAppSelector(selectEntities);
  const [edittingComment, setEdittingComment] = useState<CommentItemModel>();
  const chapter = useAppSelector(activeChapterSelector);

  const commentData = commentsEntities[commentId]!;

  useLayoutEffect(() => {
    const editor = quillInstance?.editor;
    if (editor && tooltipRef.current) {
      const reference = editor.getBounds(
        blot.offset(editor.scroll),
        blot.length()
      );
      let left = reference.left;
      let top = reference.bottom + editor.root.scrollTop;
      tooltipRef.current.style.left = left + 'px';
      tooltipRef.current.style.top = top + 'px';
      let containerBounds = document
        .querySelector('.quill')!
        .getBoundingClientRect();
      let rootBounds = tooltipRef.current.getBoundingClientRect();
      let shift = 0;
      if (rootBounds.right > containerBounds.right) {
        shift = containerBounds.right - rootBounds.right;
        tooltipRef.current.style.left = left + shift + 'px';
      }
      if (rootBounds.left < containerBounds.left) {
        shift = containerBounds.left - rootBounds.left;
        tooltipRef.current.style.left = left + shift + 'px';
      }
      if (rootBounds.bottom > containerBounds.bottom) {
        let height = rootBounds.bottom - rootBounds.top;
        let verticalShift = reference.bottom - reference.top + height;
        tooltipRef.current.style.top = top - verticalShift + 'px';
      }

      editor.root.addEventListener('scroll', () => {
        if (tooltipRef.current) {
          tooltipRef.current.style.marginTop =
            -1 * editor.root.scrollTop + 'px';
        }
      });
    }
  }, [blot, quillInstance]);

  const handleSubmit = useCallback(async () => {
    if (commentText.length) {
      setLoading(true);
      if (edittingComment) {
        await dispatch(
          editComment({
            id: commentId,
            commentId: edittingComment.commentId,
            comment: commentText,
          })
        );
        setEdittingComment(undefined);
      } else {
        const newCommentData = {
          id: commentData.id,
          comments: { comment: commentText },
        };
        await dispatch(addReply(newCommentData));
      }

      setCommentText('');
      setLoading(false);
    }
  }, [commentText, edittingComment, dispatch, commentId, commentData]);

  const handleKeyDown = useCallback(
    async (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  useKeyPressListener({
    code: Keys.Esc,
    handler: onExit,
  });

  useOnClickOutside(tooltipRef, onExit);

  const handleDelete = useCallback(
    async (commentId: string) => {
      if (commentData.comments.length > 1) {
        // delete only reply
        await dispatch(deleteCommentReply({ id: commentData.id, commentId }));
      } else if (commentData.comments.length === 1) {
        // delete entire comment
        const editor = quillInstance!.getEditor();
        const el = document.getElementById(commentId);
        if (el) {
          const blot = Quill.find(el);
          if (blot) {
            editor.deleteText(blot.offset(editor.scroll), 1, 'user');
          }
        }

        await dispatch(deleteMainComment({ id: commentData.id }));
        if (chapter) {
          dispatch(setChapterDirty(chapter.id));
        }
      }
    },
    [
      chapter,
      commentData.comments.length,
      commentData.id,
      dispatch,
      quillInstance,
    ]
  );

  const handleEditMode = useCallback(async (commentItem: CommentItemModel) => {
    setEdittingComment(commentItem);
    setCommentText(commentItem.comment);
  }, []);

  const handleCancelEditMode = useCallback(() => {
    setEdittingComment(undefined);
    setCommentText('');
  }, []);

  return (
    <div ref={tooltipRef} className={Styles['tooltip-wrapper']}>
      <div className={Styles['tooltip-body']}>
        {commentData.comments.map((item) => {
          return (
            <TooltipCommentItem
              onDelete={handleDelete}
              onEdit={handleEditMode}
              key={item.commentId}
              data={item}
            />
          );
        })}

        <div>
          <Textarea
            placeholder="Comment"
            autoFocus
            value={commentText}
            onChangeValue={(v) => setCommentText(v)}
            onKeyDown={handleKeyDown}
          />
          <Stack spacing={1} justifyContent="flex-end">
            {edittingComment && (
              <Stack.Item>
                <Button
                  disabled={isLoading}
                  onClick={handleCancelEditMode}
                  secondary
                  small
                >
                  Cancel
                </Button>
              </Stack.Item>
            )}
            <Stack.Item>
              <Button
                loading={isLoading}
                onClick={handleSubmit}
                primary
                small
                disabled={isLoading || !commentText.length}
              >
                {edittingComment ? 'Edit' : 'Add'}
              </Button>
            </Stack.Item>
          </Stack>
        </div>
      </div>
    </div>
  );
};
