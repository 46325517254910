import React from 'react';
import {Modal, ModalSizes} from 'modules/common/components/Modal/Modal';
import {Stack} from 'modules/common/components/Stack/Stack';
import {Alert} from 'modules/common/components/Alert/Alert';
import {Button} from 'modules/common/components/Button/Button';
import {TOCChapter} from 'modules/common/types';

import styles from './ChaptersTooLongPopup.module.scss';
import classNames from 'classnames';

type ChaptersTooLongPopupProps = {
 allChapters: TOCChapter[],
 chaptersTooLong: TOCChapter[] | null,
 onClose(): void
};

const numberFormatter = new Intl.NumberFormat();

const ChaptersTooLongPopup = ({allChapters, chaptersTooLong, onClose}: ChaptersTooLongPopupProps) => {
  return <Modal
    portal
    open={!!chaptersTooLong}
    isCloseIcon={true}
    onClose={onClose}
    gray
    size={ModalSizes.MM}
    isClosableOnClickOutside={false}
  >
   <Stack
     className="m-t-4"
     alignItems="center"
     justifyContent="center"
     direction="column"
   >
    <Stack.Item className="m-b-4 font-size-lg fw-700">
     Too long chapters
    </Stack.Item>
    <Stack.Item className="m-b-1 font-size-sm ta-center">
     Listed chapters and sub-chapters are too long to proceed.
    </Stack.Item>
    <Stack.Item className="m-b-4 font-size-sm ta-center">
     Please ensure each chapter and sub-chapter is no more than 65,000 characters.
    </Stack.Item>

    <div className={classNames('m-b-2', styles.table)}>
     <div className={styles.tableHead}>
      <p>Chapter</p>
      <p>Sub-chapter</p>
      <p>Characters</p>
      <p>Pages</p>
     </div>

     <div className={styles.tableBody}>
     {chaptersTooLong?.map(chapter => {
      return <div className={styles.tableRow} key={chapter.id}>
       <p>{!chapter.isSubChapter ? chapter.name : allChapters.find(item => item.id === chapter.parentChapterId)?.name || ''}</p>
       <p>{chapter.isSubChapter ? chapter.name : '-'}</p>
       <p>{numberFormatter.format(chapter.characterCount)}</p>
       <p>{chapter.startPage} - {chapter.endPage}</p>
      </div>
     })}
     </div>
    </div>

    <Alert className={classNames("m-b-3", styles.alert)} type="warning" icon="info">
     Important: you won’t be able to edit table of contents in the editor in future.
    </Alert>

    <Button style={{width: 209}} onClick={onClose}>Go back to editing</Button>
   </Stack>
  </Modal>;
};

export default ChaptersTooLongPopup;
