import React from 'react';
import {
  ColumnFiltersState,
  getCoreRowModel,
  getExpandedRowModel,
  OnChangeFn,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import TableHeader from './TableHeader';
import TableFooter from './TableFooter';
import TableBody from './TableBody';

import Styles from './table.module.scss';
import TablePagination from './Pagination/TablePagination';
import classNames from 'classnames';

interface Props extends Omit<TableOptions<any>, 'getCoreRowModel'> {
  totalDataCount?: number;
  isLoading?: boolean;
  showFooter?: boolean;
  emptyMessage?: string;
  paginated?: boolean;
  selectedRowId?: any;
  page?: (num: number) => void;
  onRowClick?: (row: any) => void;
  getRowDisabled?: (row: any) => void;
  onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>;
}

const Table: React.FC<Props> = ({
  paginated,
  isLoading,
  emptyMessage = 'No data is available',
  showFooter,
  totalDataCount = 0,
  selectedRowId,
  page,
  onRowClick,
  getRowId,
  getRowDisabled,
  onColumnFiltersChange,
  ...props
}) => {
  const [paginationPage, setPaginationPage] = React.useState(1);

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualPagination: true,
    manualFiltering: true,
    manualSorting: true,
    getRowId: getRowId,
    onColumnFiltersChange,
    ...props,
  });

  const handlePageChange = React.useCallback(
    (currentPage: number) => {
      setPaginationPage(currentPage === 0 ? 1 : currentPage);
      page?.(currentPage === 0 ? 1 : currentPage);
    },
    [setPaginationPage, page]
  );

  const data = !isLoading ? table.getRowModel() : null;

  return (
    <>
      <div className={Styles['table-container']}>
        <table
          className={classNames(Styles['table'], {
            [Styles.hoverable]: onRowClick,
          })}
        >
          <TableHeader data={table.getHeaderGroups()} />
          <TableBody
            data={data}
            emptyMessage={emptyMessage}
            isLoading={isLoading}
            selectedRowId={selectedRowId}
            onRowClick={onRowClick}
            getRowDisabled={getRowDisabled}
          />
          {showFooter && <TableFooter data={table.getFooterGroups()} />}
        </table>
      </div>
      {!isLoading && paginated && (
        <TablePagination
          currentDataCount={data ? data.rows.length : 0}
          totalCount={totalDataCount}
          currentPage={paginationPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Table;
