import { Quill } from 'react-quill';
import QUtil from 'utils/QuillUtil';
import {
  ifEmbeddingNonEmbeddedTagCleanse,
  optimizeMerge,
} from 'modules/edit/v2/Editor/Blots/genericSSMLBlotFactory';

const Inline = Quill.import('blots/inline');

export interface ChapterIconBlotValue {
  id: string;
  chapterText: string;
  isSubChapter?: string;
}

export class ChapterIconBlot extends Inline {
  static blotName = 'chapter';
  static tagName = 'chapter';

  static create(value: string) {
    let node: HTMLSpanElement = super.create('chapter');
    const chapter = JSON.parse(value) as ChapterIconBlotValue;
    if (value && chapter.id) {
      node.setAttribute('class', 'chapter-icon');
      node.setAttribute('id', chapter.id);
      node.setAttribute('data-name', chapter.chapterText);

      if (chapter.id === '1') {
        const prevClasses = node.getAttribute('class');
        node.setAttribute('class', (prevClasses || '') + ' the-first-chapter');
        node.setAttribute(
          'style',
          `--bg-image: url(${process.env.PUBLIC_URL}/svg/lock.svg);`
        );
      }
      if (chapter.isSubChapter) {
        node.setAttribute('data-sub-chapter', chapter.isSubChapter);
        const prevClasses = node.getAttribute('class');
        node.setAttribute('class', (prevClasses || '') + ' sub-chapter');
      }
      node.tabIndex = -1;
    }
    return node;
  }

  static formats(domNode: HTMLElement) {
    const obj: ChapterIconBlotValue = {
      id: domNode.id,
      chapterText: !domNode.dataset.name
        ? `Chapter ${domNode.id}`
        : domNode.dataset.name,
    };
    if (domNode.dataset['subChapter']) {
      obj['isSubChapter'] = 'true';
    }
    return JSON.stringify(obj);
  }
  formatAt(index: number, length: number, name: string, value: any): void {
    // close case for embedded tags
    if (this.statics.blotName && this.statics.blotName !== name && !value) {
      QUtil.forEachRecursive(this.children, function (child) {
        if (QUtil.isTagNameEqual(child?.statics.blotName, name)) {
          child.unwrap();
        }
      });
      return;
    }
    super.formatAt(index, length, name, value);
  }

  optimize(): void {
    if (this.domNode['__blot'] != null) {
      delete this.domNode['__blot'].mutations;
    }

    if (this.children.length === 0) {
      if (this.statics.defaultChild == null) {
        this.remove();
      }
    }

    let formats = this.formats();

    if (Object.keys(formats).length === 0) {
      return this.unwrap();
    }

    if (this.domNode && document.contains(this.domNode)) {
      optimizeMerge.call(this);
      ifEmbeddingNonEmbeddedTagCleanse.call(this);
      return;
    }
  }
}
