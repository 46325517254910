import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'redux/store';
import { getFetchingSelector } from 'redux/reducers/loader/selectors';
import { AsyncRequestStatus } from 'redux/interfaces/loader';
import {
  addComment,
  CommentsAdapter,
  editComment,
  fetchcomments,
} from './commentsSlice';

export const { selectAll, selectById, selectEntities } =
  CommentsAdapter.getSelectors<RootState>(({ comments }) => comments);

export const commentsIsFetchingSelector = createSelector(
  getFetchingSelector(fetchcomments.typePrefix),
  (status) => status === AsyncRequestStatus.pending
);

export const commentsFetchedSelector = createSelector(
  getFetchingSelector(fetchcomments.typePrefix),
  (status) => status === AsyncRequestStatus.fulfilled
);

export const commentIsAddingSelector = createSelector(
  getFetchingSelector(addComment.typePrefix),
  (status) => status === AsyncRequestStatus.pending
);

export const commentIsEditingSelector = createSelector(
  getFetchingSelector(editComment.typePrefix),
  (status) => status === AsyncRequestStatus.pending
);
