import React from 'react';
import classnames from 'classnames';
import { VoiceLabels } from 'modules/common/types';
import { filterAndSortLabelTypes } from '../lib';
import Styles from './voiceselect.module.scss';

export const OptionLabelTypes: React.FC<{
  voiceLabels: VoiceLabels | undefined;
}> = ({ voiceLabels }) => {
  // Display only particular label types and sorted in a particular way.
  const labelsTypes = voiceLabels
    ? filterAndSortLabelTypes(Object.keys(voiceLabels))
    : undefined;

  if (!labelsTypes?.length) return null;

  return (
    <div className={Styles['Types']}>
      {labelsTypes.map((type) => (
        <div
          className={classnames(
            Styles['Type'],
            Styles[type.replace(/\s/g, '')]
          )}
          key={type}
        >
          {voiceLabels?.[type as keyof VoiceLabels]}
        </div>
      ))}
    </div>
  );
};
