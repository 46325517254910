import { useCallback, useState } from 'react';
import { SORT } from 'modules/common/types';

export type useSortObj = {
  sortBy?: string;
  sortType?: SORT;
  changeSortBy: (accessorName: string, sort: SORT) => void;
  backToDefaultSort: () => void;
};

export default function useSorting(
  defaultSortType?: SORT,
  defaultSortBy?: string
): useSortObj {
  const [sortType, setSortType] = useState<SORT | undefined>(defaultSortType);
  const [sortBy, setSortBy] = useState<string>(defaultSortBy || '');

  const changeSortBy = useCallback(
    (accessorName: string, sort: SORT) => {
      // minor optimization to get rid of re-rendering
      if (accessorName !== sortType || sort !== sortBy) {
        setSortType(sort);
        setSortBy(accessorName);
      }
    },
    [sortType, sortBy]
  );

  const backToDefaultSort = useCallback(() => {
    setSortBy('');
    setSortType(undefined);
  }, []);

  return { sortBy, sortType, changeSortBy, backToDefaultSort };
}
