import { AxiosResponse } from 'axios';
import axiosInstance from 'utils/axios';
import {
  USER_EMAIL,
  USER_ID,
  USER_LASTNAME,
  USER_NAME,
} from 'modules/edit/api/CONSTANTS';
import { ProjectModel, reviewerType } from 'modules/common/models/Project';
import {
  ElevenLabsVoiceSettings,
  KeyedResultForm,
  ResultForm,
  ReviewersHashed,
} from 'modules/projects/Project/types';
import { adjustElevenLabsSettingsDataForNoneMultilingualV2Model } from 'modules/projects/Project/lib';
import { TOCMessageResponse, TOCResponse } from 'modules/common/types/api';

export enum SplitBookStatus {
  INITIAL = 'INITIAL',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export type SplitBookResponse = {
  processingStatus: SplitBookStatus;
  logException: string;
};
export interface IProjectApiV2 {
  createProject: (res: ResultForm, file: any) => Promise<ProjectModel>;
  editProject: (
    id: string,
    res: ResultForm,
    fileType: string
  ) => Promise<ProjectModel>;
  editProjectFile: (
    id: string,
    res: ResultForm,
    file: any
  ) => Promise<ProjectModel>;
  getProjectTOCData: (projectId: any) => Promise<TOCResponse>;
  getStatusOfSplitBooks: (projectId: string) => Promise<SplitBookResponse>;
  getOne: (id: string, projectId: string) => Promise<ProjectModel | null>;
}

interface postProjectData {
  name: string;
  ttsEngine: string;
  voice: string;
  elevenLabsModel: string;
  author: {
    firstName: string;
    lastName: string;
    email: string;
    userId: string;
  };
  voiceSettings?: ElevenLabsVoiceSettings;
  reviewers?: reviewerType[];
  fileType: string;
}

interface putProjectData extends postProjectData {
  id: string;
}

export class ProjectApiV2 implements IProjectApiV2 {
  static voiceAction = '/voices';
  static projectAction = '/project';
  static fileTOCUploading = '/v2/projects/check-toc';
  static projectBookToChapterAction = (projectId: string) =>
    `/v2/projects/${projectId}/split-book`;

  private static getFileType(str: string) {
    let fileType = str.split('.');
    return fileType[fileType.length - 1];
  }

  private static getReviewers(reviewersHash: ReviewersHashed): reviewerType[] {
    if (Object.keys(reviewersHash).length) {
      return Object.values(reviewersHash).filter((item: reviewerType) => {
        return (
          item.email.length && item.firstName.length && item.lastName.length
        );
      });
    }
    return [];
  }

  private static async uploadBookToProject(
    upload: ProjectModel['uploadUrl'],
    file: any
  ) {
    if (!upload) {
      return;
    }
    const bodyFormData = new FormData();
    Object.entries(upload.fields).forEach(([k, v]) => {
      bodyFormData.append(k, v);
    });
    bodyFormData.append('file', file);

    await axiosInstance.post(upload.url, bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      baseURL: upload.url,
    });
  }

  private static async getProjectTOC(
    projectId: any
  ): Promise<AxiosResponse<TOCResponse>> {
    return axiosInstance.get(`/v2/projects/${projectId}/toc`);
  }

  async getProjectTOCData(projectId: any): Promise<TOCResponse> {
    const response = await axiosInstance.get(`/v2/projects/${projectId}/toc`);
    return response.data;
  }

  async getTOCText(preAssignedUrl: string): Promise<string> {
    const res = await axiosInstance.get(preAssignedUrl, {
      baseURL: preAssignedUrl,
      responseType: 'text',
    });
    return res.data;
  }

  private static async initializeBookToChapters(
    projectId: string
  ): Promise<void> {
    return axiosInstance.post(
      ProjectApiV2.projectBookToChapterAction(projectId),
      {
        projectId: projectId,
      }
    );
  }

  private static getSendData(
    id: string,
    res: ResultForm,
    fileType: string
  ): putProjectData {
    const sendData: putProjectData = {
      id,
      name: res[KeyedResultForm.name],
      ttsEngine: res[KeyedResultForm.ttsEngine],
      voice: res[KeyedResultForm.voice],
      elevenLabsModel: res[KeyedResultForm.elevenLabsModel],
      author: {
        firstName: USER_NAME,
        lastName: USER_LASTNAME,
        email: USER_EMAIL,
        userId: USER_ID,
      },
      voiceSettings: res[KeyedResultForm.elevenLabsVoiceSettings],
      fileType: fileType,
    };
    sendData.reviewers = ProjectApiV2.getReviewers(res.reviewers);

    return sendData;
  }

  private static async editACreatedProjectWithFileUpload(
    data: ProjectModel,
    file: any
  ): Promise<ProjectModel> {
    await ProjectApiV2.uploadBookToProject(data.uploadUrl, file);
    const tocResponse = await ProjectApiV2.getProjectTOC(data.id);

    const isTOCReady =
      tocResponse.data.message === TOCMessageResponse.tocIsReady;

    if (isTOCReady) {
      await ProjectApiV2.initializeBookToChapters(data.id);
    }

    return {
      id: data.id,
      name: data.name,
      fileName: data.fileName,
      ttsEngine: data.ttsEngine,
      voice: data.voice,
      elevenLabsModel: data.elevenLabsModel,
      reviewers: data.reviewers,
      fileType: data.fileType,
      projectStatus: data.projectStatus,
      author: data.author,
      chapters: data.chapters,
      orders: data.orders,
      tocReady: isTOCReady,
    };
  }
  async initializeBookToChapters(projectId: string): Promise<void> {
    await ProjectApiV2.initializeBookToChapters(projectId);
  }

  async getStatusOfSplitBooks(projectId: string): Promise<SplitBookResponse> {
    const res = await axiosInstance.get(
      ProjectApiV2.projectBookToChapterAction(projectId),
      {
        params: {
          projectId: projectId,
        },
      }
    );
    return res.data;
  }

  async createProject(res: ResultForm, file: any): Promise<ProjectModel> {
    const voiceSettings = res[KeyedResultForm.elevenLabsVoiceSettings];
    if (
      res[KeyedResultForm.elevenLabsModel] !== 'eleven_multilingual_v2' &&
      voiceSettings
    ) {
      res = {
        ...res,
        [KeyedResultForm.elevenLabsVoiceSettings]:
          adjustElevenLabsSettingsDataForNoneMultilingualV2Model(voiceSettings),
      };
    }
    const fileType = ProjectApiV2.getFileType(file.name);

    const sendData: postProjectData = {
      name: res[KeyedResultForm.name],
      ttsEngine: res[KeyedResultForm.ttsEngine],
      voice: res[KeyedResultForm.voice],
      elevenLabsModel: res[KeyedResultForm.elevenLabsModel],
      voiceSettings: res[KeyedResultForm.elevenLabsVoiceSettings],
      author: {
        firstName: USER_NAME,
        lastName: USER_LASTNAME,
        email: USER_EMAIL,
        userId: USER_ID,
      },
      fileType,
    };
    sendData.reviewers = ProjectApiV2.getReviewers(res.reviewers);

    const result = await axiosInstance.post(
      ProjectApiV2.projectAction,
      sendData
    );
    const data = result.data.result;

    return ProjectApiV2.editACreatedProjectWithFileUpload(data, file);
  }

  async editProject(
    id: string,
    res: ResultForm,
    fileType: string
  ): Promise<ProjectModel> {
    const sendData = ProjectApiV2.getSendData(id, res, fileType);

    const result = await axiosInstance.put(
      ProjectApiV2.projectAction,
      sendData
    );
    const data = result.data.result;

    return {
      id: data.id,
      name: data.name,
      fileName: data.fileName,
      ttsEngine: data.ttsEngine,
      voice: data.voice,
      elevenLabsModel: data.elevenLabsModel,
      voiceSettings: data.voiceSettings,
      reviewers: data.reviewers,
      fileType: data.fileType,
      projectStatus: data.projectStatus,
      author: data.author,
      chapters: data.chapters,
      orders: data.orders,
    };
  }

  /***
   * @Description a project that is created with invalid file only
   * */
  async editProjectFile(
    id: string,
    res: ResultForm,
    file: any
  ): Promise<ProjectModel> {
    const fileType = ProjectApiV2.getFileType(file.name);

    const sendData = ProjectApiV2.getSendData(id, res, fileType);

    const result = await axiosInstance.put(
      ProjectApiV2.projectAction,
      sendData
    );
    const data = result.data.result;

    return ProjectApiV2.editACreatedProjectWithFileUpload(data, file);
  }

  async getOne(id: string): Promise<ProjectModel | null> {
    const res = await axiosInstance.get(ProjectApiV2.projectAction, {
      params: {
        id,
      },
    });
    return res.data || null;
  }
}

export const ProjectApiInstance = new ProjectApiV2();
