import { useAppSelector } from 'modules/common/hooks/redux';
import {
  isProjectStatusAssigned,
  isProjectStatusDecline,
  isProjectStatusDone,
  isProjectStatusPending,
  isWebSiteUpToDateSelector,
} from 'redux/reducers/project/selectors';

export function useCanSeeBookmark() {
  const isUpToDate = useAppSelector(isWebSiteUpToDateSelector);
  const isDecline = useAppSelector(isProjectStatusDecline);
  const isAssigned = useAppSelector(isProjectStatusAssigned);
  const isDone = useAppSelector(isProjectStatusDone);
  const isPending = useAppSelector(isProjectStatusPending);

  return isUpToDate && !isDone && !isDecline && !isAssigned && !isPending;
}
