import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Quill, Range } from 'react-quill';

import { Tag } from 'modules/edit/Tags/Tag';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import {
  isTagsAvailable,
  selectAll,
  selectTagsApplyAll,
} from 'redux/reducers/tags/selectors';
import Presets from 'modules/edit/Presets/Presets';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Button } from 'modules/common/components/Button/Button';
import useQuill from 'modules/common/hooks/useQuill';
import { filterTags } from 'redux/reducers/tags/tagsApplySlice';
import { TagType } from 'modules/common/models/Tags';
import { Skip } from './Skip';
import { InsertText } from './InsertText';
import { CopySelectedFragment } from './CopySelectedFragment';
import { isNumeric } from 'utils';
import TabsFocusContext from 'modules/common/context/TabsFocusContext';
import { Alert } from 'modules/common/components/Alert/Alert';
import SidebarContext from 'modules/common/context/SidebarContext';
import { useTrackEvent, AnalyticsEvent } from 'services/amplitude';
import { PreProcessButton } from './PreProcessButton';

type TagsProps = {
  isSSML: boolean;
};
export const Tags: FC<TagsProps> = ({ isSSML }) => {
  const tags = useAppSelector(selectAll);
  const tagsApply = useAppSelector(selectTagsApplyAll);
  const isTagsFound = useAppSelector(isTagsAvailable);
  const [clearDisabled, setClearDisabled] = useState(true);
  const quillInstance = useQuill();
  const dispatch = useAppDispatch();
  const [tabsFocus, setTabsFocus] = useState(false);
  const { showAlert, setShowAlert } = useContext(SidebarContext);
  const trackEvent = useTrackEvent();

  const selectionChangeHandlerCallback = useCallback(
    async (editor, range: Range) => {
      if (range) {
        await Promise.resolve();
        if (range.length) {
          const tagsToIterate = tags.filter(
            (t) => t.tagType === TagType.WordTags
          );
          let disabled = true;
          tagsToIterate.every((tag) => {
            const formats = editor.getFormat(range);
            if (formats.hasOwnProperty(tag.id)) {
              let format = formats[tag.id];
              if (Array.isArray(format)) {
                format.every((item) => {
                  item = JSON.parse(item);
                  const el = document.querySelector(
                    `[data-id="${item.blotId}"]`
                  )!;
                  if (!el) {
                    return false;
                  }
                  const blot = Quill.find(el);
                  const blotRange: Range = {
                    index: blot.offset(editor.scroll),
                    length: blot.length(),
                  };
                  disabled =
                    blotRange.index !== range.index ||
                    blotRange.length !== range.length;
                  return disabled;
                });
              } else {
                format = JSON.parse(format);
                const el = document.querySelector(
                  `[data-id="${format.blotId}"]`
                )!;
                if (!el) {
                  return false;
                }
                const blot = Quill.find(el);
                const blotRange: Range = {
                  index: blot.offset(editor.scroll),
                  length: blot.length(),
                };
                disabled =
                  blotRange.index !== range.index ||
                  blotRange.length !== range.length;
              }
            } else {
              disabled = true;
            }
            return disabled;
          });
          setClearDisabled(disabled);
        } else {
          const tagsToIterate = tags.filter(
            (t) => t.tagType === TagType.SentenceTags
          );
          let disabled = true;
          tagsToIterate.every((tag) => {
            if (
              tag.id in editor.getFormat(range) ||
              tag.id in editor.getFormat({ index: range.index + 1, length: 0 })
            ) {
              disabled = false;
            }

            return disabled;
          });
          setClearDisabled(disabled);
        }
      } else {
        setClearDisabled(true);
      }
    },
    [tags]
  );

  useEffect(() => {
    const editor = quillInstance?.getEditor();
    if (editor) {
      const selectionChangeHandler = async (range: Range) => {
        return selectionChangeHandlerCallback(editor, range);
      };
      editor.on('selection-change', selectionChangeHandler);
      return () => {
        editor.off('selection-change', selectionChangeHandler);
      };
    }
  }, [dispatch, quillInstance, selectionChangeHandlerCallback, tags]);

  useEffect(() => {
    const editor = quillInstance?.getEditor();
    if (editor) {
      const selectionChangeHandler = (range: Range) => {
        if (range && editor.hasFocus()) {
          if (range.length) {
            if (showAlert) {
              setShowAlert(false);
            }
            const text = editor.getText(range.index, range.length);
            if (isNumeric(text)) {
              dispatch(
                filterTags(tags.filter((t) => t.tagType === TagType.NumTags))
              );
            } else {
              let wordTags = tags.filter((t) => t.tagType === TagType.WordTags);
              const text = editor.getText(range.index, range.length).trim();
              if (text.length) {
                wordTags = wordTags.filter((t) => t.name !== 'customPause');
              }
              dispatch(filterTags(wordTags));
            }
          } else {
            dispatch(
              filterTags(tags.filter((t) => t.tagType === TagType.SentenceTags))
            );
          }
        }
      };
      editor.on('selection-change', selectionChangeHandler);
      return () => {
        editor.off('selection-change', selectionChangeHandler);
      };
    }
  }, [
    dispatch,
    quillInstance,
    setShowAlert,
    showAlert,
    selectionChangeHandlerCallback,
    tags,
  ]);

  const handleClear = useCallback(() => {
    const editor = quillInstance!.getEditor();
    let selection = editor.getSelection();
    if (selection) {
      if (selection.length) {
        editor.removeFormat(selection.index, selection.length, 'user');
      } else {
        const tagsToIterate = tags.filter(
          (t) => t.tagType === TagType.SentenceTags
        );
        let clear = false;
        let before;
        let after;
        tagsToIterate.every((tag) => {
          before = tag.id in editor.getFormat(selection!);
          after =
            tag.id in
            editor.getFormat({ index: selection!.index + 1, length: 0 });
          if (before || after) {
            clear = true;
          }
          return !clear;
        });
        if (clear) {
          let index = selection.index;
          if (before) {
            index = selection.index - 1;
          }
          editor.deleteText(index, 1, 'user');
        }
      }
      trackEvent({
        eventName: AnalyticsEvent.ClearAll,
      });
      selectionChangeHandlerCallback(editor, selection);
    }
  }, [quillInstance, selectionChangeHandlerCallback, tags, trackEvent]);

  const handleBlur = useCallback(() => {
    setTabsFocus(false);
  }, []);

  const handleFocus = useCallback(() => {
    setTabsFocus(true);
  }, []);

  return (
    <TabsFocusContext.Provider value={tabsFocus}>
      <Stack
        tabIndex={-1}
        onBlur={handleBlur}
        onFocus={handleFocus}
        className="h-100"
        direction="column"
        justifyContent="space-between"
      >
        <Stack.Item>
          {isSSML && <CopySelectedFragment />}
          <Skip />
          <PreProcessButton />
          <div className="p-x-3 p-t-3">
            <Stack className="m-b-2" justifyContent="space-between">
              <Stack.Item className="fw-600 font-size-sm">Tags</Stack.Item>
              <Stack.Item>
                <Button inline disabled={clearDisabled} onClick={handleClear}>
                  Clear all
                </Button>
              </Stack.Item>
            </Stack>
            {!!tagsApply.length ? (
              tagsApply.map((tag) => <Tag key={tag.id} tag={tag} />)
            ) : (
              <p>No available tags</p>
            )}
            {showAlert && (
              <Alert icon="info" className="m-b-3" type="warning">
                Select fragment of the text to see all the available tags
              </Alert>
            )}
          </div>
          <InsertText />
          {isTagsFound ? <Presets quillInstance={quillInstance} /> : null}
        </Stack.Item>
      </Stack>
    </TabsFocusContext.Provider>
  );
};
