import { FC } from 'react';
import classnames from 'classnames';

import { Overlay, OverlayProps } from '../Overlay/Overlay';

import Styles from './modal.module.scss';

export enum ModalSizes {
  XS = 'XS',
  S = 'S',
  M = 'M',
  MM = 'MM',
  L = 'L',
}

interface ModalProps extends OverlayProps {
  size?: ModalSizes;
  className?: string;
  headerClassName?: string;
  header?: React.ReactNode;
  isCloseIcon?: boolean;
  isClosableOnClickOutside?: boolean;
  noPadding?: boolean;
  gray?: boolean;
}

export const Modal: FC<ModalProps> = ({
  children,
  className,
  headerClassName,
  open,
  portal = true,
  size = ModalSizes.M,
  header,
  isCloseIcon,
  isClosableOnClickOutside = true,
  noPadding,
  onClose,
  gray = false,
}) => {
  const modalClassNames = classnames(
    Styles.modal,
    Styles[`modal-${size}`],
    {
      [Styles['modal-no-padding']]: noPadding,
    },
    {
      [Styles['bg-gray']]: gray,
    },
    className
  );

  return (
    <Overlay
      backdrop
      onClose={isClosableOnClickOutside ? onClose : undefined}
      open={open}
      portal={portal}
    >
      <div className={modalClassNames}>
        {isCloseIcon && (
          <span className={Styles['close-icon']} onClick={onClose}>
            &#x2715;
          </span>
        )}
        {header && (
          <span className={classnames(Styles['header'], headerClassName)}>
            {header}
          </span>
        )}
        {children}
      </div>
    </Overlay>
  );
};
