import React, { FC, useCallback, useState } from 'react';
import { activeChapterSelector } from 'redux/reducers/chapters/selectors';
import { Modal } from 'modules/common/components/Modal/Modal';
import { useAppSelector } from 'modules/common/hooks/redux';
import Styles from './preprocessingreportconfirmationpopup.module.scss';
import { ConfirmationPlaceholder } from '../ConfirmationPlaceholder';

interface Props {
  isOpen: boolean;
  setClose(): void;
  onSaveChanges(): Promise<void>;
}

const PreProcessingConfirmationPopup: FC<Props> = ({
  isOpen,
  setClose,
  onSaveChanges,
}) => {
  const currentChapter = useAppSelector(activeChapterSelector);
  const [isLoading, setIsLoading] = useState(false);

  const saveChapterInfo = useCallback(async () => {
    setIsLoading(true);
    await onSaveChanges();
    setIsLoading(false);
  }, [onSaveChanges]);

  return (
    <Modal
      open={isOpen}
      onClose={setClose}
      isCloseIcon
      gray
      header="Changes not saved yet"
      className={Styles['container']}
    >
      <ConfirmationPlaceholder
        isLoading={isLoading}
        selectedOption={currentChapter?.name}
        onSave={saveChapterInfo}
        onEdit={setClose}
      />
    </Modal>
  );
};

export default PreProcessingConfirmationPopup;
