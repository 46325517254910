import { Dispatch, FC, SetStateAction } from 'react';
import { useGetRequestedProjectsQuery } from 'redux/api/app.api';
import ProjectsView from 'modules/dashboard/ProjectsView/ProjectsView';
import ProjectItem from './ProjectItem';
import { EditorRequestedProjects } from 'modules/dashboard/ProjectsTableConfig';

interface Props {
  viewAllRequestedProjects: boolean;
  setViewAllRequestedProjects: Dispatch<SetStateAction<boolean>>;
}

const RequestedProjects: FC<Props> = ({
  viewAllRequestedProjects,
  setViewAllRequestedProjects,
}) => {
  const { data, isFetching } = useGetRequestedProjectsQuery();

  return (
    <ProjectsView<EditorRequestedProjects>
      viewProjects={viewAllRequestedProjects}
      setViewProjects={setViewAllRequestedProjects}
      data={data?.data}
      isFetching={isFetching}
      render={(project, isFetching) => (
        <ProjectItem project={project} isFetching={isFetching} />
      )}
      header="project request"
    />
  );
};

export default RequestedProjects;
