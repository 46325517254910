import React, { useRef, useState, ChangeEvent, FC } from 'react';
import Styles from './fileuploader.module.scss';
import { Button } from '../Button/Button';
import classnames from 'classnames';

export interface IFileUploader {
  onFileUpload: (file: any) => void;
  accept?: string;
  disabled?: boolean;
  disabledName?: string;
}
const FileUploader: FC<IFileUploader> = ({
  onFileUpload,
  accept,
  disabled,
  disabledName,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string>('');

  const onDelete = () => {
    setFileName('');
    if (fileRef.current) {
      fileRef.current.value = '';
    }
    onFileUpload('');
  };

  const onAdd = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const changeHandlerFile = (evt: ChangeEvent<HTMLInputElement>) => {
    onFileUpload(evt.currentTarget.files?.[0]);
    setFileName(
      evt.currentTarget.files?.[0] ? evt.currentTarget.files?.[0].name : ''
    );
  };

  return (
    <div
      className={classnames(Styles['file-uploader'], {
        [Styles['file-uploader-disabled']]: disabled,
      })}
    >
      <input
        type="file"
        ref={fileRef}
        onChange={changeHandlerFile}
        accept={accept}
        disabled={disabled}
      />
      <span
        className={classnames(
          Styles['label'],
          { [Styles['selected']]: fileName.length },
          { [Styles['disabled']]: disabled }
        )}
        onClick={onAdd}
      >
        {disabledName
          ? disabledName
          : fileName.length
          ? fileName
          : 'No File Selected'}
      </span>
      {fileName.length ? (
        <Button small danger inline onClick={onDelete} disabled={disabled}>
          Delete
        </Button>
      ) : (
        <Button small onClick={onAdd} disabled={disabled}>
          Choose
        </Button>
      )}
    </div>
  );
};

export default FileUploader;
