import {
  SocketEventType,
  SocketNotification,
} from 'modules/common/models/Notifications';

export type messageHandlerFn<T> = (messageData: SocketNotification<T>) => void;

class WebSocketService {
  private static instance: WebSocketService | undefined;

  private websocket: WebSocket | undefined;
  private isOpen: boolean = false;
  private userToken: string | undefined = undefined;
  private messageListener: Map<
    SocketEventType,
    {
      type: SocketEventType;
      listener: messageHandlerFn<any>;
    }[]
  > = new Map();

  private constructor(token: string) {
    this.websocket = new WebSocket(process.env.REACT_APP_WS_URL || '');
    this.websocket.onopen = this.onConnectionOpenHandler;
    this.websocket.onmessage = this.onMessageHandler;
    this.websocket.onclose = this.onConnectionCloseHandler;
    this.userToken = token;
  }

  private cleanUpSocketInit() {
    this.websocket?.close();
  }

  private onConnectionOpenHandler = () => {
    this.isOpen = true;
    // this is the custom route that we got connected in the aws
    this.websocket?.send(
      JSON.stringify({
        action: 'registry',
        token: this.userToken,
      })
    );
  };

  private onConnectionCloseHandler = () => {
    this.isOpen = false;
    this.websocket = undefined;
    this.userToken = undefined;
  };

  private onMessageHandler = (ev: MessageEvent) => {
    let data: SocketNotification<any> = ev?.data;

    if (!data) return;

    try {
      data = JSON.parse(ev?.data);
    } catch (e) {
      return;
    }

    if (!data.notification_type) {
      const errorTypeEvents = this.messageListener.get(SocketEventType.Error);

      errorTypeEvents?.forEach((item) => {
        item.listener(data);
      });
      return;
    }

    // first run all the event that has type All
    const allTypeEvents = this.messageListener.get(SocketEventType.All);
    const specificTypeEvents = this.messageListener.get(data.notification_type);

    allTypeEvents?.forEach((item) => {
      item.listener(data);
    });

    specificTypeEvents?.forEach((item) => {
      item.listener(data);
    });
  };

  /**
   *  @description Used by application to register different listeners for
   *  different message types
   */
  public addMessageListener<T>(
    type: SocketEventType,
    listener: messageHandlerFn<T>
  ) {
    const prevListeners = this.messageListener.get(type);
    if (prevListeners) {
      this.messageListener.set(
        type,
        prevListeners.concat([{ type, listener }])
      );
      return;
    }
    this.messageListener.set(type, [{ type, listener }]);
  }

  /**
   *  @description Used by application to deregister different listeners for
   *  different message types
   */
  public removeMessageListener<T>(
    type: SocketEventType,
    listener: messageHandlerFn<T>
  ) {
    const prevListeners = this.messageListener.get(type);
    if (prevListeners) {
      this.messageListener.set(
        type,
        prevListeners.filter((item) => item.listener !== listener)
      );
    }
  }

  public static initWSService(token: string) {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService(token);
      return WebSocketService.instance;
    }

    return WebSocketService.instance;
  }

  public static cleanUpWSService() {
    if (WebSocketService.instance) {
      WebSocketService.instance.cleanUpSocketInit();
      WebSocketService.instance = undefined;
    }
  }
}

export const getWSService = WebSocketService.initWSService;

export const cleanUpWSService = WebSocketService.cleanUpWSService;
