import { FC, MouseEvent, useState } from 'react';
import { Icon } from 'icons';
import { ActionMenu } from 'modules/common/components/ActionMenu/ActionMenu';
import { useActions } from 'modules/common/hooks/useActions';
import { useDeleteProjectMutation, useCopyProjectMutation } from 'redux/api/app.api';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalSizes } from 'modules/common/components/Modal/Modal';
import { Button } from 'modules/common/components/Button/Button';
import { Input } from 'modules/common/components/Input/Input';

interface Props {
  id: string;
  name: string;
}

const ActionsCell: FC<Props> = ({ id, name }) => {
  const navigate = useNavigate();
  const { actionMenuOpen, handleCloseActions, handleOpenActions } = useActions();
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [newProjectName, setNewProjectName] = useState(`Copy of ${name}`);

  const [deleteProject, { isLoading: isDeleteLoading }] = useDeleteProjectMutation();
  const [copyProject, { isLoading: isCopyLoading }] = useCopyProjectMutation();

  const handleDelete = async (e: MouseEvent) => {
    e.stopPropagation();
    if (isDeleteLoading) return;
    handleCloseActions();
    await deleteProject(id);
  };

  const handleEditMode = (e: MouseEvent) => {
    e.stopPropagation();
    if (isDeleteLoading || isCopyLoading) return;
    navigate(`/projects/edit/${id}`);
  };

  const handleDuplicateClick = (e: MouseEvent) => {
    e.stopPropagation();
    handleCloseActions();
    setIsDuplicateModalOpen(true);
  };

  const handleDuplicate = async () => {
    if (isDeleteLoading || isCopyLoading) return;
    await copyProject({
      source_project_id: id,
      new_project_name: newProjectName
    });
    setIsDuplicateModalOpen(false);
  };

  return (
    <>
      <ActionMenu
        direction="bl"
        portal
        open={actionMenuOpen}
        width="xs"
        trigger={<Icon onClick={handleOpenActions} name="more_horiz" size={20} />}
        onClickOutside={handleCloseActions}
      >
        <ActionMenu.Item onClick={handleEditMode}>Edit</ActionMenu.Item>
        <ActionMenu.Item onClick={handleDuplicateClick}>Duplicate</ActionMenu.Item>
        <ActionMenu.Item onClick={handleDelete}>Delete</ActionMenu.Item>
      </ActionMenu>

      <Modal
        open={isDuplicateModalOpen}
        onClose={() => setIsDuplicateModalOpen(false)}
        size={ModalSizes.S}
        isCloseIcon
        header="Duplicate Project"
      >
        <div className="m-b-3 m-t-3">
          <Input
            label="New Project Name"
            value={newProjectName}
            onChange={(e: any) => setNewProjectName(e.target.value)}
          />
        </div>
        <Button onClick={handleDuplicate} disabled={!newProjectName.trim() || isCopyLoading} loading={isCopyLoading}>
          Duplicate
        </Button>
      </Modal>
    </>
  );
};

export default ActionsCell;
