import { createContext } from 'react';
import ReactQuill from 'react-quill';

interface IContext {
  setQuillInstance: (param: ReactQuill | null) => void;
  quillInstance: ReactQuill | null;
}

const QuillContext = createContext({} as IContext);

export default QuillContext;
