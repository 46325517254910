import { MouseEvent, useCallback, useState } from 'react';

export function useActions(): {
  actionMenuOpen: boolean;
  hovered: boolean;
  handleOpenActions: (e: MouseEvent) => void;
  handleCloseActions: () => void;
  setActionMenuOpen: (b: boolean) => void;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
} {
  const [actionMenuOpen, setActionMenuOpen] = useState<boolean>(false);
  const [hovered, setHovered] = useState(false);

  const handleOpenActions = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setActionMenuOpen(true);
  }, []);

  const handleCloseActions = useCallback(() => {
    setActionMenuOpen(false);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setActionMenuOpen(false);
    setHovered(false);
  }, [setActionMenuOpen]);

  return {
    actionMenuOpen,
    handleOpenActions,
    handleCloseActions,
    setActionMenuOpen,
    hovered,
    handleMouseEnter,
    handleMouseLeave,
  };
}
