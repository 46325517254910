import { FC } from 'react';
import classnames from 'classnames';

import Styles from './stack.module.scss';

interface StackItemProps {
  className?: string;
  fill?: boolean;
  flex?: boolean;
  grow?: number | 0 | 1 | 2 | 3 | 4 | 5;
  shrink?: number | 0 | 1 | 2 | 3 | 4 | 5;
  basis?: string;
}

export const StackItem: FC<StackItemProps> = ({
  children,
  fill,
  flex,
  shrink,
  grow,
  className,
  basis,
}) => {
  return (
    <div
      className={classnames(Styles.item, className, {
        [Styles.fill]: fill,
        [Styles.flex]: flex,
        [Styles[`shrink-${shrink}`]]: shrink !== undefined,
        [Styles[`grow-${grow}`]]: grow !== undefined,
      })}
      style={{ flexBasis: basis }}
    >
      {children}
    </div>
  );
};
