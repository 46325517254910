import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useGetVoicesQuery } from 'redux/api/app.api';
import LabelInput from 'modules/common/components/LabeledInputs/LabelInput';
import LabelSelect from 'modules/common/components/LabeledInputs/LabelSelect';
import { Stack } from 'modules/common/components/Stack/Stack';
import {
  LabelValue,
  ParsedVoiceValue,
  SelectValue,
} from 'modules/common/types';
import {
  formUpdateFunType,
  FormUpdateState,
} from 'modules/projects/Project/Project';
import LabeledFileInput from 'modules/common/components/LabeledInputs/LabeledFileInput';
import { VoiceSettingsDisclosure } from 'modules/common/components/VoiceSettingsDisclosure';
import {
  ProjectModel,
  VoiceGenerationSettings,
} from 'modules/common/models/Project';
import { VoiceSelect } from 'modules/common/components/VoiceSelect';

import Styles from '../project.module.scss';
import {
  ElevenLabsVoiceSettings,
  KeyedResultForm,
  ProjectCreationFormSteps,
  ResultForm,
} from '../types';
import {
  DEFAULT_TTS_ENGINE_ENHANCED_WITH_PREPROCESSING,
  DEFAULT_VOICE_VALUE,
  Label,
  TTS_ENGINES,
} from './lib';
import { SwitchingModelRecommendMessage } from './SwitchingModelRecommendMessage';
import { GenerateAudio } from './GenerateAudio';
import { AudioPlayerProjectProps } from 'modules/common/hooks/useAudioPlayerProject';

interface IInformation {
  errorMessage: string;
  formData: ResultForm | null;
  onFormChange: formUpdateFunType;
  onFileUpload: (s: any) => void;
  projectCreationFormStep: ProjectCreationFormSteps;
  playerProps: AudioPlayerProjectProps;
  previewText: string;
  setPreviewText: React.Dispatch<React.SetStateAction<string>>;
  project?: ProjectModel | null;
  projectVoiceSettings?: VoiceGenerationSettings;
}

export const Information: FC<IInformation> = ({
  errorMessage,
  formData,
  onFormChange,
  onFileUpload,
  projectCreationFormStep,
  playerProps,
  previewText,
  setPreviewText,
  project,
  projectVoiceSettings,
}) => {
  const isEdit = !!project;
  const { data: voicesData, isLoading } = useGetVoicesQuery();

  const elevenLabsVoiceDefaultSettings =
    voicesData?.defaultVoiceSettings?.elevenlabs;
  const ttsEnginesEnhancedWithPreprocessing = useMemo(
    () =>
      TTS_ENGINES.map((engine) => {
        if (voicesData?.enginesWithPreprocessing[engine.value])
          return { ...engine, pre_process: true };
        return { ...engine, pre_process: false };
      }),
    [voicesData?.enginesWithPreprocessing]
  );
  const chosenProjectEngine: Label | undefined = project
    ? ttsEnginesEnhancedWithPreprocessing.find(
        (item) => item.value === project.ttsEngine
      )
    : undefined;

  const ttsEngine = useMemo<Label>(() => {
    if (!formData)
      return chosenProjectEngine || ttsEnginesEnhancedWithPreprocessing[0];
    const matchingEngine = ttsEnginesEnhancedWithPreprocessing.find(
      ({ value }) => value === formData[KeyedResultForm.ttsEngine]
    );

    return matchingEngine || DEFAULT_TTS_ENGINE_ENHANCED_WITH_PREPROCESSING;
  }, [chosenProjectEngine, formData, ttsEnginesEnhancedWithPreprocessing]);
  const [voice, setVoice] = useState<ParsedVoiceValue | null>(
    DEFAULT_VOICE_VALUE
  );
  const [model, setModel] = useState<LabelValue>({ label: '', value: '' });

  const voicesOption = useMemo(
    () =>
      voicesData?.voices && ttsEngine && ttsEngine.value
        ? (voicesData?.voices[
            ttsEngine.value.toLowerCase()
          ] as ParsedVoiceValue[])
        : [],
    [ttsEngine, voicesData]
  );

  const isElevenLabsVoiceSettingStep =
    projectCreationFormStep === 'elevenLabsVoiceSetting';

  const isElevenLabsTTSEngineSelected = ttsEngine?.value === 'elevenlabs';

  useEffect(() => {
    if (
      project &&
      voice &&
      voice.value !== projectVoiceSettings?.voice &&
      voicesData?.voices[project.ttsEngine.toLowerCase()]
    ) {
      let projectVoice: ParsedVoiceValue | undefined = voicesData.voices[
        project.ttsEngine.toLowerCase()
      ].find(
        (item: SelectValue) =>
          item && item.value === projectVoiceSettings?.voice
      );
      setVoice(projectVoice ? projectVoice : DEFAULT_VOICE_VALUE);
    }
  }, [voice, voicesData, project, projectVoiceSettings]);

  const onModelChange = useCallback(
    (value: SelectValue | string | null) => {
      let v;
      if (!value) {
        v = { label: '', value: '' };
      } else if (typeof value === 'string') {
        v = voicesData?.models.elevenlabs.find(
          (option) => option.value === value
        ) ?? { label: '', value: '' };
      } else {
        v = value;
      }
      setModel(v);
      onFormChange(FormUpdateState.Key, {
        name: KeyedResultForm.elevenLabsModel,
        value: v.value,
      });
    },
    [onFormChange, voicesData?.models.elevenlabs]
  );

  const onChangeTTSEngine = (value: SelectValue) => {
    if (ttsEngine?.value === 'elevenlabs' && value?.value !== 'elevenlabs') {
      onModelChange(null);
    } else if (
      ttsEngine?.value !== 'elevenlabs' &&
      value?.value === 'elevenlabs'
    ) {
      onModelChange({ label: '', value: '' });
    }
    onFormChange(FormUpdateState.Key, {
      name: KeyedResultForm.ttsEngine,
      value: value ? value.value : '',
    });
  };

  const onVoiceChange = useCallback(
    (value) => {
      setVoice(value as ParsedVoiceValue);
      onFormChange(FormUpdateState.Key, {
        name: KeyedResultForm.voice,
        value: value ? value.value : '',
      });
    },
    [onFormChange]
  );

  const onVoiceSettingsChange = useCallback(
    (value: ElevenLabsVoiceSettings) => {
      onFormChange(FormUpdateState.Key, {
        name: KeyedResultForm.elevenLabsVoiceSettings,
        value,
      });
    },
    [onFormChange]
  );

  // Set the initially selected voice and change it on every ttsEngine change.
  useEffect(() => {
    if (
      voicesData &&
      ttsEngine &&
      voicesData.voices[ttsEngine.value.toLowerCase()]
    ) {
      const theFirstVoice = voicesData.voices[ttsEngine.value.toLowerCase()][0];
      if (theFirstVoice) {
        onVoiceChange(theFirstVoice);
        onModelChange(voicesData.models.elevenlabs[0]);
      }
    }
  }, [onModelChange, onVoiceChange, ttsEngine, voicesData]);

  const onProjectNameChange = (value: string) => {
    onFormChange(FormUpdateState.Key, { name: KeyedResultForm.name, value });
  };

  const isSelectedModelMatchRecommended =
    !voice?.high_quality_base_model_ids?.length ||
    voice?.high_quality_base_model_ids.includes(model.value);

  return (
    <>
      <LabelInput
        large
        placeholder="Enter project name"
        labelText="Project name"
        value={formData?.[KeyedResultForm.name] || ''}
        onChangeWithValueHandler={onProjectNameChange}
      />
      <Stack spacing="4" direction="column">
        {!isElevenLabsVoiceSettingStep && (
          <LabelSelect
            size="large"
            labelText="TTS Engine"
            value={ttsEngine}
            onChange={onChangeTTSEngine}
            options={ttsEnginesEnhancedWithPreprocessing}
            disabled={isEdit}
          />
        )}
        {isElevenLabsVoiceSettingStep && (
          <>
            <LabelSelect
              size="large"
              labelText="Model"
              value={model}
              isSearchable
              onChange={onModelChange}
              options={voicesData?.models.elevenlabs || []}
            />
            <SwitchingModelRecommendMessage
              elevenLabsModels={voicesData?.models.elevenlabs || []}
              selectedModel={model}
              high_quality_base_model_ids={voice?.high_quality_base_model_ids}
              onModelChange={onModelChange}
            />
          </>
        )}
        {(isElevenLabsVoiceSettingStep || !isElevenLabsTTSEngineSelected) && (
          <VoiceSelect
            playerProps={playerProps}
            previewText={previewText}
            size="large"
            labelText="Voice"
            isSearchable
            value={voice}
            onChange={onVoiceChange}
            options={voicesOption}
            disabled={isLoading || isEdit}
            isLoading={isLoading}
          />
        )}
        {isElevenLabsVoiceSettingStep &&
          formData?.[KeyedResultForm.elevenLabsVoiceSettings] && (
            <VoiceSettingsDisclosure
              label="Voice settings"
              values={
                formData[
                  KeyedResultForm.elevenLabsVoiceSettings
                ] as ElevenLabsVoiceSettings
              }
              isSelectedModelMatchRecommended={isSelectedModelMatchRecommended}
              defaultValues={elevenLabsVoiceDefaultSettings}
              modelSelected={model}
              onVoiceSettingsChange={onVoiceSettingsChange}
            />
          )}
        {isElevenLabsVoiceSettingStep && formData && (
          <GenerateAudio
            previewText={previewText}
            setPreviewText={setPreviewText}
            formData={formData}
            playerProps={playerProps}
          />
        )}
        <LabeledFileInput
          labelText="Upload file (.pdf or .epub)"
          onFileUpload={onFileUpload}
          accept=".epub, .pdf"
          disabledName={project?.fileName}
          disabled={isEdit}
        />
      </Stack>

      {errorMessage && (
        <span className={Styles.errorMessage}>{errorMessage}</span>
      )}
    </>
  );
};

export default Information;
