import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Modal } from 'modules/common/components/Modal/Modal';
import useModal from 'modules/common/hooks/useModal';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Button } from 'modules/common/components/Button/Button';
import Styles from './confirmationpopup.module.scss';

export type ConfirmationSetup = {
  buttonChild?: ReactNode;
  bodyChild?: ReactNode;
  onCloseCleanupFn?: () => void;
  onAccept?: () => void;
  header?: string;
};

interface Props {
  setConfirm: (func: (obj: ConfirmationSetup) => void) => void;
}

const ConfirmationPopup: FC<Props> = ({ setConfirm }) => {
  const { isOpen, setOpen, setClose } = useModal();
  const [popupState, setPopupState] = useState<ConfirmationSetup | null>(null);

  const setConfirmation = useCallback(
    (ConfirmationSetup: ConfirmationSetup) => {
      setPopupState(ConfirmationSetup);
      setOpen();
    },
    [setOpen]
  );

  const onCloseConfirmation = () => {
    popupState?.onCloseCleanupFn?.();
    setClose();
  };

  const onAcceptConfirmation = () => {
    popupState?.onAccept?.();
    setClose();
  };

  useEffect(() => {
    setConfirm((ConfirmationSetup: ConfirmationSetup) => {
      setConfirmation(ConfirmationSetup);
    });
  }, [setConfirm, setConfirmation]);

  return popupState ? (
    <Modal
      open={isOpen}
      onClose={onCloseConfirmation}
      isCloseIcon
      gray
      header={
        popupState.header
          ? popupState.header
          : 'Are you Sure you want to do this Action'
      }
    >
      <div className={classNames('m-t-2', Styles['confirm-body'])}>
        {popupState.bodyChild}
        <Stack justifyContent="center">
          <Button onClick={onAcceptConfirmation} className="m-t-3" large>
            {popupState.buttonChild ? popupState.buttonChild : 'Confirm'}
          </Button>
        </Stack>
      </div>
    </Modal>
  ) : null;
};

export default ConfirmationPopup;
