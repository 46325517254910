import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { BookmarkModel } from 'modules/common/models/Bookmark';
import BookmarksInstanceApi from 'modules/edit/api/Bookmarks';
import { rejectRequest } from 'utils/rejectRequest';
import { AxiosError } from 'axios';

export const BookmarksAdapter = createEntityAdapter<BookmarkModel>({
  selectId: (bookmark) => bookmark.id,
});

const initialState = {
  ...BookmarksAdapter.getInitialState(),
};

export const fetchBookmarks = createAsyncThunk<BookmarkModel[], string>(
  'bookmarks/fetchBookmarks',
  async (projectId, { rejectWithValue }) => {
    try {
      return await BookmarksInstanceApi.getAll(projectId);
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

export const addBookmark = createAsyncThunk<
  BookmarkModel,
  { bookmark: Omit<BookmarkModel, 'id'>; projectId: string }
>(
  'bookmarks/addBookmarks',
  async ({ bookmark, projectId }, { rejectWithValue }) => {
    try {
      const data = await BookmarksInstanceApi.add(bookmark, projectId);
      return data.result;
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

// this should be checked what the BE returns
export const editBookmark = createAsyncThunk<
  BookmarkModel,
  {
    id: BookmarkModel['id'];
    newValue: BookmarkModel;
    projectId: string;
  }
>(
  'bookmarks/editBookmark',
  async ({ id, newValue, projectId }, { rejectWithValue }) => {
    try {
      const data = await BookmarksInstanceApi.edit(newValue, projectId);
      return data.result;
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

export const deleteBookmark = createAsyncThunk<
  BookmarkModel['id'],
  { id: BookmarkModel['id']; projectId: string }
>(
  'bookmarks/deleteBookmark',
  async ({ id, projectId }, { rejectWithValue }) => {
    try {
      await BookmarksInstanceApi.delete(id, projectId);
      return id;
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

const BookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState,
  reducers: {
    resetBookmarks: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookmarks.fulfilled, (state, { payload }) => {
        BookmarksAdapter.setAll(state, payload);
      })
      .addCase(addBookmark.fulfilled, (state, { payload }) => {
        BookmarksAdapter.addOne(state, payload);
      })
      .addCase(editBookmark.fulfilled, (state, { payload }) => {
        const { id, ...changes } = payload;
        BookmarksAdapter.updateOne(state, { id, changes });
      })
      .addCase(deleteBookmark.fulfilled, (state, { payload }) => {
        BookmarksAdapter.removeOne(state, payload);
      });
  },
});

export const { resetBookmarks } = BookmarksSlice.actions;

export default BookmarksSlice.reducer;
