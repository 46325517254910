import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
} from 'react';
import classnames from 'classnames';

import Styles from './textarea.module.scss';

export interface TextareaProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  autoresize?: boolean;
  onChangeValue?: (str: string) => void;
}

export const Textarea: FC<TextareaProps> = ({
  onChangeValue,
  className,
  value,
  autoresize = true,
  ...props
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const resizeTextArea = () => {
    if (autoresize && textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + 'px';
    }
  };

  useEffect(resizeTextArea, [autoresize, value]);

  const changeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeValue?.(e.target.value);
  };

  return (
    <textarea
      value={value}
      {...props}
      ref={textAreaRef}
      className={classnames(Styles['textarea'], className)}
      onChange={onChangeValue ? changeHandler : props.onChange}
    />
  );
};

export default Textarea;
