import React, { FC, useMemo, useState } from 'react';
import classnames from 'classnames';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Notification } from 'modules/common/models/Notifications';
import { SomethingWentWrong } from 'modules/common/components/Notify';
import useLoading from 'modules/common/hooks/useLoading';
import { getStatusHash } from 'modules/common/constants/functions';
import { prettifyDateShort } from 'utils';

import Styles from './notificaitons.module.scss';
import { ProjectStatus } from 'modules/common/types';

interface Props {
  notification: Notification<any>;
  readANotification: (id: string) => void;
}

const NotificationItem: FC<Props> = ({ notification, readANotification }) => {
  // local loading for the whole tree re-render as well
  const { isLoading, setLoading } = useLoading();

  // local state that inherits from the data and changes it locally to avoid whole notification tree re-render
  const [isRead, setRead] = useState(notification.isRead);

  const status = useMemo(() => {
    return getStatusHash();
  }, []);

  let text = `Someone commented "${notification.message}"`;

  if (status[notification.message as ProjectStatus]) {
    text = `Status has been changed to ${
      status[notification.message as ProjectStatus]
    }`;
  }

  const unReadHandler = async (id: string) => {
    try {
      setLoading(true);
      await readANotification(id);
      setLoading(false);
      setRead(true);
    } catch (err) {
      SomethingWentWrong();
    }
  };

  return (
    <div
      className={classnames({
        [Styles['unread']]: !isRead,
        [Styles['disabled']]: isLoading,
      })}
      onClick={!isRead ? () => unReadHandler(notification.id) : undefined}
    >
      <Stack justifyContent="space-between">
        <Stack.Item className="t-truncate">
          <span className={classnames(Styles['dot'])} />
          <span className="fw-600">{notification.projectTitle}</span>
        </Stack.Item>
        <Stack.Item shrink={0}>
          {prettifyDateShort(notification.createdAt)}
        </Stack.Item>
      </Stack>
      <p className="font-size-xs">{text}</p>
    </div>
  );
};

export default NotificationItem;
