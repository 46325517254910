import { VoiceDescription } from '../../types';
import Styles from './description.module.scss';
import { PopoverInfo } from './PopoverInfo';

export const Description: React.FC<VoiceDescription> = ({
  label,
  popoverText,
}) => (
  <div className={Styles['Description']}>
    <div>{label}</div>
    {popoverText && <PopoverInfo>{popoverText}</PopoverInfo>}
  </div>
);
