import Quill from 'quill';

declare global {
  interface Navigator {
    userAgentData: {
      platform: string;
    };
  }
}

const Keyboard = Quill.import('modules/keyboard');

export class KeyboardModule extends Keyboard {
  static platform =
    navigator?.userAgentData?.platform || navigator?.platform || 'unknown';

  static shortKey = /Mac/i.test(KeyboardModule.platform)
    ? 'metaKey'
    : 'ctrlKey';

  constructor(quill: Quill, options: any) {
    super(quill, options);
    this.preventDefault(quill);
  }

  handleKeyDown(e: KeyboardEvent) {
    const allowedKeys = [
      KeyboardModule.keys.LEFT,
      KeyboardModule.keys.RIGHT,
      KeyboardModule.keys.UP,
      KeyboardModule.keys.DOWN,
    ];
    const allowedBindings = ['r', 'c'];
    // console.log(e);

    if (
      allowedBindings.includes(e.key) &&
      e[KeyboardModule.shortKey as keyof KeyboardEvent]
    ) {
      return;
    }

    if (!allowedKeys.includes(e.keyCode)) {
      e.preventDefault();
    }
  }

  preventDefault(quill: Quill) {
    quill.root.oncut = function (e) {
      e.preventDefault();
    };
    quill.root.onpaste = function (e) {
      e.preventDefault();
    };
    quill.root.ondrop = function (e) {
      e.preventDefault();
    };

    delete this.bindings[Keyboard.keys.ENTER];
    delete this.bindings[Keyboard.keys.BACKSPACE];
    delete this.bindings[Keyboard.keys.DELETE];
    quill.root.onkeydown = this.handleKeyDown.bind(this);
  }
}
