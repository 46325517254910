import Quill from 'quill';
import { Range } from 'react-quill';
import { updateProjectTOCPage } from 'redux/reducers/project/projectSlice';
import { ChapterIconBlot } from 'modules/edit/Editor/Blots/ChapterIconBlot';
import { useAppDispatch } from 'modules/common/hooks/redux';
import { useGetNewChapterId } from './lib';

export const useAddChapterTag = ({ editor }: { editor: Quill | undefined }) => {
  const dispatch = useAppDispatch();
  const getNewChapterId = useGetNewChapterId();

  return ({
    cachedRange,
    newChapterName,
    isSubChapterShouldBeCreated,
  }: {
    isSubChapterShouldBeCreated: boolean;
    cachedRange: Range | undefined;
    newChapterName: string;
  }) => {
    if (!editor || !cachedRange) return;
    let cursor = cachedRange.index;

    const newId = getNewChapterId(editor, cursor);

    if (newId === undefined) return;

    editor.insertText(
      cursor,
      '\u00A0',
      ChapterIconBlot.blotName,
      JSON.stringify({
        id: newId,
        chapterText: newChapterName
          ? newChapterName
          : isSubChapterShouldBeCreated
          ? `Sub-chapter ${newId}`
          : `Chapter ${newId}`,
        isSubChapter: isSubChapterShouldBeCreated,
      }),
      'user'
    );

    const page = editor.root.childNodes[0] as HTMLElement;
    const pageIdSearchParam = new URLSearchParams(window.location.search).get(
      'pageId'
    );
    if (pageIdSearchParam) {
      dispatch(
        updateProjectTOCPage({
          id: pageIdSearchParam,
          innerHTML: page.innerHTML,
          textContent: page.textContent || '',
          hasChapterTag: true,
        })
      );
    }
  };
};
