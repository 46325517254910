import { Dict } from 'modules/common/types';
import SaveCacheApiInstance, {
  SaveCacheResponse,
} from 'modules/edit/api/SaveCache';

/***
 * During S3 save the editor will be disabled and not timer should start
 * */
export class EditorSave {
  private static _instance: EditorSave;
  private _isRequestPending = false;
  private _chapterId = '';
  private _projectId = '';
  _lastUnSavedState = '';
  private _intervalId: ReturnType<typeof setInterval> | null = null;

  private constructor() {}

  public static getInstance(): EditorSave {
    if (!EditorSave._instance) {
      EditorSave._instance = new EditorSave();
    }

    return EditorSave._instance;
  }

  public async saveCacheImmediately() {
    if (!this._isRequestPending && !!this._lastUnSavedState) {
      this._isRequestPending = true;
      await SaveCacheApiInstance.set(
        this._projectId,
        this._chapterId,
        this._lastUnSavedState
      );
      this._isRequestPending = false;
    }
  }

  /**
   * @description initialize when editor instance is defined
   * this should be called in order to initialize the instance itself
   * and if chapter change to delete the older instance
   * */
  public async startSaving(projectId: string, chapterId: string) {
    if (this._chapterId) {
      this.deleteEditorSaveStates();
    }

    this._chapterId = chapterId;
    this._projectId = projectId;
    this._intervalId = setInterval(async () => {
      if (!this._isRequestPending && !!this._lastUnSavedState) {
        this._isRequestPending = true;
        await SaveCacheApiInstance.set(
          this._projectId,
          this._chapterId,
          this._lastUnSavedState
        );
        this._isRequestPending = false;
      }
    }, 5000);
  }

  public async stopSaving() {
    if (this._intervalId) {
      clearInterval(this._intervalId);
      this._intervalId = null;
    }
  }

  public get isSaving(): boolean {
    return this._intervalId !== null;
  }

  /**
   * @description should be called on every change
   * */
  public saveText(str: string) {
    this._lastUnSavedState = str;
  }

  /**
   * @description should be called when the editor is unmounting new chapter or other page
   * */
  public deleteEditorSaveStates() {
    if (!this._chapterId) {
      return;
    }

    if (this._intervalId) {
      clearInterval(this._intervalId);
      this._intervalId = null;
    }
    this._lastUnSavedState = '';
    this._isRequestPending = false;
    this._chapterId = '';
    this._projectId = '';
  }

  /**
   * @description get Saved History Stack
   * */
  public async getSaveHistoryStack(): Promise<Dict<any> | null> {
    const historyStack = await SaveCacheApiInstance.get({
      chapterId: this._chapterId,
      projectId: this._projectId,
    });

    return this.parseHistoryStack(historyStack);
  }

  public parseHistoryStack(histObj: SaveCacheResponse): Dict<any> | null {
    const obj = histObj?.value ? JSON.parse(histObj.value) : null;

    return obj || null;
  }
}
