import { FC, useMemo } from 'react';
import { ProjectStatus } from 'modules/common/types';
import classNames from 'classnames';
import Styles from './statuscell.module.scss';
import { getStatusList } from 'modules/common/constants/functions';

interface Props {
  type: ProjectStatus;
}

const StatusCell: FC<Props> = ({ type }) => {
  const PROJECT_STATUSES: { [k in ProjectStatus]: string } = useMemo(
    () =>
      getStatusList().reduce((item, curr) => {
        const obj: { [k in ProjectStatus]: string } = { ...item };
        obj[curr.value as ProjectStatus] = curr.label;
        return obj;
      }, {} as { [k in ProjectStatus]: string }),
    []
  );

  return (
    <div
      className={classNames(Styles['container'], {
        [Styles['incomplete']]: type === ProjectStatus.incomplete,
        [Styles['in-review']]: type === ProjectStatus.in_review,
        [Styles['retake']]: type === ProjectStatus.re_take,
        [Styles['in-progress']]: type === ProjectStatus.in_progress,
        [Styles['pending']]: type === ProjectStatus.pending,
        [Styles['assigned']]: type === ProjectStatus.assigned,
        [Styles['deactivated']]: type === ProjectStatus.deactivated,
        [Styles['declined']]: type === ProjectStatus.declined,
        [Styles['new-project']]: type === ProjectStatus.new_project,
        [Styles['done']]: type === ProjectStatus.done,
      })}
    >
      {PROJECT_STATUSES[type]}
    </div>
  );
};

export default StatusCell;
