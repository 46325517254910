import { RBBlot } from 'modules/edit/v2/Editor/Blots/RBBlot';

export class FoundTextBlot extends RBBlot {
  static blotName = 'found-text';
  static className = 'rb-found-text';
  static tagName = 'div';
}

export class FoundTextCurrentBlot extends RBBlot {
  static blotName = 'found-text-current';
  static className = 'rb-found-text-current';
  static tagName = 'div';
  static dataAttrName = 'data-found-id';

  static create(value: string) {
    let node: HTMLElement = super.create();
    if (value) {
      node.setAttribute(this.dataAttrName, value);
      node.tabIndex = -1;
    }
    return node;
  }
}
