import { useCallback, useState } from 'react';

export default function useCheckboxes<T>(
  initiallyChecked: T[],
  totalLength?: number
): {
  checkedCheckboxes: Set<T>;
  setCheckboxes: (element: T | T[]) => void;
  unSetCheckBoxes: (element: T | T[]) => void;
  toggleCheckBoxes: (element: T | T[]) => void;
  setAllCheckboxes: (element: T[]) => void;
  unSetAllCheckboxes: () => void;
  allCheckbox: boolean | null;
} {
  const [checkedCheckboxes, setCheckedCheckboxes] = useState<Set<T>>(
    new Set(initiallyChecked)
  );

  const [allCheckbox, setAllCheckbox] = useState<boolean | null>(null);

  const syncAllCheckboxes = useCallback(
    (size: number) => {
      if (typeof totalLength === 'number') {
        setAllCheckbox(size === totalLength);
      }
    },
    [totalLength]
  );

  const setCheckboxes = useCallback(
    (element: T | T[]) => {
      if (Array.isArray(element)) {
        setCheckedCheckboxes((prev) => {
          let newSet = new Set(prev);
          element.forEach((item) => {
            newSet.add(item);
          });

          syncAllCheckboxes(newSet.size);

          return newSet;
        });
        return;
      }

      setCheckedCheckboxes((prev) => {
        let newSet = new Set(prev);
        newSet.add(element);
        syncAllCheckboxes(newSet.size);
        return newSet;
      });
    },
    [syncAllCheckboxes]
  );

  const unSetCheckBoxes = useCallback(
    (element: T | T[]) => {
      if (Array.isArray(element)) {
        setCheckedCheckboxes((prev) => {
          let newSet = new Set(prev);
          element.forEach((item) => {
            newSet.delete(item);
          });

          syncAllCheckboxes(newSet.size);
          return newSet;
        });
        return;
      }

      setCheckedCheckboxes((prev) => {
        let newSet = new Set(prev);
        newSet.delete(element);

        syncAllCheckboxes(newSet.size);

        return newSet;
      });
    },
    [syncAllCheckboxes]
  );

  const toggleCheckBox = useCallback(
    (element: T) => {
      if (checkedCheckboxes.has(element)) {
        unSetCheckBoxes(element);
        return;
      }

      setCheckboxes(element);
    },
    [checkedCheckboxes, setCheckboxes, unSetCheckBoxes]
  );

  const toggleCheckBoxes = useCallback(
    (element: T | T[]) => {
      if (Array.isArray(element)) {
        element.forEach((item: T) => {
          toggleCheckBox(item);
        });

        return;
      }

      toggleCheckBox(element);
    },
    [toggleCheckBox]
  );

  const setAllCheckboxes = useCallback((items: T[]) => {
    setCheckedCheckboxes(new Set(items));
    setAllCheckbox(true);
  }, []);

  const unSetAllCheckboxes = useCallback(() => {
    setCheckedCheckboxes(new Set());
    setAllCheckbox(false);
  }, []);

  return {
    checkedCheckboxes,
    setCheckboxes,
    unSetCheckBoxes,
    toggleCheckBoxes,
    setAllCheckboxes,
    unSetAllCheckboxes,
    allCheckbox,
  };
}
