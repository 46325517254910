import {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  Fragment,
} from 'react';
import classnames from 'classnames';
import Styles from './divider.module.scss';

interface DividerPropsStrict {
  className?: string;
  alignContent?: 'start' | 'center' | 'end';
  el?: any;
  spacing?: '0' | '1' | '2' | '3' | '4' | '5' | 0 | 1 | 2 | 3 | 4 | 5;
  vertical?: boolean;
}

interface DividerProps extends DividerPropsStrict {
  [propName: string]: any;
}

export const Divider: ForwardRefExoticComponent<DividerProps> = forwardRef(
  (
    { children, className, el, spacing = 0, vertical, alignContent, ...props },
    ref: ForwardedRef<any>
  ) => {
    const DividerElement = el
      ? el
      : children
      ? 'div'
      : vertical
      ? 'span'
      : 'hr';

    const DividerClasses = classnames(Styles.divider, className, {
      [Styles['has-children']]: children && el !== 'hr',
      [Styles[`spacing-${spacing}`]]: spacing,
      [Styles.vertical]: vertical,
    });

    const line = <div className={Styles['divider-line']} />;
    return (
      <DividerElement ref={ref} className={DividerClasses}>
        {children && el !== 'hr' ? (
          <Fragment>
            {alignContent === 'start' ? null : line}
            <div className={Styles['divider-content']}>{children}</div>
            {alignContent === 'end' ? null : line}
          </Fragment>
        ) : null}
      </DividerElement>
    );
  }
);
