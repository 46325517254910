import React from 'react';
import { Stack } from 'modules/common/components/Stack/Stack';
import Styles from './audioplayer.module.scss';
import { calculateTime } from './util';
import Slider from 'modules/common/components/Slider/Slider';

interface AudioSliderProps {
  text: string;
  currentTime: number;
  duration: number;
  changeRange: () => void;
  progressBarRef: React.RefObject<HTMLInputElement>;
}

export const AudioSlider: React.FC<AudioSliderProps> = ({
  text,
  currentTime,
  duration,
  changeRange,
  progressBarRef,
}: AudioSliderProps) => {
  return (
    <Stack direction="column">
      <Stack.Item>
        <Stack spacing={2} alignItems="center" justifyContent="space-between">
          <Stack.Item>
            <p className={Styles['progress-text']}>{text ? text : ''}</p>
          </Stack.Item>
          <Stack.Item shrink={0}>
            <p className={Styles['timing']}>
              {calculateTime(currentTime)} -{' '}
              {calculateTime(duration ? duration : 0)}
            </p>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Slider
          max={duration ? duration : `${duration}`}
          min="0"
          step="1"
          defaultValue="0"
          onChange={changeRange}
          ref={progressBarRef}
        />
      </Stack.Item>
    </Stack>
  );
};
