export const DEFAULT_CIRCLE_SIZE = 64;
export const DEFAULT_CIRCLE_WIDTH = 4;

export const cleanPercentage = (percentage: number) => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100;
  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

export const getCircleColour = ({ percentage }: { percentage: number }) => {
  switch (true) {
    case percentage >= 0 && percentage < 36:
      return '#C01313';
    case percentage >= 36 && percentage < 76:
      return '#EAB325';
    case percentage >= 76 && percentage <= 100:
      return '#2A9FD5';
    default:
      return 'gray';
  }
};
