import React from 'react';
import { MouseEvent } from 'react';
import { Icon } from 'icons';
import { Loader } from 'modules/common/components/Loader/Loader';
import Styles from './voiceselect.module.scss';
import { AudioPlayerProjectProps } from 'modules/common/hooks/useAudioPlayerProject';
import classnames from 'classnames';

interface OptionVoiceControlsProps {
  playerProps: AudioPlayerProjectProps;
  previewText: string;
  voiceId: string;
}

export const OptionVoiceControls: React.FC<OptionVoiceControlsProps> = ({
  playerProps,
  previewText,
  voiceId,
}: OptionVoiceControlsProps) => {
  const count = previewText.length;
  const maxCount = 500;
  const isOverLimit = count > maxCount;

  const isLoadingSomething = playerProps.isLoading;
  const isThisVoice = playerProps.voiceIdRef.current === voiceId;
  const isLoading = playerProps.isLoading && isThisVoice;
  const isPlaying = playerProps.isPlaying && isThisVoice;
  const isPlayingDifferentVoice = playerProps.isPlaying && !isThisVoice;
  const isDisabled =
    isLoadingSomething || isPlayingDifferentVoice || isOverLimit;

  const handleClick = async (event: MouseEvent) => {
    // prevent the click event from bubbling up to the parent and selecting the option
    event.stopPropagation();
    if (isDisabled) return;

    playerProps.voiceIdRef.current = voiceId;
    playerProps.handlePlayPause();
  };

  if (isLoading) {
    return (
      <div className={Styles['Loader']}>
        <Loader
          loadingBackSet="#031E50"
          loadingPartColor="#ffffff"
          size="16px"
        />
      </div>
    );
  }

  return (
    <button
      onClick={handleClick}
      className={classnames(Styles['Option-button'], {
        [Styles['disabled']]: isDisabled,
      })}
    >
      {isPlaying ? (
        <Icon name="pause" size={20} />
      ) : (
        <Icon name="play_arrow" size={20} />
      )}
    </button>
  );
};
