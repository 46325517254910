import { FC, useRef, useState } from 'react';
import { useOnClickOutside } from 'modules/common/hooks/useOnClickOutside';
import { Button } from 'modules/common/components/Button/Button';
import { useNotification } from 'modules/common/hooks/useNotification';

import { Icon } from 'icons';
import NotificationList from './NotificationList';
import classnames from 'classnames';

import Styles from './notificaitons.module.scss';

const NotificationsView: FC = () => {
  const [isNotificationOpen, setNotificationOpen] = useState<boolean>(false);
  const notificationRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(notificationRef, function () {
    setNotificationOpen(false);
  });

  const {
    notifications,
    isFetching,
    setNextPage,
    hasMore,
    unreadCount,
    readANotification,
    animate,
  } = useNotification();

  return (
    <>
      <Button
        onClick={() => setNotificationOpen((prev) => !prev)}
        fill="subtle"
      >
        <Icon
          name="notifications"
          size="20px"
          className={classnames(Styles['notification-icon'], {
            [Styles['unread']]: unreadCount > 0,
            [Styles['animate']]: animate,
          })}
        />
      </Button>

      {isNotificationOpen && (
        <div
          style={{ position: 'relative', display: 'inline-block' }}
          ref={notificationRef}
        >
          <NotificationList
            notifications={notifications}
            isFetching={isFetching}
            setNextPage={setNextPage}
            hasMore={hasMore}
            readANotification={readANotification}
          />
        </div>
      )}
    </>
  );
};

export default NotificationsView;
