import ReactQuill from 'react-quill';
import Quill, { RangeStatic, StringMap } from 'quill';
import { selectTagsApplyAll } from 'redux/reducers/tags/selectors';
import { isMacOSRunning } from 'utils';
import { useHandleCheckboxChange } from 'modules/edit/Tags/lib';
import { useAppDispatch, useAppSelector } from './redux';
import useEventListener from './useEventListener';
import { setFocusAliasInput } from 'redux/reducers/focus/focusSlice';

const isAliasTagPresent = (format: StringMap) => {
  const tagValue = Object.values(format)?.[0];
  const tagName = tagValue ? JSON.parse(tagValue)?.['name'] : null;
  return tagName === 'customAlias';
};

export function useAddAliasHotKeyListener({
  quillInstance,
}: {
  quillInstance: ReactQuill | null;
}) {
  const tagsApply = useAppSelector(selectTagsApplyAll);
  const editor = quillInstance?.getEditor();
  const range = editor?.getSelection();

  // Add alias tag on Cmd+A

  const handleCheckboxChange = useHandleCheckboxChange({});

  const aliasTagHotkeyHandler = ({
    quillEditor,
    selectedRange,
  }: {
    quillEditor: Quill;
    selectedRange: RangeStatic;
  }) => {
    const aliasTag = tagsApply.find((tag) => tag.name === 'customAlias');
    if (!aliasTag) return;
    let format = quillEditor.getFormat(selectedRange);

    // filter out sentence tags from the format
    format = Object.fromEntries(
      Object.entries(format).filter(([key]) => key !== 'sentence')
    );

    if (Object.keys(format).length !== 0 && !isAliasTagPresent(format)) return;
    const shouldAddTag = Object.keys(format).length === 0;
    handleCheckboxChange(aliasTag, shouldAddTag);
  };

  const onKeydownCmdA = (event: globalThis.KeyboardEvent) => {
    const isMacOS = isMacOSRunning();
    const isCmdA =
      (isMacOS ? event.metaKey : event.ctrlKey) && event.code === 'KeyA';
    if (isCmdA && editor?.hasFocus() && range) {
      event.preventDefault();
      aliasTagHotkeyHandler({ quillEditor: editor, selectedRange: range });
    }
  };

  useEventListener('keydown', onKeydownCmdA);

  // Focus alias input on Enter

  const dispatch = useAppDispatch();

  const onKeydownEnter = (event: globalThis.KeyboardEvent) => {
    const isEnter = event.code === 'Enter';
    if (!isEnter) return;
    event.preventDefault();

    if (editor?.hasFocus()) {
      // focus alias input
      if (!range) return;
      const aliasTag = tagsApply.find((tag) => tag.name === 'customAlias');
      if (!aliasTag) return;
      const format = editor.getFormat(range);
      if (isAliasTagPresent(format)) {
        dispatch(setFocusAliasInput(true));
      }
    } else {
      // focus quill
      const isAliasInputFocused =
        document.activeElement?.tagName.toLowerCase() === 'input' &&
        document.activeElement?.getAttribute('name') === 'alias';
      if (isAliasInputFocused) {
        editor?.focus();
      }
    }
  };

  useEventListener('keydown', onKeydownEnter);
}
