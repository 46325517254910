import { ColumnFiltersState } from '@tanstack/react-table';
import { TOCChapter } from './types';
import { ProjectTOCPage } from './models/Project';

// Generate a new ID between leftId and rightId to preserve the order of IDs.

export const generateId = (
  leftId: number,
  rightId: number,
  precision: number = 4
): number => {
  if (rightId <= leftId) {
    throw new Error('rightId must be greater than leftId.');
  }

  // Calculate midpoint
  let newId = (leftId + rightId) / 2;

  // Apply precision to ensure it does not exceed the bounds of leftId and rightId
  const decimalFactor = Math.pow(10, precision);
  newId = Math.floor(newId * decimalFactor) / decimalFactor;

  if (newId > leftId && newId < rightId) {
    return newId;
  } else {
    throw new Error('No valid ID can be generated with the given precision.');
  }
};

export const modifyString = ({
  originalStr,
  startIndex,
  length,
  newSubstring,
}: {
  originalStr: string;
  startIndex: number;
  length: number;
  newSubstring: string;
}) => {
  if (startIndex < 0 || startIndex >= originalStr.length) {
    throw new Error('startIndex out of bounds');
  }
  if (length < 0) {
    throw new Error('length cannot be negative');
  }

  // Extract the part of the string before the modification
  const before = originalStr.substring(0, startIndex);

  // Extract the part of the string after the modification
  const after = originalStr.substring(startIndex + length);

  // Concatenate the parts with the new substring
  return before + newSubstring + after;
};

export const getTOCChapterNodes = (pages: ProjectTOCPage[]): TOCChapter[] => {
  const chaptersArr: TOCChapter[] = [];

  pages.forEach(({ id, innerHTML }, pageIndex) => {
    const doc = new DOMParser().parseFromString(innerHTML, 'text/html');

    [...doc.body.childNodes].forEach((currentNode: any) => {
      if (currentNode) {
        if (currentNode.nodeName === '#text') {
          if (chaptersArr.length) {
            const previousChapter = chaptersArr[chaptersArr.length - 1];
            previousChapter.characterCount += currentNode.textContent.length;
            previousChapter.endPage = pageIndex + 1;
          }
        }

        if (currentNode.nodeName === 'CHAPTER') {
          const { outerHTML } = currentNode;
          const startIndex = innerHTML.indexOf(outerHTML);
          const isSubChapter = !!currentNode.dataset.subChapter;
          chaptersArr.push({
            pageId: id,
            id: Number(currentNode.id),
            name: currentNode.dataset.name,
            isSubChapter,
            outerHTML,
            outerHTMLLength: outerHTML.length,
            outerHTMLStartIndex: startIndex,
            characterCount: 0,
            startPage: pageIndex + 1,
            endPage: pageIndex + 1,
            parentChapterId: isSubChapter
              ? [...chaptersArr].reverse().find((item) => !item.isSubChapter)
                  ?.id
              : undefined,
          });
        }
      }
    });
  });

  return chaptersArr;
};

export const getFiltersToSend = (filters: ColumnFiltersState) => {
  if (!filters.length) return {};
  console.log('filters: ', filters);
  const check: Record<string, any> = {};
  filters.forEach((obj) => {
    const values = Object.values(obj);
    if (values.length !== 2) return;
    check[values[0] as string] = values[1];
  });
  return check;
};
