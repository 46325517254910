import axiosInstance from 'utils/axios';
import { ResultWithMessage } from 'modules/common/types';
import { CommentItemModel, CommentModel } from 'modules/common/models/Comment';

type CommentsReturn = ResultWithMessage<CommentModel>;

export interface CommentAddModel {
  projectId: string;
  chapterId: string;
  comments: {
    comment: string;
  };
}

export interface AddCommentReplyModel {
  id: string;
  comments: {
    comment: string;
  };
}

export interface AddReplyResponseModel {
  id: string;
  comments: CommentItemModel;
}

interface IComments {
  add(commentData: CommentAddModel): Promise<CommentsReturn>;
  addReply(
    commentData: AddCommentReplyModel
  ): Promise<ResultWithMessage<AddReplyResponseModel>>;
  getProjectComments(projectId: string): Promise<CommentModel[]>;
  getChapterComments(chapter_id: string): Promise<CommentModel[]>;
  edit(
    id: string,
    commentId: string,
    comment: string
  ): Promise<ResultWithMessage<CommentItemModel>>;
  deleteMainComment(id: string): Promise<void>;
  deleteCommentReply(id: string, commentId: string): Promise<void>;
}

class CommentsApi implements IComments {
  static action = '/project/comments';

  async getProjectComments(projectId: string): Promise<CommentModel[]> {
    const res = await axiosInstance.get(CommentsApi.action, {
      params: {
        projectId,
      },
    });
    return res.data;
  }

  async getChapterComments(chapter_id: string): Promise<CommentModel[]> {
    const res = await axiosInstance.get(CommentsApi.action, {
      params: {
        chapter_id,
      },
    });
    return res.data;
  }

  async add(commentData: CommentAddModel): Promise<CommentsReturn> {
    const res = await axiosInstance.post(CommentsApi.action, commentData);

    return {
      message: res.data.message,
      result: res.data.result,
    };
  }

  async addReply(
    commentData: AddCommentReplyModel
  ): Promise<ResultWithMessage<AddReplyResponseModel>> {
    const res = await axiosInstance.post(CommentsApi.action, commentData);

    return {
      message: res.data.message,
      result: res.data.result,
    };
  }

  async edit(
    id: string,
    commentId: string,
    comment: string
  ): Promise<ResultWithMessage<CommentItemModel>> {
    const res = await axiosInstance.put(CommentsApi.action, {
      id,
      commentId,
      comment,
    });

    return {
      message: res.data.message,
      result: res.data.result,
    };
  }

  async deleteMainComment(id: string): Promise<void> {
    const res = await axiosInstance.delete(CommentsApi.action, {
      params: {
        id,
      },
    });
    return res.data;
  }

  async deleteCommentReply(id: string, commentId: string): Promise<void> {
    const res = await axiosInstance.delete(CommentsApi.action, {
      params: {
        id,
        commentId,
      },
    });
    return res.data;
  }
}

const CommentsApiInstance = new CommentsApi();

export default CommentsApiInstance;
