import Quill from 'quill';

const History = Quill.import('modules/history');

export class HistoryModule extends History {
  private _isStackLoaded = false;

  loadHistoryStack(stack: any) {
    this._isStackLoaded = false;
    this.loadHistoryStackMemory(stack);
    this._isStackLoaded = true;
  }

  public loadHistoryStackMemory(stack: any) {
    const Delta = Quill.import('delta');

    if (stack.undo.length > 0) {
      for (let item of stack.undo) {
        const obj = {
          redo: new Delta(item.redo.ops),
          undo: new Delta(item.undo.ops),
        };

        this.stack.undo.push(obj);
      }
    }
    if (stack.redo.length > 0) {
      for (let item of stack.redo) {
        const obj = {
          redo: new Delta(item.redo.ops),
          undo: new Delta(item.undo.ops),
        };
        this.stack.redo.push(obj);
      }
    }

    this.stack.redo = [...this.stack.undo.reverse()];
    this.stack.undo = [];

    while (this.stack.redo.length) {
      this.redo();
    }
  }

  private isStackHistoryLoaded(): boolean {
    return this._isStackLoaded;
  }

  public setStackHistoryValue(val: boolean): void {
    this._isStackLoaded = val;
  }
}
