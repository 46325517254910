import { IconNameType } from 'icons/build/icons';

export enum TagType {
  SentenceTags = 'sentenceTags',
  WordTags = 'wordTags',
  NumTags = 'numTags',
}

export enum AttributeTypes {
  Integer = 'integer',
  Enum = 'enum',
  String = 'string',
}

export interface AttributeValue {
  name: string;
  value: string;
}

export interface AttributeModel {
  id: string;
  name: string;
  displayName: string;
  type: AttributeTypes;
  values?: AttributeValue[];
  min?: number;
  max?: number;
  postfix?: string;
  prefix?: string;
  defaultValue?: string;
  converters?: {
    ssmlToUi: string;
    uiToSSML: string;
  };
}

export interface TagBaseModel {
  id: string;
  name: string;
  iconName: IconNameType;
  iconColor: string;
  iconBgColor: string;
  iconBorderColor?: string;
  allowedTags: string[];
}

export interface TagBlotModel extends TagBaseModel {
  blotId: string;
  attributeValues: [string, string][];
  '--bg-color'?: string;
  '--icon-color'?: string;
  '--bd-color'?: string;
  'data-id'?: string;
  ai_generated_tag?: string;
}

export interface TagModel extends TagBaseModel {
  displayName: string;
  attributes: AttributeModel[];
  tagType: TagType;
  description: string;
  selectedAttrs?: AttributeValue[];
  fixedAttributes?: Record<string, string | number>;
}
