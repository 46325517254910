import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { PresetsModel } from 'modules/common/models/Presets';
import PresetApiInstance from 'modules/edit/api/Preset';
import { rejectRequest } from 'utils/rejectRequest';
import { AxiosError } from 'axios';

export const PresetsAdapter = createEntityAdapter<PresetsModel>({
  selectId: (preset) => preset.id,
});

const initialState = {
  ...PresetsAdapter.getInitialState(),
};

export const fetchPresets = createAsyncThunk<PresetsModel[], string>(
  'presets/fetchPresets',
  async (projectId, { rejectWithValue }) => {
    try {
      return await PresetApiInstance.getAll(projectId);
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

export const addPreset = createAsyncThunk<
  PresetsModel,
  { preset: Omit<PresetsModel, 'id'>; projectId: string }
>('presets/addPreset', async ({ preset, projectId }, { rejectWithValue }) => {
  try {
    const data = await PresetApiInstance.add(preset, projectId);
    return data.result;
  } catch (err) {
    return rejectWithValue(rejectRequest(err as AxiosError));
  }
});

export const editPreset = createAsyncThunk<
  PresetsModel,
  { preset: PresetsModel; projectId: string }
>('presets/editPreset', async ({ preset, projectId }, { rejectWithValue }) => {
  try {
    const data = await PresetApiInstance.edit(preset, projectId);
    return data.result;
  } catch (err) {
    return rejectWithValue(rejectRequest(err as AxiosError));
  }
});

export const deletePreset = createAsyncThunk<
  PresetsModel['id'],
  { id: PresetsModel['id']; projectId: string }
>('presets/deletePreset', async ({ id, projectId }, { rejectWithValue }) => {
  try {
    await PresetApiInstance.delete(id, projectId);
    return id;
  } catch (err) {
    return rejectWithValue(rejectRequest(err as AxiosError));
  }
});

const presetsSlice = createSlice({
  name: 'presets',
  initialState,
  reducers: {
    resetPresets: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPresets.fulfilled, (state, { payload }) => {
        PresetsAdapter.setAll(state, payload);
      })
      .addCase(addPreset.fulfilled, (state, { payload }) => {
        PresetsAdapter.addOne(state, payload);
      })
      .addCase(editPreset.fulfilled, (state, { payload }) => {
        const { id, ...changes } = payload;
        PresetsAdapter.updateOne(state, { id, changes });
      })
      .addCase(deletePreset.fulfilled, (state, { payload }) => {
        PresetsAdapter.removeOne(state, payload);
      });
  },
});

export const { resetPresets } = presetsSlice.actions;

export default presetsSlice.reducer;
