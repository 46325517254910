import React, { FC } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {}

const Image: FC<Props> = ({ src, alt, ...props }) => {
  return <img {...props} src={`${process.env.PUBLIC_URL}/${src}`} alt={alt} />;
};

export default Image;
