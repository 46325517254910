import React, { FC } from 'react';
import {
  Textarea,
  TextareaProps,
} from 'modules/common/components/Textarea/Textarea';
import LabelContainer, { ILabelContainer } from './LabelContainer';

interface LabelTextareaProps extends ILabelContainer, TextareaProps {}

export const LabelTextarea: FC<LabelTextareaProps> = ({
  labelText,
  className,
  ...rest
}) => {
  return (
    <LabelContainer labelText={labelText} className={className}>
      <Textarea {...rest} />
    </LabelContainer>
  );
};
