import { Quill } from 'react-quill';

const Inline = Quill.import('blots/inline');

export class PageBlot extends Inline {
  static blotName = 'page';
  static tagName = 'page';

  static create(value: string) {
    let node: HTMLSpanElement = super.create(PageBlot.tagName.toLowerCase());
    const page = JSON.parse(value) as {
      id: string;
    };
    if (value) {
      node.setAttribute('id', page.id);
    }
    return node;
  }

  static formats(domNode: HTMLElement) {
    return JSON.stringify({
      id: domNode.id,
    });
  }
}
