import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { getFetchingSelector } from 'redux/reducers/loader/selectors';
import {
  ChapterAdapter,
  setActiveChapter,
  fetchChapters,
} from './chaptersSlice';
import { AsyncRequestStatus } from 'redux/interfaces/loader';

const chaptersState = ({ chapters }: RootState) => chapters;

export const { selectById, selectAll, selectEntities, selectTotal } =
  ChapterAdapter.getSelectors<RootState>(chaptersState);

export const activeChapterSelector = createSelector(
  chaptersState,
  (state) => state.currentChapter
);

export const chaptersWithReport = createSelector(selectAll, (state) =>
  state.filter(({ report }) => report)
);

export const isChaptersLoadingSelector = createSelector(
  getFetchingSelector(fetchChapters.typePrefix),
  (status) => status === AsyncRequestStatus.pending
);

export const activeChapterIdSelector = createSelector(
  activeChapterSelector,
  (currentChapter) => (currentChapter ? currentChapter.id : undefined)
);

export const activeChapterTaskArnSelector = createSelector(
  activeChapterSelector,
  (currentChapter) => (currentChapter ? currentChapter.task_arn : undefined)
);

export const chapterDetailSelector = createSelector(
  chaptersState,
  (state) => state.details
);

export const chapterPostUrlSelector = createSelector(
  chapterDetailSelector,
  (state) => state?.postUrl
);

export const isChapterActiveLoading = createSelector(
  getFetchingSelector(setActiveChapter.typePrefix),
  (status) => status === AsyncRequestStatus.pending
);

export const isCurrentChapterDirtySelector = createSelector(
  chaptersState,
  activeChapterSelector,
  (state, active) => (active ? !!state.dirtyChapters[active.id] : false)
);

export const isCurrentChapterHasSavedChangesSelector = createSelector(
  chaptersState,
  activeChapterSelector,
  (state, active) =>
    active ? !!state.chaptersWithSavedChanges[active.id] : false
);

export const isCurrentChapterPendingSelector = createSelector(
  chaptersState,
  activeChapterSelector,
  (state, active) => (active ? !!state.pendingSaveChapters[active.id] : false)
);

export const isAnyChapterIsDirtySelector = createSelector(
  chaptersState,
  (state) => !!Object.keys(state.dirtyChapters).length
);

export const dirtyChaptersSelector = createSelector(
  chaptersState,
  (state) => state.dirtyChapters
);

export const isAllChaptersPendingSelector = createSelector(
  chaptersState,
  (state) => state.allChaptersSaving
);
