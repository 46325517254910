import { Options, SingleValue } from 'react-select';
export * from './api';

export type LabelValue = {
  value: string;
  label: string;
};

type LabelValueBoolean = {
  value: string | boolean;
  label: string;
};

export const VOICE_LABELS_TO_DISPLAY = {
  featured: 'featured',
  accent: 'accent',
  description: 'description',
  descriptive: 'descriptive',
  'use case': 'use case',
  use_case: 'use_case',
  age: 'age',
} as const;

export const VOICE_LABELS = {
  ...VOICE_LABELS_TO_DISPLAY,
  gender: 'gender',
} as const;

export type VoiceLabelsKey = keyof typeof VOICE_LABELS;
export type VoiceLabelsToDisplayKey = keyof typeof VOICE_LABELS_TO_DISPLAY;
export type VoiceLabels = Record<VoiceLabelsKey, string>;

export interface Voice {
  high_quality_base_model_ids: string[];
  id: string;
  name: string;
  pre_process?: boolean;
  labels?: VoiceLabels;
  language?: string;
}

export type SelectValue = SingleValue<LabelValue>;

export type SelectValueBoolean = SingleValue<LabelValueBoolean>;

export type ParsedVoiceValue = LabelValue &
  Omit<Voice, 'name' | 'labels' | 'id'> & { voiceLabels?: VoiceLabels };

export type SelectOptions = Options<{ value: string; label: string }>;

export interface Dict<T> {
  [id: string]: T;
}

export type ResultWithMessage<T = any> = {
  message: string;
  result: T;
};

export enum ProjectStatus {
  new_project = 'new_project',
  incomplete = 'incomplete',
  pending = 'pending',
  assigned = 'assigned',
  in_progress = 'in_progress',
  declined = 'declined',
  deactivated = 'deactivated',
  in_review = 'in_review',
  re_take = 're_take',
  done = 'done',
}

export enum EditorStatus {
  Available = 'Available',
  Unavailable = 'Unavailable',
}

export enum RequestProjectStatus {
  Accept = 'Accept',
  Decline = 'Decline',
}

export enum EditorCapacity {
  Zero = '0',
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
}

export enum DateRanges {
  yesterday = 'yesterday',
  today = 'today',
  this_month = 'this_month',
  last_month = 'last_month',
  last_3_months = 'last_3_months',
}

export enum SORT {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface TablesConfigParam {
  sortBy?: string;
  sortType?: SORT;
  changeSortBy: (accessorName: string, sort: SORT) => void;
}

export interface TablesConfigParamWithFilters extends TablesConfigParam {
  voicesWithPreprocessing?: Voice[];
}

export interface PaginatedResponse<T> {
  data: T[];
  index: number;
  totalCount: number;
}

export type PaginatedRequest<T> = {
  index?: number;
  sortBy?: string;
  sortType?: SORT;
} & Partial<T>;

export const TTS_ENGINES = {
  aws: 'aws',
  azure: 'azure',
  google: 'google',
  wellsaid: 'wellSaid',
  deepzen: 'deepzen',
  elevenlabs: 'elevenlabs',
} as const;

export type TTSEngine = keyof typeof TTS_ENGINES;

export type TOCChapter = {
  id: number;
  pageId: string;
  name: string;
  outerHTML: string;
  outerHTMLStartIndex: number;
  outerHTMLLength: number;
  isSubChapter: boolean;
  characterCount: number;
  startPage: number;
  endPage: number;
  parentChapterId?: number;
};
