import { useEffect, useState } from 'react';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Checkbox } from 'modules/common/components/Checkbox/Checkbox';
import { Divider } from 'modules/common/components/Divider/Divider';
import CheckboxList from 'modules/common/components/CheckboxList/CheckboxList';
import useCheckboxes from 'modules/common/hooks/useCheckboxes';
import useLoading from 'modules/common/hooks/useLoading';
import { useAppSelector } from 'modules/common/hooks/redux';
import { activeProjectSelectorId } from 'redux/reducers/project/selectors';
import ExportApiInstance from 'modules/edit/api/Export';
import Loader from 'modules/common/components/Loader/Loader';
import { Button } from 'modules/common/components/Button/Button';
import { downloadAudioByIframe } from 'utils';

export const AudioSideBar = () => {
  const [chapters, setChapters] = useState<
    { id: string; chapterName: string; getUrl: string }[]
  >([]);
  const projectId = useAppSelector(activeProjectSelectorId);
  const { isLoading, setLoading, setError } = useLoading();
  const {
    checkedCheckboxes,
    setCheckboxes,
    setAllCheckboxes,
    unSetCheckBoxes,
    unSetAllCheckboxes,
    allCheckbox,
  } = useCheckboxes<string>([], chapters.length);

  useEffect(() => {
    setLoading(true);
    ExportApiInstance.getPreAssignedAudio(projectId)
      .then((res) => {
        setChapters(
          Object.values(res).map((item) => {
            return {
              id: item.audioId,
              chapterName: item.chapterName,
              getUrl: item.getUrl,
            };
          })
        );
        setLoading(false);
      })
      .catch(() => {
        setError();
      });
  }, [projectId, setLoading, setError]);

  const handleCheckAll = (value: boolean) => {
    if (value) {
      setAllCheckboxes(chapters.map((item) => item.id));
      return;
    }

    unSetAllCheckboxes();
  };

  const handleSingleCheckboxChange = (id: string, value: boolean) => {
    if (value) {
      setCheckboxes(id);
      return;
    }

    unSetCheckBoxes(id);
  };

  const downloadSelectedAudios = () => {
    for (const checkedCheckboxesKey of checkedCheckboxes) {
      let item = chapters.find((item) => item.id === checkedCheckboxesKey);
      if (item) {
        downloadAudioByIframe(item.getUrl);
      }
    }
  };

  return (
    <Stack direction="column" justifyContent="center">
      <Stack.Item className="p-2">Exported Audio</Stack.Item>
      <Divider />
      {chapters?.length ? (
        <>
          <Stack.Item className="p-2">
            <Stack
              spacing="2"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack.Item className="m-r-1">All Chapters</Stack.Item>
              <Stack.Item>
                <Checkbox checked={!!allCheckbox} onChange={handleCheckAll} />
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Divider />
          <Stack.Item className="p-2">
            <CheckboxList
              listOfItems={chapters}
              onChangeCheckbox={handleSingleCheckboxChange}
              checkedIds={checkedCheckboxes}
            />
          </Stack.Item>
          <Stack.Item>
            <Stack justifyContent="center">
              <Button
                onClick={downloadSelectedAudios}
                disabled={checkedCheckboxes.size === 0}
              >
                Download
              </Button>
            </Stack>
          </Stack.Item>
        </>
      ) : (
        <Stack.Item className="p-2">
          <Stack justifyContent="center">
            {isLoading ? (
              <Loader
                loadingPartColor="#031E50"
                loadingBackSet="#ffffff"
                size="40px"
                loaderWidth="3px"
              />
            ) : (
              'No Exported Audio'
            )}
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};
