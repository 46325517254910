import { createColumnHelper } from '@tanstack/react-table';
import SortableInputCell from 'modules/common/components/Table/TableCells/SortableInputCell/SortableInputCell';
import {
  ProjectStatus,
  TablesConfigParamWithFilters,
  TTSEngine,
} from 'modules/common/types';
import { prettifyDate } from 'utils';
import {
  getDatesRanges,
  getReTakesList,
  getStatusList,
} from 'modules/common/constants/functions';
import SelectCell from 'modules/common/components/Table/TableCells/SelectCell/SelectCell';
import SortableSelectCell from 'modules/common/components/Table/TableCells/SortableSelectCell/SortableSelectCell';
import StatusCellWithData from 'modules/common/components/Table/TableCells/StatusCellWithDate/StatusCellWithData';
import ActionsCell from 'modules/common/components/Table/TableCells/ActionsCell/ActionsCell';
import NameCellLogo from 'modules/common/components/Table/TableCells/NameCellLogo/NameCellLogo';

export interface AdminProjects {
  id: string;
  name: string;
  projectStatus: ProjectStatus;
  declinedReason?: string;
  lastUpdated: string;
  author: string;
  editor: string;
  dateAdded: string;
}

export interface EditorRequestedProjects {
  id: string;
  editorId: string;
  name: string;
  projectStatus: ProjectStatus;
  lastUpdated: string;
  author: string;
  dateAdded: string;
}

export interface AdminProjectsPageTable {
  id: string;
  apiVersion: string | null;
  name: string;
  email: string;
  projectStatus: ProjectStatus;
  reTakes: string | null;
  lastUpdated: string;
  author: string;
  editor: string;
  dateAdded: string;
  projectStatusUpdateDate: string;
  ttsEngine: TTSEngine;
  voice: string;
  elevenLabsModel: string;
  actions: null;
}

export interface EditorProjectsPageTable {
  id: string;
  apiVersion: string | null;
  editorId: string;
  name: string;
  projectStatus: ProjectStatus;
  lastUpdated: string;
  author: string;
  dateAdded: string;
  projectStatusUpdateDate: string | null;
  ttsEngine: TTSEngine;
  voice: string;
  elevenLabsModel: string;
}

export function getAdminProjectsTableColumnsConfig({
  changeSortBy,
  sortBy,
  sortType,

  voicesWithPreprocessing,
}: TablesConfigParamWithFilters) {
  const columnHelper = createColumnHelper<AdminProjectsPageTable>();

  return [
    columnHelper.accessor('name', {
      header: (info) => (
        <SortableInputCell
          text="Name"
          accessorName={info.header.column.id}
          onSortChange={changeSortBy}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          sort={sortBy === info.header.column.id ? sortType : undefined}
        />
      ),
      cell: (info) => (
        <NameCellLogo
          id={info.row.original.id}
          isV2={info.row.original.apiVersion === 'V2'}
          ttsEngine={info.row.original.ttsEngine}
          value={info.getValue()}
          voice_name={info.row.original.voice}
          elevenLabsModel={info.row.original.elevenLabsModel}
          isPreprocessAvailable={
            voicesWithPreprocessing?.length
              ? !!voicesWithPreprocessing.find(
                  (voiceWithPreprocessing) =>
                    voiceWithPreprocessing.name === info.row.original.voice
                )
              : false
          }
        />
      ),
    }),
    columnHelper.accessor('email', {
      header: (info) => (
        <SortableInputCell
          text="Author Email"
          accessorName={info.header.column.id}
          onSortChange={changeSortBy}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          sort={sortBy === info.header.column.id ? sortType : undefined}
        />
      ),
    }),
    columnHelper.accessor('projectStatus', {
      header: (info) => (
        <SelectCell
          text="Status"
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          data={getStatusList()}
          width="180px"
        />
      ),
      cell: (info) => {
        const value = info.getValue();
        return value ? (
          <StatusCellWithData
            type={info.getValue()}
            date={
              info.row.original.projectStatusUpdateDate
                ? prettifyDate(info.row.original.projectStatusUpdateDate)
                : ''
            }
          />
        ) : (
          ''
        );
      },
    }),
    columnHelper.accessor('reTakes', {
      header: (info) => (
        <SelectCell
          text="Re-takes"
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          data={getReTakesList()}
          width="100px"
        />
      ),
      cell: (info) => {
        const value = info.getValue();
        return value ? `${value}/10` : `0/10`;
      },
    }),
    columnHelper.accessor('lastUpdated', {
      header: (info) => (
        <SortableSelectCell
          text="Last Updated"
          accessorName={info.header.column.id}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          data={getDatesRanges()}
          onSortChange={changeSortBy}
          sort={sortBy === info.header.column.id ? sortType : undefined}
          width="180px"
        />
      ),
      cell: (info) => {
        const dataStr = info.getValue();
        return dataStr ? prettifyDate(info.getValue()) : null;
      },
    }),
    columnHelper.accessor('author', {
      header: (info) => (
        <SortableInputCell
          text="Author"
          accessorName={info.header.column.id}
          onSortChange={changeSortBy}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          sort={sortBy === info.header.column.id ? sortType : undefined}
        />
      ),
    }),
    columnHelper.accessor('editor', {
      header: (info) => (
        <SortableInputCell
          text="Editor Name"
          accessorName={info.header.column.id}
          onSortChange={changeSortBy}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          sort={sortBy === info.header.column.id ? sortType : undefined}
        />
      ),
    }),
    columnHelper.accessor('dateAdded', {
      header: (info) => (
        <SortableSelectCell
          text="Data Added"
          accessorName={info.header.column.id}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          data={getDatesRanges()}
          onSortChange={changeSortBy}
          sort={sortBy === info.header.column.id ? sortType : undefined}
          width="180px"
        />
      ),
      cell: (info) => {
        const dataStr = info.getValue();
        return dataStr ? prettifyDate(info.getValue()) : null;
      },
    }),
    columnHelper.accessor('actions', {
      header: () => '',
      cell: (info) => <ActionsCell id={info.row.original.id} name={info.row.original.name} />,
    }),
  ];
}

export function getEditorProjectsTableColumnsConfig({
  changeSortBy,
  sortBy,
  sortType,
  voicesWithPreprocessing,
}: TablesConfigParamWithFilters) {
  const columnHelper = createColumnHelper<EditorProjectsPageTable>();

  return [
    columnHelper.accessor('name', {
      header: (info) => (
        <SortableInputCell
          text="Name"
          accessorName={info.header.column.id}
          onSortChange={changeSortBy}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          sort={sortBy === info.header.column.id ? sortType : undefined}
        />
      ),
      cell: (info) => (
        <NameCellLogo
          id={info.row.original.id}
          isV2={info.row.original.apiVersion === 'V2'}
          ttsEngine={info.row.original.ttsEngine}
          value={info.getValue()}
          voice_name={info.row.original.voice}
          elevenLabsModel={info.row.original.elevenLabsModel}
          isPreprocessAvailable={
            voicesWithPreprocessing?.length
              ? !!voicesWithPreprocessing.find(
                  (voiceWithPreprocessing) =>
                    voiceWithPreprocessing.name === info.row.original.voice
                )
              : false
          }
        />
      ),
    }),
    columnHelper.accessor('projectStatus', {
      header: (info) => (
        <SelectCell
          text="Status"
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as ProjectStatus) || ''}
          data={getStatusList().filter(
            (item) =>
              item.value !== ProjectStatus.pending &&
              item.value !== ProjectStatus.new_project
          )}
          width="180px"
        />
      ),
      cell: (info) => {
        const value = info.getValue();
        return value ? (
          <StatusCellWithData
            type={info.getValue()}
            date={
              info.row.original.projectStatusUpdateDate
                ? prettifyDate(info.row.original.projectStatusUpdateDate)
                : ''
            }
          />
        ) : (
          ''
        );
      },
    }),
    columnHelper.accessor('lastUpdated', {
      header: (info) => (
        <SortableSelectCell
          text="Last Updated"
          accessorName={info.header.column.id}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          data={getDatesRanges()}
          onSortChange={changeSortBy}
          sort={sortBy === info.header.column.id ? sortType : undefined}
          width="180px"
        />
      ),
      cell: (info) => {
        const dataStr = info.getValue();
        return dataStr ? prettifyDate(info.getValue()) : null;
      },
    }),
    columnHelper.accessor('author', {
      header: (info) => (
        <SortableInputCell
          text="Author"
          accessorName={info.header.column.id}
          onSortChange={changeSortBy}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          sort={sortBy === info.header.column.id ? sortType : undefined}
        />
      ),
    }),
    columnHelper.accessor('dateAdded', {
      header: (info) => (
        <SortableSelectCell
          text="Data Added"
          accessorName={info.header.column.id}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          data={getDatesRanges()}
          onSortChange={changeSortBy}
          sort={sortBy === info.header.column.id ? sortType : undefined}
          width="180px"
        />
      ),
      cell: (info) => {
        const dataStr = info.getValue();
        return dataStr ? prettifyDate(info.getValue()) : null;
      },
    }),
  ];
}
