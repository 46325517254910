import React, { FC } from 'react';
import { ProjectStatus } from 'modules/common/types';
import { Stack } from 'modules/common/components/Stack/Stack';
import StatusCell from 'modules/common/components/Table/TableCells/StatusCell/StatusCell';

interface Props {
  type: ProjectStatus;
  date: string;
}

const StatusCellWithData: FC<Props> = ({ type, date }) => {
  return (
    <Stack direction="column">
      <Stack.Item>
        <StatusCell type={type} />
      </Stack.Item>
      <Stack.Item className="color-primary opacity-50 font-size-xss">
        {date}
      </Stack.Item>
    </Stack>
  );
};

export default StatusCellWithData;
