import {
  FC,
  ReactElement,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePopper } from 'react-popper';

import { Portal } from '../Portal/Portal';
import { ActionMenuItem, ActionMenuItemProps } from './ActionMenuItem';

import Styles from './action-menu.module.scss';
import { Placement, StrictModifiers } from '@popperjs/core';
import classnames from 'classnames';
import { Property } from 'csstype';

interface Offset {
  /** Displaces the popper along the reference element */
  skidding?: number;

  /** Displaces the popper away from, or toward, the reference element in the direction of its placement */
  distance?: number;
}

interface ActionMenuProps {
  actionItemWrapperClassname?: string;
  open?: boolean;
  trigger: ReactElement;
  triggerDisplayFormat?: Property.Display;
  el?: 'span' | 'div';
  children:
    | ReactNode
    | ReactElement<ActionMenuItemProps>
    | ReactElement<ActionMenuItemProps>[];
  portal?: boolean;
  width?: 'xs' | 's' | 'm' | 'l' | '100' | 'auto';
  autoFlipHorizontally?: boolean;
  autoFlipVertically?: boolean;
  direction?:
    | 't'
    | 'tr'
    | 'tl'
    | 'r'
    | 'rt'
    | 'rb'
    | 'b'
    | 'br'
    | 'bl'
    | 'l'
    | 'lt'
    | 'lb'
    | 'c';
  offset?: Offset;
  onClickOutside?: () => void;
  // Pass the externalContainerRef to change a referencable node element to close the modal on clicking outside
  externalContainerRef?: RefObject<any>;
  externalStyles?: React.CSSProperties;
}

interface IActionMenu extends FC<ActionMenuProps> {
  Item: typeof ActionMenuItem;
}

export const ActionMenu: IActionMenu = ({
  actionItemWrapperClassname,
  children,
  open = false,
  portal = false,
  trigger,
  triggerDisplayFormat,
  el = 'span',
  width = 's',
  direction = 'bottom-end',
  autoFlipHorizontally = false,
  autoFlipVertically = false,
  onClickOutside,
  offset = { skidding: 0, distance: 0 },
  externalStyles = {},
  externalContainerRef,
}) => {
  const popperRef: RefObject<any> = useRef();

  const widthSwitch = useMemo(() => {
    const PopoverBaseWidth = 16;
    switch (width) {
      case 'l':
        return PopoverBaseWidth * 32;
      case 'm':
        return PopoverBaseWidth * 20;
      case 's':
        return PopoverBaseWidth * 12;
      case 'xs':
        return PopoverBaseWidth * 8;
      case '100':
        return `100`;
      default:
        return `auto`;
    }
  }, [width]);

  const widthObserver = useMemo(
    () => ({
      name: 'widthObserver',
      enabled: widthSwitch !== '100',
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }: any) => {
        if (typeof widthSwitch === 'string') {
          state.styles.popper.width = `${widthSwitch}px`;
        }
        state.styles.popper.width = widthSwitch;
      },
      effect: ({ instance }: any) => {
        instance.forceUpdate();
      },
    }),
    [widthSwitch]
  );

  const sameWidth = useMemo(
    () => ({
      name: 'sameWidth',
      enabled: widthSwitch === '100',
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }: any) => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
      },
      effect: ({ state }: any) => {
        state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
      },
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [popperRef.current?.getBoundingClientRect().width]
  );

  const directionMapping = () => {
    switch (direction) {
      case 't':
        return 'top';
      case 'tr':
        return 'top-start';
      case 'tl':
        return 'top-end';
      case 'r':
        return 'right';
      case 'rt':
        return 'right-end';
      case 'rb':
        return 'right-start';
      case 'b':
      case 'c':
        return 'bottom';
      case 'br':
        return 'bottom-start';
      case 'bl':
        return 'bottom-end';
      case 'l':
        return 'left';
      case 'lt':
        return 'left-end';
      case 'lb':
        return 'left-start';
      default:
        return 'bottom-end';
    }
  };

  const fallbackPlacements = () => {
    if (autoFlipHorizontally) {
      switch (direction) {
        case 'r':
          return ['left'];
        case 'rb':
          return ['left-start'];
        case 'rt':
          return ['left-end'];
        case 'l':
          return ['right'];
        case 'lb':
          return ['right-start'];
        case 'lt':
          return ['right-end'];
        default:
          break;
      }
    }

    if (autoFlipVertically) {
      switch (direction) {
        case 't':
          return ['bottom'];
        case 'tr':
          return ['bottom-start'];
        case 'tl':
          return ['bottom-end'];
        case 'b':
          return ['top'];
        case 'br':
          return ['top-start'];
        case 'bl':
          return ['top-end'];
        default:
          break;
      }
    }

    return [];
  };

  const centerPlacement = useMemo(
    () => ({
      name: 'offset',
      enabled: true,
      options: {
        offset: ({ reference, popper }: any) => {
          if (direction === 'c') {
            const offsetY = -(reference.height / 2) + -(popper.height / 2);
            return [0, offsetY];
          }

          return [offset.skidding ?? 0, offset.distance ?? 4];
        },
      },
    }),
    [direction, offset.skidding, offset.distance]
  );

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      centerPlacement as StrictModifiers,
      widthObserver as StrictModifiers,
      sameWidth as StrictModifiers,
      {
        name: 'flip',
        enabled:
          (autoFlipHorizontally || autoFlipVertically) &&
          direction !== 'center',
        options: {
          fallbackPlacements: fallbackPlacements() as Placement[],
        },
      },
    ],
    placement: directionMapping(),
  });

  const El = el;

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (!open) return;
      //  Target is not part of wrapper or element
      const target = event.composedPath?.()[0] ?? event.target;
      // Suppress onClickOutside() if target is inside
      if (
        externalContainerRef?.current?.contains(target) ||
        popperRef?.current?.contains(target) ||
        popperElement?.contains(target)
      ) {
        return;
      }

      onClickOutside?.();
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, onClickOutside, popperElement, popperRef, externalContainerRef]);

  return (
    <El ref={popperRef}>
      <div
        ref={setReferenceElement}
        style={{
          display: triggerDisplayFormat
            ? triggerDisplayFormat
            : el === 'span'
            ? 'inline-block'
            : 'initial',
        }}
      >
        {trigger}
      </div>
      <Portal conditional={portal}>
        {open && (
          <div
            className={classnames(
              Styles['action-menu-content'],
              actionItemWrapperClassname
            )}
            style={{ ...styles.popper, zIndex: 1000, ...externalStyles }}
            ref={setPopperElement}
            {...attributes.popper}
          >
            {children}
          </div>
        )}
      </Portal>
    </El>
  );
};

ActionMenu.Item = ActionMenuItem;
