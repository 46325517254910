import { FC, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Icon } from 'icons';
import { DOTS, usePagination } from 'modules/common/hooks/usePagination';
import { Input } from 'modules/common/components/Input/Input';
import Styles from './pagination.module.scss';

interface Props {
  totalCount: number;
  currentPage: number;
  className?: string;
  format?: 'short' | 'long';
  onChange: (currentPage: number) => void;
}
const Pagination: FC<Props> = ({
  totalCount,
  currentPage,
  format = 'long',
  className,
  onChange,
}) => {
  const [current, setCurrent] = useState<number | ''>(currentPage);

  useEffect(() => {
    setCurrent(currentPage);
  }, [currentPage]);

  const paginationRange = usePagination({
    totalCount,
    currentPage,
    pageSize: 10,
  });

  const onNext = () => {
    onChange(currentPage + 1);
  };

  const onPrevious = () => {
    onChange(currentPage - 1);
  };

  const changeHandler = (v: string) => {
    if(!v) {
      return setCurrent('');
    }

    const n = Number(v);
    setCurrent(n);
    if (n >= 1 && n <= totalCount) {
      onChange(n);
    }
  };

  const onBlur = useCallback(() => {
    if(current !== currentPage) {
      setCurrent(currentPage);
    }
  }, [current, currentPage])

  if (!paginationRange) {
    return <></>;
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  if (format === 'short') {
    return (
      <div
        className={classnames(Styles['ShortPaginationContainer'], className)}
      >
        <Icon
          name="arrow_left"
          size={24}
          onClick={onPrevious}
          disabled={currentPage === 1}
        />
        <Input
          type="text"
          pattern="[0-9]{5}"
          value={current}
          className={Styles['ShortPaginationInput']}
          onChangeWithValueHandler={changeHandler}
          onBlur={onBlur}
        />
        <div> / {totalCount}</div>
        <Icon
          name="arrow_right"
          size={24}
          onClick={onNext}
          disabled={currentPage === totalCount}
        />
      </div>
    );
  }

  return (
    <ul className={Styles['pagination-container']}>
      <li
        className={classnames(Styles['pagination-item'], Styles['prev'], {
          [Styles['disabled']]: currentPage === 1 || totalCount === 0,
        })}
        onClick={onPrevious}
      >
        Previous
      </li>
      {paginationRange?.map((pageNumber, index) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <li
              className={classnames(Styles['pagination-item'], Styles['dots'])}
              key={`dots${index}`}
            >
              &#8230;
            </li>
          );
        }

        // Render our Page Pills
        return (
          <li
            className={classnames(Styles['pagination-item'], {
              [Styles['selected']]: pageNumber === currentPage,
            })}
            onClick={() => onChange(pageNumber)}
            key={pageNumber}
          >
            {pageNumber}
          </li>
        );
      })}

      <li
        className={classnames(Styles['pagination-item'], Styles['next'], {
          [Styles['disabled']]: currentPage === lastPage || totalCount === 0,
        })}
        onClick={onNext}
      >
        Next
      </li>
    </ul>
  );
};

export default Pagination;
