import React from 'react';
import { fetchProjectCaches } from 'redux/reducers/chapters/chaptersSlice';
import ChaptersApiInstance from 'modules/edit/api/Chapter';
import SaveCacheApiInstance from 'modules/edit/api/SaveCache';
import { useAppDispatch } from 'modules/common/hooks/redux';
import QuillUtil from 'utils/QuillUtil';

export const useSaveNextChapterChanges = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    async ({
      chapterId,
      projectId,
    }: {
      chapterId: string;
      projectId: string;
    }) => {
      const { postUrl, getUrl } = await ChaptersApiInstance.getChapterInfo(
        chapterId,
        projectId
      );
      const text = await ChaptersApiInstance.getChapterText(getUrl);
      const history = await SaveCacheApiInstance.get({
        chapterId,
        projectId,
      });
      const textWithHistory = QuillUtil.getQuillValueOptimizedWithHistory(
        text,
        JSON.parse(history.value)
      );
      await ChaptersApiInstance.saveChapterInS3(postUrl, textWithHistory);
      await SaveCacheApiInstance.delete(projectId, chapterId);
      dispatch(fetchProjectCaches(projectId));
    },
    [dispatch]
  );
};
