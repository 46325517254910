import { FC } from 'react';
import { Stack } from 'modules/common/components/Stack/Stack';
import Pagination from './Pagination';
import Styles from './pagination.module.scss';

interface Props {
  currentDataCount?: number;
  totalCount: number;
  currentPage: number;
  onChange: (currentPage: number) => void;
}

const TablePagination: FC<Props> = ({
  totalCount,
  currentPage,
  onChange,
  currentDataCount = 0,
}) => {
  return (
    <Stack justifyContent="space-between" alignItems="center" className="m-t-2">
      <Stack.Item className={Styles['text']}>
        Showing {currentDataCount} out of {totalCount} results
      </Stack.Item>
      <Stack.Item>
        <Pagination
          totalCount={totalCount}
          currentPage={currentPage}
          onChange={onChange}
        />
      </Stack.Item>
    </Stack>
  );
};

export default TablePagination;
