import Styles from './loader.module.scss';
import { FC } from 'react';

interface LoaderProps {
  size?: string;
  loaderWidth?: string;
  loadingPartColor?: string;
  loadingBackSet?: string;
}

export const Loader: FC<LoaderProps> = ({
  size,
  loadingPartColor,
  loadingBackSet,
  loaderWidth,
}) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderColor: loadingPartColor,
        borderTopColor: loadingBackSet,
        borderWidth: loaderWidth,
      }}
      className={Styles['loader']}
    />
  );
};

export default Loader;
