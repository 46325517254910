import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { getProjectTOCPagesNumber } from 'redux/reducers/project/selectors';
import Pagination from 'modules/common/components/Table/Pagination/Pagination';
import { useAppSelector } from 'modules/common/hooks/redux';
import Styles from '../styles.module.scss';

export const IncompleteProjectPagination = () => {
  const projectTOCPagesNumber = useAppSelector(getProjectTOCPagesNumber);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = React.useMemo(() => {
    const pageIdSearchParam = searchParams.get('pageId');
    // The increment with one is necessary because the page index starts with a zero value.
    return Number(pageIdSearchParam) + 1 ?? 0;
  }, [searchParams]);

  const setPage = (value: number) =>
    setSearchParams({
      // The decreasing with one is necessary because the page index starts with a zero value.
      pageId: String(value - 1),
    });

  if (!projectTOCPagesNumber) return null;

  return (
    <Pagination
      totalCount={projectTOCPagesNumber}
      currentPage={currentPage}
      onChange={setPage}
      format="short"
      className={Styles.IncompletePaginationContainer}
    />
  );
};
