import React from 'react';
import { getProjectTOCPages } from 'redux/reducers/project/selectors';
import QuillUtil from 'utils/QuillUtil';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Input } from 'modules/common/components/Input/Input';
import useQuill from 'modules/common/hooks/useQuill';
import { useAppSelector } from 'modules/common/hooks/redux';
import { Icon } from 'icons';

import {
  applySearch,
  useNavigateToTheNextElementInTheSequence,
  useSearchNavigationBySelectingItem,
  useUpdateQuillPageForSearch,
} from './lib';
import { HasMoreFlag, SearchTOCItem } from './type';
import Styles from './search.module.scss';
import { List } from './components/List';

const INITIAL_SIZE = 20;
const REGULAR_SIZE = 5;

export const Search: React.FC = () => {
  const projectTOCPages = useAppSelector(getProjectTOCPages);
  const valueToFind = React.useRef('');
  const [overallFoundResult, setOverallFoundResult] = React.useState<
    SearchTOCItem[]
  >([]);
  const [currentResultIndex, setCurrentResultIndex] = React.useState<
    number | null
  >(null);
  const [currentResultId, setCurrentResultId] = React.useState<string | null>(
    null
  );
  const [resultsToBeDisplayedInTheList, setResultsToBeDisplayedInTheList] =
    React.useState<SearchTOCItem[]>([]);
  const searchMenuRef = React.useRef<HTMLDivElement>(null);
  const navigateBySelectingItem = useSearchNavigationBySelectingItem();
  const navigateToTheNextElementInTheSequence =
    useNavigateToTheNextElementInTheSequence();
  const quillInstance = useQuill();
  const editor = quillInstance?.getEditor();
  const observer = React.useRef<IntersectionObserver | null>(null);
  const [hasMore, setHasMore] = React.useState<HasMoreFlag>({
    upper: true,
    lower: true,
  });
  const isTheInitialSearchWasTriggeredBeforeRef = React.useRef(false);
  const memoizedUpperIndex = React.useRef<number | null>(null);
  const memoizedLowerIndex = React.useRef<number | null>(null);

  const loadMoreItems = React.useCallback(
    (direction?: 'up' | 'down') => {
      if (overallFoundResult.length <= INITIAL_SIZE * 2) {
        setHasMore({
          upper: false,
          lower: false,
        });
        setResultsToBeDisplayedInTheList(overallFoundResult);
        return;
      }

      if (
        resultsToBeDisplayedInTheList.length >= overallFoundResult.length ||
        currentResultIndex === null
      ) {
        setHasMore({
          upper: false,
          lower: false,
        });
        return;
      }
      // The scrollTop and scrollHeight intended to adjust the scroll behaviour while scrolling up
      // and setting new elements
      let scrollTop;
      let scrollHeight;

      if (!isTheInitialSearchWasTriggeredBeforeRef.current) {
        isTheInitialSearchWasTriggeredBeforeRef.current = true;
        memoizedUpperIndex.current = currentResultIndex;
        memoizedLowerIndex.current = currentResultIndex + INITIAL_SIZE;
      } else if (
        memoizedUpperIndex.current !== null &&
        memoizedLowerIndex.current !== null
      ) {
        if (direction === 'up') {
          if (memoizedUpperIndex.current <= 0) return;

          if (
            memoizedLowerIndex.current - memoizedUpperIndex.current ===
            INITIAL_SIZE
          ) {
            memoizedUpperIndex.current =
              memoizedUpperIndex.current - INITIAL_SIZE < 0
                ? 0
                : memoizedUpperIndex.current - INITIAL_SIZE;
          } else {
            memoizedUpperIndex.current =
              memoizedUpperIndex.current - REGULAR_SIZE < 0
                ? 0
                : memoizedUpperIndex.current - REGULAR_SIZE;

            memoizedLowerIndex.current =
              memoizedLowerIndex.current - REGULAR_SIZE;
          }
          if (searchMenuRef.current) {
            scrollTop = searchMenuRef.current.scrollTop;
            scrollHeight = searchMenuRef.current.scrollHeight;
          }
        } else if (direction === 'down') {
          memoizedUpperIndex.current += REGULAR_SIZE;
          memoizedLowerIndex.current += REGULAR_SIZE;
        }
      }

      if (
        memoizedUpperIndex.current === null ||
        memoizedLowerIndex.current === null
      )
        return;

      const newResultsToBeDisplayedInTheList = overallFoundResult.slice(
        memoizedUpperIndex.current < 0 ? 0 : memoizedUpperIndex.current,
        memoizedLowerIndex.current
      );
      setResultsToBeDisplayedInTheList(newResultsToBeDisplayedInTheList);
      // If scrolling up with setting new elements, adjust the scroll behaviour.
      if (
        searchMenuRef.current &&
        direction === 'up' &&
        scrollHeight !== undefined &&
        scrollTop !== undefined
      ) {
        const newScrollTop =
          searchMenuRef.current.scrollHeight - scrollHeight + scrollTop;
        const scrollToSet =
          newScrollTop > 0
            ? newScrollTop
            : Math.round(
                (searchMenuRef.current.scrollHeight / (INITIAL_SIZE * 2)) *
                  REGULAR_SIZE
              );
        searchMenuRef.current.scrollTop = scrollToSet;
      }
      setHasMore({
        upper:
          newResultsToBeDisplayedInTheList[0].id !== overallFoundResult[0].id,
        lower:
          newResultsToBeDisplayedInTheList[
            newResultsToBeDisplayedInTheList.length - 1
          ].id !== overallFoundResult[overallFoundResult.length - 1].id,
      });
    },
    [
      currentResultIndex,
      overallFoundResult,
      resultsToBeDisplayedInTheList.length,
    ]
  );

  // This effect is intended for the initial processing of the request.
  React.useEffect(() => {
    if (
      !!overallFoundResult.length &&
      !isTheInitialSearchWasTriggeredBeforeRef.current
    ) {
      loadMoreItems();
    }
  }, [overallFoundResult, loadMoreItems]);

  const handleObserver = React.useCallback(
    (entities: any[]) => {
      const target = entities.find((entity) => !!entity.isIntersecting)?.target;
      if (!target) return;
      if (target.className === 'start-of-list') {
        loadMoreItems('up');
      } else if (target.className === 'end-of-list') {
        loadMoreItems('down');
      }
    },
    [loadMoreItems]
  );

  React.useEffect(() => {
    observer.current = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    });
    const endOfListElement = document.querySelector('.end-of-list');
    const startOfListElement = document.querySelector('.start-of-list');
    if (observer.current && endOfListElement && startOfListElement) {
      observer.current.observe(startOfListElement);
      observer.current.observe(endOfListElement);
    }
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [handleObserver]);

  useUpdateQuillPageForSearch({
    currentResultIndex: currentResultIndex ?? 0,
    valueToFind,
    overallFoundResult,
  });

  const setValueToFind = (v: string) => {
    valueToFind.current = v;
  };

  const removeStyle = React.useCallback(() => {
    QuillUtil.removeFindAndApplyStylesTOC(quillInstance);
  }, [quillInstance]);

  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      // This one needs to prevent the start/pause payback functionality.
      e.stopPropagation();
      if (e.key !== 'Enter' || !editor || !projectTOCPages?.length) return;

      const value = valueToFind.current.trim();

      if (!value) return;

      isTheInitialSearchWasTriggeredBeforeRef.current = false;

      applySearch({
        editor,
        valueToFind: value,
        projectTOCPages,
        removeStyle,
        setCurrentResultIndex,
        setCurrentResultId,
        setOverallFoundResult,
      });
    },
    [editor, projectTOCPages, removeStyle]
  );

  const incrementCurrentHandler = React.useCallback(() => {
    if (currentResultIndex === null) return;
    const prevId = overallFoundResult[currentResultIndex].id;
    let newCurrentIndex = currentResultIndex + 1;
    if (newCurrentIndex > overallFoundResult.length - 1) {
      newCurrentIndex = 0;
    }
    const itemNavigateTo = overallFoundResult[newCurrentIndex];
    if (!itemNavigateTo) return;

    setCurrentResultIndex(newCurrentIndex);
    setCurrentResultId(itemNavigateTo.id);

    const isAnElementInTheCurrentList = !!resultsToBeDisplayedInTheList.find(
      (item) => item.id === itemNavigateTo.id
    );
    if (!isAnElementInTheCurrentList && searchMenuRef.current) {
      isTheInitialSearchWasTriggeredBeforeRef.current = false;
      setResultsToBeDisplayedInTheList([]);
    }

    navigateToTheNextElementInTheSequence({
      prevId,
      itemNavigateTo,
      valueToFind: valueToFind.current,
      overallFoundResult,
    });
  }, [
    currentResultIndex,
    overallFoundResult,
    resultsToBeDisplayedInTheList,
    navigateToTheNextElementInTheSequence,
  ]);

  const decrementCurrentHandler = React.useCallback(() => {
    if (currentResultIndex === null) return;
    const prevId = overallFoundResult[currentResultIndex].id;
    let newCurrentIndex = currentResultIndex - 1;
    if (newCurrentIndex < 0) {
      newCurrentIndex = overallFoundResult.length - 1;
    }
    const itemNavigateTo = overallFoundResult[newCurrentIndex];
    if (!itemNavigateTo) return;

    setCurrentResultIndex(newCurrentIndex);
    setCurrentResultId(itemNavigateTo.id);
    const isAnElementInTheCurrentList = !!resultsToBeDisplayedInTheList.find(
      (item) => item.id === itemNavigateTo.id
    );
    if (!isAnElementInTheCurrentList && searchMenuRef.current) {
      if (newCurrentIndex - currentResultIndex === 1) {
        searchMenuRef.current.scrollTop = 0;
      } else {
        isTheInitialSearchWasTriggeredBeforeRef.current = false;
        setResultsToBeDisplayedInTheList([]);
      }
    }

    navigateToTheNextElementInTheSequence({
      prevId,
      itemNavigateTo,
      valueToFind: valueToFind.current,
      overallFoundResult,
    });
  }, [
    currentResultIndex,
    navigateToTheNextElementInTheSequence,
    overallFoundResult,
    resultsToBeDisplayedInTheList,
  ]);

  const clickHandler = React.useCallback(
    (item: SearchTOCItem) => {
      if (currentResultIndex === null) return;
      const prevId = overallFoundResult[currentResultIndex].id;
      const newCurrentIndex = overallFoundResult.findIndex(
        (i) => i.id === item.id
      );
      if (newCurrentIndex === -1) return;

      setCurrentResultIndex(newCurrentIndex);
      setCurrentResultId(overallFoundResult[newCurrentIndex].id);

      navigateBySelectingItem({
        item,
        valueToFind: valueToFind.current,
        prevId,
        overallFoundResult,
      });
    },
    [currentResultIndex, overallFoundResult, navigateBySelectingItem]
  );

  React.useEffect(() => {
    return () => {
      removeStyle();
    };
  }, [removeStyle]);

  return (
    <>
      <div className="overflow-hidden flex flex-column">
        <p className="m-b-1 font-size-sm fw-600">Find Text</p>
        <div className={Styles['find-input-wrapper']}>
          <Input
            type="text"
            onChangeWithValueHandler={setValueToFind}
            onKeyDown={handleKeyDown}
            placeholder="Enter text"
            className={Styles['find-input']}
          />
          <Stack className={Styles['arrows']} alignItems="center">
            {!!overallFoundResult.length && (
              <Stack.Item>
                {(currentResultIndex || 0) + 1} of {overallFoundResult.length}
              </Stack.Item>
            )}
            <Stack.Item>
              <Icon
                disabled={overallFoundResult.length <= 1}
                color="#031E50"
                onClick={incrementCurrentHandler}
                name="arrow_drop_down"
              />
              <Icon
                disabled={overallFoundResult.length <= 1}
                color="#031E50"
                onClick={decrementCurrentHandler}
                name="arrow_drop_up"
              />
            </Stack.Item>
          </Stack>
          <List
            searchMenuRef={searchMenuRef}
            selectedItemId={currentResultId}
            findResult={resultsToBeDisplayedInTheList}
            clickHandler={clickHandler}
            hasMore={hasMore}
          />
        </div>
      </div>
    </>
  );
};
