import { useSearchParams } from 'react-router-dom';
import { setActiveChapter } from 'redux/reducers/chapters/chaptersSlice';
import { useAppDispatch } from 'modules/common/hooks/redux';

export const useOpenNextChapter = () => {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  return ({
    chapterId,
    projectId,
  }: {
    chapterId: string;
    projectId: string;
  }) => {
    dispatch(setActiveChapter({ chapterId, projectId }));
    setSearchParams({ chapterId });
  };
};
