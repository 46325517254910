import Styles from './helpcenter.module.scss';

const PresetsView = () => {
  return (
    <>
      <p className={Styles['main-header']} id="presets_view">
        Presets for single click editing
      </p>
      <p className={Styles['text']}>
        Presets help to combine several tags and then apply this combination to
        words and phrases.
      </p>

      <p className={Styles['alert']}>
        Some tags cannot be applied to a word or phrase simultaneously. Read
        more
      </p>
      <p className={Styles['text']}>To create a preset:</p>

      <ol className={Styles['list']}>
        <li>Select a word or a text fragment.</li>
        <li>
          Go to the <b>Edit</b> tab and scroll down to the <b>Presets</b>.{' '}
        </li>
        <li>
          Go to the <b>Edit</b> tab and scroll down to the <b>Presets</b>.{' '}
        </li>
        <li>Select one or several tags and enter a preset name.</li>
        <li>Click on Create preset.</li>
      </ol>
    </>
  );
};
export default PresetsView;
