import React, { useCallback, useEffect, useState } from 'react';
import { Icon } from 'icons';
import { useActions } from 'modules/common/hooks/useActions';
import { ActionMenu } from 'modules/common/components/ActionMenu/ActionMenu';
import { Button } from 'modules/common/components/Button/Button';
import { Form, FormUpdate } from '../types';
import { EDIT_VOICE_SETTINGS_MODAL_STYLES } from '../lib';
import { EditVoiceSettingsForm } from './form';
import Styles from '../editvoicesettings.module.scss';
import { useChangeProjectVoiceSettingsMutation } from 'redux/api/app.api';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import { setProjectVoiceSettings } from 'redux/reducers/project/projectSlice';
import { error, success } from 'modules/common/components/Notify';
import {
  activeProjectSelector,
  activeProjectSelectorId,
  activeProjectVoiceSettingsSelector,
} from 'redux/reducers/project/selectors';
import { TryVoiceRequest } from 'redux/api/types';
import { EditorSave } from 'utils/EditorSave';
import { activeChapterIdSelector } from 'redux/reducers/chapters/selectors';

interface EditVoiceSettingsProps {
  isLoadingAudio: boolean;
  isPlayingAudio: boolean;
  audioPlayerContainerRef: React.RefObject<HTMLDivElement>;
  onGenerateAudio(
    payloadForVoiceDemoPlayback: Omit<TryVoiceRequest, 'text'>
  ): void;
}

export const EditVoiceSettings: React.FC<EditVoiceSettingsProps> = ({
  isLoadingAudio,
  isPlayingAudio,
  audioPlayerContainerRef,
  onGenerateAudio,
}) => {
  const project = useAppSelector(activeProjectSelector);
  const projectId = useAppSelector(activeProjectSelectorId);
  const currentChapterId = useAppSelector(activeChapterIdSelector);
  const projectVoiceSettings = useAppSelector(
    activeProjectVoiceSettingsSelector
  );
  const [formData, setFormData] = useState<Form>({
    voice: projectVoiceSettings?.voice,
    elevenLabsModel: projectVoiceSettings?.elevenLabsModel,
    elevenLabsVoiceSettings: projectVoiceSettings?.voiceSettings,
  } as Form);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [wereSavingEditorHistory, setWereSavingEditorHistory] = useState(false);
  const { actionMenuOpen, handleCloseActions, setActionMenuOpen } =
    useActions();
  const dispatch = useAppDispatch();
  const [changeSettings, { isLoading: isChangingSettings }] =
    useChangeProjectVoiceSettingsMutation();

  const onFormChange = (v: FormUpdate) => {
    setFormData((prevState) => ({ ...prevState, [v.name]: v.value }));
    setIsFormDirty(true);
  };

  // when the action menu is open, we stop saving the editor history
  // when the action menu is closed, we resume saving the editor history
  useEffect(() => {
    const editorInstanceSave = EditorSave.getInstance();
    if (actionMenuOpen && editorInstanceSave.isSaving) {
      setWereSavingEditorHistory(editorInstanceSave.isSaving);
      editorInstanceSave.stopSaving();
    } else if (!actionMenuOpen && wereSavingEditorHistory && projectId && currentChapterId) {
      editorInstanceSave.startSaving(projectId, currentChapterId);
    }
  }, [actionMenuOpen, currentChapterId, projectId, wereSavingEditorHistory]);

  const onSubmit = useCallback(async () => {
    const response = await changeSettings({
      projectId,
      body: {
        voice_id: formData.voice,
        voice_settings: formData.elevenLabsVoiceSettings,
        el_model_id: formData.elevenLabsModel,
      },
    });
    if ('error' in response) {
      const children = 'Something went while changing project voice settings';
      error({ children });
      return;
    }
    dispatch(
      setProjectVoiceSettings({
        voice: response.data.voice,
        voiceSettings: response.data.voiceSettings,
        elevenLabsModel: response.data.elevenLabsModel,
      })
    );
    success({ children: 'Project voice settings updated successfully' });
    setIsFormDirty(false);
  }, [projectId, changeSettings, formData, dispatch]);

  const generateAudioHandler = () => {
    onGenerateAudio({
      voice_id: formData.voice,
      el_model_id: formData.elevenLabsModel,
      voice_settings: formData.elevenLabsVoiceSettings,
      karaoke_enabled: true,
    });
  };

  return (
    <ActionMenu
      externalContainerRef={audioPlayerContainerRef}
      direction="tl"
      open={actionMenuOpen}
      trigger={
        <Button
          inline
          className={Styles['OpenMenuButton']}
          onClick={() => setActionMenuOpen(!actionMenuOpen)}
        >
          <Icon
            name="settings"
            size={24}
            className={Styles['OpenMenuSettingsIcon']}
          />
          <Icon
            name={actionMenuOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
            size={24}
          />
        </Button>
      }
      onClickOutside={handleCloseActions}
      actionItemWrapperClassname={Styles['EditVoiceSettingsContainer']}
      externalStyles={EDIT_VOICE_SETTINGS_MODAL_STYLES}
    >
      {formData && (
        <EditVoiceSettingsForm
          formData={formData}
          isFormDirty={isFormDirty}
          project={project!}
          onFormChange={onFormChange}
          onSubmit={onSubmit}
          isChangingSettings={isChangingSettings}
          isLoadingAudio={isLoadingAudio}
          isPlayingAudio={isPlayingAudio}
          onGenerateAudio={generateAudioHandler}
        />
      )}
    </ActionMenu>
  );
};
