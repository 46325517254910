import { VoiceDescription, VoiceAdditionalSetting } from './types';
import { ElevenLabsVoiceSettingsParamType } from 'modules/projects/Project/types';

export const DESCRIPTIONS: Record<
  Exclude<ElevenLabsVoiceSettingsParamType, 'use_speaker_boost'>,
  VoiceDescription[]
> = {
  stability: [
    {
      label: 'More variable',
      popoverText:
        'Increasing variability can make speech more expressive with output varying between re-generations. It can also lead to instabilities.',
    },
    {
      label: 'More stable',
      popoverText:
        'Increasing stability will make the voice more consistent between re-generations, but it can also make it sounds a bit monotone. On longer text fragments we recommend lowering this value.',
    },
  ],
  similarity_boost: [
    {
      label: 'Low',
      popoverText:
        'Low values are recommended if background artifacts are present in generated speech.',
    },
    {
      label: 'High',
      popoverText:
        'High enhancement boosts overall voice clarity and target speaker similarity. Very high values can cause artifacts, so adjusting this setting to find the optimal value is encouraged.',
    },
  ],
  style: [
    {
      label: 'None (Fastest)',
    },
    {
      label: 'Exaggeration',
      popoverText:
        'High values are recommended if the style of the speech should be exaggerated compared to the uploaded audio. Higher values can lead to more instability in the generated speech. Setting this to 0.0 will greatly increase generation speed and is the default setting.',
    },
  ],
};

export const VOICE_ADDITIONAL_SETTINGS: Record<
  Exclude<ElevenLabsVoiceSettingsParamType, 'use_speaker_boost'>,
  VoiceAdditionalSetting
> = {
  stability: {
    unstableRange: { from: '0.0', to: '0.3' },
  },
  similarity_boost: {
    unstableRange: undefined,
  },
  style: {
    unstableRange: { from: '0.5', to: '1' },
  },
};
