import {
  DateRanges,
  EditorCapacity,
  EditorStatus,
  LabelValue,
  ProjectStatus,
} from 'modules/common/types';

export function getStatusHash(): { [str in ProjectStatus]: string } {
  return {
    [ProjectStatus.deactivated]: 'Deactivated',
    [ProjectStatus.incomplete]: 'Incomplete',
    [ProjectStatus.new_project]: 'New Project',
    [ProjectStatus.assigned]: 'Assigned',
    [ProjectStatus.pending]: 'Pending',
    [ProjectStatus.in_progress]: 'In Progress',
    [ProjectStatus.in_review]: 'In Review',
    [ProjectStatus.re_take]: 'Re-take',
    [ProjectStatus.declined]: 'Decline',
    [ProjectStatus.done]: 'Done',
  };
}

export function getStatusList(): LabelValue[] {
  const hash = getStatusHash();
  return [
    {
      value: ProjectStatus.incomplete,
      label: hash[ProjectStatus.incomplete],
    },
    {
      value: ProjectStatus.new_project,
      label: hash[ProjectStatus.new_project],
    },
    {
      value: ProjectStatus.deactivated,
      label: hash[ProjectStatus.deactivated],
    },
    { value: ProjectStatus.assigned, label: hash[ProjectStatus.assigned] },
    { value: ProjectStatus.pending, label: hash[ProjectStatus.pending] },
    {
      value: ProjectStatus.in_progress,
      label: hash[ProjectStatus.in_progress],
    },
    { value: ProjectStatus.in_review, label: hash[ProjectStatus.in_review] },
    { value: ProjectStatus.re_take, label: hash[ProjectStatus.re_take] },
    { value: ProjectStatus.declined, label: hash[ProjectStatus.declined] },
    { value: ProjectStatus.done, label: hash[ProjectStatus.done] },
  ];
}

export function getReTakesList(): LabelValue[] {
  return [
    { value: '0', label: '0/10' },
    { value: '1', label: '1/10' },
    { value: '2', label: '2/10' },
    { value: '3', label: '3/10' },
    { value: '4', label: '4/10' },
    { value: '5', label: '5/10' },
    { value: '6', label: '6/10' },
    { value: '7', label: '7/10' },
    { value: '8', label: '8/10' },
    { value: '9', label: '9/10' },
    { value: '10', label: '10/10' },
  ];
}

export function getEditorAvailabilityList(): LabelValue[] {
  return [
    { value: EditorCapacity.Zero.toString(), label: '0/5' },
    { value: EditorCapacity.One.toString(), label: '1/5' },
    { value: EditorCapacity.Two.toString(), label: '2/5' },
    { value: EditorCapacity.Three.toString(), label: '3/5' },
    { value: EditorCapacity.Four.toString(), label: '4/5' },
    { value: EditorCapacity.Five.toString(), label: '5/5' },
  ];
}

export function getDatesRanges(): LabelValue[] {
  return [
    { value: DateRanges.yesterday, label: 'Yesterday' },
    { value: DateRanges.today, label: 'Today' },
    { value: DateRanges.this_month, label: 'This Month' },
    { value: DateRanges.last_month, label: 'Last Month' },
    { value: DateRanges.last_3_months, label: 'Last 3 Months' },
  ];
}

export function getEditorsStatus(): LabelValue[] {
  return [
    { value: EditorStatus.Available, label: 'Available' },
    { value: EditorStatus.Unavailable, label: 'Unavailable' },
  ];
}
