import { FC } from 'react';

const AzureIcon: FC = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.206 40.0241C28.3729 38.9399 33.4664 38.0428 33.5249 38.0304L33.6312 38.008L27.8091 31.116C24.6069 27.3254 21.9869 24.2093 21.9869 24.1914C21.9869 24.1574 27.9988 7.6816 28.0326 7.62299C28.0439 7.60339 32.1352 14.6329 37.9501 24.6629C43.3941 34.0532 47.8824 41.7955 47.9241 41.8681L48 42L29.4967 41.9976L10.9935 41.9953L22.206 40.0241ZM0 37.9223C0 37.9126 2.74337 33.1733 6.09637 27.3904L12.1927 16.8761L19.2974 10.9425C23.2049 7.6791 26.4125 5.00501 26.4254 5.00013C26.4382 4.99526 26.3868 5.12401 26.3111 5.28625C26.2355 5.44849 22.7639 12.8589 18.5965 21.7537L11.0195 37.9262L5.50976 37.9331C2.47939 37.9369 0 37.932 0 37.9223Z"
        fill="#0089D6"
      />
    </svg>
  );
};

export default AzureIcon;
