import { ParsedVoiceValue, TTSEngine } from 'modules/common/types';

export type Label = {
  label: string;
  value: TTSEngine;
};

export const TTS_ENGINES: Label[] = [
  {
    label: 'AWS',
    value: 'aws',
  },
  {
    label: 'Azure',
    value: 'azure',
  },
  {
    label: 'GCP',
    value: 'google',
  },
  {
    label: 'WellSaid',
    value: 'wellsaid',
  },
  {
    label: 'DeepZen',
    value: 'deepzen',
  },
  {
    label: 'ElevenLabs',
    value: 'elevenlabs',
  },
];

export const DEFAULT_TTS_ENGINE_ENHANCED_WITH_PREPROCESSING = {
  ...TTS_ENGINES[0],
  pre_process: false,
};

export const DEFAULT_VOICE_VALUE: ParsedVoiceValue = {
  value: '',
  label: '',
  high_quality_base_model_ids: [],
};

export const ELEVENLABS_DEFAULT_VOICE_PREVIEW_TEXT =
  'The perfect voice for your next book awaits. Listen closely – can this voice be your trusted companion as you delve into a world of knowledge and discovery?';
