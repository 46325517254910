import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Button } from 'modules/common/components/Button/Button';
import HeaderWithLogo from 'modules/common/components/HeaderWithLogo/HeaderWithLogo';

import { Page } from 'modules/common/components/Page/Page';
import { useAuth } from 'modules/common/hooks/useAuth';
import Image from 'modules/common/components/Image/Image';

export const NotFound: FC = () => {
  const auth = useAuth();

  return (
    <Page header={<HeaderWithLogo />}>
      <Stack justifyContent="center" alignItems="center" className="h-100">
        <Stack.Item className="ta-center">
          <Stack direction="column">
            <Stack.Item>
              <Image src="svg/error.svg" alt="error 404" />
              <p className="color-primary font-size-lg fw-600 m-t-4">
                sorry we can't find the page
              </p>
              <p className="color-primary font-size-md m-t-2">
                Press the button bellow
              </p>
            </Stack.Item>
            <Stack.Item>
              <Button className="m-t-3">
                <Link to="/">Go to {auth ? 'Dashboard' : 'Login Page'}</Link>
              </Button>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Page>
  );
};
