import { FC, MouseEvent } from 'react';

import Styles from './action-menu.module.scss';
import classNames from 'classnames';

export interface ActionMenuItemProps {
  className?: string;
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void;
}

export const ActionMenuItem: FC<ActionMenuItemProps> = ({
  children,
  className,
  disabled,
  onClick,
}) => {
  const clickHandler = (event: MouseEvent) => {
    if (disabled) return;
    onClick?.(event);
  };
  return (
    <div
      onClick={clickHandler}
      className={classNames(
        Styles['action-menu-item'],
        { [Styles['disabled']]: disabled },
        className
      )}
    >
      {children}
    </div>
  );
};
