import { FC, useState, useEffect, useMemo } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { Edit } from 'modules/edit/Edit';
import { Edit as EditV2 } from 'modules/edit/v2/Edit';
import { Project } from 'modules/projects/Project/Project';
import { Auth } from 'modules/auth/Auth';
import { PublicOutlet } from 'modules/common/components/Routes/PublicOutlet';
import { PrivateOutlet } from 'modules/common/components/Routes/PrivateOutlet';
import UserContext from 'modules/common/context/UserContext';
import { getCurrentSession, getUserGroups } from 'aws';
import { ADMIN_USER_GROUP } from 'modules/common/constants';
import { NotFound } from 'modules/NotFound';
import Dashboard from 'modules/dashboard';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export const App: FC = () => {
  const [userGroups, setUserGroups] = useState<string[]>([]);
  const [userSession, setUserSession] = useState<CognitoUserSession | null>(
    null
  );

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const userGroups = await getUserGroups();
        const session = await getCurrentSession();
        setUserGroups(userGroups);
        setUserSession(session);
      } catch (err) {
        // console.log(err);
      } finally {
        setInitialized(true);
      }
    })();
  }, []);

  // const editorRoutes = useMemo(() => {
  //   return (
  //     userGroups?.includes(EDITOR_USER_GROUP) && [
  //       <Route
  //         key={EDITOR_USER_GROUP}
  //         path="projects"
  //         element={<PrivateOutlet />}
  //       >
  //         <Route index element={<Projects />} />
  //         <Route path="create" element={<Project />} />
  //         <Route path="edit/:id" element={<Project />} />
  //       </Route>,
  //     ]
  //   );
  // }, [userGroups]);

  const adminRoutes = useMemo(() => {
    return (
      userGroups?.includes(ADMIN_USER_GROUP) && [
        <Route
          key={ADMIN_USER_GROUP}
          path="projects"
          element={<PrivateOutlet />}
        >
          <Route index element={<Navigate to="create" />} />
          <Route path="create" element={<Project />} />
          <Route path="edit/:id" element={<Project />} />
        </Route>,
      ]
    );
  }, [userGroups]);

  return (
    <UserContext.Provider
      value={{
        userGroups,
        userSession,
        setUserGroups,
        setUserSession,
      }}
    >
      <BrowserRouter>
        {initialized && (
          <Routes>
            <Route path="auth" element={<PublicOutlet />}>
              <Route path="*" element={<Auth />} />
            </Route>
            {adminRoutes}
            <Route path="dashboard" element={<PrivateOutlet />}>
              <Route index element={<Dashboard />} />
            </Route>
            <Route path="editor" element={<PrivateOutlet />}>
              <Route path=":id" element={<Edit />} />
            </Route>
            <Route path="editor/v2" element={<PrivateOutlet />}>
              <Route path=":id" element={<EditV2 />} />
            </Route>
            <Route path="/" element={<Navigate to="dashboard" />} />,
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate to="not-found" />} />
          </Routes>
        )}
      </BrowserRouter>
    </UserContext.Provider>
  );
};
