import React from 'react';
import Quill from 'quill';
import { useSearchParams } from 'react-router-dom';
import { isInTheViewport } from 'utils';
import QuillUtil from 'utils/QuillUtil';
import {
  getProjectTOCPages,
  getProjectTOCPagesNumber,
  getProjectTOCPagesWithChapters,
  useGetCurrentProjectTOCPageIndex,
  useGetProjectTOCPageById,
} from 'redux/reducers/project/selectors';
import {
  updateProjectTOCPage,
  updateProjectTOCPageMany,
} from 'redux/reducers/project/projectSlice';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import { generateId, modifyString } from 'modules/common/lib';
import { TOCChapter } from 'modules/common/types';
import useQuill from 'modules/common/hooks/useQuill';
import { ChapterIconBlot } from 'modules/edit/Editor/Blots/ChapterIconBlot';
import { ProjectTOCPage } from 'modules/common/models/Project';

const findLeftAndRightChapterIds = (
  chapterBlot: ChapterIconBlot[],
  cursor: number
) => {
  let index = 0;
  while (
    index < chapterBlot.length &&
    chapterBlot[index].offset() + chapterBlot[index].length() < cursor
  ) {
    index++;
  }
  if (!!chapterBlot[index]) {
    return {
      left: Number(chapterBlot[index - 1].domNode.id),
      right: Number(chapterBlot[index].domNode.id),
    };
  }

  return { left: Number(chapterBlot[index - 1].domNode.id), right: null };
};

const getChapterBlot = ({
  editor,
  innerHTML,
}: {
  editor: Quill;
  innerHTML: string;
}) => {
  const delta = editor.clipboard.convert(innerHTML);

  let tempContainer = document.createElement('div');
  tempContainer.style.display = 'none';
  document.body.appendChild(tempContainer);

  const tempQuill = new Quill(tempContainer);
  tempQuill.setContents(delta, 'api');

  (tempQuill.scroll as any).optimize();

  const chapterBlot = QuillUtil.getChapterChildren(tempQuill, {
    index: 0,
    length: innerHTML.length,
  });

  document.body.removeChild(tempContainer);
  return chapterBlot;
};

export const getTOCChapterOffsetByItsId = ({
  tocChapterId,
  editor,
  innerHTML,
}: {
  tocChapterId: string;
  editor: Quill;
  innerHTML: string;
}): number | undefined => {
  const chapterBlot = getChapterBlot({ editor, innerHTML });
  return chapterBlot.find((blot) => blot.domNode.id === tocChapterId)?.offset();
};

const useGetANextChapterIdFromSomeFollowingPage = () => {
  const [searchParams] = useSearchParams();
  const projectTOCPages = useAppSelector(getProjectTOCPages);
  const projectTOCPagesIdWithChapters = useAppSelector(
    getProjectTOCPagesWithChapters
  );
  const currentPageId = searchParams.get('pageId');

  return (editor: Quill) => {
    if (!projectTOCPages?.length || currentPageId === null) return;
    const numberCurrentPageId = Number(currentPageId);
    const nextProjectIdWithChaptersInIt = projectTOCPagesIdWithChapters.find(
      (project) => Number(project.id) > numberCurrentPageId
    );
    if (!nextProjectIdWithChaptersInIt) return;

    const chapterBlot = getChapterBlot({
      editor,
      innerHTML: nextProjectIdWithChaptersInIt.innerHTML,
    });

    const theFirstChapterIdOnThePage = Number(chapterBlot[0].domNode.id);

    return theFirstChapterIdOnThePage;
  };
};

const useGetAPreviousChapterIdFromSomePreviousPage = () => {
  const [searchParams] = useSearchParams();
  const projectTOCPages = useAppSelector(getProjectTOCPages);
  const projectTOCPagesIdWithChapters = useAppSelector(
    getProjectTOCPagesWithChapters
  );
  const currentPageId = searchParams.get('pageId');

  return (editor: Quill) => {
    if (!projectTOCPages?.length || currentPageId === null) return;
    const numberCurrentPageId = Number(currentPageId);
    const previousPageIdWithChaptersInIt = projectTOCPagesIdWithChapters
      .filter((project) => Number(project.id) < numberCurrentPageId)
      .pop();
    if (!previousPageIdWithChaptersInIt) return;

    const chapterBlot = getChapterBlot({
      editor,
      innerHTML: previousPageIdWithChaptersInIt.innerHTML,
    });

    const theLastChapterIdOnThePage = Number(
      chapterBlot[chapterBlot.length - 1].domNode.id
    );

    return theLastChapterIdOnThePage;
  };
};

const useGetChapterIdForTheFirstPage = () => {
  const getANextChapterIdFromSomeFollowingPage =
    useGetANextChapterIdFromSomeFollowingPage();

  return ({
    chapterBlot,
    editor,
    cursor,
  }: {
    chapterBlot: ChapterIconBlot[];
    editor: Quill;
    cursor: number;
  }) => {
    const lastChapterWithinThePage = chapterBlot[chapterBlot.length - 1];
    // If the last chapter on the page placed before the one to add.
    if (
      lastChapterWithinThePage.offset() + lastChapterWithinThePage.length() <
      cursor
    ) {
      const nextChapterIdFromSomeFollowingPage =
        getANextChapterIdFromSomeFollowingPage(editor);
      if (nextChapterIdFromSomeFollowingPage === undefined) {
        return Number(lastChapterWithinThePage.domNode.id) + 1;
      }

      return generateId(
        Number(lastChapterWithinThePage.domNode.id),
        nextChapterIdFromSomeFollowingPage
      );
    }
    const { left, right } = findLeftAndRightChapterIds(chapterBlot, cursor);

    return generateId(left, right as number);
  };
};

const useGetChapterIdForTheLastPage = () => {
  const getAPreviousChapterIdFromSomePreviousPage =
    useGetAPreviousChapterIdFromSomePreviousPage();
  const projectTOCPages = useAppSelector(getProjectTOCPages);

  return ({
    chapterBlot,
    editor,
    cursor,
  }: {
    chapterBlot: ChapterIconBlot[];
    editor: Quill;
    cursor: number;
  }) => {
    if (!projectTOCPages?.length) return;

    const prevChapterIdFromSomePreviousPage =
      getAPreviousChapterIdFromSomePreviousPage(editor);

    if (
      !chapterBlot.length &&
      prevChapterIdFromSomePreviousPage !== undefined
    ) {
      return prevChapterIdFromSomePreviousPage + 1;
    }

    const firstChapterWithinThePage = chapterBlot[chapterBlot.length - 1];
    // If the first chapter on the page placed after the one to add.
    if (
      firstChapterWithinThePage.offset() + firstChapterWithinThePage.length() >
      cursor
    ) {
      if (prevChapterIdFromSomePreviousPage === undefined) return;

      return generateId(
        prevChapterIdFromSomePreviousPage,
        Number(firstChapterWithinThePage.domNode.id)
      );
    }
    const { left, right } = findLeftAndRightChapterIds(chapterBlot, cursor);

    return generateId(left, right as number);
  };
};

const useGetChapterIdFromPreviousAndFollowingPages = () => {
  const getAPreviousChapterIdFromSomePreviousPage =
    useGetAPreviousChapterIdFromSomePreviousPage();
  const getANextChapterIdFromSomeFollowingPage =
    useGetANextChapterIdFromSomeFollowingPage();

  return ({ editor }: { editor: Quill }) => {
    const APreviousChapterIdFromSomePreviousPage =
      getAPreviousChapterIdFromSomePreviousPage(editor) as number;
    const ANextChapterIdFromSomeFollowingPage =
      getANextChapterIdFromSomeFollowingPage(editor);

    if (ANextChapterIdFromSomeFollowingPage !== undefined) {
      return generateId(
        APreviousChapterIdFromSomePreviousPage,
        ANextChapterIdFromSomeFollowingPage
      );
    }

    return APreviousChapterIdFromSomePreviousPage + 1;
  };
};

const useGetChapterIdFromCurrentAndNearestPages = () => {
  const getAPreviousChapterIdFromSomePreviousPage =
    useGetAPreviousChapterIdFromSomePreviousPage();
  const getANextChapterIdFromSomeFollowingPage =
    useGetANextChapterIdFromSomeFollowingPage();

  return ({
    editor,
    chapterBlot,
    cursor,
  }: {
    editor: Quill;
    chapterBlot: ChapterIconBlot[];
    cursor: number;
  }) => {
    const isTheOnlyChapterOnThePage = chapterBlot.length === 1;
    if (isTheOnlyChapterOnThePage) {
      const isOldChapterPlacedBeforeTheNewChapter =
        chapterBlot[0].offset() + chapterBlot[0].length() < cursor;
      const isOldChapterPlacedAfterTheNewChapter =
        chapterBlot[0].offset() > cursor;
      if (isOldChapterPlacedBeforeTheNewChapter) {
        const NextChapterIdFromSomeFollowingPage =
          getANextChapterIdFromSomeFollowingPage(editor);
        if (!!NextChapterIdFromSomeFollowingPage) {
          return generateId(
            Number(chapterBlot[0].domNode.id),
            NextChapterIdFromSomeFollowingPage
          );
        }
        return Number(chapterBlot[0].domNode.id) + 1;
      } else if (isOldChapterPlacedAfterTheNewChapter) {
        const PreviousChapterIdFromSomePreviousPage =
          getAPreviousChapterIdFromSomePreviousPage(editor) as number;
        return generateId(
          PreviousChapterIdFromSomePreviousPage,
          Number(chapterBlot[0].domNode.id)
        );
      }
    }

    const isFirstChapterPlacedAfterTheNewChapter =
      chapterBlot[0].offset() > cursor;

    if (isFirstChapterPlacedAfterTheNewChapter) {
      const PreviousChapterIdFromSomePreviousPage =
        getAPreviousChapterIdFromSomePreviousPage(editor) as number;
      return generateId(
        PreviousChapterIdFromSomePreviousPage,
        Number(chapterBlot[0].domNode.id)
      );
    }
    const isLastChapterPlacedBeforeTheNewChapter =
      chapterBlot[chapterBlot.length - 1].offset() +
        chapterBlot[chapterBlot.length - 1].length() <
      cursor;

    if (isLastChapterPlacedBeforeTheNewChapter) {
      const NextChapterIdFromSomeFollowingPage =
        getANextChapterIdFromSomeFollowingPage(editor);
      if (!!NextChapterIdFromSomeFollowingPage) {
        return generateId(
          Number(chapterBlot[chapterBlot.length - 1].domNode.id),
          NextChapterIdFromSomeFollowingPage
        );
      }
      return Number(chapterBlot[chapterBlot.length - 1].domNode.id) + 1;
    }

    const { left, right } = findLeftAndRightChapterIds(chapterBlot, cursor);

    return generateId(left, right as number);
  };
};

export const useGetNewChapterId = () => {
  const projectTOCPagesNumber = useAppSelector(getProjectTOCPagesNumber);

  const currentProjectTOCPageIndex = useGetCurrentProjectTOCPageIndex();

  const getChapterIdForTheFirstPage = useGetChapterIdForTheFirstPage();
  const getChapterIdForTheLastPage = useGetChapterIdForTheLastPage();
  const getChapterIdFromPreviousAndFollowingPages =
    useGetChapterIdFromPreviousAndFollowingPages();
  const getChapterIdFromCurrentAndNearestPages =
    useGetChapterIdFromCurrentAndNearestPages();

  return (editor: Quill, cursor: number) => {
    const currentPageChapterBlots = QuillUtil.getChapterChildren(editor, {
      index: 0,
      length: editor.getLength(),
    });

    const isCurrentPageTheFirst = currentProjectTOCPageIndex === 0;

    // If a page is the first, that means, we don't need to seek for a "left"/previous id value from previous pages.
    if (isCurrentPageTheFirst) {
      return getChapterIdForTheFirstPage({
        editor,
        chapterBlot: currentPageChapterBlots,
        cursor,
      });
    }
    const isCurrentPageTheLast =
      !!projectTOCPagesNumber &&
      currentProjectTOCPageIndex === projectTOCPagesNumber - 1;

    // If a page is the last, that means, we don't need to seek for a "right"/next id value from following pages.
    if (isCurrentPageTheLast) {
      return getChapterIdForTheLastPage({
        editor,
        cursor,
        chapterBlot: currentPageChapterBlots,
      });
    }

    // If there is no chapter within the current page.
    if (!currentPageChapterBlots.length) {
      console.log('currentPageChapterBlots: ', currentPageChapterBlots);
      return getChapterIdFromPreviousAndFollowingPages({ editor });
    }

    // If the current page has chapters, but we are not sure about where a new chapter should be located.

    return getChapterIdFromCurrentAndNearestPages({
      editor,
      chapterBlot: currentPageChapterBlots,
      cursor,
    });
  };
};

export const useDeleteTOCChapter = () => {
  const dispatch = useAppDispatch();
  const getProjectTOCPageById = useGetProjectTOCPageById();
  return ({
    chapterToDelete,
    editor,
  }: {
    chapterToDelete: TOCChapter;
    editor: Quill;
  }) => {
    const projectTOCPage = getProjectTOCPageById(chapterToDelete.pageId);

    if (!projectTOCPage) return;

    const tocChapterOffset = getTOCChapterOffsetByItsId({
      editor,
      innerHTML: projectTOCPage.innerHTML,
      tocChapterId: chapterToDelete.id.toString(),
    });
    const currentPageId = new URLSearchParams(window.location.search).get(
      'pageId'
    );
    if (typeof tocChapterOffset !== 'number') return;

    if (projectTOCPage.id === currentPageId) {
      editor.deleteText(tocChapterOffset, 1);
    }

    const modifiedInnerHTML = projectTOCPage.innerHTML.replace(
      chapterToDelete.outerHTML,
      ''
    );

    dispatch(
      updateProjectTOCPage({
        id: projectTOCPage.id,
        innerHTML: modifiedInnerHTML,
        textContent: projectTOCPage.textContent,
        hasChapterTag: modifiedInnerHTML.includes('<chapter '),
      })
    );
  };
};

export const useDeleteTOCChapterMany = () => {
  const dispatch = useAppDispatch();
  const getProjectTOCPageById = useGetProjectTOCPageById();
  return ({
    chaptersToDelete,
    editor,
  }: {
    chaptersToDelete: TOCChapter[];
    editor: Quill;
  }) => {
    let pagesToUpdate: ProjectTOCPage[] = [];

    chaptersToDelete.forEach((chapterToDelete) => {
      const alreadyEditedPage = pagesToUpdate.find(
        (page) => page.id === chapterToDelete.pageId
      );
      const projectTOCPage =
        alreadyEditedPage || getProjectTOCPageById(chapterToDelete.pageId);

      if (!projectTOCPage) return;

      const tocChapterOffset = getTOCChapterOffsetByItsId({
        editor,
        innerHTML: projectTOCPage.innerHTML,
        tocChapterId: chapterToDelete.id.toString(),
      });
      const currentPageId = new URLSearchParams(window.location.search).get(
        'pageId'
      );
      if (typeof tocChapterOffset !== 'number') return;

      if (projectTOCPage.id === currentPageId) {
        editor.deleteText(tocChapterOffset, 1);
      }

      const modifiedInnerHTML = projectTOCPage.innerHTML.replace(
        chapterToDelete.outerHTML,
        ''
      );

      if (alreadyEditedPage) {
        const indexToUpdate = pagesToUpdate.findIndex(
          (item) => item.id === projectTOCPage.id
        );
        if (indexToUpdate !== -1) {
          pagesToUpdate[indexToUpdate] = {
            id: projectTOCPage.id,
            innerHTML: modifiedInnerHTML,
            hasChapterTag: modifiedInnerHTML.includes('<chapter '),
            textContent: projectTOCPage.textContent,
          };
        }
      } else {
        pagesToUpdate.push({
          id: projectTOCPage.id,
          innerHTML: modifiedInnerHTML,
          hasChapterTag: modifiedInnerHTML.includes('<chapter '),
          textContent: projectTOCPage.textContent,
        });
      }
    });

    dispatch(updateProjectTOCPageMany(pagesToUpdate));
  };
};

export const useEditTOCChapter = () => {
  const dispatch = useAppDispatch();
  const getProjectTOCPageById = useGetProjectTOCPageById();

  return ({
    value,
    chapterToEdit,
    editor,
  }: {
    value: string;
    chapterToEdit: TOCChapter;
    editor: Quill;
  }) => {
    const currentPageId = new URLSearchParams(window.location.search).get(
      'pageId'
    );

    const projectTOCPage = getProjectTOCPageById(chapterToEdit.pageId);
    if (!projectTOCPage) return;

    const chapterElement = new DOMParser()
      .parseFromString(chapterToEdit.outerHTML, 'text/html')
      .querySelector('chapter');

    let newSubstring = chapterToEdit.outerHTML;

    if (chapterElement) {
      chapterElement.setAttribute('data-name', value);
      if (chapterToEdit.isSubChapter) {
        chapterElement.setAttribute('data-sub-chapter', value);
      } else {
        chapterElement.removeAttribute('data-sub-chapter');
      }
      newSubstring = chapterElement.outerHTML;
    }

    const modifiedInnerHTML = modifyString({
      originalStr: projectTOCPage.innerHTML,
      startIndex: chapterToEdit.outerHTMLStartIndex,
      length: chapterToEdit.outerHTMLLength,
      newSubstring,
    });

    if (chapterToEdit.pageId === currentPageId) {
      editor.formatText(
        { index: chapterToEdit.outerHTMLStartIndex, length: 1 },
        ChapterIconBlot.blotName,
        JSON.stringify({
          id: chapterToEdit.id,
          chapterText: value,
          isSubChapter: chapterToEdit.isSubChapter,
        })
      );
    }

    dispatch(
      updateProjectTOCPage({
        id: chapterToEdit.pageId,
        innerHTML: modifiedInnerHTML,
        textContent: projectTOCPage.textContent,
        hasChapterTag: true,
      })
    );
  };
};

export const useScrollToTOCChapter = () => {
  const getProjectTOCPageById = useGetProjectTOCPageById();
  const [searchParams, setSearchParams] = useSearchParams();
  const quillInstance = useQuill();
  const elementToScroll = React.useRef<number | undefined>();

  const scrollToTheElement = (editor: Quill, tocChapterId: string) => {
    const tocChapterOffset = getTOCChapterOffsetByItsId({
      editor,
      innerHTML: editor.root.innerHTML,
      tocChapterId,
    });
    if (typeof tocChapterOffset !== 'number') return;

    const { top, bottom } = editor.getBounds(tocChapterOffset, 1);
    const { offsetHeight, scrollTop } = editor.root;
    if (!isInTheViewport({ top, bottom, offsetHeight })) {
      editor.root.scrollBy({
        top: top < 0 || scrollTop < top || top > bottom ? top : bottom,
      });
    }
  };

  // This effect scrolls to an element after page change.
  // Don't remove it!
  React.useEffect(() => {
    const editor = quillInstance?.editor;
    if (!editor) return;
    if (elementToScroll.current === undefined) return;
    scrollToTheElement(editor, elementToScroll.current.toString());
    elementToScroll.current = undefined;
  }, [searchParams, quillInstance]);

  return ({
    chapterToSee,
    editor,
  }: {
    chapterToSee: TOCChapter;
    editor: Quill;
  }) => {
    const currentPageId = new URLSearchParams(window.location.search).get(
      'pageId'
    );
    const projectTOCPage = getProjectTOCPageById(chapterToSee.pageId);

    if (!projectTOCPage) return;

    // If the page changing needed, the scroll effect will be caused by the useEffect statement above.
    if (chapterToSee.pageId !== currentPageId) {
      setSearchParams({
        pageId: chapterToSee.pageId,
      });
      elementToScroll.current = chapterToSee.id;
    } else {
      scrollToTheElement(editor, chapterToSee.id.toString());
    }
  };
};
