import React, { useMemo, useState } from 'react';
import {
  useGetEditorsProjectsQuery,
  useGetVoicesQuery,
} from 'redux/api/app.api';
import { getEditorProjectsTableColumnsConfig } from './ProjectsTableConfig';
import useSorting from 'modules/common/hooks/useSorting';
import Table from 'modules/common/components/Table/Table';
import RequestedProjects from './project-request/RequestedProjects';
import { SORT } from 'modules/common/types';
import { ColumnFiltersState } from '@tanstack/react-table';
import { getFiltersToSend } from '../common/lib';

const ProjectsTabAdmin = () => {
  const [page, setPage] = useState(1);
  const { sortBy, sortType, changeSortBy } = useSorting(
    SORT.ASC,
    'lastUpdated'
  );
  const [filters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const filtersToSend = React.useMemo(
    () => getFiltersToSend(filters),
    [filters]
  );
  const [viewAllProjects, setViewAllProjects] = useState(false);

  const { data, isFetching } = useGetEditorsProjectsQuery({
    index: page * 10,
    sortBy,
    sortType,
    ...filtersToSend,
  });
  const { data: voicesData } = useGetVoicesQuery();
  const columns = useMemo(() => {
    return getEditorProjectsTableColumnsConfig({
      sortBy,
      sortType,
      changeSortBy,
      voicesWithPreprocessing: voicesData?.voicesWithPreprocessing,
    });
  }, [sortBy, sortType, changeSortBy, voicesData?.voicesWithPreprocessing]);
  return (
    <>
      <RequestedProjects
        viewAllRequestedProjects={viewAllProjects}
        setViewAllRequestedProjects={setViewAllProjects}
      />
      {!viewAllProjects && (
        <Table
          data={data && data.data ? data.data : []}
          columns={columns}
          isLoading={isFetching}
          getPaginationRowModel={undefined}
          totalDataCount={data ? data.totalCount : 0}
          page={setPage}
          onColumnFiltersChange={setColumnFilters}
          paginated
        />
      )}
    </>
  );
};

export default ProjectsTabAdmin;
