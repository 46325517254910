import axios from 'axios';
import { getCurrentSession } from 'aws';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

axiosInstance.interceptors.request.use(async (config) => {
  const session = await getCurrentSession();
  if (session?.isValid() && config.baseURL === process.env.REACT_APP_URL) {
    config.headers!['Authorization'] = `${session.getIdToken().getJwtToken()}`;
  }
  return config;
});

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     console.dir(error);

//     if (error.response.status === 401) {
//       console.log('asdasdasd');
//     }
//     return error;
//   }
// );

export default axiosInstance;
