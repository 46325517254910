import { Quill } from 'react-quill';
import { TagBlotModel, TagModel, TagType } from 'modules/common/models/Tags';
import QUtil, { genericInline } from 'utils/QuillUtil';
import { SentenceBlot } from './SentenceBlot';

const Parchment = Quill.import('parchment');
const Inline = Quill.import('blots/inline');
const Text = Quill.import('blots/text');

function optimizeMergeStartEndText(this: genericInline) {
  let child = this.children.tail;
  if (
    QUtil.areTagsSSMLEqual(child?.domNode, this?.next?.domNode) &&
    !QUtil.areTagsSSMLEqual(child?.domNode, this?.prev?.domNode)
  ) {
    const value = QUtil.getValueFromTag(child.domNode);
    child.unwrap();
    let next = this.next;
    this.wrap(child.statics.blotName, value);
    next.moveChildren(this.parent);
    next.remove();
    return;
  }

  if (
    QUtil.areTagsSSMLEqual(child?.domNode, this?.prev?.domNode) &&
    !QUtil.areTagsSSMLEqual(child?.domNode, this?.next?.domNode)
  ) {
    child.unwrap();
    this.prev.appendChild(this);
    return;
  }
}

function splitMultipleTags(
  wrappingOrder: genericInline[],
  ctx: genericInline,
  config: { prev?: boolean; next?: boolean } = { prev: true, next: true }
) {
  let context = ctx;
  for (let i = wrappingOrder.length - 1; i >= 0; i--) {
    const item = wrappingOrder[i];
    const value = QUtil.getValueFromTag(item.domNode);
    const blotName = item.statics.blotName;
    if (config.prev) {
      context.prev.wrap(blotName, value);
    }
    if (config.next) {
      context.next.wrap(blotName, value);
    }
    context = (context.wrap as (blotName: string, value: string) => any)(
      blotName,
      value
    );
    context.parent.unwrap();
  }
}

function correctEmbedding(this: genericInline) {
  if (QUtil.isTagWithTextChild(this)) {
    return true;
  }

  let child = this.children.tail;

  if (!child || !this.statics.allowedTags || !child.statics.allowedTags) {
    return;
  }

  if (
    this.statics.allowedTags.includes('*') &&
    child.statics.allowedTags.includes('*')
  ) {
    return;
  }

  // easy optimizations otherwise editor is do not let it
  if (
    child &&
    child.statics.allowedTags.length &&
    (child.statics.allowedTags.includes('*') ||
      child.statics.allowedTags.includes(this.statics.ssmlTagName))
  ) {
    QUtil.swapWithChild(this);
  }
}

export function optimizeMerge(this: genericInline) {
  // Always work with ( this ) so that you can capture the correct tag
  /***********  Before After Case  ***********/
  if (QUtil.areTagsSSMLEqual(this.prev?.domNode, this.next?.domNode)) {
    let currentSSMLTag = this.prev.domNode;

    if (this.children && this.children.length === 1) {
      let child = this.children.tail;

      if (QUtil.areTagsSSMLEqual(child.domNode, currentSSMLTag)) {
        // moving the rouge tag into prev tag
        child.unwrap(); // if the other case then child child unwrap problem solve maybe check the this ;)
        this.prev.appendChild(this);

        let outerTag = this.prev.parent;

        outerTag.next.moveChildren(outerTag);
        outerTag.next.remove();
      } else if (QUtil.areTagsSSMLEqual(this.domNode, currentSSMLTag)) {
        this.moveChildren(this.prev);
        this.remove();

        this.next.moveChildren(this.prev);
        this.next.remove();
      }

      return;
    }
  }
  /***********  Before After Case  ***********/

  /***********  Before After Case where its embedded  ***********/
  if (
    this.parent &&
    this.parent.statics.blotName !== SentenceBlot.blotName && // prevent tag from being merged with sentence tag
    QUtil.areTagsSSMLEqual(this.parent.prev?.domNode, this.parent.next?.domNode)
  ) {
    let next = this.parent.next;
    let prev = this.parent.prev;

    // moving the rouge tag into prev tag
    this.parent.unwrap();
    prev.appendChild(this);
    next.moveChildren(prev);
    next.remove();
  }
  /***********  Before After Case where its embedded  ***********/

  /***********  Before OR After Case  ***********/
  optimizeMergeStartEndText.call(this);
  /***********  Before OR After Case  ***********/
}

function optimizeSplit(this: genericInline) {
  // Bear in mind that we sometimes reuse the optimization that the quill has
  // To build out own , so any modification should be treated carefully

  // as the container size so no optimizations
  if (this.next === null && this.prev === null) {
    return;
  }

  /***********  Singular Case  ***********/
  // between during small subsection form a large tag
  if (
    this.parent.prev &&
    this.parent.next &&
    QUtil.isTextBlot(this.parent.prev) &&
    QUtil.isTextBlot(this.parent.next)
  ) {
    // singular case
    const value = QUtil.getValueFromTag(this.parent.domNode);
    const blotName = this.parent.statics.blotName;
    if (QUtil.isChildOf(this.parent, this.prev)) {
      this.prev.wrap(blotName, value);
    }
    if (QUtil.isChildOf(this.parent, this.next)) {
      this.next.wrap(blotName, value);
    }
    let context = (this.wrap as (blotName: string, value: string) => any)(
      blotName,
      value
    );
    context.parent.unwrap();
    return;
  }

  if (this.parent.next && this.prev && QUtil.isTextBlot(this.parent.next)) {
    const value = QUtil.getValueFromTag(this.parent.domNode);
    const blotName = this.parent.statics.blotName;
    this.prev.wrap(blotName, value);
    let context = (this.wrap as (blotName: string, value: string) => any)(
      blotName,
      value
    );
    context.parent.unwrap();
    return;
  }

  if (this.parent.prev && this.next && QUtil.isTextBlot(this.parent.prev)) {
    const value = QUtil.getValueFromTag(this.parent.domNode);
    const blotName = this.parent.statics.blotName;
    this.next.wrap(blotName, value);
    let context = (this.wrap as (blotName: string, value: string) => any)(
      blotName,
      value
    );
    context.parent.unwrap();
    return;
  }

  /***********  Singular Case  ***********/

  /***********  Multiple Case  ***********/
  if (QUtil.isSSML(this.parent)) {
    let superParent = QUtil.getSuperParentSSMLTagContainer(this);
    if (this.prev && this.next) {
      // right and left

      // non optimized
      if (QUtil.isTextBlot(this.prev) && QUtil.isTextBlot(this.next)) {
        // with no quill Parchment optimization
        // in center;
        let wrappingOrder: any[] = [superParent];
        QUtil.forEachSSML(superParent, (child) => {
          if (child !== this) {
            wrappingOrder.push(child);
          }
        });

        splitMultipleTags(wrappingOrder, this);
        return;
      }

      // partially optimized case by parchment
      if (
        QUtil.haveSingleChild(this) &&
        QUtil.areTagsSSMLEqual(
          this.children.tail?.domNode,
          this.prev?.domNode
        ) &&
        QUtil.areTagsSSMLEqual(this.children.tail?.domNode, this.next?.domNode)
      ) {
        let context = QUtil.swapWithChild(this);
        let wrappingOrder: any[] = [superParent];
        QUtil.forEachSSML(superParent, (child) => {
          if (child !== context) {
            wrappingOrder.push(child);
          }
        });

        wrappingOrder = wrappingOrder.filter(
          (item) =>
            item.statics.blotName !== this.statics.blotName &&
            item.statics.blotName !== context.statics.blotName
        );

        splitMultipleTags(wrappingOrder, context);
        return;
      }
    }

    if (this.prev) {
      if (QUtil.isTextBlot(this.prev)) {
        // with no quill Parchment optimization
        // in center;
        let wrappingOrder: any[] = [superParent];
        QUtil.forEachSSML(superParent, (child) => {
          if (child !== this) {
            wrappingOrder.push(child);
          }
        });

        splitMultipleTags(wrappingOrder, this, { prev: true });
        return;
      }
    }

    if (this.next) {
      // non optimized
      if (QUtil.isTextBlot(this.next)) {
        // with no quill Parchment optimization
        // in center;
        let wrappingOrder: any[] = [superParent];
        QUtil.forEachSSML(superParent, (child) => {
          if (child !== this) {
            wrappingOrder.push(child);
          }
        });

        splitMultipleTags(wrappingOrder, this, { next: true });
        return;
      }
    }
  }
  /***********  Multiple Case  ***********/
}

function optimizeMergeIfSameTag(this: genericInline) {
  // this then should take into consideration the attribute

  /***********  Same Tag Child  ***********/
  if (this.parent && QUtil.isSSMLTagEqual(this, this.parent)) {
    // merge context to the parent
    this.unwrap();
  }
  /***********  Same Tag Child  ***********/

  /***********  Text Child  ***********/
  if (QUtil.isTagWithTextChild(this)) {
    let context = this;
    if (
      QUtil.isTagWithTextChild(context.prev) &&
      QUtil.isSSMLTagEqual(context, context.prev)
    ) {
      let prev = context.prev;
      context.prev.appendChild(context);
      context.unwrap();
      context = prev;
    }

    if (
      QUtil.isTagWithTextChild(context.next) &&
      QUtil.isSSMLTagEqual(context, context.next)
    ) {
      let next = context.next;
      context.appendChild(context.next);
      next.unwrap();
    }
    return;
  }
  /***********  Text Child  ***********/

  /***********  Tag Child  ***********/
  if (QUtil.isUpperTagWithTagChild(this)) {
    let context = this;
    if (
      QUtil.isUpperTagWithTagChild(context.prev) &&
      QUtil.isFullyEqual(context, context.prev)
    ) {
      let prev = context.prev;
      let text = QUtil.getInnerMostText(context);
      let innerTag = QUtil.getInnerMostSSML(prev);
      innerTag.appendChild(text);
      context.remove();
      context = prev;
    }

    if (
      QUtil.isUpperTagWithTagChild(context.next) &&
      QUtil.isFullyEqual(context, context.next)
    ) {
      let next = context.next;
      let text = QUtil.getInnerMostText(context.next);
      let innerTag = QUtil.getInnerMostSSML(context);
      innerTag.appendChild(text);
      next.remove();
    }
  }
  /***********  Tag Child  ***********/
}

export function ifEmbeddingNonEmbeddedTagCleanse(this: genericInline) {
  const child = this.children?.tail;
  if (child && QUtil.areTagsSSMLEqual(this.domNode, child?.domNode)) {
    // don't use remove or add custom html it is blowing up the in memory format of the editor
    child.unwrap();
  }
}

export function genericSSMLBlotFactory(tag: TagModel): typeof Inline {
  return class extends Inline {
    static blotName = tag.id;
    static classNameInternal =
      tag.tagType === TagType.SentenceTags
        ? 'rb-ssml-tags sentence-tag'
        : 'rb-ssml-tags';
    static tagName = `${tag.name}__${tag.id}`;
    static ssmlTagName = tag.name;
    static allowedChildren = [Text, Inline];
    static allowedTags = tag.allowedTags;
    static scope = Parchment.Scope.INLINE_BLOT;
    static currentBlotType = tag.tagType;
    static isSSML = true;

    // be-aware of position changes

    static create(val: string) {
      const value = JSON.parse(val) as TagBlotModel;
      const aiGeneratedTag = value.ai_generated_tag;
      let node: HTMLElement = super.create();
      node.setAttribute('ssml-blot-name', this.blotName);
      node.setAttribute('ssml-tag-name', this.ssmlTagName);
      node.setAttribute('ssml-tag-type', tag.tagType);
      node.setAttribute('ssml-tag-id', tag.id);
      if (value) {
        if (aiGeneratedTag)
          node.setAttribute('ai_generated_tag', aiGeneratedTag);
        node.setAttribute('class', this.classNameInternal);
        node.setAttribute(
          'style',
          // Be extremely gentle with these expressions! They stand to distinguish between custom and ai-generated tags.
          `--bg-image: url(${process.env.PUBLIC_URL}/svg/${
            tag.iconName
          }.svg);--bg-color: ${
            value['--bd-color']
              ? ''
              : value['--bg-color'] === undefined
              ? tag.iconBgColor
              : value['--bg-color']
          };--bd-color: ${value['--bd-color'] || ''}; --icon-border: ${
            value['--bd-color'] ? `1px solid ${value['--bd-color']}` : ''
          }; --icon-color: ${value['--icon-color']}`
        );
        node.setAttribute('data-id', value['data-id'] || value.blotId!);
        let beforeText = `<${tag.name}`;

        if (value.attributeValues?.length) {
          node.setAttribute(
            'data-selected-attributes',
            JSON.stringify(value.attributeValues)
          );
          for (let [attr, attrValue] of value.attributeValues) {
            beforeText += ` ${attr}="${attrValue}"`;
            node.setAttribute(attr, attrValue);
          }
        }
        if (tag.fixedAttributes) {
          Object.keys(tag.fixedAttributes).forEach((key) => {
            const attribute = String(tag?.fixedAttributes?.[key]);
            node.setAttribute(key, attribute);
            beforeText += ` ${key}="${attribute}"`;
          });
        }

        beforeText += tag.tagType === TagType.SentenceTags ? ' />' : '>';
        node.setAttribute('data-before-text', beforeText);
        if (tag.tagType !== TagType.SentenceTags) {
          node.setAttribute('data-after-text', `</${tag.name}>`);
        }
      }
      return node;
    }

    static formats(domNode: HTMLElement) {
      return QUtil.getValueFromTag(domNode);
    }

    formatAt(index: number, length: number, name: string, value: any): void {
      // when removing tag inside a sentence tag
      if (this.parent.statics.blotName === 'sentence' && !value) {
        this.unwrap();
        this.parent.children.forEach(function (child: any) {
          if (child.statics.blotName === 'text') {
            child.optimize();
          }
        });
        return;
      }

      // close case for embedded tags
      if (this.statics.blotName && this.statics.blotName !== name && !value) {
        QUtil.forEachRecursive(this.children, function (child) {
          if (QUtil.isTagNameEqual(child?.statics.blotName, name)) {
            child.unwrap();
          }
        });
        return;
      }
      super.formatAt(index, length, name, value);
    }

    optimize(): void {
      // Don't call with super otherwise
      // the order will be implemented and your optimize will be lost

      // Carry over from inheritance
      if (this.domNode['__blot'] != null) {
        // @ts-ignore
        delete this.domNode['__blot'].mutations;
      }

      if (this.children.length === 0) {
        if (this.statics.defaultChild == null) {
          this.remove();
        }
      }

      let formats = this.formats();

      if (Object.keys(formats).length === 0) {
        return this.unwrap(); // un-formatted span
      }

      // this.domNode.parentNode maybe use this if you fell lag because of the API

      // to not optimize un-shown deleted Refs
      if (this.domNode && document.contains(this.domNode)) {
        // major optimizations are needed to avoid unnecessary optimizations
        if (this.statics.currentBlotType === TagType.SentenceTags) {
          optimizeMerge.call(this);
          ifEmbeddingNonEmbeddedTagCleanse.call(this);
          return;
        }

        if (this.statics.currentBlotType === TagType.WordTags) {
          // order is important
          optimizeSplit.call(this);
          correctEmbedding.call(this);
          optimizeMergeIfSameTag.call(this);
          return;
        }
      }
    }
  };
}
