import { useCallback, useMemo } from 'react';
import { getCurrentSession } from 'aws';
import {
  activeProjectSelectorId,
  projectAuthorSelector,
  projectNameSelector,
} from 'redux/reducers/project/selectors';
import { activeChapterIdSelector } from 'redux/reducers/chapters/selectors';
import { ADMIN_USER_GROUP, EDITOR_USER_GROUP } from 'modules/common/constants';
import { useAppSelector } from 'modules/common/hooks/redux';
import { isNeedProjectMeta } from './lib';
import { amplitude, AnalyticsEvent } from './config';

const useGetCurrentProjectMetaToTrack = () => {
  const projectId = useAppSelector(activeProjectSelectorId);
  const bookChapter = useAppSelector(activeChapterIdSelector);
  const bookName = useAppSelector(projectNameSelector);
  const projectAuthorMeta = useAppSelector(projectAuthorSelector);
  const bookAuthor = projectAuthorMeta
    ? `${projectAuthorMeta.firstName ?? ''} ${projectAuthorMeta.lastName ?? ''}`
    : '';

  return useMemo(
    () => ({
      bookName,
      bookAuthor,
      bookChapter,
      projectId,
    }),
    [bookChapter, bookAuthor, projectId, bookName]
  );
};

export const logAmplitudeEvent = async ({
  eventName,
  properties,
}: {
  eventName: AnalyticsEvent;
  properties: Record<string, unknown>;
}) => {
  const userEnvironment =
    window.location.origin === process.env.REACT_APP_QA_ENV_URL
      ? 'QA'
      : window.location.origin === process.env.REACT_APP_PROD_ENV_URL
      ? 'PROD'
      : undefined;
  // if it's not QA or PROD, we don't want to track the event
  if (!userEnvironment) return;

  const currentSession = await getCurrentSession();
  let currentUserPayload = {};
  if (currentSession) {
    const payload = currentSession.getIdToken().payload;
    currentUserPayload = {
      userEnvironment,
      userName: payload.name,
      userEmail: payload.email,
      userRole: payload['cognito:groups'].includes(ADMIN_USER_GROUP)
        ? 'admin'
        : payload['cognito:groups'].includes(EDITOR_USER_GROUP)
        ? 'editor'
        : '',
    };
  }

  amplitude.logEvent(eventName, {
    ...properties,
    ...currentUserPayload,
    pageURL: window.location.href,
    userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

export const useTrackEvent = () => {
  const currentProjectMeta = useGetCurrentProjectMetaToTrack();
  return useCallback(
    ({
      eventName,
      customProperties,
    }: {
      eventName: AnalyticsEvent;
      customProperties?: Record<string, unknown>;
    }) => {
      let properties = {};
      if (isNeedProjectMeta(eventName)) properties = { ...currentProjectMeta };

      logAmplitudeEvent({
        eventName,
        properties: {
          ...properties,
          ...(customProperties || {}),
        },
      });
    },
    [currentProjectMeta]
  );
};
