import { AnalyticsEvent } from './config';

export const isNeedProjectMeta = (eventName: AnalyticsEvent) => {
  switch (eventName) {
    case AnalyticsEvent.OpenProject:
      return true;
    case AnalyticsEvent.CloseProject:
      return true;
    case AnalyticsEvent.SaveChapter:
      return true;
    case AnalyticsEvent.SaveProject:
      return true;
    case AnalyticsEvent.SendProjectToReview:
      return true;
    case AnalyticsEvent.ApproveProject:
      return true;
    case AnalyticsEvent.ApplyPreProcess:
      return true;
    case AnalyticsEvent.ShowPreProcessingReport:
      return true;
    case AnalyticsEvent.AddTag:
      return true;
    case AnalyticsEvent.DeleteTag:
      return true;
    case AnalyticsEvent.EditTag:
      return true;
    case AnalyticsEvent.InsertText:
      return true;
    case AnalyticsEvent.AudioPlayedHighlighted:
      return true;
    case AnalyticsEvent.AudioPlayedPosition:
      return true;
    case AnalyticsEvent.FindAndApply:
      return true;
    case AnalyticsEvent.AddBookmark:
      return true;
    case AnalyticsEvent.DeleteBookmark:
      return true;
    case AnalyticsEvent.ClearAll:
      return true;
    default:
      return false;
  }
};
