import { Quill } from 'react-quill';
import {
  ifEmbeddingNonEmbeddedTagCleanse,
  optimizeMerge,
} from '../../v2/Editor/Blots/genericSSMLBlotFactory';
import QUtil from '../../../../utils/QuillUtil';

const Inline = Quill.import('blots/inline');

export class FoundTextTOCBlot extends Inline {
  static blotName = 'found-toc-text';
  static className = 'rb-found-toc-text';
  static tagName = 'div';
}

export class FoundTextTOCCurrentBlot extends Inline {
  static blotName = 'found-toc-text-current';
  static className = 'rb-toc-found-text-current';
  static tagName = 'div';
  static dataAttrName = 'data-found-toc-id';

  static create(value: string) {
    let node: HTMLElement = super.create();
    if (value) {
      node.setAttribute(this.dataAttrName, value);
      node.tabIndex = -1;
    }
    return node;
  }

  static formats(domNode: HTMLElement) {
    const formats = {
      foundTocId: domNode.dataset.foundTocId,
    };
    return JSON.stringify(formats);
  }

  formatAt(index: number, length: number, name: string, value: any): void {
    // close case for embedded tags
    if (this.statics.blotName && this.statics.blotName !== name && !value) {
      QUtil.forEachRecursive(this.children, function (child) {
        if (QUtil.isTagNameEqual(child?.statics.blotName, name)) {
          child.unwrap();
        }
      });
      return;
    }
    super.formatAt(index, length, name, value);
  }

  optimize(): void {
    if (this.domNode['__blot'] != null) {
      delete this.domNode['__blot'].mutations;
    }

    if (this.children.length === 0) {
      if (this.statics.defaultChild == null) {
        this.remove();
      }
    }

    let formats = this.formats();

    if (Object.keys(formats).length === 0) {
      return this.unwrap();
    }

    if (this.domNode && document.contains(this.domNode)) {
      optimizeMerge.call(this);
      ifEmbeddingNonEmbeddedTagCleanse.call(this);
      return;
    }
  }
}
