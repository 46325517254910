export function isScrolledIntoView(el: HTMLElement, container: HTMLElement) {
  const rect = el.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  const isVisible =
    elemTop >= containerRect.top && elemBottom <= containerRect.height;
  return isVisible;
}
