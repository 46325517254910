import { FC, useState } from 'react';

import { isProjectStatusDeactivated } from 'redux/reducers/project/selectors';
import { Tab } from 'modules/common/components/Tab/Tab';
import { TabGroup } from 'modules/common/components/Tab/TabGroup';
import { Alert } from 'modules/common/components/Alert/Alert';
import { Bookmarks } from 'modules/edit/v2/Bookmarks/Bookmarks';
import { FindAndApply } from 'modules/edit/v2/FindAndApply/FindAndApply';
import { AudioSideBar } from 'modules/edit/AudioSideBar/AudioSideBar';
import { Tags } from 'modules/edit/v2/Tags/Tags';
import SidebarContext from 'modules/common/context/SidebarContext';
import { Comments } from 'modules/edit/v2/Comments/Comments';
import { useCanEdit } from 'modules/common/hooks/can-permission/useCanEdit';
import { useAppSelector } from 'modules/common/hooks/redux';
import { useCanComment } from 'modules/common/hooks/can-permission/useCanComment';
import { useCanSeeBookmark } from 'modules/common/hooks/can-permission/useCanSeeBookmark';
import Styles from 'modules/edit/Sidebar/sidebar.module.scss';

enum Tabs {
  Edit = 0,
  FindAndApply,
  Bookmarks,
  Audio,
  Comments,
}

type SidebarProps = {
  isSSML: boolean;
};

export const Sidebar: FC<SidebarProps> = ({ isSSML }) => {
  const canUserEdit = useCanEdit();
  const canUserComment = useCanComment();
  const canSeeBookmarks = useCanSeeBookmark();
  const isDeactivated = useAppSelector(isProjectStatusDeactivated);
  const [activeTab, setActiveTab] = useState<Tabs>(
    canUserEdit ? Tabs.Edit : canSeeBookmarks ? Tabs.Bookmarks : Tabs.Audio
  );
  const [showAlert, setShowAlert] = useState(true);

  return (
    <SidebarContext.Provider value={{ showAlert: showAlert, setShowAlert }}>
      <div className={Styles['sidebar-header']}>
        <TabGroup underline className="p-l-3">
          {canUserEdit ? (
            <>
              <Tab
                active={activeTab === Tabs.Edit}
                onClick={() => setActiveTab(Tabs.Edit)}
              >
                Edit
              </Tab>
              <Tab
                active={activeTab === Tabs.FindAndApply}
                onClick={() => setActiveTab(Tabs.FindAndApply)}
              >
                Find & Apply
              </Tab>
            </>
          ) : (
            <></>
          )}
          {canSeeBookmarks ? (
            <Tab
              active={activeTab === Tabs.Bookmarks}
              onClick={() => setActiveTab(Tabs.Bookmarks)}
            >
              Bookmarks
            </Tab>
          ) : (
            <></>
          )}
          <Tab
            active={activeTab === Tabs.Audio}
            onClick={() => setActiveTab(Tabs.Audio)}
          >
            Audio
          </Tab>
          {canUserComment ? (
            <Tab
              active={activeTab === Tabs.Comments}
              onClick={() => setActiveTab(Tabs.Comments)}
            >
              Comments
            </Tab>
          ) : (
            <></>
          )}
        </TabGroup>
      </div>

      <div className={Styles['sidebar-content']}>
        {activeTab === Tabs.Bookmarks && <Bookmarks />}
        {activeTab === Tabs.FindAndApply && <FindAndApply />}
        {canUserEdit && activeTab === Tabs.Edit && <Tags isSSML={isSSML} />}
        {activeTab === Tabs.Audio && <AudioSideBar />}
        {canUserComment && activeTab === Tabs.Comments && <Comments />}
      </div>
      {isDeactivated && (
        <Alert
          icon="close_outline"
          className={Styles['deactivation-status-alert']}
          type="error"
          spacing={0}
        >
          The project was deactivated. Please stop the work on this project.
        </Alert>
      )}
    </SidebarContext.Provider>
  );
};
