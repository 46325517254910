import React from 'react';
import { Button } from 'modules/common/components/Button/Button';
import Styles from './confirmationplaceholder.module.scss';

interface Props {
  isLoading: boolean;
  selectedOption?: string;
  onSave(): void;
  onEdit(): void;
}

export const ConfirmationPlaceholder: React.FC<Props> = ({
  isLoading,
  selectedOption,
  onSave,
  onEdit,
}) => (
  <div className={Styles['confirm-body']}>
    <div className={Styles['confirm-text']}>
      Please be informed that all changes in{' '}
      {selectedOption === 'Full book' ? 'the book' : 'this chapter'} will be
      saved if the report will be generated.
      <br /> Are you sure you want to generate the report?
    </div>
    <div className={Styles['buttons']}>
      <Button
        className={Styles['save-and-generate-button']}
        onClick={onSave}
        loading={isLoading}
        disabled={isLoading}
      >
        Save & Generate the report
      </Button>
      <Button
        className={Styles['edit-chapter-button']}
        fill="subtle"
        secondary
        onClick={onEdit}
      >
        Edit{' '}
        {selectedOption
          ? selectedOption === 'Full book'
            ? 'book'
            : selectedOption
          : 'Chapter'}
      </Button>
    </div>
  </div>
);
