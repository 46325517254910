import { FC } from 'react';
import SortableCell, { SortableCellProps } from '../SortableCell/SortableCell';
import { Select } from 'modules/common/components/Select/Select';
import { LabelValue, SelectValue } from 'modules/common/types';

interface Props extends SortableCellProps {
  onChangeValue: (value: string | undefined) => void;
  value?: string;
  data: LabelValue[];
}

const SortableSelectCell: FC<Props> = ({
  accessorName,
  onChangeValue,
  onSortChange,
  value,
  text,
  data,
  width,
  sort,
}) => {
  const onChangeSelect = (value: SelectValue) => {
    onChangeValue(value?.value);
  };

  const selectValue = data.find((item) => item.value === value);

  return (
    <SortableCell
      accessorName={accessorName}
      onSortChange={onSortChange}
      text={text}
      width={width}
      sort={sort}
    >
      <Select
        value={selectValue}
        onChange={onChangeSelect}
        options={data}
        isClearable
        portal
      />
    </SortableCell>
  );
};

export default SortableSelectCell;
