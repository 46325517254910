import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCurrentSession } from 'aws';
import { CACHED_AUDIO_ENDPOINT_NAME } from './lib';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_URL,
  prepareHeaders: async (headers, api) => {
    const session = await getCurrentSession();
    if (!session?.isValid() || api.endpoint === CACHED_AUDIO_ENDPOINT_NAME) {
      return headers;
    }
    headers.set('Authorization', `${session.getIdToken().getJwtToken()}`);
    return headers;
  },
});

export default baseQuery;
