import { Quill } from 'react-quill';
import QUtil from 'utils/QuillUtil';
import {
  ifEmbeddingNonEmbeddedTagCleanse,
  optimizeMerge,
} from 'modules/edit/v2/Editor/Blots/genericSSMLBlotFactory';

const Inline = Quill.import('blots/inline');

export interface ChapterIconBlotValue {
  id: string;
  chapterText: string;
}

export class ChapterIconBlot extends Inline {
  static blotName = 'chapter';
  static tagName = 'chapter';

  static create(value: string) {
    let node: HTMLSpanElement = super.create('chapter');
    const chapter = JSON.parse(value) as ChapterIconBlotValue;
    if (value && chapter.id) {
      node.setAttribute('class', 'chapter-icon');
      node.setAttribute('id', chapter.id);
      node.setAttribute('data-name', chapter.chapterText);
      node.tabIndex = -1;
    }
    return node;
  }

  static formats(domNode: HTMLElement) {
    return JSON.stringify({
      id: domNode.id,
      chapterText: `Chapter ${domNode.id}`,
    });
  }
  formatAt(index: number, length: number, name: string, value: any): void {
    // close case for embedded tags
    if (this.statics.blotName && this.statics.blotName !== name && !value) {
      QUtil.forEachRecursive(this.children, function (child) {
        if (QUtil.isTagNameEqual(child?.statics.blotName, name)) {
          child.unwrap();
        }
      });
      return;
    }
    super.formatAt(index, length, name, value);
  }

  optimize(): void {
    if (this.domNode['__blot'] != null) {
      delete this.domNode['__blot'].mutations;
    }

    if (this.children.length === 0) {
      if (this.statics.defaultChild == null) {
        this.remove();
      }
    }

    let formats = this.formats();

    if (Object.keys(formats).length === 0) {
      return this.unwrap();
    }

    if (this.domNode && document.contains(this.domNode)) {
      optimizeMerge.call(this);
      ifEmbeddingNonEmbeddedTagCleanse.call(this);
      return;
    }
  }
}
