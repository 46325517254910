import React from 'react';
import { Modal } from 'modules/common/components/Modal/Modal';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Button } from 'modules/common/components/Button/Button';

const TOCPopup: React.FC<{
  isOpen: boolean;
  onClose(): void;
  onCreateTOC(): void;
}> = ({ isOpen, onClose, onCreateTOC }) => (
  <Modal
    portal
    open={isOpen}
    isCloseIcon={true}
    onClose={onClose}
    gray
    isClosableOnClickOutside={false}
  >
    <Stack
      className="m-t-4"
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Stack.Item className="m-b-2 font-size-lg fw-700">
        Table of contents is missed
      </Stack.Item>
      <Stack.Item className="m-b-4 font-size-sm ta-center">
        Uploaded file doesn’t consist any information about table of contents.
        You will be redirected to Chapters editor
      </Stack.Item>

      <Button onClick={onCreateTOC}>Create Table of contents</Button>
    </Stack>
  </Modal>
);

export default TOCPopup;
