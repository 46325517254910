import React, { FC, useRef } from 'react';
import { Input, InputProps } from 'modules/common/components/Input/Input';

interface Props extends Omit<InputProps, 'value'> {
  initialValue?: string;
  debounce?: number;
}

const DebouncedInput: FC<Props> = ({
  initialValue,
  onChangeWithValueHandler,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = React.useState<string>(initialValue || '');

  const firstUpdate = useRef(true);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const timeout = setTimeout(() => {
      onChangeWithValueHandler?.(value);
    }, debounce);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]); // eslint-disable-line

  return <Input {...props} value={value} onChangeWithValueHandler={setValue} />;
};

export default DebouncedInput;
