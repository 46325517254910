import { Stack } from 'modules/common/components/Stack/Stack';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import classNames from 'classnames';

import Styles from './auth.module.scss';
import { Login } from './Login';
import { Logo } from 'modules/common/components/Logo/Logo';
import { ResetPassword } from './ResetPassword';
import { ForgotPassword } from './ForgotPassword';
import { SignUp } from './SignUp';
import { ActivateAccount } from './ActivateAccount';

const AuthHeader: FC = () => {
  return (
    <header className="p-y-1-5 bg-white">
      <Stack justifyContent="center">
        <Stack.Item flex>
          <Logo />
        </Stack.Item>
      </Stack>
    </header>
  );
};

export const Auth: FC = () => {
  return (
    <Stack
      className={classNames(Styles['auth-page'])}
      alignItems="center"
      direction="column"
    >
      <Stack.Item className="w-100">
        <AuthHeader />
      </Stack.Item>
      <Stack.Item fill className="w-100">
        <Stack
          className="h-100"
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Stack.Item className="w-100">
            <Routes>
              <Route path="sign-up" element={<SignUp />} />
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="activate-account" element={<ActivateAccount />} />
            </Routes>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
