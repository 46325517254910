export function getModulesConfig() {
  return {
    toolbar: false,
    clipboard: {
      matchVisual: false,
    },
    history: {
      delay: 2000,
      maxStack: 500,
      userOnly: true,
    },
    // selection: true,
    keyboard: {
      bindings: {
        tab: {
          key: 9,
          handler: function () {},
        },
        backspace: {
          key: 8,
          handler: function () {},
        },
        13: {
          key: 13,
          handler: function () {},
        },
      },
    },
  };
}
