import React, { createElement, FormHTMLAttributes } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';

interface IFormProps<T extends FieldValues>
  extends Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  onSubmit: SubmitHandler<T>;
  methods: UseFormReturn<T>;
}

export default function Form<T extends FieldValues>({
  methods,
  children,
  onSubmit,
  ...rest
}: IFormProps<T> & { children: JSX.Element[] | JSX.Element }) {
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} {...rest} noValidate>
        {Array.isArray(children)
          ? children.map((child) => {
              return child.props.name
                ? createElement(child.type, {
                    ...{
                      ...child.props,
                      register: methods.register,
                      key: child.props.name,
                    },
                  })
                : child;
            })
          : children}
      </form>
    </FormProvider>
  );
}
