import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import Styles from './card.module.scss';

interface CardProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string;
  light?: boolean;
  hoverable?: boolean;
}

export const Card: FC<CardProps> = ({
  children,
  className,
  light = false,
  hoverable = false,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={classNames(Styles.card, className, {
        [Styles.light]: light,
        [Styles.hoverable]: hoverable,
      })}
    >
      <div className={Styles['card-body']}>{children}</div>
    </div>
  );
};
