import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { rejectRequest } from 'utils/rejectRequest';
import { CommentItemModel, CommentModel } from 'modules/common/models/Comment';
import CommentsApiInstance, {
  AddCommentReplyModel,
  AddReplyResponseModel,
  CommentAddModel,
} from 'modules/edit/api/Comments';

export const CommentsAdapter = createEntityAdapter<CommentModel>({
  selectId: (comment) => comment.id,
});

const initialState = {
  ...CommentsAdapter.getInitialState(),
};

export const fetchcomments = createAsyncThunk<CommentModel[], string>(
  'comments/fetchComments',
  async (projectId, { rejectWithValue }) => {
    try {
      return await CommentsApiInstance.getProjectComments(projectId);
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

export const addComment = createAsyncThunk<CommentModel, CommentAddModel>(
  'comments/addComment',
  async (commentData, { rejectWithValue }) => {
    try {
      const data = await CommentsApiInstance.add(commentData);
      return data.result;
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

export const addReply = createAsyncThunk<
  AddReplyResponseModel,
  AddCommentReplyModel
>('comments/addReply', async (commentData, { rejectWithValue }) => {
  try {
    const data = await CommentsApiInstance.addReply(commentData);
    return data.result;
  } catch (err) {
    return rejectWithValue(rejectRequest(err as AxiosError));
  }
});

export const editComment = createAsyncThunk<
  { parentCommentId: string; commentData: CommentItemModel },
  {
    id: string;
    commentId: string;
    comment: string;
  }
>(
  'comments/editComment',
  async ({ id, comment, commentId }, { rejectWithValue }) => {
    try {
      const data = await CommentsApiInstance.edit(id, commentId, comment);
      return {
        parentCommentId: id,
        commentData: data.result,
      };
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

export const deleteMainComment = createAsyncThunk<string, { id: string }>(
  'comments/deleteComment',
  async ({ id }, { rejectWithValue }) => {
    try {
      await CommentsApiInstance.deleteMainComment(id);
      return id;
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

export const deleteCommentReply = createAsyncThunk<
  { id: string; commentId: string },
  { id: string; commentId: string }
>(
  'comments/deleteCommentReply',
  async ({ id, commentId }, { rejectWithValue }) => {
    try {
      await CommentsApiInstance.deleteCommentReply(id, commentId);
      return { id, commentId };
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

const CommentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    resetComments: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchcomments.fulfilled, (state, { payload }) => {
        CommentsAdapter.setAll(state, payload);
      })
      .addCase(addComment.fulfilled, (state, { payload }) => {
        CommentsAdapter.addOne(state, payload);
      })
      .addCase(addReply.fulfilled, (state, { payload }) => {
        const comment = state.entities[payload.id];
        if (comment) {
          comment.comments.push(payload.comments);
          CommentsAdapter.updateOne(state, {
            id: payload.id,
            changes: comment,
          });
        }
      })
      .addCase(editComment.fulfilled, (state, { payload }) => {
        const { parentCommentId, commentData } = payload;
        const comment = state.entities[parentCommentId];
        if (comment) {
          const commentItem = comment.comments.find(
            (c) => c.commentId === commentData.commentId
          );
          if (commentItem) {
            commentItem.dateUpdated = commentData.dateUpdated;
            commentItem.comment = commentData.comment;
            CommentsAdapter.updateOne(state, {
              id: parentCommentId,
              changes: comment,
            });
          }
        }
      })
      .addCase(deleteMainComment.fulfilled, (state, { payload }) => {
        CommentsAdapter.removeOne(state, payload);
      })
      .addCase(deleteCommentReply.fulfilled, (state, { payload }) => {
        const { id, commentId } = payload;
        const comment = state.entities[id];
        if (comment) {
          comment.comments = comment.comments.filter(
            (c) => c.commentId !== commentId
          );
          CommentsAdapter.updateOne(state, {
            id,
            changes: comment,
          });
        }
      });
  },
});

export const { resetComments } = CommentsSlice.actions;

export default CommentsSlice.reducer;
