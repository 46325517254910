import { useAppSelector } from 'modules/common/hooks/redux';
import {
  isProjectStatusInReTake,
  isProjectStatusInReview,
  isWebSiteUpToDateSelector,
} from 'redux/reducers/project/selectors';

export function useCanComment(): boolean {
  const inReview = useAppSelector(isProjectStatusInReview);
  const inRetake = useAppSelector(isProjectStatusInReTake);
  const isWebSiteUptoDate = useAppSelector(isWebSiteUpToDateSelector);

  return (inReview || inRetake) && isWebSiteUptoDate;
}
