import React from 'react';
import { RangeStatic } from 'quill';

import { activeChapterSelector } from 'redux/reducers/chapters/selectors';
import {
  activeProjectSelector,
  activeProjectSelectorId,
} from 'redux/reducers/project/selectors';
import { Stack } from 'modules/common/components/Stack/Stack';
import useEventListener from 'modules/common/hooks/useEventListener';
import useQuill from 'modules/common/hooks/useQuill';
import { isMacOSRunning } from 'utils';
import { getSSMLText, sanitizeSSMLText } from 'utils/SsmlUtils';
import { useScreenSize } from 'modules/common/hooks/useScreenSize';
import { error } from 'modules/common/components/Notify';
import { useAppSelector } from 'modules/common/hooks/redux';
import useStopwatch from 'modules/common/hooks/audioPlayer/useStopwatch';
import { useAddPauseHotKeyListener } from 'modules/common/hooks/useAddPauseHotKeyListener';
import { useAddAliasHotKeyListener } from 'modules/common/hooks/useAddAliasHotKeyListener';
import { TTSEngine } from 'modules/common/types';
import { ElevenLabsVoiceSettings } from 'modules/projects/Project/types';
import { useTrackEvent, AnalyticsEvent } from 'services/amplitude';
import AudioPlayerVolume from './AudioPlayerVolume';
import AudioControls from './AudioControls';
import { DEFAULT_FULL_TEXT_LENGTH } from './util';
import Styles from './audioplayer.module.scss';
import { EditVoiceSettings } from '../EditVoiceSettings';
import { AudioSlider } from './AudioSlider';
import { useLazyTextToTtsQuery, useLazyTryVoiceQuery } from 'redux/api/app.api';
import {
  TextToTtsResponse,
  TryVoiceRequest,
  TryVoiceResponse,
} from 'redux/api/types';
import { useKaraoke } from 'modules/common/hooks/audioPlayer/useKaraoke';
import { useIsEditorRole } from 'modules/common/hooks/useIsEditorRole';

interface AudioPlayerProps {
  audio_file_type: 'mp3' | 'pcm';
  voice_id: string;
  tts_engine_type: string;
  audio_file_bit_rate: string;
  elevenLabsModel: string;
  voiceSettings: ElevenLabsVoiceSettings;
  engine_name?: TTSEngine;
}

export const AudioPlayer: React.FC<AudioPlayerProps> = ({
  audio_file_type = 'mp3',
  voice_id,
  tts_engine_type = 'neural',
  audio_file_bit_rate = '24000',
  elevenLabsModel,
  voiceSettings,
  engine_name = 'aws',
}) => {
  const controller = React.useMemo(() => new AbortController(), []);
  const abortSignal = controller.signal;
  const { getTime, handleStart, handleReset, handlePauseResume } =
    useStopwatch();
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);

  const isStartedByCursorPosition = React.useRef<boolean>(false);
  const getIsStartedByCursorPosition = React.useCallback(
    () => isStartedByCursorPosition.current,
    []
  );
  const [selectedText, setSelectedText] = React.useState<string>('');
  const [textToPlay, setTextToPlay] = React.useState<string>('');
  const [duration, setDuration] = React.useState<number>(0);
  const [currentTime, setCurrentTime] = React.useState<number>(0);
  const quillInstance = useQuill();
  const project = useAppSelector(activeProjectSelector);
  const chapter = useAppSelector(activeChapterSelector);
  const projectId = useAppSelector(activeProjectSelectorId);
  const trackEvent = useTrackEvent();
  const { isMobile } = useScreenSize();
  const audioPlayerContainerRef = React.useRef<HTMLDivElement>(null);
  const selectionRef = React.useRef<RangeStatic>({
    index: 0,
    length: DEFAULT_FULL_TEXT_LENGTH[engine_name],
  });
  const wordsRef = React.useRef<{
    words: string[];
    wordStartTimes: number[];
  }>();
  const isMouseOverForm = React.useRef<boolean>(false);
  const isEditorRole = useIsEditorRole();

  const [tryVoiceQueryTrigger, { isFetching: isTryVoiceFetching }] =
    useLazyTryVoiceQuery();
  const [textToTtsQueryTrigger, { isFetching: isTextToTtsFetching }] =
    useLazyTextToTtsQuery();
  const isLoading = isTryVoiceFetching || isTextToTtsFetching;

  useAddPauseHotKeyListener({ quillInstance });
  useAddAliasHotKeyListener({ quillInstance });

  // Refs
  const audioPlayer = React.useRef<HTMLAudioElement>(new Audio());
  const progressBar = React.useRef<HTMLInputElement>(null); // reference our progress bar
  const animationRef = React.useRef<number>(); // reference the animation

  const { setupKaraoke, drawKaraokeFrame } = useKaraoke({
    selectionRef,
    wordsRef,
    audioPlayer,
  });

  const resetHandler = React.useCallback(() => {
    if (getIsStartedByCursorPosition()) {
      trackEvent({
        eventName: AnalyticsEvent.AudioPlayedPosition,
        customProperties: {
          timePlayed: `${getTime()} sec.`,
        },
      });
    }
    handleReset();
  }, [getIsStartedByCursorPosition, getTime, handleReset, trackEvent]);

  const changePlayerCurrentTime = React.useCallback(() => {
    if (!progressBar.current) {
      return;
    }
    let value = parseFloat(progressBar.current.value);
    progressBar.current.style.setProperty(
      '--seek-before-width',
      `${(value / parseFloat(progressBar.current.max)) * 100}%`
    );
    setCurrentTime(value);
  }, [progressBar]);

  const whilePlaying = React.useCallback(async () => {
    if (!progressBar.current) {
      return;
    }

    drawKaraokeFrame();

    progressBar.current.value = audioPlayer.current.currentTime.toString();
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  }, [changePlayerCurrentTime, drawKaraokeFrame]);

  const collapseSelectionToCursor = React.useCallback(() => {
    quillInstance?.editor?.setSelection(
      selectionRef.current.index,
      0,
      'silent'
    );
  }, [quillInstance?.editor]);

  const restoreSelection = React.useCallback(() => {
    quillInstance?.editor?.setSelection(
      selectionRef.current.index,
      selectionRef.current.length,
      'silent'
    );
  }, [quillInstance?.editor]);

  const togglePlayPause = React.useCallback(async () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      if (!getTime()) {
        handleStart();
      } else {
        handlePauseResume();
      }
      // we need to get rid of the selection when the audio is playing
      // so that we can see the karaoke effect
      collapseSelectionToCursor();
      await audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      restoreSelection();
      audioPlayer.current.pause();
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    }
  }, [
    getTime,
    handlePauseResume,
    handleStart,
    isPlaying,
    whilePlaying,
    collapseSelectionToCursor,
    restoreSelection,
  ]);

  const changeRange = React.useCallback(() => {
    audioPlayer.current.currentTime = parseFloat(
      (progressBar.current as HTMLInputElement).value
    );
    changePlayerCurrentTime();
  }, [changePlayerCurrentTime, audioPlayer]);

  const mouseEnterHandler = () => (isMouseOverForm.current = true);

  const mouseLeaveHandler = () => (isMouseOverForm.current = false);

  const resetProgress = React.useCallback(() => {
    if (isMouseOverForm.current) return;

    const editor = quillInstance?.editor;
    if (editor) {
      document.getElementById('highlight-sheet')?.remove();
    }

    setIsPlaying(false);
    if (getIsStartedByCursorPosition()) resetHandler();
    audioPlayer.current.pause();
    (progressBar.current as HTMLInputElement).value = '0';
    changeRange();
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
  }, [
    changeRange,
    getIsStartedByCursorPosition,
    quillInstance?.editor,
    resetHandler,
  ]);

  /*const rewind = useCallback(() => {
    (progressBar.current as HTMLInputElement).value = (
      parseFloat((progressBar.current as HTMLInputElement).value) - 10
    ).toString();
    changeRange();
  }, [changeRange, progressBar]);

  const fastForward = useCallback(() => {
    (progressBar.current as HTMLInputElement).value = (
      parseFloat((progressBar.current as HTMLInputElement).value) + 10
    ).toString();
    changeRange();
  }, [changeRange, progressBar]);
  */

  const getAudioData = React.useCallback(
    async (
      tryVoicePayload?: Omit<TryVoiceRequest, 'text'>
    ): Promise<TryVoiceResponse | TextToTtsResponse> => {
      if (tryVoicePayload) {
        const tryVoiceRequest = {
          ...tryVoicePayload,
          text: textToPlay,
        };

        const request = tryVoiceQueryTrigger(tryVoiceRequest, true);
        abortSignal.onabort = () => request.abort();

        return request.unwrap();
      }

      const textToTtsRequest = {
        text: textToPlay,
        audio_file_type,
        voice_id,
        engine_name,
        elevenLabsModel,
        voiceSettings,
        tts_engine_type,
        audio_file_bit_rate,
        volumeRate: '+0',
        speedRate: '100',
      };

      const request = textToTtsQueryTrigger(textToTtsRequest, true);
      abortSignal.onabort = () => request.abort();

      return request.unwrap();
    },
    [
      textToPlay,
      audio_file_type,
      voice_id,
      engine_name,
      elevenLabsModel,
      voiceSettings,
      tts_engine_type,
      audio_file_bit_rate,
      textToTtsQueryTrigger,
      tryVoiceQueryTrigger,
      abortSignal,
    ]
  );

  const playPauseWithRequest = React.useCallback(
    async (payloadForVoiceDemoPlayback?: Omit<TryVoiceRequest, 'text'>) => {
      const isPausedHalfway = audioPlayer.current.currentTime > 0;
      if (isPlaying || isPausedHalfway) {
        await togglePlayPause();
        return;
      }
      if (getIsStartedByCursorPosition()) resetHandler();

      setupKaraoke();

      try {
        const audioDownloadingStartTime = Date.now();

        const response = await getAudioData(payloadForVoiceDemoPlayback);

        if (!getIsStartedByCursorPosition()) {
          trackEvent({
            eventName: AnalyticsEvent.AudioPlayedHighlighted,
            customProperties: {
              textPlayed: selectedText,
              timePlayed: `${getTime()} sec.`,
            },
          });
        }

        if ('audioStream' in response) {
          audioPlayer.current.src = `data:audio/mp3;base64, ${response.audioStream}`;
        } else {
          audioPlayer.current.src = response.audio_url;
        }
        wordsRef.current = {
          words: response.words ?? [],
          wordStartTimes: response.wordStartTimes ?? [],
        };

        await togglePlayPause();
        const audioDownloadingEndTime = Date.now();
        const timeToPlay = (
          (audioDownloadingEndTime - audioDownloadingStartTime) /
          1000
        ).toFixed(2);
        trackEvent({
          eventName: AnalyticsEvent.TimeToPlay,
          customProperties: {
            timeToPlay,
            projectId,
          },
        });
      } catch (err: any) {
        if (err.message === 'Aborted') return;
        const children = err?.response?.data
          ? `Error: ${err?.response?.data}`
          : 'Something went Wrong with the Audio';
        error({ children });
      }
    },
    [
      getIsStartedByCursorPosition,
      getTime,
      projectId,
      resetHandler,
      isPlaying,
      selectedText,
      togglePlayPause,
      trackEvent,
      getAudioData,
      setupKaraoke,
    ]
  );

  useEventListener('keydown', (event) => {
    const editor = quillInstance?.getEditor();
    if (!editor || !editor.hasFocus()) return;

    const isMacOS = isMacOSRunning();
    const isCtrlSpace = event.ctrlKey && event.code === 'Space';
    const isSpace = event.code === 'Space';
    const isCtrlSpaceOrSpace = isMacOS ? isSpace : isCtrlSpace;
    if (isCtrlSpaceOrSpace) playPauseWithRequest();
  });

  // Events
  useEventListener(
    'loadeddata',
    function () {
      const seconds = Math.floor(audioPlayer.current.duration);
      setDuration(seconds);
      if (progressBar.current) {
        progressBar.current.max = seconds.toString();
      }
    },
    audioPlayer
  );

  useEventListener(
    'ended',
    () => {
      restoreSelection();
      resetProgress();
    },
    audioPlayer
  );

  useEventListener(
    'pause',
    () => getIsStartedByCursorPosition() && handlePauseResume(),
    audioPlayer
  );

  React.useEffect(() => {
    const editor = quillInstance?.getEditor();

    if (editor) {
      const selectionChangeHandler = (
        range: RangeStatic,
        oldRange: RangeStatic
      ) => {
        const editor = quillInstance?.getEditor();
        if (!editor) return;

        const someRange = range || oldRange;

        // ensure there is always a selection
        const newRange = someRange
          ? {
              index: someRange.index,
              length: someRange.length || DEFAULT_FULL_TEXT_LENGTH[engine_name],
            }
          : { index: 0, length: DEFAULT_FULL_TEXT_LENGTH[engine_name] };

        // if no range or length is 0, then the selection is a cursor
        isStartedByCursorPosition.current =
          !someRange || !someRange.length ? true : false;

        // prepare visible text
        const contents = editor.getContents(newRange.index, newRange.length);
        setSelectedText(sanitizeSSMLText(contents));

        // prepare text to play
        setTextToPlay(getSSMLText(editor, newRange));
        selectionRef.current = newRange;
        resetProgress();
      };

      editor?.on('selection-change', selectionChangeHandler);
      return () => {
        editor?.off('selection-change', selectionChangeHandler);
      };
    }
  }, [quillInstance, engine_name, resetProgress]);

  React.useEffect(() => {
    resetProgress();
  }, [chapter, resetProgress]);

  React.useEffect(() => {
    return () => {
      controller.abort();
      audioPlayer.current.pause();
      audioPlayer.current.src = '';
      // eslint-disable-next-line react-hooks/exhaustive-deps
      audioPlayer.current.remove();
      wordsRef.current = undefined;
      handleReset();
    };
  }, [controller, handleReset]);

  return (
    <div
      className={Styles.audioplayer}
      ref={audioPlayerContainerRef}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <Stack
        direction={isMobile ? 'row-reverse' : 'row'}
        alignItems="center"
        spacing={3}
      >
        <Stack.Item shrink={0}>
          <AudioControls
            isPlaying={isPlaying}
            onPlayPauseClick={() => playPauseWithRequest()}
            isDisabled={isLoading}
            isLoading={isLoading}
          />
        </Stack.Item>
        <Stack.Item fill>
          <AudioSlider
            text={selectedText}
            currentTime={currentTime}
            duration={duration}
            changeRange={changeRange}
            progressBarRef={progressBar}
          />
        </Stack.Item>
        {!isMobile && (
          <Stack.Item shrink={0} flex>
            <AudioPlayerVolume audioPlayerRef={audioPlayer} />
            {isEditorRole && project?.ttsEngine === 'elevenlabs' && (
              <EditVoiceSettings
                isLoadingAudio={isLoading}
                isPlayingAudio={isPlaying}
                audioPlayerContainerRef={audioPlayerContainerRef}
                onGenerateAudio={playPauseWithRequest}
              />
            )}
          </Stack.Item>
        )}
      </Stack>
    </div>
  );
};
