import { FC, ReactElement } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  conditional?: boolean;
  target?: HTMLElement;
}

export const Portal: FC<PortalProps> = ({
  children,
  conditional = true,
  target,
}) => {
  if (!conditional) {
    return children as ReactElement;
  }
  const node = target ?? document.body;

  return createPortal(children, node);
};
