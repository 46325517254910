import { RBBlot } from './RBBlot';

export class InsertBlot extends RBBlot {
  static blotName = 'insert';
  static tagName = 'insert';
  static classNameInternal = 'rb-insert-tag';
  static allowedTags = ['*'];

  static create(value: string) {
    let node = super.create();
    if (value) {
      node.setAttribute('class', this.classNameInternal);
      node.setAttribute('ssml-blot-name', this.blotName);
      let beforeText = `<${InsertBlot.tagName.toLowerCase()}>`;
      let afterText = `</${InsertBlot.tagName.toLowerCase()}>`;
      node.setAttribute('data-before-text', beforeText);
      node.setAttribute('data-after-text', afterText);
    }
    return node;
  }
}
