import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Logo } from 'modules/common/components/Logo/Logo';
import { Button } from 'modules/common/components/Button/Button';
import { useCanSeeNotification } from 'modules/common/hooks/can-permission/useCanSeeNotification';
import NotificationsView from 'modules/common/components/NotificationsView/NotificationsView';
import { useAuth } from 'modules/common/hooks/useAuth';
import { useLogout } from 'modules/common/hooks/useLogout';
import useEventListener from 'modules/common/hooks/useEventListener';
import { cleanUpWSService } from 'services/WebSocketService';
import { useWebSocket } from 'modules/common/hooks/useWebSocket';
import { SocketEventType } from 'modules/common/models/Notifications';
import { info } from 'modules/common/components/Notify';
import { ProjectStatus } from 'modules/common/types';
import { getStatusHash } from 'modules/common/constants/functions';
import { useTrackEvent, AnalyticsEvent } from 'services/amplitude';
import { Icon } from 'icons';
import Styles from './styles.module.scss';

const HeaderWithLogo: FC<{ showBackButton?: boolean }> = ({
  showBackButton,
}) => {
  const auth = useAuth();
  const handleLogout = useLogout();
  const canSeeNotification = useCanSeeNotification();
  const trackEvent = useTrackEvent();
  const navigate = useNavigate();

  useEventListener('beforeunload', function () {
    cleanUpWSService();
  });

  useWebSocket<ProjectStatus>(SocketEventType.status, function (socketInfo) {
    const message = `( ${
      socketInfo.value.projectTitle
    } ) Project status is changed to ${
      getStatusHash()[socketInfo.value.message]
    }`;
    trackEvent({
      eventName: AnalyticsEvent.PushNotifications,
      customProperties: {
        message,
        projectId: socketInfo.value.projectId,
        projectTitle: socketInfo.value.projectTitle,
      },
    });
    info({
      children: message,
    });
  });

  useWebSocket<ProjectStatus>(SocketEventType.comment, function (socketInfo) {
    const message = `( ${socketInfo.value.projectTitle} ) someone made a comment "${socketInfo.value.message}"`;
    trackEvent({
      eventName: AnalyticsEvent.PushNotifications,
      customProperties: {
        message,
        projectId: socketInfo.value.projectId,
        projectTitle: socketInfo.value.projectTitle,
      },
    });
    info({
      children: `( ${socketInfo.value.projectTitle} ) someone made a comment "${socketInfo.value.message}"`,
    });
  });

  return (
    <div className="w-100">
      <header className="p-y-1-5 p-x-3 bg-white">
        <Stack justifyContent={'space-between'} alignItems="center">
          {showBackButton && (
            <Stack.Item>
              <Button inline onClick={() => navigate('/')}>
                <div className={Styles['back-button']}>
                  <Icon name="arrow_backward" />
                  Back
                </div>
              </Button>
            </Stack.Item>
          )}
          <Stack.Item flex>
            <Logo />
          </Stack.Item>
          <Stack.Item>
            <Stack alignItems="center">
              {canSeeNotification && (
                <Stack.Item>
                  <NotificationsView />
                </Stack.Item>
              )}
              <Stack.Item>
                {auth ? (
                  <Button
                    onClick={handleLogout}
                    icon="exit_to_app"
                    small
                    secondary
                    fill="subtle"
                  />
                ) : (
                  <Button onClick={handleLogout} small secondary fill="subtle">
                    Login
                  </Button>
                )}
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </header>
    </div>
  );
};

export default HeaderWithLogo;
