import { useState } from 'react';
import { Icon } from 'icons';
import { ActionMenu } from 'modules/common/components/ActionMenu/ActionMenu';
import Styles from './description.module.scss';

export const PopoverInfo: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen((prevState) => !prevState);
  const closeHandler = () => isOpen && setIsOpen(false);

  return (
    <ActionMenu
      open={isOpen}
      direction="t"
      width="xs"
      el="div"
      portal
      actionItemWrapperClassname={Styles['PopoverMenuContent']}
      trigger={<Icon onClick={toggleOpen} name="info" size={16} />}
      onClickOutside={closeHandler}
    >
      <ActionMenu.Item className={Styles['PopoverItemContent']}>
        {children}
      </ActionMenu.Item>
    </ActionMenu>
  );
};
