import Styles from './bouncingdotsloader.module.scss';

const BouncingDotsLoader = () => {
  return (
    <div className={Styles['bouncing-loader']}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default BouncingDotsLoader;
