import { useEffect, useState } from 'react';
import { Range } from 'react-quill';
import useQuill from './useQuill';

export function useSelectionWithLength(): boolean {
  const quillInstance = useQuill();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const editor = quillInstance?.getEditor();
    if (editor) {
      const localFirstRange = editor.getSelection();
      if (localFirstRange) {
        setShow(!localFirstRange || !!localFirstRange.length);
      }

      const selectionChangeHandler = (range: Range, oldRange: Range) => {
        range = range || oldRange;
        setShow(!range || !!range.length);
      };

      editor.on('selection-change', selectionChangeHandler);
      return () => {
        editor.off('selection-change', selectionChangeHandler);
      };
    }
  }, [quillInstance]);

  return show;
}
