import { LabelValue } from 'modules/common/types';
import { Button } from 'modules/common/components/Button/Button';
import Styles from './switchingmodelrecommendmessage.module.scss';

export const SwitchingModelRecommendMessage: React.FC<{
  high_quality_base_model_ids: string[] | undefined;
  elevenLabsModels: LabelValue[];
  selectedModel: LabelValue;
  onModelChange(value: string): void;
}> = ({
  elevenLabsModels,
  high_quality_base_model_ids,
  selectedModel,
  onModelChange,
}) => {
  if (
    !high_quality_base_model_ids?.length ||
    high_quality_base_model_ids.includes(selectedModel.value)
  )
    return null;

  return (
    <div className={Styles['Container']}>
      <div className="font-size-xs">
        We recommend switching to the{' '}
        <span className={Styles['Model']}>
          {elevenLabsModels.find(
            ({ value }) => value === high_quality_base_model_ids[0]
          )?.label || ''}
        </span>{' '}
        model to get the best possible quality for this voice.
      </div>
      <Button
        secondary
        onClick={() => onModelChange(high_quality_base_model_ids[0])}
        className={Styles['SwitchButton']}
        small
      >
        Switch
      </Button>
    </div>
  );
};
