import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Styles from './auth.module.scss';
import { signUp } from 'aws';
import { Button } from 'modules/common/components/Button/Button';
import { Input } from 'modules/common/components/Input/Input';
import { Stack } from 'modules/common/components/Stack/Stack';
import { getSignupFormSchema } from './validation-schemas';
import { Alert } from 'modules/common/components/Alert/Alert';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Form from 'modules/common/components/Form/Form';
import useLoading from 'modules/common/hooks/useLoading';

interface SignUpForm {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export const SignUp: FC = () => {
  const navigate = useNavigate();
  const {
    isLoading,
    setLoading,
    setError,
    errorMessage: error,
    isError,
  } = useLoading();

  const methods = useForm<SignUpForm>({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(getSignupFormSchema()),
  });

  const {
    formState: { errors },
    setValue,
  } = methods;

  const onSubmit = async (values: SignUpForm) => {
    try {
      setLoading(true);
      await signUp(values.name, values.email, values.password);
      setLoading(false);
      navigate(
        `/auth/activate-account?username=${encodeURIComponent(values.email)}`
      );
    } catch (err: any) {
      const specialError = 'PreSignUp failed with error ';
      if (err.message.includes(specialError)) {
        err.message = err.message.slice(specialError.length);
      }
      setError(err.message);
    }
  };

  return (
    <div className={Styles['auth-form']}>
      <h4 className="fw-700 font-size-lg ta-center m-b-5">Sign Up</h4>
      {isError && (
        <Alert className="m-b-2" type="error">
          {error}
        </Alert>
      )}
      <Form<SignUpForm> onSubmit={onSubmit} methods={methods}>
        <p className="m-b-1 font-size-sm fw-600">Name</p>
        <Input
          large
          name="name"
          type="text"
          placeholder="Enter Name"
          onInput={v => setValue('name', v)}
        />
        <p className="m-b-4 font-size-xs color-error">{errors.name?.message}</p>

        <p className="m-b-1 font-size-sm fw-600">Email</p>
        <Input
          large
          name="email"
          type="email"
          placeholder="Enter e-mail"
          onInput={v => setValue('email', v)}
        />
        <p className="m-b-4 font-size-xs color-error">
          {errors.email?.message}
        </p>

        <p className="m-b-1 font-size-sm fw-600">Password</p>
        <Input
          large
          type="password"
          name="password"
          placeholder="Enter password"
          onInput={v => setValue('password', v)}
        />
        <p className="m-b-4 font-size-xs color-error">
          {errors.password?.message}
        </p>

        <p className="m-b-1 font-size-sm fw-600">Confirm Password</p>
        <Input
          large
          name="confirmPassword"
          type="password"
          placeholder="Enter confirm password"
          autoComplete="off"
          onInput={v => setValue('confirmPassword', v)}
        />
        <p className="m-b-4 font-size-xs color-error">
          {errors.confirmPassword?.message}
        </p>

        <Stack justifyContent="space-between" alignItems="center">
          <Stack.Item>
            <Link
              className="font-size-sm color-primary-i fw-600"
              to="/auth/login"
            >
              Already a member?
            </Link>
          </Stack.Item>
          <Stack.Item>
            <Button type="submit" loading={isLoading} disabled={isLoading}>
              Continue
            </Button>
          </Stack.Item>
        </Stack>
      </Form>
    </div>
  );
};
