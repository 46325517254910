import { logout } from 'aws';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'modules/common/hooks/redux';
import { apiSlice } from 'redux/api/app.api';
import { cleanUpWSService } from 'services/WebSocketService';
import { useContext } from 'react';
import UserContext from 'modules/common/context/UserContext';
import { useTrackEvent, AnalyticsEvent } from 'services/amplitude';

/**
 * @description Uniform way to logging out from an application
 * */
export function useLogout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const trackEvent = useTrackEvent();
  const { setUserGroups, setUserSession } = useContext(UserContext);

  return async () => {
    await logout();
    trackEvent({ eventName: AnalyticsEvent.LogOut });
    dispatch(apiSlice.util.resetApiState());
    setUserGroups([]);
    setUserSession(null);
    cleanUpWSService();
    navigate('/auth/login');
  };
}
