import { FC, useCallback, useEffect, useState } from 'react';
import { Quill, Range } from 'react-quill';

import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import useQuill from 'modules/common/hooks/useQuill';
import BouncingDotsLoader from 'modules/common/components/BouncingDotsLoader/BouncingDotsLoader';
import { Button } from 'modules/common/components/Button/Button';
import { Divider } from 'modules/common/components/Divider/Divider';
import { Stack } from 'modules/common/components/Stack/Stack';
import { activeChapterSelector } from 'redux/reducers/chapters/selectors';
import { activeProjectSelectorId } from 'redux/reducers/project/selectors';
import { setChapterDirty } from 'redux/reducers/chapters/chaptersSlice';
import { TabGroup } from 'modules/common/components/Tab/TabGroup';
import { Tab } from 'modules/common/components/Tab/Tab';
import { ChapterComments } from './ChapterComments';
import { BookComments } from './BookComments';
// import { isScrolledIntoView } from 'utils';
import { Icon } from 'icons';
import { CommentBlot } from '../Editor/Blots/CommentBlot';
import Textarea from 'modules/common/components/Textarea/Textarea';
import {
  addComment,
  deleteMainComment,
  editComment,
} from 'redux/reducers/comments/commentsSlice';
import { CommentAddModel } from '../api/Comments';
import { CommentModel } from 'modules/common/models/Comment';
import {
  commentIsAddingSelector,
  commentsIsFetchingSelector,
  selectAll,
} from 'redux/reducers/comments/selectors';

enum Tabs {
  Chapter = 0,
  Book,
}

export const Comments: FC = () => {
  const dispatch = useAppDispatch();
  const isCommentsFetching = useAppSelector(commentsIsFetchingSelector);
  const isCommentAdding = useAppSelector(commentIsAddingSelector);
  const chapter = useAppSelector(activeChapterSelector);
  const projectId = useAppSelector(activeProjectSelectorId);
  const quillInstance = useQuill();
  const [commentDisabled, setCommentDisabled] = useState(true);
  const [commentCreateMode, setcommentCreateMode] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Chapter);
  const comments = useAppSelector(selectAll);

  const handleCommentAdd = useCallback(async () => {
    if (!commentCreateMode) {
      setcommentCreateMode(true);
    } else {
      const editor = quillInstance?.getEditor();
      const selection = editor?.getSelection(true);
      if (editor && chapter && selection) {
        const commentData: CommentAddModel = {
          chapterId: chapter.id,
          projectId: projectId,
          comments: {
            comment: commentText,
          },
        };
        const { payload } = await dispatch(addComment(commentData));
        const commentPayload = payload as CommentModel;
        editor.insertText(
          selection.index,
          '\u00A0',
          CommentBlot.blotName,
          JSON.stringify({
            id: commentPayload.id,
          }),
          'user'
        );

        setcommentCreateMode(false);
        setCommentText('');
        dispatch(setChapterDirty(chapter.id));
      }
    }
  }, [
    chapter,
    commentCreateMode,
    commentText,
    dispatch,
    projectId,
    quillInstance,
  ]);

  useEffect(() => {
    const editor = quillInstance?.getEditor();

    if (editor) {
      const selectionChangeHandler = (range: Range) => {
        if (!range || range.length) {
          setCommentDisabled(true);
        } else {
          setTimeout(() => {
            const format = editor.getFormat({
              index: range.index,
              length: 1,
            });
            if (format.hasOwnProperty(CommentBlot.blotName)) {
              setCommentDisabled(true);
            } else {
              setCommentDisabled(false);
            }
          });
        }
      };

      const selection = editor.getSelection();
      selectionChangeHandler(selection);

      editor.on('selection-change', selectionChangeHandler);
      return () => {
        editor.off('selection-change', selectionChangeHandler);
      };
    }
  }, [quillInstance]);

  const handleInputChange = useCallback((value: string) => {
    setCommentText(value);
  }, []);

  const handleDelete = useCallback(
    async (commentId: string, chapterId: string) => {
      await dispatch(deleteMainComment({ id: commentId }));
      if (chapterId === chapter?.id) {
        const editor = quillInstance!.getEditor();
        const el = document.getElementById(commentId);
        if (el) {
          const blot = Quill.find(el);
          if (blot) {
            editor.deleteText(blot.offset(editor.scroll), 1, 'user');
          }
        }
        dispatch(setChapterDirty(chapter.id));
      }
    },
    [quillInstance, dispatch, chapter]
  );

  const handleEdit = async (id: string, commentId: string, comment: string) => {
    await dispatch(editComment({ id, commentId, comment }));
  };

  const handleClick = useCallback((bookmarkId: string) => {
    // const editor = quillInstance!.getEditor();
    // const bookmarkEl = document.querySelector(
    //   `${BookmarkIconBlot.tagName}[${BookmarkIconBlot.dataBookmarkIdName}="${bookmarkId}"]`
    // )! as HTMLElement;
    // if (!bookmarkEl) {
    //   // TODO remove this after text upload modifications
    //   return;
    // }
    // const scrollEl = editor.scroll.domNode as HTMLDivElement;
    // if (!isScrolledIntoView(bookmarkEl, scrollEl)) {
    //   bookmarkEl.focus({ preventScroll: false });
    //   const observer = new IntersectionObserver(
    //     (entries) => {
    //       if (entries[0].isIntersecting) {
    //         bookmarkEl.animate([{ transform: 'scale(1.4)' }], {
    //           duration: 200,
    //           iterations: 1,
    //         });
    //         observer.disconnect();
    //       }
    //     },
    //     {
    //       root: scrollEl,
    //       threshold: 1.0,
    //     }
    //   );
    //   observer.observe(bookmarkEl);
    // } else {
    //   bookmarkEl.animate([{ transform: 'scale(1.4)' }], {
    //     duration: 200,
    //     iterations: 1,
    //   });
    // }
  }, []);

  return (
    <Stack direction="column" className="h-100">
      <Stack.Item>
        <div className="p-3">
          {commentCreateMode && (
            <Textarea
              className="m-b-2"
              value={commentText}
              placeholder="Comment"
              onChangeValue={handleInputChange}
            />
          )}
          <Button
            onClick={handleCommentAdd}
            full
            primary
            loading={isCommentAdding}
            disabled={
              isCommentAdding || (!commentCreateMode && commentDisabled)
            }
          >
            Add Comment
          </Button>
        </div>
        <Divider />
      </Stack.Item>
      <Stack.Item>
        <Stack className="h-100" alignItems="center" justifyContent="center">
          <Stack.Item>
            {isCommentsFetching && <BouncingDotsLoader />}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      {isCommentsFetching ||
        (!comments.length && (
          <Stack className="h-100" alignItems="center" justifyContent="center">
            <Stack.Item shrink={0}>
              <div className="ta-center">
                <Icon name="comment" size={40} className="opacity-30" />
                <p className="m-t-2 fw-600 font-size-sm opacity-50">
                  List of comments is empty
                </p>
              </div>
            </Stack.Item>
          </Stack>
        ))}
      {!!comments.length && (
        <>
          <Stack.Item>
            <TabGroup underline className="p-l-3">
              <Tab
                active={activeTab === Tabs.Chapter}
                onClick={() => setActiveTab(Tabs.Chapter)}
              >
                Chapter
              </Tab>
              <Tab
                active={activeTab === Tabs.Book}
                onClick={() => setActiveTab(Tabs.Book)}
              >
                Book
              </Tab>
            </TabGroup>
          </Stack.Item>
          <Stack.Item fill>
            {activeTab === Tabs.Chapter && (
              <ChapterComments
                onClick={handleClick}
                onDelete={handleDelete}
                onEdit={handleEdit}
              />
            )}
            {activeTab === Tabs.Book && (
              <BookComments
                onClick={handleClick}
                onDelete={handleDelete}
                onEdit={handleEdit}
              />
            )}
          </Stack.Item>
        </>
      )}
    </Stack>
  );
};
