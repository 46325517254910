import React, { useMemo, useState } from 'react';
import { getProjectTOCPagesChapters } from 'redux/reducers/project/selectors';
import ToCChaptersContext, { ToCChaptersContextType } from 'modules/edit/TOCChapters/context';
import { useAppSelector } from 'modules/common/hooks/redux';
import TOCBulkDelete from 'modules/edit/TOCChapters/BulkDelete/TOCBulkDelete';
import { ChaptersList } from './ChaptersList';
import { Actions } from './Actions';

export const TOCChapters: React.FC<{
    isContentLoading: boolean;
    setIsContentLoading(value: boolean): void;
}> = ({ isContentLoading, setIsContentLoading }) => {
  const projectTOCChapters = useAppSelector(getProjectTOCPagesChapters);
  const [selectedChapterIds, setSelectedChapterIds] = useState<number[]>([]);
  const selectedChaptersWOFirst = useMemo(() => selectedChapterIds.filter(id => id !== projectTOCChapters[0].id), [projectTOCChapters, selectedChapterIds]);

  const contextValues = useMemo<ToCChaptersContextType>(() => {
    return {
      chapters: projectTOCChapters,
      selectedChapterIds,
      setSelectedChapterIds
    }
  }, [projectTOCChapters, selectedChapterIds]);

  return <>
    <Actions />
    <ToCChaptersContext.Provider value={contextValues}>
      <ChaptersList isContentLoading={isContentLoading}
                    setIsContentLoading={setIsContentLoading} />
      {!!selectedChaptersWOFirst.length && <TOCBulkDelete />}
    </ToCChaptersContext.Provider>
  </>
};
