import { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import { UNDO_REDO_CANVAS_ID } from 'modules/edit/Actions/config';

type Props = {
  quillInstance: ReactQuill | null;
};

export const useInitializeUndoRedoCanvas = ({ quillInstance }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>();

  useEffect(() => {
    const editor = quillInstance?.editor;
    if (editor) {
      const canvas = document.createElement('canvas');
      canvas.id = UNDO_REDO_CANVAS_ID;
      canvasRef.current = canvas;
      editor.addContainer(canvas);

      editor.root.addEventListener('scroll', () => {
        if (canvasRef.current)
          canvasRef.current.style.marginTop = -1 * editor.root.scrollTop + 'px';
      });
    }
  }, [quillInstance?.editor]);

  return canvasRef;
};
