import Image from 'modules/common/components/Image/Image';
import Styles from './helpcenter.module.scss';

const TagsEdit = () => {
  return (
    <>
      <p className={Styles['main-header']} id="text_editing">
        Text editing and bookmarks
      </p>
      <p className={Styles['sub-header']} id="text_editing_how">
        How to edit text
      </p>
      <p className={Styles['text']}>
        You may insert and remove words and phrases.
      </p>
      <p className={Styles['text']}>To insert a fragment:</p>
      <ol className={Styles['list']}>
        <li>
          Go to the <b>Edit</b> tab.
        </li>
        <li>Put the cursor at the certain place in a text.</li>
        <li>
          On the right panel, add the required fragment and click on
          <b>Insert fragment</b> button.
        </li>
      </ol>
      <p className={Styles['text']}>To remove a fragment:</p>
      <ol className={Styles['list']}>
        <li>
          Go to the <b>Edit</b> tab.
        </li>
        <li>Select a word or phrase.</li>
        <li>
          On the right panel, choose the <b>Skip selected fragment</b>.
        </li>
      </ol>

      <p className={Styles['sub-header']} id="text_editing_format">
        Text formats
      </p>
      <p className={Styles['text']}>
        To work with a text, you may select a plain text format or a speech
        synthesis markup language (SSML) format, which makes applied tags
        visible. SSML tags help customize words and phrases by changing
        pronunciation, volume, speech rate and other speech aspects.
      </p>
      <p className={Styles['text']}>
        To change a text format, use Visual and SSML tabs:
      </p>
      <Image
        src="helpcenter/text_format_helpcenter.png"
        alt="helpcenter text format"
      />
      <p className={Styles['text']}>To copy a fragment with applied tags:</p>

      <ol className={Styles['list']}>
        <li>Select a text fragment (word or phrase).</li>
        <li>
          Go to the <b>Edit</b> tab and select one or several tags.{' '}
        </li>
        <li>
          Click on <b>Copy fragment with tags</b> button.
        </li>
        <li>Paste a fragment into a third-party text editor.</li>
      </ol>
      <p className={Styles['sub-header']} id="text_editing_bookmarks">
        Bookmarks
      </p>
      <p className={Styles['text']}>
        To mark a specific place in a text, create a bookmark:
      </p>

      <ol className={Styles['list']}>
        <li>
          Go to the <b>Bookmarks</b> tab.
        </li>
        <li>Put the cursor at the certain place in a text.</li>
        <li>
          On the right panel, click on <b>Add bookmark</b> button.
        </li>
        <li>Enter a name of the bookmark and then click on ✔️.</li>
      </ol>
      <p className={Styles['alert']}>
        Make a bookmark name so that it is easy to find this bookmark in the
        list. We recommend to use a phrase which is before or after a bookmark
        location
      </p>
      <p className={Styles['text']}>To rename or delete a bookmark:</p>
      <ol className={Styles['list']}>
        <li>On the right panel, navigate to a bookmark.</li>
        <li>Select ··· and then choose an option.</li>
      </ol>
      <p className={Styles['text']}>
        To find a fragment tagged with a bookmark:
      </p>
      <ol className={Styles['list']}>
        <li>
          Go to the <b>Bookmarks</b> tab.
        </li>
        <li>On the right panel, select the required bookmark.</li>
      </ol>
      <p className={Styles['text']}>
        A fragment tagged with this bookmark will open in the text window (the
        Visual tab).
      </p>
    </>
  );
};

export default TagsEdit;
