import axiosInstance from 'utils/axios';
import { TagModel } from 'modules/common/models/Tags';
import { TTSEngine } from 'modules/common/types';

interface TagsResponse {
  sentenceTags: TagModel[];
  wordTags: TagModel[];
  numTags: TagModel[];
}

interface ITagsApi {
  getAll(): Promise<TagsResponse>;
}

class TagsApi implements ITagsApi {
  static action = '/ssml_tags';

  async getAll(engine_name: TTSEngine = 'aws'): Promise<TagsResponse> {
    const res = await axiosInstance.get<TagsResponse>(TagsApi.action, {
      params: {
        engine_name,
      },
    });
    return Object.keys(res.data).reduce(
      (prev, curr) => {
        return {
          ...prev,
          [curr]: (res.data[curr as keyof TagsResponse] || []).map((item) => ({
            ...item,
            attributes: item.attributes.map((attribute) => ({
              ...attribute,
              postfix: attribute.postfix || '',
              prefix: attribute.prefix || '',
            })),
          })),
        };
      },
      {
        sentenceTags: [],
        wordTags: [],
        numTags: [],
      }
    );
  }
}

const tagsApiInstance = new TagsApi();

export default tagsApiInstance;
