import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  IAuthenticationDetailsData,
  ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';

export function getCognitoUserPool() {
  const poolData: ICognitoUserPoolData = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID!,
  };
  return new CognitoUserPool(poolData);
}

export function getAuthenticationDetails(
  authenticationData: IAuthenticationDetailsData
) {
  return new AuthenticationDetails(authenticationData);
}

export function getCognitoUser(username: string) {
  const cognitoUserPool = getCognitoUserPool();
  return new CognitoUser({ Username: username, Pool: cognitoUserPool });
}

export function getCurrentSession() {
  const userPool = getCognitoUserPool();
  const cognitoUser = userPool.getCurrentUser();

  if (cognitoUser) {
    return new Promise<CognitoUserSession>((res, rej) => {
      cognitoUser.getSession(
        (err: Error | null, session: CognitoUserSession) => {
          if (err) {
            return rej(err);
          }
          res(session);
        }
      );
    });
  }
  return Promise.resolve(null);
}

export async function signUp(name: string, email: string, password: string) {
  const userPool = getCognitoUserPool();
  const attributeEmail = new CognitoUserAttribute({
    Name: 'email',
    Value: email,
  });
  const attributeName = new CognitoUserAttribute({
    Name: 'name',
    Value: name,
  });
  return new Promise((res, rej) => {
    userPool.signUp(
      email,
      password,
      [attributeEmail, attributeName],
      [attributeEmail, attributeName],
      (err, result) => {
        if (err) {
          return rej(err);
        }
        res(result);
      }
    );
  });
}

export async function signIn(
  username: string,
  password: string
): Promise<CognitoUserSession> {
  const cognitoUser = getCognitoUser(username);
  const authDetails = getAuthenticationDetails({
    Username: username,
    Password: password,
  });
  return new Promise((res, rej) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: function (result) {
        res(result);
      },
      onFailure: function (err) {
        rej(err);
      },
    });
  });
}

export async function logout() {
  const userPool = getCognitoUserPool();
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
}

export async function forgotPassword(username: string) {
  const cognitoUser = getCognitoUser(username);
  return new Promise((res, rej) => {
    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        res(result);
      },
      onFailure: function (err) {
        rej(err);
      },
    });
  });
}

export async function resetPassword(
  username: string,
  code: string,
  password: string
) {
  const cognitoUser = getCognitoUser(username);
  return new Promise((res, rej) => {
    cognitoUser.confirmPassword(code, password, {
      onSuccess: function (result) {
        res(result);
      },
      onFailure: function (err) {
        rej(err);
      },
    });
  });
}

export async function confirmRegistration(username: string, code: string) {
  const cognitoUser = getCognitoUser(username);
  return new Promise((res, rej) => {
    cognitoUser.confirmRegistration(code, false, (err, result) => {
      if (err) {
        return rej(err);
      }
      res(result);
    });
  });
}

export async function resendConfirmationCode(username: string) {
  const cognitoUser = getCognitoUser(username);
  return new Promise((res, rej) => {
    cognitoUser.resendConfirmationCode((err: any, result: any) => {
      if (err) {
        return rej(err);
      }
      res(result);
    })
  });
}

export async function getUserGroups(
  sessionParameter?: CognitoUserSession
): Promise<string[]> {
  if (sessionParameter) {
    return sessionParameter.getIdToken().payload['cognito:groups'];
  }

  const session = await getCurrentSession();
  if (session) {
    return session.getIdToken().payload['cognito:groups'];
  }
  throw new Error('Invalid User');
}

export async function getUserId(): Promise<string> {
  const session = await getCurrentSession();
  if (session) {
    return session.getIdToken().payload['sub'];
  }
  throw new Error('Invalid User');
}

export async function getUserEmail(): Promise<string> {
  const session = await getCurrentSession();
  if (session) {
    return session.getIdToken().payload['email'];
  }
  throw new Error('Invalid User');
}
