import { FC, useMemo } from 'react';
import { Select } from 'modules/common/components/Select/Select';
import { SelectValue } from 'modules/common/types';
import { useAppSelector } from 'modules/common/hooks/redux';
import {
  chaptersWithReport,
  selectTotal,
} from 'redux/reducers/chapters/selectors';
import { activeProjectSelectorId } from 'redux/reducers/project/selectors';
import Styles from './preprocessingreportpopup.module.scss';

interface Props {
  isLoading: boolean;
  selectedChapter: SelectValue | undefined;
  setSelectedChapter(value: SelectValue): void;
}

const MAX_MENU_HEIGHT = 150;

export const Header: FC<Props> = ({
  isLoading,
  selectedChapter,
  setSelectedChapter,
}) => {
  const projectId = useAppSelector(activeProjectSelectorId);
  const totalCount = useAppSelector(selectTotal);
  const reportChapters = useAppSelector(chaptersWithReport);

  const fulBookOption = useMemo(
    () => ({
      value: projectId,
      label: 'Full book',
    }),
    [projectId]
  );

  const chaptersDropdown: {
    value: string;
    label: string;
  }[] = useMemo(() => {
    if (!reportChapters) {
      return [fulBookOption];
    }
    const options = reportChapters.map((item) => ({
      value: item.id,
      label: item.chapterName,
    }));
    if (totalCount === reportChapters.length) options.unshift(fulBookOption);

    return options;
  }, [reportChapters, fulBookOption, totalCount]);

  return (
    <div className={Styles['header']}>
      <div>Report</div>
      <Select
        disabled={isLoading}
        value={selectedChapter}
        maxMenuHeight={MAX_MENU_HEIGHT}
        onChange={setSelectedChapter}
        options={chaptersDropdown}
        size="small"
        className={Styles['header-select']}
      />
    </div>
  );
};
