import { TTSEngine } from 'modules/common/types';
import { reviewerType } from 'modules/common/models/Project';

export type ProjectCreationFormSteps = 'default' | 'elevenLabsVoiceSetting';

export type ReviewersHashed = {
  [id: string]: reviewerType;
};

export const ElevenLabsVoiceSettingsParam = {
  stability: 'stability',
  similarity_boost: 'similarity_boost',
  style: 'style',
  use_speaker_boost: 'use_speaker_boost',
} as const;

export type ElevenLabsVoiceSettingsParamType =
  keyof typeof ElevenLabsVoiceSettingsParam;

export type ElevenLabsVoiceSettings = {
  [ElevenLabsVoiceSettingsParam.stability]: string;
  [ElevenLabsVoiceSettingsParam.similarity_boost]: string;
  [ElevenLabsVoiceSettingsParam.style]: string;
  [ElevenLabsVoiceSettingsParam.use_speaker_boost]: boolean;
};

export enum KeyedResultForm {
  name = 'name',
  ttsEngine = 'ttsEngine',
  voice = 'voice',
  elevenLabsVoiceSettings = 'elevenLabsVoiceSettings',
  elevenLabsModel = 'elevenLabsModel',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  userId = 'userId',
}

export interface ResultForm {
  [KeyedResultForm.name]: string;
  [KeyedResultForm.ttsEngine]: TTSEngine;
  [KeyedResultForm.voice]: string;
  [KeyedResultForm.elevenLabsVoiceSettings]?: ElevenLabsVoiceSettings;
  [KeyedResultForm.elevenLabsModel]: string;
  reviewers: ReviewersHashed;
}
