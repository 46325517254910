import {
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  HTMLAttributes,
} from 'react';
import classnames from 'classnames';

import Styles from './stack.module.scss';
import { StackItem } from './StackItem';

export interface StackProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  alignContent?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'center';
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  spacing?: '0' | '1' | '2' | '3' | '4' | '5' | 0 | 1 | 2 | 3 | 4 | 5;
  className?: string;
}

interface IStack extends ForwardRefExoticComponent<StackProps> {
  Item: typeof StackItem;
}

export const Stack: IStack = forwardRef(
  (
    {
      children,
      justifyContent = 'flex-start',
      alignItems = 'stretch',
      alignContent = 'stretch',
      direction = 'row',
      wrap = 'nowrap',
      spacing = 0,
      className,
      ...rest
    },
    ref: ForwardedRef<any>
  ) => {
    return (
      <div
        ref={ref}
        className={classnames(
          Styles.stack,
          justifyContent && Styles[`jc-${justifyContent}`],
          alignItems && Styles[`ai-${alignItems}`],
          className,
          {
            [Styles[`direction-${direction}`]]: direction,
            [Styles[`spacing-${spacing}`]]: spacing,
            [Styles[`wrap-${wrap}`]]: wrap,
          }
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
) as IStack;

Stack.Item = StackItem;

Stack.displayName = 'Stack';
