import React, { useCallback, useContext, useMemo } from 'react';

import { Icon } from 'icons';
import { Button } from 'modules/common/components/Button/Button';
import { Stack } from 'modules/common/components/Stack/Stack';
import { ActionMenu } from 'modules/common/components/ActionMenu/ActionMenu';
import { Input } from 'modules/common/components/Input/Input';
import { useActions } from 'modules/common/hooks/useActions';
import { TOCChapter } from 'modules/common/types';
import useQuill from 'modules/common/hooks/useQuill';
import Styles from './tocChapters.module.scss';
import { useScrollToTOCChapter } from '../lib';
import classNames from 'classnames';
import { Checkbox } from 'modules/common/components/Checkbox/Checkbox';
import ToCChaptersContext from 'modules/edit/TOCChapters/context';
import { useAppSelector } from 'modules/common/hooks/redux';
import { getProjectTOCPagesChapters } from 'redux/reducers/project/selectors';

export const ChaptersListItem: React.FC<{
  tocChapter: TOCChapter;
  onDelete: (tocChapter: TOCChapter) => void;
  onSaveChangedValue: (value: string, tocChapter: TOCChapter) => void;
}> = ({ tocChapter, onDelete, onSaveChangedValue }) => {
  const scrollToTOCChapter = useScrollToTOCChapter();
  const [editMode, setEditMode] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const quillInstance = useQuill();
  const editor = quillInstance?.getEditor();
  const projectTOCChapters = useAppSelector(getProjectTOCPagesChapters);
  const context = useContext(ToCChaptersContext);
  const {
    actionMenuOpen,
    handleCloseActions,
    handleOpenActions,
    setActionMenuOpen,
    hovered,
    handleMouseEnter,
    handleMouseLeave,
  } = useActions();

  const handleMakeChapter = () => {
    const newObj = { ...tocChapter, isSubChapter: false };
    onSaveChangedValue(tocChapter.name, newObj);
    setActionMenuOpen(false);
  };

  const handleMakeSubChapter = () => {
    const newObj = { ...tocChapter, isSubChapter: true };
    onSaveChangedValue(tocChapter.name, newObj);
    setActionMenuOpen(false);
  };

  const handleDelete = () => {
    onDelete(tocChapter);
    setActionMenuOpen(false);
  };

  const handleEditMode = () => {
    setActionMenuOpen(false);
    setEditMode(true);
  };

  const handleEditDone = () => {
    const value = inputRef.current?.value;
    if (!value || !editor) return;
    onSaveChangedValue(value, tocChapter);
    setEditMode(false);
  };

  const navigateToElementHandler = () => {
    if (!editor) return;
    scrollToTOCChapter({ editor, chapterToSee: tocChapter });
  };

  const isSelected = useMemo(() => context.selectedChapterIds.includes(tocChapter.id), [context.selectedChapterIds, tocChapter.id]);

  const handleCheckboxChange = useCallback((checked: boolean) => {
    if(checked) {
      let idsToSelect = [tocChapter.id];
      if(!tocChapter.isSubChapter) {
        idsToSelect.push(...projectTOCChapters
        .filter(item => item.parentChapterId === tocChapter.id && !context.selectedChapterIds.includes(item.id))
        .map(item => item.id))
      }
      context.setSelectedChapterIds([...context.selectedChapterIds, ...idsToSelect]);
    }
    else {
      context.setSelectedChapterIds(context.selectedChapterIds.filter(id => id !== tocChapter.id));
    }
  }, [context, projectTOCChapters, tocChapter.id, tocChapter.isSubChapter]);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={Styles['toc-chapter']}
      onClick={navigateToElementHandler}
    >
      <Stack alignItems="center" justifyContent="flex-start">
        <Stack.Item className="m-r-1">
          {editMode ? (
            <Stack
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack.Item fill>
                <Input
                  ref={inputRef}
                  type="text"
                  small
                  autoFocus
                  defaultValue={tocChapter.name}
                />
              </Stack.Item>
              <Stack.Item flex>
                <Button onClick={handleEditDone} icon="done" />
              </Stack.Item>
            </Stack>
          ) : (
            <div
              className={classNames(Styles['toc-chapter-title'], {
                [Styles['sub-chapter']]: tocChapter.isSubChapter,
              })}
            >
              {tocChapter.name}
            </div>
          )}
        </Stack.Item>
        {!editMode && hovered && (
          <Stack.Item shrink={0} className="m-r-1">
            <ActionMenu
              direction="bl"
              portal
              triggerDisplayFormat="flex"
              open={actionMenuOpen}
              width="xs"
              trigger={
                <Icon onClick={handleOpenActions} name="more_horiz" size={20} />
              }
              onClickOutside={handleCloseActions}
              actionItemWrapperClassname={Styles['toc-chapter-actions']}
              autoFlipVertically
            >
              {tocChapter.isSubChapter ? (
                <ActionMenu.Item
                  onClick={handleMakeChapter}
                  className={Styles['toc-chapter-action']}
                >
                  Make a chapter
                </ActionMenu.Item>
              ) : Number(tocChapter.id) !== 1 ? (
                <ActionMenu.Item
                  onClick={handleMakeSubChapter}
                  className={Styles['toc-chapter-action']}
                >
                  Make a sub-chapter
                </ActionMenu.Item>
              ) : null}
              <ActionMenu.Item
                onClick={handleEditMode}
                className={Styles['toc-chapter-action']}
              >
                Rename
              </ActionMenu.Item>
              <ActionMenu.Item
                // Don't let remove the first chapter
                disabled={Number(tocChapter.id) === 1}
                onClick={handleDelete}
                className={Styles['toc-chapter-action']}
              >
                Remove
              </ActionMenu.Item>
            </ActionMenu>
          </Stack.Item>
        )}
        <Stack.Item shrink={0} className="m-l-auto" flex>
          <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
        </Stack.Item>
      </Stack>
    </div>
  );
};
