import { FC, useCallback, useEffect, useState } from 'react';
import { Quill, Range } from 'react-quill';

import { BookmarkIconBlot } from 'modules/edit/v2/Editor/Blots/BookmarkIconBlot';
import { BookmarkModel } from 'modules/common/models/Bookmark';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import { bookMarksIsFetchingSelector } from 'redux/reducers/bookmarks/selectors';
import {
  addBookmark,
  deleteBookmark,
  editBookmark,
} from 'redux/reducers/bookmarks/bookmarksSlice';
import useQuill from 'modules/common/hooks/useQuill';
import BouncingDotsLoader from 'modules/common/components/BouncingDotsLoader/BouncingDotsLoader';
import { Button } from 'modules/common/components/Button/Button';
import { Divider } from 'modules/common/components/Divider/Divider';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Input } from 'modules/common/components/Input/Input';
import { activeChapterSelector } from 'redux/reducers/chapters/selectors';
import { activeProjectSelectorId } from 'redux/reducers/project/selectors';
import { setChapterDirty } from 'redux/reducers/chapters/chaptersSlice';
import { TabGroup } from 'modules/common/components/Tab/TabGroup';
import { Tab } from 'modules/common/components/Tab/Tab';
import { useTrackEvent, AnalyticsEvent } from 'services/amplitude';
import { ChapterBookmarks } from 'modules/edit/Bookmarks/ChapterBookmarks';
import { BookBookmarks } from 'modules/edit/Bookmarks/BookBookmarks';
import { isScrolledIntoView } from 'utils';

enum Tabs {
  Chapter = 0,
  Book,
}

export const Bookmarks: FC = () => {
  const dispatch = useAppDispatch();
  const isBookmarkFetching = useAppSelector(bookMarksIsFetchingSelector);
  const chapter = useAppSelector(activeChapterSelector);
  const projectId = useAppSelector(activeProjectSelectorId);
  const trackEvent = useTrackEvent();
  const quillInstance = useQuill();
  const [bookmarkDisabled, setBookmarkDisabled] = useState(true);
  const [bookmarkCreateMode, setBookmarkCreateMode] = useState(false);
  const [bookmarkName, setBookmarkName] = useState('');
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Chapter);

  const handleBookmarkAdd = useCallback(() => {
    setBookmarkCreateMode(true);
  }, []);

  const handleBookmarkCreate = useCallback(async () => {
    const editor = quillInstance?.getEditor();
    const selection = editor?.getSelection(true);
    if (editor && chapter && selection) {
      const bookmark = {
        title: bookmarkName,
        chapter: chapter.id,
      };

      const { payload } = await dispatch(addBookmark({ bookmark, projectId }));
      const bookmarkPayload = payload as BookmarkModel;
      trackEvent({
        eventName: AnalyticsEvent.AddBookmark,
        customProperties: {
          bookmarkId: bookmarkPayload.id,
        },
      });

      editor.insertText(
        selection.index,
        '\u00A0',
        BookmarkIconBlot.blotName,
        JSON.stringify({
          bookmarkId: bookmarkPayload.id,
          projectId,
        }),
        'user'
      );

      setBookmarkCreateMode(false);
      setBookmarkName('');
      dispatch(setChapterDirty(chapter.id));
    }
  }, [quillInstance, chapter, bookmarkName, dispatch, projectId, trackEvent]);

  useEffect(() => {
    const editor = quillInstance?.getEditor();

    if (editor) {
      const selectionChangeHandler = (range: Range) => {
        if (!range || range.length) {
          setBookmarkDisabled(true);
        } else {
          setTimeout(() => {
            const format = editor.getFormat({
              index: range.index,
              length: 1,
            });
            if (format.hasOwnProperty(BookmarkIconBlot.blotName)) {
              setBookmarkDisabled(true);
            } else {
              setBookmarkDisabled(false);
            }
          });
        }
      };

      const selection = editor.getSelection();
      selectionChangeHandler(selection);

      editor.on('selection-change', selectionChangeHandler);
      return () => {
        editor.off('selection-change', selectionChangeHandler);
      };
    }
  }, [quillInstance]);

  const handleInputChange = useCallback((value: string) => {
    setBookmarkName(value);
  }, []);

  const handleDelete = useCallback(
    async (bookmarkId: string) => {
      const editor = quillInstance!.getEditor();
      const el = document.querySelector(
        `${BookmarkIconBlot.tagName}[${BookmarkIconBlot.dataBookmarkIdName}="${bookmarkId}"]`
      )!;
      if (!el) {
        return;
      }
      const blot = Quill.find(el);
      editor.deleteText(blot.offset(editor.scroll), 1, 'user');

      await dispatch(deleteBookmark({ id: bookmarkId, projectId }));
      trackEvent({
        eventName: AnalyticsEvent.AddBookmark,
        customProperties: {
          bookmarkId,
        },
      });
      if (chapter) {
        dispatch(setChapterDirty(chapter.id));
      }
    },
    [quillInstance, dispatch, projectId, trackEvent, chapter]
  );

  const handleEdit = async (id: string, newValue: BookmarkModel) => {
    await dispatch(editBookmark({ id, newValue, projectId }));
    const editor = quillInstance?.getEditor();
    const bookmarkEl = document.getElementById(id);

    if (bookmarkEl && editor) {
      const bookmarkBlot = Quill.find(bookmarkEl);

      const range: Range = {
        index: bookmarkBlot.offset(editor.scroll),
        length: bookmarkBlot.length(),
      };

      editor.formatText(
        range,
        bookmarkBlot.statics.blotName,
        JSON.stringify({
          bookmarkId: id,
          projectId,
        }),
        'user'
      );
    }

    if (chapter) {
      dispatch(setChapterDirty(chapter.id));
    }
  };

  const handleClick = useCallback(
    (bookmarkId: string) => {
      const editor = quillInstance!.getEditor();
      const bookmarkEl = document.querySelector(
        `${BookmarkIconBlot.tagName}[${BookmarkIconBlot.dataBookmarkIdName}="${bookmarkId}"]`
      )! as HTMLElement;
      if (!bookmarkEl) {
        // TODO remove this after text upload modifications
        return;
      }
      const scrollEl = editor.scroll.domNode as HTMLDivElement;
      if (!isScrolledIntoView(bookmarkEl, scrollEl)) {
        bookmarkEl.focus({ preventScroll: false });
        const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              bookmarkEl.animate([{ transform: 'scale(1.4)' }], {
                duration: 200,
                iterations: 1,
              });
              observer.disconnect();
            }
          },
          {
            root: scrollEl,
            threshold: 1.0,
          }
        );
        observer.observe(bookmarkEl);
      } else {
        bookmarkEl.animate([{ transform: 'scale(1.4)' }], {
          duration: 200,
          iterations: 1,
        });
      }
    },
    [quillInstance]
  );

  return (
    <Stack direction="column" className="h-100">
      <Stack.Item>
        <div className="p-3">
          {bookmarkCreateMode ? (
            <Stack spacing={2}>
              <Stack.Item fill>
                <Input
                  placeholder="Bookmark name"
                  value={bookmarkName}
                  onChangeWithValueHandler={handleInputChange}
                />
              </Stack.Item>
              <Stack.Item>
                <Button
                  large
                  onClick={handleBookmarkCreate}
                  primary
                  disabled={!bookmarkName.length}
                  icon="done"
                />
              </Stack.Item>
            </Stack>
          ) : (
            <Button
              onClick={handleBookmarkAdd}
              full
              primary
              disabled={bookmarkDisabled}
            >
              Add bookmark
            </Button>
          )}
        </div>
        <Divider />
      </Stack.Item>
      <Stack.Item>
        <Stack className="h-100" alignItems="center" justifyContent="center">
          <Stack.Item>
            {isBookmarkFetching && <BouncingDotsLoader />}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <TabGroup underline className="p-l-3">
          <Tab
            active={activeTab === Tabs.Chapter}
            onClick={() => setActiveTab(Tabs.Chapter)}
          >
            Chapter
          </Tab>
          <Tab
            active={activeTab === Tabs.Book}
            onClick={() => setActiveTab(Tabs.Book)}
          >
            Book
          </Tab>
        </TabGroup>
      </Stack.Item>
      <Stack.Item fill>
        {activeTab === Tabs.Chapter && (
          <ChapterBookmarks
            onClick={handleClick}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        )}
        {activeTab === Tabs.Book && (
          <BookBookmarks
            onClick={handleClick}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        )}
      </Stack.Item>
    </Stack>
  );
};
