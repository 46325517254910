import { FC, memo, useState } from 'react';
import Styles from './helpcenter.module.scss';
import classnames from 'classnames';
import TagsCustomize from './TagsCustomize';
import TagsEdit from './TagsEdit';
import PresetsView from './PresetsView';
import SaveDownloadView from './SaveDownloadView';
import { Divider } from 'modules/common/components/Divider/Divider';
import Draggable from 'react-draggable';

interface Props {
  setHelpCenter: (value: boolean) => void;
}

const HelpCenter: FC<Props> = memo(({ setHelpCenter }) => {
  const [showMoreTagsCustomize, setShowMoreTagsCustomize] = useState(false);

  return (
    <Draggable handle={`.help-center-header`}>
      <div className={Styles['container']}>
        <div className={classnames(Styles['drag-drop'], 'help-center-header')}>
          SSML Editor
          <span
            className={Styles['close-icon']}
            onClick={() => setHelpCenter(false)}
          >
            &#x2715;
          </span>
        </div>
        <div className={Styles['body']}>
          <p className={classnames(Styles['main-header'], Styles['first'])}>
            Table of contents
          </p>
          <ol className={classnames(Styles['list'], Styles['linked'])}>
            <li>
              <a href="#overview">Overview</a>
            </li>
            <li className={Styles['not-linked']}>
              <a className={Styles['link']} href="#tags_customize">
                Tags to customise speech
              </a>
              <ol className={Styles['list']}>
                <li>
                  <a href="#tags_customize_enable">How to enable tags</a>
                </li>
                <li>
                  <a href="#tags_customize_apply">How to apply tags</a>
                </li>
                <li>
                  <a href="#tags_customize_emphasis">Emphasize a phrase</a>
                </li>
                {showMoreTagsCustomize ? (
                  <>
                    <li>
                      <a href="#tags_customize_adjust">Adjust pronunciation</a>
                    </li>
                    <li>
                      <a href="#tags_customize_date">
                        Date, time and abbreviations
                      </a>
                    </li>
                    <li>
                      <a href="#tags_customize_censored">Censored effects</a>
                    </li>
                    <li>
                      <a href="#tags_customize_compatibility">
                        Tag compatibility
                      </a>
                    </li>
                    <li
                      className={Styles['button-link']}
                      onClick={() => setShowMoreTagsCustomize(false)}
                    >
                      show less
                    </li>
                  </>
                ) : (
                  <li
                    className={Styles['button-link']}
                    onClick={() => setShowMoreTagsCustomize(true)}
                  >
                    show more
                  </li>
                )}
              </ol>
            </li>
            <li className={Styles['not-linked']}>
              <a href="#text_editing" className={Styles['link']}>
                Text editing and bookmarks
              </a>
              <ol className={Styles['list']}>
                <li>
                  <a href="#text_editing_how">How to edit text</a>
                </li>
                <li>
                  <a href="#text_editing_format">Text formats</a>
                </li>
                <li>
                  <a href="#text_editing_bookmarks">Bookmarks</a>
                </li>
              </ol>
            </li>
            <li>
              <a href="#presets_view">Presets for single click editing</a>
            </li>
            <li>
              <a href="#save_download_view">Save and download audio</a>
            </li>
          </ol>

          <p className={Styles['main-header']} id="overview">
            Overview
          </p>
          <p className={Styles['text']}>
            SSML Editor is a Text to Speech tool based on Speech Synthesis
            Markup Language (SSML).{' '}
            <b>The Editor is a part of an authoring ecosystem</b> for creating
            and distributing your audiobooks.
          </p>
          <p className={Styles['sub-header']}>How it works</p>
          <p className={Styles['text']}>
            Upload a pdf or epub file → Select voice, edit text and audio, apply
            effects → Download a ready-to-use audiobook (mp3)
          </p>
          <p className={Styles['sub-header']}>Features</p>
          <p>
            <a href="#tags_customize">Tags to customize speech</a>
          </p>
          <p>
            <a href="#text_editing">Text editing and bookmarks</a>
          </p>
          <p>
            <a href="#presets_view">Presets for single click editing</a>
          </p>
          <p>
            <a href="#save_download_view">Save and download audio</a>
          </p>

          <Divider spacing="2" />
          <TagsCustomize />

          <Divider spacing="2" />
          <TagsEdit />

          <Divider spacing="2" />
          <PresetsView />

          <Divider spacing="2" />
          <SaveDownloadView />
        </div>
      </div>
    </Draggable>
  );
});

export default HelpCenter;
