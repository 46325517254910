import { FC, useCallback, useMemo, useRef, useState } from 'react';

import { Button } from 'modules/common/components/Button/Button';
import { Input } from 'modules/common/components/Input/Input';
import { Modal, ModalSizes } from 'modules/common/components/Modal/Modal';
import { Stack } from 'modules/common/components/Stack/Stack';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import { addPreset } from 'redux/reducers/presets/presetsSlice';
import { selectAll, selectEntities } from 'redux/reducers/tags/selectors';
import { presetIsAddingSelector } from 'redux/reducers/presets/selectors';
import { activeProjectSelectorId } from 'redux/reducers/project/selectors';
import { TagContainer } from 'modules/edit/Tags/TagContainer';
import {
  AttributeModel,
  AttributeTypes,
  TagModel,
  TagType,
} from 'modules/common/models/Tags';
import { PresetTag, PresetTagAttribute } from 'modules/common/models/Presets';
import { Alert } from 'modules/common/components/Alert/Alert';
import { AnalyticsEvent, useTrackEvent } from 'services/amplitude';
import { isEmpty } from 'lodash';
import Styles from './preset.module.scss';
import { getLastValueInMap } from 'utils/map';

interface CreatePresetModalProps {
  isOpen: boolean;
  setClose(): void;
}

export const CreatePresetModal: FC<CreatePresetModalProps> = ({
  isOpen,
  setClose,
}) => {
  const reduxDispatch = useAppDispatch();
  const tags = useAppSelector(selectAll);
  const tagEntities = useAppSelector(selectEntities);
  const trackEvent = useTrackEvent();
  const [presetName, setPresetName] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState(
    new Map<string, PresetTag>()
  );
  const projectId = useAppSelector(activeProjectSelectorId);
  const isLoading = useAppSelector(presetIsAddingSelector);
  const appliedTagNames = useRef<Set<string>>(new Set());

  const tagsToApply = useMemo(() => {
    const lastAdded = getLastValueInMap(selectedTags) as PresetTag | undefined;
    const lastAddedTag = lastAdded ? tagEntities[lastAdded.id] : undefined;
    return tags.filter((t) => {
      return (
        (t.tagType === TagType.WordTags || t.tagType === TagType.NumTags) &&
        (selectedTags.has(t.id) ||
          (lastAddedTag
            ? lastAddedTag.allowedTags.includes('*') ||
              lastAddedTag.allowedTags.includes(t.name)
            : true))
      );
    });
  }, [selectedTags, tagEntities, tags]);

  const onCloseCleanUps = useCallback(() => {
    setPresetName('');
    setClose();
  }, [setClose]);

  const handleCheckBoxChange = useCallback(
    (tag: TagModel, checked: boolean) => {
      const newMap = new Map(selectedTags);
      if (checked) {
        appliedTagNames.current.add(tag.name);
        const attributes: PresetTagAttribute[] = tag.attributes.map((a) => {
          if (a.type === AttributeTypes.Enum) {
            return {
              id: a.id,
              value: a.values![0].value,
              name: a.name,
            };
          }
          if (a.type === AttributeTypes.Integer) {
            return {
              id: a.id,
              value: a.defaultValue!,
              name: a.name,
            };
          }
          return {
            id: a.id,
            value: '',
            name: a.name,
          };
        });
        newMap.set(tag.id, {
          id: tag.id,
          name: tag.name,
          attributes: attributes,
          tagType: tag.tagType,
          ...(!isEmpty(tag.fixedAttributes)
            ? {
                fixedAttributes: tag.fixedAttributes,
              }
            : {}),
        });
      } else {
        appliedTagNames.current.delete(tag.name);
        newMap.delete(tag.id);
      }
      setSelectedTags(newMap);
    },
    [selectedTags]
  );

  const handleAttrValueChange = useCallback(
    (t: TagModel, attr: AttributeModel, value?: string) => {
      const tag = selectedTags.get(t.id);

      if (tag) {
        tag.attributes = tag.attributes.map((item) =>
          item.id === attr.id
            ? { id: attr.id, name: attr.name, value: value! }
            : item
        );

        const newMap = new Map(selectedTags);
        setSelectedTags(newMap);
      }
    },
    [selectedTags]
  );

  const handleSubmit = useCallback(async () => {
    if (!presetName.length || !selectedTags.size) {
      return;
    }
    const tags = Array.from(selectedTags.values());

    await reduxDispatch(
      addPreset({
        preset: {
          name: presetName,
          tags,
        },
        projectId: projectId,
      })
    );
    trackEvent({
      eventName: AnalyticsEvent.CreatePreset,
      customProperties: {
        tagType: {
          type: {
            tagName: tags[0].tagName,
            tagType: tags[0].tagType,
          },
          presetName,
        },
      },
    });

    onCloseCleanUps();
  }, [
    presetName,
    selectedTags,
    reduxDispatch,
    projectId,
    trackEvent,
    onCloseCleanUps,
  ]);

  return (
    <Modal
      open={isOpen}
      onClose={onCloseCleanUps}
      isCloseIcon={true}
      noPadding
      size={ModalSizes.L}
    >
      <Stack className="overflow-hidden">
        <Stack.Item basis="60%">
          <div className={Styles['preset-modal-tags']}>
            {tagsToApply.map((tag) => {
              let attributeValues = new Map();
              const selectedTag = selectedTags.get(tag.id);
              if (selectedTag) {
                attributeValues = new Map(
                  selectedTag.attributes.map((a) => [a.name, a.value])
                );
              }
              return (
                <div key={tag.id}>
                  <TagContainer
                    tag={tag}
                    onCheckBoxChange={handleCheckBoxChange}
                    checkboxSelected={selectedTags.has(tag.id)}
                    onAttrValueChange={handleAttrValueChange}
                    attributeValues={attributeValues}
                  />
                </div>
              );
            })}
          </div>
        </Stack.Item>
        <Stack.Item basis="40%">
          <div className="p-4 h-100">
            <Stack
              direction="column"
              className="h-100"
              justifyContent="center"
              alignItems="center"
            >
              <Stack.Item className="w-100" fill>
                <h4 className="font-size-lg fw-700 m-b-3 m-t-2 ta-center">
                  Create new preset
                </h4>
                <Input
                  autoFocus
                  onChangeWithValueHandler={(value) => setPresetName(value)}
                  value={presetName}
                  className="m-b-3"
                  placeholder="Enter name"
                />
                <Alert className="m-b-3" type="warning">
                  Note that some tags cannot be combined with other tags.{' '}
                  <a href="/">Read more</a>
                </Alert>
              </Stack.Item>
              <Stack.Item className="w-100">
                <Button
                  disabled={
                    !presetName.length || !selectedTags.size || isLoading
                  }
                  onClick={handleSubmit}
                  loading={isLoading}
                  full
                >
                  Create preset
                </Button>
              </Stack.Item>
            </Stack>
          </div>
        </Stack.Item>
      </Stack>
    </Modal>
  );
};
