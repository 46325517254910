import React from 'react';
import { VoiceDescription, VoiceAdditionalSetting } from '../types';
import { ElevenLabsVoiceSettingsParamType } from 'modules/projects/Project/types';
import { SelectValueBoolean } from 'modules/common/types';
import { Description } from './Description';
import { VoiceSettingsSlider } from './VoiceSettingsSlider';
import Styles from './voisesettingsitem.module.scss';

interface Props {
  value: string;
  name: ElevenLabsVoiceSettingsParamType;
  label: React.ReactNode;
  descriptions?: VoiceDescription[];
  voiceSettings: VoiceAdditionalSetting;
  onChange(value: SelectValueBoolean): void;
}

export const VoiceSettingsItem: React.FC<Props> = ({
  value,
  name,
  descriptions,
  label,
  voiceSettings,
  onChange,
}) => {
  const onChangeHandler = (value: string) => onChange({ value, label: name });

  return (
    <div>
      <div className={Styles['Label']}>{label}</div>
      <VoiceSettingsSlider
        step="0.01"
        defaultValue={value}
        onChange={onChangeHandler}
        {...voiceSettings}
      />
      {descriptions?.length && (
        <div className={Styles['Descriptions']}>
          {descriptions.map((description) => (
            <Description key={description.label} {...description} />
          ))}
        </div>
      )}
    </div>
  );
};
