import React, { memo } from 'react';
import classNames from 'classnames';
import { Stack } from 'modules/common/components/Stack/Stack';
import Styles from '../search.module.scss';
import { SearchTOCItem } from '../type';
import { useScrollMenuItemEffect } from '../lib';

type FindResultItemProps = {
  searchMenuRef: React.RefObject<HTMLDivElement>;
  isFirstElementWasFound: React.MutableRefObject<boolean>;
  resultItem: SearchTOCItem;
  selectedItemId: string | null;
  onClick(item: SearchTOCItem): void;
  setIsFirstElementWasFound(value: boolean): void;
};

export const FindSearchItem: React.FC<FindResultItemProps> = memo(
  ({
    searchMenuRef,
    isFirstElementWasFound,
    resultItem,
    selectedItemId,
    onClick,
    setIsFirstElementWasFound,
  }) => {
    const menuItemRef = React.useRef<HTMLDivElement>(null);
    const selected = resultItem.id === selectedItemId;

    // The effect for scrolling to a selected item while navigating within the list
    useScrollMenuItemEffect({
      selected,
      menuRef: searchMenuRef,
      menuItemRef,
      isFirstElementWasFound,
      selectedItemId,
      setIsFirstElementWasFound,
    });

    return (
      <Stack
        className={classNames(Styles['result-item'], {
          [Styles['selected']]: selected,
        })}
        alignItems="center"
        justifyContent="space-between"
        onClick={() => onClick(resultItem)}
        ref={menuItemRef}
      >
        <Stack.Item>
          <span
            id={resultItem.id}
            dangerouslySetInnerHTML={{ __html: resultItem.text }}
          />
        </Stack.Item>
      </Stack>
    );
  }
);
