import { optimizeMerge } from './genericSSMLBlotFactory';
import { Quill } from 'react-quill';
const Inline = Quill.import('blots/inline');

export class SkipBlot extends Inline {
  static blotName = 'skip';
  static tagName = 'skip';
  static classNameInternal = 'rb-skip-tag';
  static allowedTags = [];

  static create(value: string) {
    let node = super.create();
    if (value) {
      node.setAttribute('class', this.classNameInternal);
      node.setAttribute('ssml-blot-name', this.blotName);
      let beforeText = `<${SkipBlot.tagName.toLowerCase()}>`;
      let afterText = `</${SkipBlot.tagName.toLowerCase()}>`;
      node.setAttribute('data-before-text', beforeText);
      node.setAttribute('data-after-text', afterText);
    }
    return node;
  }

  optimize(): void {
    // Don't call with super otherwise
    // the order will be implemented and your optimize will be lost

    let formats = this.formats();

    if (Object.keys(formats).length === 0) {
      return this.unwrap(); // un-formatted span
    }

    // this.domNode.parentNode maybe use this if you fell lag because of the API

    // to not optimize un-shown deleted Refs
    if (this.domNode && document.contains(this.domNode)) {
      optimizeMerge.call(this);
      return;
    }
  }
}
