import { Icon } from 'icons';
import { Stack } from 'modules/common/components/Stack/Stack';
import { useAppSelector } from 'modules/common/hooks/redux';
import { BookmarkModel } from 'modules/common/models/Bookmark';
import { FC, Fragment, useMemo } from 'react';
import {
  bookMarksIsFetchingSelector,
  selectAll,
} from 'redux/reducers/bookmarks/selectors';
import { activeChapterSelector } from 'redux/reducers/chapters/selectors';
import { BookmarkItem } from './BookmarkItem';

interface ChapterBookmarksProps {
  onDelete: (bookmarkId: string) => void;
  onEdit: (bookmarkId: string, value: BookmarkModel) => void;
  onClick: (bookmarkId: string) => void;
}

export const ChapterBookmarks: FC<ChapterBookmarksProps> = ({
  onClick,
  onDelete,
  onEdit,
}) => {
  const bookmarks = useAppSelector(selectAll);
  const isBookmarkFetching = useAppSelector(bookMarksIsFetchingSelector);
  const chapter = useAppSelector(activeChapterSelector);

  const chapterBookmarks = useMemo(() => {
    return bookmarks.filter((b) => b.chapter === chapter?.id);
  }, [bookmarks, chapter?.id]);

  return (
    <Fragment>
      {!isBookmarkFetching && !chapterBookmarks.length && (
        <Stack className="h-100" alignItems="center" justifyContent="center">
          <Stack.Item shrink={0}>
            <div className="ta-center">
              <Icon name="bookmarks" size={40} className="opacity-30" />
              <p className="m-t-2 fw-600 font-size-sm opacity-50">
                List of bookmarks is empty
              </p>
            </div>
          </Stack.Item>
        </Stack>
      )}
      {chapterBookmarks.map((bookmark) => {
        return (
          <BookmarkItem
            key={bookmark.id}
            bookmark={bookmark}
            onDelete={onDelete}
            onEdit={onEdit}
            onClick={onClick}
          />
        );
      })}
    </Fragment>
  );
};
