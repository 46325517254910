import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnalyticsEvent, useTrackEvent } from 'services/amplitude';
import classNames from 'classnames';

import { Stack } from 'modules/common/components/Stack/Stack';
import { Button } from 'modules/common/components/Button/Button';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import {
  useGetIsFeatureEnabledQuery,
  useGetVoicesQuery,
  useInvalidateProjectsList,
} from 'redux/api/app.api';
import {
  fetchProject,
  setProjectInactive,
} from 'redux/reducers/project/projectSlice';
import {
  activeProjectSelector,
  activeProjectVoiceSettingsSelector,
  isProjectLoadingSelector,
} from 'redux/reducers/project/selectors';
import Loader from 'modules/common/components/Loader/Loader';
import { ProjectModel } from 'modules/common/models/Project';
import useAppParams from 'modules/common/hooks/useAppParams';
import {
  ProjectApiInstance as ProjectApiInstanceV1,
  SplitBookStatus,
} from 'modules/edit/api/Project';
import { ProjectApiInstance as ProjectApiInstanceV2 } from 'modules/edit/v2/api/Project';
import {
  error,
  SomethingWentWrong,
  success,
} from 'modules/common/components/Notify';
import useLoading from 'modules/common/hooks/useLoading';
import { Page } from 'modules/common/components/Page/Page';
import HeaderWithLogo from 'modules/common/components/HeaderWithLogo/HeaderWithLogo';
import { AudioPlayerProject } from 'modules/edit/AudioPlayer/AudioPlayerProject';
import { useAudioPlayerProject } from 'modules/common/hooks/useAudioPlayerProject';
import { useUserEmail } from 'modules/common/hooks/useUserEmail';
import { ELEVENLABS_DEFAULT_VOICE_PREVIEW_TEXT } from './Information/lib';
import { Information } from './Information';
import { KeyedResultForm, ProjectCreationFormSteps, ResultForm } from './types';
import { DEFAULT_RESULT_FORM_DATA } from './lib';
import Styles from './project.module.scss';
import TOCPopup from './TOCPopup';

export enum FormUpdateState {
  Key = 'Key',
  Reviewer = 'Reviewer',
}

export enum ErrorTypes {
  FileDataError = 'FileDataError',
}

export type formUpdateFunType = (
  type: FormUpdateState,
  obj: {
    id?: string;
    name: KeyedResultForm;
    value: string | Record<any, any>;
    delete?: boolean;
  }
) => void;

/**
 * This was supposed to be a temporary page , but all of a sudden it became part of the projects
 *  And it used to have a reviewer part , if they want it in the future add the component
 * and uncomment the reviewer part in this code it should work
 * that is why validation and everything else is different than the others
 * */
export const Project: FC = () => {
  const { id } = useAppParams<{ id: string }>();
  const userEmail = useUserEmail();
  const project = useAppSelector(activeProjectSelector);
  const projectVoiceSettings = useAppSelector(
    activeProjectVoiceSettingsSelector
  );
  const invalidateProjectsList = useInvalidateProjectsList();
  const [projectCreationFormStep, setProjectCreationFormStep] =
    useState<ProjectCreationFormSteps>('default');
  const [resultFormData, setResultFormData] = useState<ResultForm | null>(null);
  const dispatch = useAppDispatch();
  const { isLoading: isRequestPending, setLoading } = useLoading();
  const [newProjectWithoutTOCId, setNewProjectWithoutTOCId] =
    useState<string>();
  const trackEvent = useTrackEvent();
  const { data } = useGetVoicesQuery();

  const isProjectLoading = useAppSelector(isProjectLoadingSelector);
  const [file, setFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);
  const [failedProject, setFailedProjectInfo] = useState<string | null>(null);
  const [previewText, setPreviewText] = useState<string>(
    ELEVENLABS_DEFAULT_VOICE_PREVIEW_TEXT
  );
  const { playerProps } = useAudioPlayerProject({
    formData: resultFormData,
    previewText,
  });

  const isFormOnTheElevenLabsVoiceSettingsStep =
    projectCreationFormStep === 'elevenLabsVoiceSetting';

  const { data: isUseCachingEnabled } = useGetIsFeatureEnabledQuery(
    {
      featureName: 'use-caching',
      context: {
        tts_engine: resultFormData
          ? resultFormData[KeyedResultForm.ttsEngine]
          : '',
        user_email: userEmail,
      },
    },
    { skip: !resultFormData?.[KeyedResultForm.ttsEngine] || !userEmail }
  );

  const ProjectApiInstance = !!isUseCachingEnabled
    ? ProjectApiInstanceV2
    : ProjectApiInstanceV1;

  const runningLoop = (res: ProjectModel) => {
    intervalId.current = setInterval(async () => {
      const { processingStatus, logException } =
        await ProjectApiInstance.getStatusOfSplitBooks(res.id);
      if (processingStatus !== SplitBookStatus.RUNNING) {
        if (intervalId.current) {
          clearInterval(intervalId.current);
        }

        if (processingStatus === SplitBookStatus.FAILED) {
          // to get edited
          setLoading(false);
          setFailedProjectInfo(res.id);
          logException.includes(ErrorTypes.FileDataError)
            ? setErrorMessage(logException)
            : error({
                children: logException
                  ? logException
                  : 'Something went wrong please check your uploaded book',
              });
        }

        if (processingStatus === SplitBookStatus.COMPLETED) {
          success({ children: 'Project is added Successfully' });
          dispatch(setProjectInactive());
          navigate(`/dashboard`);
        }
      }
    }, 5000);
  };

  const createProject = async () => {
    if (!file || !resultFormData) {
      if (!file) {
        error({
          children: 'File is Required',
        });
      }
      return;
    }

    setLoading(true);
    try {
      let res = await ProjectApiInstance.createProject(resultFormData, file);
      const bookName = resultFormData[KeyedResultForm.name];
      const voice = resultFormData[KeyedResultForm.voice];
      trackEvent({
        eventName: AnalyticsEvent.CreateProject,
        customProperties: {
          bookName,
          voice,
        },
      });
      if (!res.tocReady) {
        setNewProjectWithoutTOCId(res.id);
      } else {
        runningLoop(res);
      }
    } catch (e) {
      SomethingWentWrong();
      setLoading(false);
    }
  };

  const editProjectHandler = async () => {
    if (!resultFormData || !project) return;

    setLoading(true);
    try {
      await ProjectApiInstance.editProject(
        project.id,
        {
          ...resultFormData,
        },
        project.fileType
      );

      dispatch(setProjectInactive());
      navigate(`/dashboard`);
    } catch (e) {
      SomethingWentWrong();
    } finally {
      setLoading(false);
    }
  };

  const editFailedProject = async () => {
    if (!file || !resultFormData || !failedProject) {
      if (!file) {
        error({
          children: 'File is Required',
        });
      }
      return;
    }

    setLoading(true);
    try {
      let res = await ProjectApiInstance.editProjectFile(
        failedProject,
        resultFormData,
        file
      );
      runningLoop(res);
    } catch (e) {
      SomethingWentWrong();
      setLoading(false);
    }
  };

  const onFormUpdate: formUpdateFunType = useCallback(
    (type: FormUpdateState, obj) => {
      setResultFormData((prev) => {
        if (prev === null) {
          return DEFAULT_RESULT_FORM_DATA;
        }
        if (FormUpdateState.Key === type && obj.name) {
          return {
            ...(prev || {}),
            [obj.name]: obj.value,
          };
        }
        return DEFAULT_RESULT_FORM_DATA;
      });
    },
    []
  );

  const createProjectHandler = () => {
    if (
      !isFormOnTheElevenLabsVoiceSettingsStep &&
      resultFormData?.ttsEngine === 'elevenlabs'
    ) {
      onFormUpdate(FormUpdateState.Key, {
        name: KeyedResultForm.elevenLabsVoiceSettings,
        value: data?.defaultVoiceSettings?.elevenlabs || {},
      });
      return setProjectCreationFormStep('elevenLabsVoiceSetting');
    }
    return createProject();
  };

  const onFormSubmit = async () => {
    if (failedProject) {
      return editFailedProject();
    }

    if (id) {
      return editProjectHandler();
    }

    return createProjectHandler();
  };

  const onFileUpload = (file: any) => {
    setErrorMessage('');
    setFile(file);
  };

  useEffect(() => {
    // fetch project if id from url is present and not equal to the project id in the store
    if (id && id !== project?.id) {
      dispatch(fetchProject(id))
        .unwrap()
        .then(function (res) {
          if (!res) {
            navigate('/');
          }
        });
    }
  }, [id, project, dispatch, navigate]);

  useEffect(() => {
    if (!id) {
      dispatch(setProjectInactive());
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (project && projectVoiceSettings) {
      setResultFormData({
        name: project.name,
        ttsEngine: project.ttsEngine,
        voice: projectVoiceSettings.voice!,
        elevenLabsModel: project.elevenLabsModel,
        reviewers: project.reviewers.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.userId]: curr,
          };
        }, {}),
      });
    }
    return () => {
      setResultFormData(null);
    };
  }, [project, projectVoiceSettings]);

  const getSubmitButtonText = () => {
    if (!!id) return 'Edit Project';

    if (
      resultFormData?.ttsEngine === 'elevenlabs' &&
      !isFormOnTheElevenLabsVoiceSettingsStep
    ) {
      return 'Continue';
    }

    return 'Create project';
  };

  const setDefaultForm = () => {
    setProjectCreationFormStep('default');
    setResultFormData(DEFAULT_RESULT_FORM_DATA);
  };

  const openNewProjectWithoutTOC = () => {
    if (!newProjectWithoutTOCId) return;
    setNewProjectWithoutTOCId(undefined);
    navigate(`/editor/${newProjectWithoutTOCId}`);
  };

  const closeModalAndGoBackToTheDashboard = () => {
    if (!newProjectWithoutTOCId) return;
    setNewProjectWithoutTOCId(undefined);
    invalidateProjectsList();
    navigate(`/dashboard`);
  };

  return (
    <>
      <TOCPopup
        isOpen={!!newProjectWithoutTOCId}
        onClose={closeModalAndGoBackToTheDashboard}
        onCreateTOC={openNewProjectWithoutTOC}
      />
      <Page
        header={<HeaderWithLogo showBackButton />}
        footer={
          isFormOnTheElevenLabsVoiceSettingsStep ? (
            <AudioPlayerProject {...playerProps} />
          ) : undefined
        }
        classNameContent={classNames(
          Styles['project-content'],
          'color-bg-gray'
        )}
        classNameFooter={classNames(Styles['project-footer'])}
        hideScroll
      >
        <Stack
          className={classNames(Styles['create-page'], 'w-100')}
          justifyContent="center"
        >
          <Stack.Item className={Styles['elements']}>
            <div className={Styles['main-header']}>
              {isFormOnTheElevenLabsVoiceSettingsStep && (
                <Button
                  icon="arrow_backward_long"
                  className={Styles['main-header-back-button']}
                  iconSize={24}
                  inline
                  onClick={setDefaultForm}
                />
              )}
              <div className={Styles['main-header-title']}>
                {id ? 'Edit project' : 'Create project'}
              </div>
            </div>
            {isProjectLoading && id ? (
              <Stack justifyContent="center" alignItems="center">
                <Loader
                  loadingPartColor="#031E50"
                  loadingBackSet="#E5E5E5"
                  size="75px"
                  loaderWidth="5px"
                />
              </Stack>
            ) : (
              <>
                <Information
                  errorMessage={errorMessage}
                  formData={resultFormData}
                  projectCreationFormStep={projectCreationFormStep}
                  onFormChange={onFormUpdate}
                  onFileUpload={onFileUpload}
                  project={project}
                  projectVoiceSettings={projectVoiceSettings}
                  playerProps={playerProps}
                  previewText={previewText}
                  setPreviewText={setPreviewText}
                />
                <Stack
                  className={Styles['buttons']}
                  justifyContent="center"
                  alignItems="center"
                  spacing="2"
                >
                  <Button
                    loading={isRequestPending}
                    onClick={onFormSubmit}
                    large
                    disabled={isRequestPending || !!errorMessage.trim()}
                  >
                    {getSubmitButtonText()}
                  </Button>
                </Stack>
              </>
            )}
          </Stack.Item>
        </Stack>
      </Page>
    </>
  );
};
