import {
  FC,
  useCallback,
  useState,
  KeyboardEvent,
  useEffect,
  useMemo,
} from 'react';
import { v4 as uuid } from 'uuid';
import { findIndices } from 'utils/find-indices';
import { Checkbox } from 'modules/common/components/Checkbox/Checkbox';
import { Divider } from 'modules/common/components/Divider/Divider';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Input } from 'modules/common/components/Input/Input';
import { Icon } from 'icons';
import {
  FoundTextBlot,
  FoundTextCurrentBlot,
} from 'modules/edit/v2/Editor/Blots/FoundTextBlot';

import Styles from 'modules/edit/FindAndApply/find-and-apply.module.scss';
import { Button } from 'modules/common/components/Button/Button';
import { useAppSelector } from 'modules/common/hooks/redux';
import { selectById } from 'redux/reducers/presets/selectors';
import { SelectValue } from 'modules/common/types';
import { PresetBlot } from 'modules/edit/v2/Editor/Blots/PresetBlot';
import { isScrolledIntoView } from 'utils';
import { FindResultItemModel } from 'modules/common/models/FindResult';
import FindResultItem from 'modules/edit/FindAndApply/FindResultItem';
import useQuill from 'modules/common/hooks/useQuill';
import { selectEntities } from 'redux/reducers/tags/selectors';
import useCheckboxes from 'modules/common/hooks/useCheckboxes';
import QuillUtil from 'utils/QuillUtil';
import classNames from 'classnames';
import { Alert } from 'modules/common/components/Alert/Alert';
import { PresetsView } from 'modules/edit/FindAndApply/components/PresetsView';
import { useTrackEvent, AnalyticsEvent } from 'services/amplitude';
import { Preset } from 'modules/edit/v2/Presets/Preset';
import { warning } from 'modules/common/components/Notify';
import { isPartOfASkippedSentence } from '../Tags/lib';

enum FindAndApplyTabs {
  RESULT = 'RESULT',
  DISABLED_RESULTS = 'DISABLED_RESULTS',
  PRESETS_VIEW = 'PRESETS_VIEW',
}

export const FindAndApply: FC = () => {
  const trackEvent = useTrackEvent();
  const [tab, setTab] = useState<FindAndApplyTabs>(FindAndApplyTabs.RESULT);
  const [find, setFind] = useState('');
  const [findResult, setFindResult] = useState<FindResultItemModel[]>([]);
  const [currentSearchPhrase, setCurrentSearchPhrase] = useState('');
  const [currentResult, setCurrentResult] = useState(0);
  const quillInstance = useQuill();
  const editor = quillInstance?.getEditor();

  const { appliedResults, disabledResults } = useMemo(() => {
    const appliedResults: FindResultItemModel[] = [];
    const disabledResults: FindResultItemModel[] = [];

    if (!editor) return { appliedResults, disabledResults };

    findResult.forEach((item) => {
      const inlines = QuillUtil.getInlineWithoutFounds(editor, {
        index: item.index,
        length: currentSearchPhrase.length,
      });
      const isDifferentTagPresent = inlines.some(
        (p) => p.domNode.dataset.id !== item.id
      );

      if (
        !isDifferentTagPresent &&
        !isPartOfASkippedSentence(editor, {
          index: item.index,
          length: currentSearchPhrase.length,
        })
      ) {
        appliedResults.push(item);
      } else {
        disabledResults.push(item);
      }
    });
    return { appliedResults, disabledResults };
  }, [findResult, currentSearchPhrase, editor]);

  const showWarningTab =
    disabledResults.length > 0 && tab === FindAndApplyTabs.DISABLED_RESULTS;
  const {
    allCheckbox,
    checkedCheckboxes: selectedResultItems,
    setAllCheckboxes,
    unSetAllCheckboxes,
    setCheckboxes,
    unSetCheckBoxes,
  } = useCheckboxes<number>([], findResult.length);
  const tagEntities = useAppSelector(selectEntities);

  const [presetsSelectValue, setPresetsSelectValue] =
    useState<SelectValue>(null);

  const preset = useAppSelector((state) =>
    selectById(state, presetsSelectValue?.value!)
  );

  const handlePresetsSelectChange = useCallback((value: SelectValue) => {
    setPresetsSelectValue(value);
    setTab(FindAndApplyTabs.RESULT);
  }, []);

  const removeStyle = useCallback(() => {
    QuillUtil.removeFindAndApplyStylesV2(quillInstance);
  }, [quillInstance]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      // This one needs to prevent the start/pause payback functionality.
      e.stopPropagation();
      if (e.key === 'Enter') {
        removeStyle();
        unSetAllCheckboxes();
        if (find.length) {
          setCurrentSearchPhrase(find);
          trackEvent({
            eventName: AnalyticsEvent.FindAndApply,
            customProperties: {
              searchText: find,
            },
          });
          const editor = quillInstance!.getEditor();
          let totalText = editor.getText();
          let regexp = new RegExp(find, 'gi');
          const indices = findIndices(regexp, totalText);
          let max = 35;
          if (find.length > 5) {
            max = 23;
          }
          const findRes = indices.map((index) => {
            const diff = Math.round((max - find.length) / 2);
            let start = index - diff;
            if (start < 0) {
              start = 0;
            }
            let end = index + find.length + diff;
            if (end > totalText.length) {
              end = totalText.length - 1;
            }
            let prevText = start > 0 ? '...' : '';
            prevText += editor.getText(start, start === 0 ? index : diff);

            let nextText = editor.getText(index + find.length, diff);
            nextText += end < totalText.length - 1 ? '...' : '';

            const textToShow = `${prevText}<strong class="found-item">${editor.getText(
              index,
              find.length
            )}</strong>${nextText}`;

            return {
              index,
              id: uuid(),
              text: textToShow,
            };
          });

          setFindResult(findRes);
          const _findRes = findRes.filter((item) => {
            return !QuillUtil.getInlineWithoutSentences(editor, {
              index: item.index,
              length: find.length,
            }).some((p) => {
              return p.domNode.dataset.id !== item.id;
            });
          });
          if (_findRes.length) {
            const [first, ...rest] = _findRes;
            editor.formatText(
              first.index,
              find.length,
              FoundTextCurrentBlot.blotName,
              first.id
            );
            rest.forEach((item) => {
              editor.formatText(
                item.index,
                find.length,
                FoundTextBlot.blotName,
                item.id
              );
            });
            const foundEl = document.querySelector(
              `${FoundTextCurrentBlot.tagName}[${FoundTextCurrentBlot.dataAttrName}="${first.id}"]`
            )! as HTMLElement;
            const scrollEl = editor.scroll.domNode as HTMLDivElement;
            if (!isScrolledIntoView(foundEl, scrollEl)) {
              foundEl.focus({ preventScroll: false });
            }
          }
        } else {
          setCurrentSearchPhrase('');
          setFindResult([]);
        }
        setCurrentResult(0);
      }
    },
    [find, quillInstance, removeStyle, trackEvent, unSetAllCheckboxes]
  );

  const handleResultItemSelect = (index: number, checked: boolean) => {
    if (checked) {
      setCheckboxes(index);
    } else {
      unSetCheckBoxes(index);
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setAllCheckboxes(appliedResults.map((item) => item.index));
    } else {
      unSetAllCheckboxes();
    }
  };

  const toggleShow = () => {
    setTab((prev) =>
      prev === FindAndApplyTabs.DISABLED_RESULTS
        ? FindAndApplyTabs.RESULT
        : FindAndApplyTabs.DISABLED_RESULTS
    );
  };

  const highlightCurrent = useCallback(
    (prev: number, current: number) => {
      const editor = quillInstance!.getEditor();
      editor.formatText(
        findResult[prev].index,
        find.length,
        FoundTextCurrentBlot.blotName,
        false
      );
      editor.formatText(
        findResult[prev].index,
        find.length,
        FoundTextBlot.blotName,
        true
      );
      editor.formatText(
        findResult[current].index,
        find.length,
        FoundTextBlot.blotName,
        false
      );
      editor.formatText(
        findResult[current].index,
        find.length,
        FoundTextCurrentBlot.blotName,
        findResult[current].id
      );
      const foundEl = document.querySelector(
        `${FoundTextCurrentBlot.tagName}[${FoundTextCurrentBlot.dataAttrName}="${findResult[current].id}"]`
      )! as HTMLElement;
      const scrollEl = editor.scroll.domNode as HTMLDivElement;
      if (!isScrolledIntoView(foundEl, scrollEl)) {
        foundEl.focus({ preventScroll: false });
      }
    },
    [find.length, findResult, quillInstance]
  );

  const incrementCurrent = useCallback(() => {
    let newCurrent = currentResult + 1;
    if (newCurrent > findResult.length - 1) {
      newCurrent = 0;
    }
    setCurrentResult(newCurrent);
    highlightCurrent(currentResult, newCurrent);
  }, [currentResult, findResult.length, highlightCurrent]);

  const decrementCurrent = useCallback(() => {
    let newCurrent = currentResult - 1;
    if (newCurrent < 0) {
      newCurrent = findResult.length - 1;
    }
    setCurrentResult(newCurrent);
    highlightCurrent(currentResult, newCurrent);
  }, [currentResult, findResult.length, highlightCurrent]);

  const handleApply = useCallback(() => {
    if (preset) {
      const isSelectedPresetContainsPause = !!preset.tags.find(
        (tag) => tag.tagName === 'customPause'
      );
      const quill = quillInstance!.getEditor();
      let selectedResultsToApply = appliedResults.filter((r) =>
        selectedResultItems.has(r.index)
      );
      // If selected preset includes pause tag, make sure found results are at the end of words.
      if (isSelectedPresetContainsPause) {
        selectedResultsToApply = selectedResultsToApply
          .filter((item) =>
            item.text.toLowerCase().includes(`${find.toLowerCase()}</strong> `)
          )
          .map((item) => ({
            ...item,
            index: item.index + find.length,
          }));
        // If the number of selected items is different from the number of items to be applied.
        if (selectedResultsToApply.length !== selectedResultItems.size) {
          warning({
            children:
              'Please pay attention that not all of the changes were applied as the Pause Wrap tag cannot be applied to the text!',
          });
        }
      }
      const lengthToFormat = isSelectedPresetContainsPause ? 1 : find.length;
      selectedResultsToApply
        .map((r) => r.index)
        .forEach((index) => {
          quill.formatText(
            index,
            lengthToFormat,
            FoundTextBlot.blotName,
            false
          );
          quill.formatText(
            index,
            lengthToFormat,
            FoundTextCurrentBlot.blotName,
            false
          );
          quill.formatText(
            index,
            lengthToFormat,
            PresetBlot.blotName,
            false,
            'user'
          );

          const presetToApply = { ...preset };
          presetToApply.tags = preset.tags.map((t) => {
            const tagStored = tagEntities[t.id];
            let tag = { ...t };
            tag.attributes = tag.attributes.map((a) => {
              let attr = { ...a };
              attr.name = tagStored?.attributes.find(
                (a) => a.id === attr.id
              )?.name;
              return attr;
            });

            tag.name = tagStored?.name;
            return tag;
          });
          quill.formatText(
            index,
            lengthToFormat,
            PresetBlot.blotName,
            JSON.stringify(presetToApply),
            'user'
          );
        });
      unSetAllCheckboxes();
    }
  }, [
    preset,
    quillInstance,
    appliedResults,
    find,
    unSetAllCheckboxes,
    selectedResultItems,
    tagEntities,
  ]);
  const showPresets = () => {
    setTab(FindAndApplyTabs.PRESETS_VIEW);
  };
  const handleBack = () => {
    setTab(FindAndApplyTabs.RESULT);
  };
  useEffect(() => {
    return () => {
      removeStyle();
    };
  }, [removeStyle]);

  return (
    <>
      {tab !== FindAndApplyTabs.RESULT && (
        <Icon
          name="arrow_upward"
          className={Styles.arrow}
          onClick={handleBack}
        />
      )}
      {tab === FindAndApplyTabs.RESULT && (
        <>
          <div className="p-3">
            <p className="m-b-1 font-size-sm fw-600">Find Text</p>
            <div className={Styles['find-input-wrapper']}>
              <Input
                type="text"
                value={find}
                onChangeWithValueHandler={setFind}
                onKeyDown={handleKeyDown}
                placeholder="Enter text"
                className={Styles['find-input']}
              />
              <Stack className={Styles['arrows']} alignItems="center">
                {!!findResult.length && (
                  <Stack.Item>
                    {currentResult + 1} of {findResult.length}
                  </Stack.Item>
                )}
                <Stack.Item>
                  <Icon
                    disabled={findResult.length <= 1}
                    color="#031E50"
                    onClick={incrementCurrent}
                    name="arrow_drop_down"
                  />
                  <Icon
                    disabled={findResult.length <= 1}
                    color="#031E50"
                    onClick={decrementCurrent}
                    name="arrow_drop_up"
                  />
                </Stack.Item>
              </Stack>
            </div>
          </div>
        </>
      )}
      {!!findResult.length &&
        (tab === FindAndApplyTabs.RESULT ||
          tab === FindAndApplyTabs.DISABLED_RESULTS) && (
          <>
            {showWarningTab && (
              <Stack
                className="p-y-2 p-x-3"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack.Item>
                  <p className="fw-600 font-size-sm">
                    {disabledResults.length} results cannot be processed
                  </p>
                </Stack.Item>
              </Stack>
            )}
            <div className={Styles.resultsContainer}>
              {!showWarningTab && (
                <Stack
                  className="p-y-2 p-x-3"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack.Item>
                    <p className="fw-600 font-size-sm">Results</p>
                  </Stack.Item>
                  {!showWarningTab && (
                    <Stack.Item>
                      <Checkbox
                        iconName="done_all"
                        onChange={handleSelectAll}
                        checked={!!allCheckbox}
                      />
                    </Stack.Item>
                  )}
                </Stack>
              )}
              <Divider />
              <div className={Styles.wrapper}>
                <div className="p-x-3 ">
                  {(tab === FindAndApplyTabs.DISABLED_RESULTS
                    ? disabledResults
                    : appliedResults
                  ).map((resultItem) => {
                    return (
                      <FindResultItem
                        showDisabledResults={
                          tab === FindAndApplyTabs.DISABLED_RESULTS
                        }
                        key={resultItem?.index}
                        resultItem={resultItem}
                        selected={selectedResultItems.has(resultItem?.index)}
                        onSelect={handleResultItemSelect}
                      />
                    );
                  })}
                </div>
              </div>
              <div></div>
            </div>

            <Divider />
            {disabledResults.length > 0 && (
              <Alert
                icon={
                  tab === FindAndApplyTabs.DISABLED_RESULTS ? 'info' : undefined
                }
                className={classNames(Styles.findAndApplyInfo, {
                  [Styles.foundedResultsDanger]: !(
                    tab === FindAndApplyTabs.DISABLED_RESULTS
                  ),
                  [Styles.foundedResultsWarning]:
                    tab === FindAndApplyTabs.DISABLED_RESULTS,
                })}
                type={
                  tab === FindAndApplyTabs.DISABLED_RESULTS
                    ? 'warning'
                    : 'error'
                }
              >
                <p>
                  {tab === FindAndApplyTabs.DISABLED_RESULTS ? (
                    <>
                      You can’t apply a preset if the selected fragment already
                      has a preset
                    </>
                  ) : (
                    <>{disabledResults.length} results cannot be processed</>
                  )}
                </p>
                {!(tab === FindAndApplyTabs.DISABLED_RESULTS) && (
                  <span onClick={toggleShow} className={Styles.showBtn}>
                    Show
                  </span>
                )}
              </Alert>
            )}

            <div className="p-x-3 m-t-4">
              <p className="fw-600 font-size-sm m-b-1">Apply preset</p>
              {presetsSelectValue?.value && preset ? (
                <Preset
                  preset={preset}
                  isSelectable={false}
                  onSelect={showPresets}
                />
              ) : (
                <Button primary={false} className={Styles.selectPresetBtn}>
                  <div
                    className={Styles.selectPresetBtnContainer}
                    onClick={showPresets}
                  >
                    <span className={Styles.label}>Select Preset</span>
                    <Icon name={'arrow_right'} />
                  </div>
                </Button>
              )}

              <Button
                onClick={handleApply}
                primary
                full
                disabled={!selectedResultItems.size}
                className="m-t-2 m-b-4"
              >
                Apply to {selectedResultItems.size} found result
              </Button>
            </div>
          </>
        )}

      {tab === FindAndApplyTabs.PRESETS_VIEW && (
        <PresetsView
          title={'Select preset'}
          handleSelectPreset={handlePresetsSelectChange}
        />
      )}
    </>
  );
};
