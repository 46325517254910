import { RangeStatic } from 'quill';
import ReactQuill, { Quill } from 'react-quill';
import { BookmarkIconBlot } from 'modules/edit/Editor/Blots/BookmarkIconBlot';
import { BookmarkIconBlot as BookmarkIconBlotV2 } from 'modules/edit/v2/Editor/Blots/BookmarkIconBlot';
import { PresetBlot } from 'modules/edit/Editor/Blots/PresetBlot';
import { PresetBlot as PresetBlotV2 } from 'modules/edit/v2/Editor/Blots/PresetBlot';
import { RBBlot } from 'modules/edit/Editor/Blots/RBBlot';
import { RBBlot as RBBlotV2 } from 'modules/edit/v2/Editor/Blots/RBBlot';
import { Dict } from 'modules/common/types';
import { CommentBlot } from 'modules/edit/Editor/Blots/CommentBlot';
import { CommentBlot as CommentBlotV2 } from 'modules/edit/v2/Editor/Blots/CommentBlot';
import { SentenceBlot } from 'modules/edit/v2/Editor/Blots/SentenceBlot';
import {
  FoundTextBlot,
  FoundTextCurrentBlot,
} from 'modules/edit/Editor/Blots/FoundTextBlot';
import {
  FoundTextBlot as FoundTextBlotV2,
  FoundTextCurrentBlot as FoundTextCurrentBlotV2,
} from 'modules/edit/v2/Editor/Blots/FoundTextBlot';
import { SkipBlot } from 'modules/edit/v2/Editor/Blots/SkipBlot';
import { ChapterIconBlot } from 'modules/edit/Editor/Blots/ChapterIconBlot';
import { ChapterIconBlot as ChapterIconBlotV2 } from 'modules/edit/v2/Editor/Blots/ChapterIconBlot';
import {
  FoundTextTOCBlot,
  FoundTextTOCCurrentBlot,
} from 'modules/edit/Editor/Blots/FoundTOCTextBlot';

const Inline = Quill.import('blots/inline');
const TextBlot = Quill.import('blots/text');

export type genericInline = any;

function parseCSSText(cssText: string) {
  const cssTxt = cssText.replace(/\/\*(.|\s)*?\*\//g, ' ').replace(/\s+/g, ' ');
  const style: Record<string, string> = {},
    [, ruleName, rule] = cssTxt.match(/ ?(.*?) ?{([^}]*)}/) || [
      undefined,
      undefined,
      cssTxt,
    ];
  const properties =
    rule?.split(';').map((o) => o.split(':').map((x) => x && x.trim())) || [];
  for (const [property, value] of properties) style[property] = value;
  return { cssText, ruleName, style };
}

export class QuillUtil {
  // maybe in the future check the attribute as well for checking the same blot and the same attribute
  static areTagsSSMLEqual(next?: HTMLElement, prev?: HTMLElement) {
    try {
      if (
        typeof next === 'undefined' ||
        typeof prev === 'undefined' ||
        !prev.getAttribute('ssml-blot-name') ||
        !next.getAttribute('ssml-blot-name')
      ) {
        return false;
      }
    } catch (e) {
      return false;
    }

    return this.isTagNameEqual(
      next.getAttribute('ssml-blot-name'),
      prev.getAttribute('ssml-blot-name')
    );
  }

  static isTagNameEqual(
    tagName?: string | null,
    anotherTagName?: string | null
  ): boolean {
    return (
      !!tagName &&
      !!anotherTagName &&
      tagName.toLowerCase() === anotherTagName.toLowerCase()
    );
  }

  static getValueFromTag(tag: HTMLElement): string {
    const name = tag.getAttribute('ssml-tag-name');
    const style = parseCSSText(tag.style.cssText).style;
    const aiGeneratedTag = tag.getAttribute('ai_generated_tag') ?? '';
    const dataId = tag.getAttribute('data-id') ?? '';
    const format: any = {
      name,
      blotId: tag.dataset.id,
      '--bg-color': style['--bg-color'],
      '--icon-color': style['--icon-color'],
      '--bd-color': style['--bd-color'],
      'data-id': dataId,
      attributeValues: JSON.parse(tag.dataset.selectedAttributes || '[]'),
      ssmlTagType: tag.getAttribute('ssml-tag-type'),
    };
    if (aiGeneratedTag) {
      format['ai_generated_tag'] = aiGeneratedTag;
    }
    return JSON.stringify(format);
  }

  static forEachRecursive(
    element: typeof Inline,
    callback: (child: typeof Inline) => void
  ) {
    element.forEach((child: typeof Inline) => {
      if (child.children) {
        QuillUtil.forEachRecursive(child.children, callback);
      }
      callback(child);
    });
  }

  static forEachSSML(
    element: typeof Inline,
    callback: (child: typeof Inline) => void
  ) {
    element.children.forEach((child: typeof Inline) => {
      if (this.isSSML(child)) {
        callback(child);
        if (child.children) {
          QuillUtil.forEachSSML(child, callback);
        }
      }
    });
  }

  static isTagWithTextChild(self: genericInline): boolean {
    return (
      self &&
      self.children &&
      self.children.length === 1 &&
      this.isTextBlot(self.children.tail)
    );
  }

  static isTextBlot(self: genericInline): boolean {
    return self && self.statics && self.statics.blotName === TextBlot.blotName;
  }

  static isSSML(self: genericInline): boolean {
    return self && !!self.statics.isSSML;
  }

  // optimized version do not work with children for some Parchment error thing
  static isSSMLTagEqual(self: genericInline, another: genericInline) {
    if (!this.isSSML(self) || !this.isSSML(another)) {
      return false;
    }

    if (!self.statics.blotName || !another.statics.blotName) {
      return false;
    }

    return self.statics.blotName === another.statics.blotName;
  }

  static getSuperParentSSMLTagContainer(self: genericInline): genericInline {
    if (!this.isSSML(self.parent)) {
      return self;
    }
    return this.getSuperParentSSMLTagContainer(self.parent);
  }

  static haveSingleChild(self: genericInline): boolean {
    return self?.children?.length === 1;
  }

  static swapWithChild(self: genericInline): any {
    if (!this.isSSML(self) && !this.haveSingleChild(self)) {
      return self;
    }
    let child = self.children.tail;
    const value = this.getValueFromTag(child.domNode);
    const blotName = child.statics.blotName;
    child.unwrap();
    return self.wrap(blotName, value);
  }

  static isChildOf(parent: genericInline, child: genericInline): boolean {
    let isChild = false;
    parent.children.forEach((myChild: any) => {
      if (child === myChild) {
        isChild = true;
        return isChild;
      }
    });
    return isChild;
  }

  static isUpperTagWithTagChild(self: genericInline) {
    return (
      self &&
      self.parent &&
      !this.isSSML(self.parent) &&
      this.haveSingleChild(self) &&
      this.isSSML(self.children.tail)
    );
  }

  // this works for single child embeddings only
  static isFullyEqual(self: genericInline, another: genericInline): boolean {
    // short circuit stuff
    if (!self || !another || !self.children || !another.children) {
      return false;
    }

    if (self.children.length !== another.children.length) {
      return false;
    }

    let currentSelf = self.children.tail;
    let anotherSelf = another.children.tail;
    while (this.isSSML(currentSelf) || this.isSSML(anotherSelf)) {
      if (!this.isSSMLTagEqual(currentSelf, anotherSelf)) {
        return false;
      }
      currentSelf = currentSelf?.children?.tail;
      anotherSelf = anotherSelf?.children?.tail;
    }

    return true;
  }

  // this works for single child embeddings only
  static getInnerMostText(self: genericInline): genericInline {
    let textElement: any;
    this.forEachRecursive(self.children, (child) => {
      if (this.isTextBlot(child)) {
        textElement = child;
      }
    });
    return textElement;
  }

  // this works for single child embeddings only
  static getInnerMostSSML(self: genericInline): genericInline {
    let innerMostSSML: genericInline;
    this.forEachSSML(self, (child) => {
      if (
        // enable this after merge children
        /*this.haveSingleChild(child) && */ this.isTextBlot(child.children.tail)
      ) {
        innerMostSSML = child;
      }
    });
    return innerMostSSML;
  }

  static getInlineChildren(
    quill: any,
    selection: RangeStatic
  ): typeof Inline[] {
    return (quill.scroll as any).scroll.descendants(
      Inline,
      selection.index,
      selection.length
    );
  }

  static getRBChildren(quill: any, selection: RangeStatic): typeof Inline[] {
    return (quill.scroll as any).scroll.descendants(
      RBBlot,
      selection.index,
      selection.length
    );
  }

  static getRBChildrenV2(quill: any, selection: RangeStatic): typeof Inline[] {
    return (quill.scroll as any).scroll.descendants(
      RBBlotV2,
      selection.index,
      selection.length
    );
  }

  static getInlineWithoutSentences(
    quill: any,
    selection: RangeStatic
  ): typeof Inline[] {
    const blots = quill.scroll.scroll.descendants(
      Inline,
      selection.index,
      selection.length
    );

    return blots.filter((blot: any) => {
      return blot.statics.blotName !== SentenceBlot.blotName;
    });
  }

  static getInlineWithoutFounds(
    quill: any,
    selection: RangeStatic
  ): typeof Inline[] {
    const blots = QuillUtil.getInlineWithoutSentences(quill, selection);

    return blots.filter((blot: any) => {
      return (
        blot.statics.blotName !== FoundTextBlotV2.blotName &&
        blot.statics.blotName !== FoundTextCurrentBlotV2.blotName
      );
    });
  }

  static getBookmarkChildren(
    quill: any,
    selection: RangeStatic
  ): typeof Inline[] {
    return (quill.scroll as any).scroll.descendants(
      BookmarkIconBlot,
      selection.index,
      selection.length
    );
  }

  static getBookmarkChildrenV2(
    quill: any,
    selection: RangeStatic
  ): typeof Inline[] {
    return (quill.scroll as any).scroll.descendants(
      BookmarkIconBlotV2,
      selection.index,
      selection.length
    );
  }
  static getChapterChildren(
    quill: any,
    selection: RangeStatic
  ): typeof Inline[] {
    return (quill.scroll as any).scroll.descendants(
      ChapterIconBlot,
      selection.index,
      selection.length
    );
  }

  static getChapterChildrenV2(
    quill: any,
    selection: RangeStatic
  ): typeof Inline[] {
    return (quill.scroll as any).scroll.descendants(
      ChapterIconBlotV2,
      selection.index,
      selection.length
    );
  }

  static getCommentChildren(
    quill: any,
    selection: RangeStatic
  ): typeof Inline[] {
    return (quill.scroll as any).scroll.descendants(
      CommentBlot,
      selection.index,
      selection.length
    );
  }

  static getCommentChildrenV2(
    quill: any,
    selection: RangeStatic
  ): typeof Inline[] {
    return (quill.scroll as any).scroll.descendants(
      CommentBlotV2,
      selection.index,
      selection.length
    );
  }

  static getNodeRange(quill: ReactQuill | null, node: Node) {
    const editor = quill?.editor;
    if (!editor) return;
    const presetBlot = Quill.find(node);
    return {
      index: presetBlot.offset(editor.scroll),
      length: presetBlot.length(),
    };
  }

  static getPresetChildren(
    quill: Required<ReactQuill>['editor'],
    selection: RangeStatic
  ) {
    return quill.scroll.scroll.descendants(
      PresetBlot,
      selection.index,
      selection.length
    );
  }

  static getPresetChildrenV2(
    quill: Required<ReactQuill>['editor'],
    selection: RangeStatic
  ) {
    return quill.scroll.scroll.descendants(
      PresetBlotV2,
      selection.index,
      selection.length
    );
  }

  static getSentenceChild(
    quill: Required<ReactQuill>['editor'],
    index: number
  ) {
    return quill.scroll.scroll.descendant(SentenceBlot, index);
  }

  static getSentenceChildren(
    quill: Required<ReactQuill>['editor'],
    selection: RangeStatic
  ) {
    return quill.scroll.scroll.descendants(
      SentenceBlot,
      selection.index,
      selection.length
    );
  }

  static getQuillValueOptimizedWithHistory(
    contents: string,
    history: Dict<string>
  ): string {
    let tempContainer = document.createElement('div');
    // Order is important

    // to make sure optimizations works
    tempContainer.style.display = 'none';
    document.body.appendChild(tempContainer);

    const tempQuill = new Quill(tempContainer);

    tempQuill.setContents(tempQuill.clipboard.convert(contents), 'api');

    // clear history after text setting
    tempQuill.history.clear();

    // apply history
    tempQuill.history.loadHistoryStackMemory(history);

    // optimize
    (tempQuill.scroll as any).optimize();

    const textToSend = tempQuill.root.innerHTML;

    tempContainer.remove();

    return textToSend;
  }

  static removeFindAndApplyStylesTOC(quill: ReactQuill | null) {
    const editor = quill?.editor;
    if (!editor) return;
    const length = editor.getText().length;
    editor.formatText(0, length, FoundTextTOCBlot.blotName, false);
    editor.formatText(0, length, FoundTextTOCCurrentBlot.blotName, false);
  }

  static removeFindAndApplyStyles(quill: ReactQuill | null) {
    const editor = quill?.editor;
    if (!editor) return;
    const length = editor.getText().length;
    editor.formatText(0, length, FoundTextBlot.blotName, false);
    editor.formatText(0, length, FoundTextCurrentBlot.blotName, false);
  }

  static removeFindAndApplyStylesV2(quill: ReactQuill | null) {
    const editor = quill?.editor;
    if (!editor) return;
    const length = editor.getText().length;
    editor.formatText(0, length, FoundTextBlotV2.blotName, false);
    editor.formatText(0, length, FoundTextCurrentBlotV2.blotName, false);
  }

  static isRangeEqual(range1: RangeStatic, range2: RangeStatic): boolean {
    return range1.index === range2.index && range1.length === range2.length;
  }

  static isFullyInRange(childRange: RangeStatic, parentRange: RangeStatic) {
    return (
      childRange.index >= parentRange.index &&
      childRange.index + childRange.length <=
        parentRange.index + parentRange.length
    );
  }

  static isMatchingSsmlPauseTagName(node: Element): boolean {
    const ssmlTagName = node.getAttribute('ssml-tag-name');
    return ssmlTagName === 'break' || ssmlTagName === 'customPause';
  }

  static isInsertingPauseTagInTheBeginningOfTheSentence(firstChild: any) {
    let breakNodeBlot;
    if (
      firstChild.nodeName === '#text' &&
      !firstChild.textContent?.trim().length
    ) {
      const nextSiblingBlot = firstChild.nextSibling;
      if (
        nextSiblingBlot &&
        QuillUtil.isMatchingSsmlPauseTagName(nextSiblingBlot)
      ) {
        breakNodeBlot = nextSiblingBlot.__blot.blot;
      }
    } else if (firstChild.nodeName !== '#text') {
      if (QuillUtil.isMatchingSsmlPauseTagName(firstChild)) {
        breakNodeBlot = firstChild.__blot.blot;
      }
    }

    return {
      isPauseTag: !!breakNodeBlot,
      blot: breakNodeBlot,
    };
  }

  static unwrapChildrenSkipTags(node: typeof Inline) {
    QuillUtil.forEachRecursive(node, function (child) {
      if (
        QuillUtil.isTagNameEqual(child?.statics.blotName, SkipBlot.blotName)
      ) {
        child.unwrap();
      }
    });
  }
}

export default QuillUtil;
