import { FC } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Styles from './auth.module.scss';
import { confirmRegistration } from 'aws';
import { Button } from 'modules/common/components/Button/Button';
import { Input } from 'modules/common/components/Input/Input';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Alert } from 'modules/common/components/Alert/Alert';
import Form from 'modules/common/components/Form/Form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { getActivateAccountFormSchema } from './validation-schemas';
import useLoading from 'modules/common/hooks/useLoading';

interface ActivateAccountForm {
  code: string;
}

export const ActivateAccount: FC = () => {
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    isLoading,
    setLoading,
    setError,
    errorMessage: error,
    isError,
  } = useLoading();

  const methods = useForm<ActivateAccountForm>({
    defaultValues: {
      code: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(getActivateAccountFormSchema()),
  });

  const {
    formState: { errors },
    setValue,
  } = methods;

  const onSubmit = async (values: ActivateAccountForm) => {
    try {
      let username = queryParams.get('username');
      if (!username) {
        return navigate('/auth/forgot-password');
      }

      // to enable the user to use characters
      username = decodeURIComponent(username);

      setLoading(true);
      await confirmRegistration(username, values.code);
      setLoading(false);
      navigate('/auth/login');
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <div className={Styles['auth-form']}>
      <h4 className="fw-700 font-size-lg ta-center m-b-5">Activate Account</h4>
      {isError && (
        <Alert className="m-b-2" type="error">
          {error}
        </Alert>
      )}
      <Form<ActivateAccountForm> onSubmit={onSubmit} methods={methods}>
        <p className="m-b-1 font-size-sm fw-600">Code</p>
        <Input
          large
          type="text"
          name="code"
          placeholder="Enter confirmation code"
          autoComplete="off"
          onInput={v => setValue('code', v)}
        />
        <p className="m-b-4 font-size-xs color-error">{errors.code?.message}</p>

        <Stack justifyContent="space-between" alignItems="center">
          <Stack.Item>
            <Button type="button" secondary>
              <Link to="/auth/login">Cancel</Link>
            </Button>
          </Stack.Item>
          <Stack.Item>
            <Button type="submit" disabled={isLoading}>
              Continue
            </Button>
          </Stack.Item>
        </Stack>
      </Form>
    </div>
  );
};
