import { useCallback, useMemo, useState } from 'react';
import { useGetVoicesQuery } from 'redux/api/app.api';
import {
  LabelValue,
  ParsedVoiceValue,
  SelectValue,
} from 'modules/common/types';
import { ProjectModel } from 'modules/common/models/Project';
import { Button } from 'modules/common/components/Button/Button';
import LabelSelect from 'modules/common/components/LabeledInputs/LabelSelect';
import { VoiceSelect } from 'modules/common/components/VoiceSelect';
import { VoiceSettingsDisclosure } from 'modules/common/components/VoiceSettingsDisclosure';
import useQuill from 'modules/common/hooks/useQuill';
import { SwitchingModelRecommendMessage } from 'modules/projects/Project/Information/SwitchingModelRecommendMessage';
import { ElevenLabsVoiceSettings } from 'modules/projects/Project/types';
import { Form, FormUpdate } from '../types';
import Styles from '../editvoicesettings.module.scss';
import { Icon } from 'icons';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import { activeProjectVoiceSettingsSelector } from 'redux/reducers/project/selectors';
import { ChangingModelWarning } from './ChangingModelWarning';
import { fetchProject } from 'redux/reducers/project/projectSlice';

interface EditVoiceSettingsFormProps {
  formData: Form;
  isFormDirty: boolean;
  project: ProjectModel;
  onFormChange(v: FormUpdate): void;
  onSubmit(): Promise<void>;
  isChangingSettings: boolean;
  isLoadingAudio: boolean;
  isPlayingAudio: boolean;
  onGenerateAudio(): void;
}

export const EditVoiceSettingsForm: React.FC<EditVoiceSettingsFormProps> = ({
  formData,
  isFormDirty,
  project,
  onFormChange,
  onSubmit,
  isChangingSettings,
  isLoadingAudio,
  isPlayingAudio,
  onGenerateAudio,
}) => {
  const dispatch = useAppDispatch();
  const quillInstance = useQuill();
  const editor = quillInstance?.getEditor();
  const { elevenLabsModel, voice, elevenLabsVoiceSettings } = formData;
  const { data: voicesData } = useGetVoicesQuery();
  const [model, setModel] = useState<LabelValue>(
    voicesData?.models.elevenlabs.find(
      ({ value }) => value === elevenLabsModel
    ) || { label: '', value: '' }
  );
  const [isConfirmChangeStep, setIsConfirmChangeStep] = useState(false);
  const projectVoiceSettings = useAppSelector(
    activeProjectVoiceSettingsSelector
  );
  const isChangedModel =
    projectVoiceSettings?.elevenLabsModel !== elevenLabsModel;
  const elevenLabsVoiceDefaultSettings =
    voicesData?.defaultVoiceSettings?.elevenlabs;

  const voicesOption = voicesData?.voices[project.ttsEngine].find(
    ({ value }) => value === voice
  );

  const isSelectedModelMatchRecommended =
    !voicesOption?.high_quality_base_model_ids?.length ||
    voicesOption?.high_quality_base_model_ids.includes(model.value);

  const voicesOptions = useMemo(
    () =>
      voicesData?.voices && project.ttsEngine
        ? (voicesData?.voices[project.ttsEngine] as ParsedVoiceValue[])
        : [],
    [project.ttsEngine, voicesData]
  );

  const onModelChange = useCallback(
    (value: SelectValue | string | null) => {
      let v;
      if (!value) {
        v = { label: '', value: '' };
      } else if (typeof value === 'string') {
        v = voicesData?.models.elevenlabs.find(
          (option) => option.value === value
        ) ?? { label: '', value: '' };
      } else {
        v = value;
      }
      setModel(v);
      onFormChange({
        name: 'elevenLabsModel',
        value: v.value,
      });
    },
    [onFormChange, voicesData?.models.elevenlabs]
  );
  const onVoiceChange = useCallback(
    (value) => {
      onFormChange({
        name: 'voice',
        value: value ? value.value : '',
      });
    },
    [onFormChange]
  );
  const onVoiceSettingsChange = useCallback(
    (value: ElevenLabsVoiceSettings) => {
      onFormChange({
        name: 'elevenLabsVoiceSettings',
        value,
      });
    },
    [onFormChange]
  );

  const generateAudioHandler = () => {
    if (editor) {
      onGenerateAudio();
    }
  };

  const onChangeVoiceClick = () => {
    setIsConfirmChangeStep(true);
  };

  const onDeclineChangeVoiceClick = () => {
    setIsConfirmChangeStep(false);
  };

  const onConfirmChangeVoiceClick = async () => {
    await onSubmit();
    if (isChangedModel) {
      // reload project after changing model
      dispatch(fetchProject(project.id));
    }
    setIsConfirmChangeStep(false);
  };

  return (
    <div className={Styles['Form']}>
      {isConfirmChangeStep ? (
        <>
          <div className={Styles['Warning']}>
            <Icon
              name="warning_outline"
              color="#EAB325"
              size={40}
              className="m-b-2"
            />
            <span className="m-b-2 font-size-lg fw-700">
              {isChangedModel ? 'Warning' : 'Change voice for entire book'}
            </span>
            <span className="m-b-1 font-size-sm fw-500">
              {isChangedModel
                ? 'Changing the model will delete all tags and apply the new voice throughout the entire book. '
                : 'The voice will be changed all over the book.'}
            </span>
            <span className="font-size-sm fw-500">Are you sure?</span>
          </div>
          <div className={Styles['Footer']}>
            <Button secondary onClick={onDeclineChangeVoiceClick}>
              Back to settings
            </Button>
            <Button
              onClick={onConfirmChangeVoiceClick}
              loading={isChangingSettings}
              disabled={isChangingSettings}
              flexLoader={true}
            >
              Apply changes
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={Styles['Body']}>
            <div className={Styles['ModelAndVoice']}>
              <LabelSelect
                size="large"
                labelText="Model"
                value={model}
                isSearchable
                onChange={onModelChange}
                options={voicesData?.models.elevenlabs || []}
              />
              <SwitchingModelRecommendMessage
                elevenLabsModels={voicesData?.models.elevenlabs || []}
                selectedModel={model}
                high_quality_base_model_ids={
                  voicesOption?.high_quality_base_model_ids
                }
                onModelChange={onModelChange}
              />
              {isChangedModel && <ChangingModelWarning />}
              <VoiceSelect
                size="large"
                labelText="Voice"
                isSearchable
                value={voicesOption}
                onChange={onVoiceChange}
                options={voicesOptions}
              />
            </div>
            <VoiceSettingsDisclosure
              className={Styles['VoiceSettings']}
              labelClassName={Styles['VoiceSettingsLabel']}
              label="Voice settings"
              isEnableDisclosure={false}
              values={elevenLabsVoiceSettings as ElevenLabsVoiceSettings}
              isSelectedModelMatchRecommended={isSelectedModelMatchRecommended}
              defaultValues={elevenLabsVoiceDefaultSettings}
              modelSelected={model}
              onVoiceSettingsChange={onVoiceSettingsChange}
            />
          </div>
          <div className={Styles['Footer']}>
            <Button
              secondary
              disabled={isLoadingAudio || isPlayingAudio}
              onClick={generateAudioHandler}
            >
              Generate audio with changes
            </Button>
            <Button
              onClick={onChangeVoiceClick}
              disabled={isChangingSettings || !isFormDirty}
            >
              Change voice for entire book
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
