import React, { useCallback, useContext, useMemo } from 'react';
import { Button } from 'modules/common/components/Button/Button';
import { TOCChapter } from 'modules/common/types';
import { useDeleteTOCChapterMany } from 'modules/edit/TOCChapters/lib';
import useQuill from 'modules/common/hooks/useQuill';
import ToCChaptersContext from 'modules/edit/TOCChapters/context';
import { useAppSelector } from 'modules/common/hooks/redux';
import { getProjectTOCPagesChapters } from 'redux/reducers/project/selectors';

const TOCBulkDelete = () => {
 const projectTOCChapters = useAppSelector(getProjectTOCPagesChapters);
 const context = useContext(ToCChaptersContext);
 const deleteTOCChapterMany = useDeleteTOCChapterMany();
 const quillInstance = useQuill();
 const editor = quillInstance?.getEditor();

 const selectedChaptersWOFirst = useMemo(() => context.selectedChapterIds.filter(id => id !== projectTOCChapters[0].id), [context, projectTOCChapters]);

 const bulkDeleteString = useMemo(() => {
  if(!selectedChaptersWOFirst.length) return '';

  const all = selectedChaptersWOFirst.length;
  const subChapters = projectTOCChapters.filter(item => selectedChaptersWOFirst.includes(item.id) && item.isSubChapter).length;
  const chapters = all - subChapters;

  const chaptersString = `${chapters} chapter${chapters === 1 ? '' : 's'}`;
  const subChaptersString = `${subChapters} sub-chapter${subChapters === 1 ? '' : 's'}`;

  return `Remove 
    ${!!chapters ? chaptersString : ''}
    ${!!(chapters && subChapters) ? ' and ' : ''}
    ${!!subChapters ? subChaptersString : ''}`
 }, [projectTOCChapters, selectedChaptersWOFirst]);

 const handleDeleteChapters = useCallback(() => {
  if (!editor) return;
  const chaptersToDelete = selectedChaptersWOFirst.map(id => projectTOCChapters.find(chapter => chapter.id === id) as TOCChapter);

  deleteTOCChapterMany({
   chaptersToDelete,
   editor
  });
  context.setSelectedChapterIds([]);
 }, [context, deleteTOCChapterMany, editor, projectTOCChapters, selectedChaptersWOFirst])

  return <Button onClick={handleDeleteChapters} className="m-t-2 w-100">{bulkDeleteString}</Button>;
};

export default TOCBulkDelete;
