import axiosInstance from 'utils/axios';
import { BookmarkModel } from 'modules/common/models/Bookmark';
import { ResultWithMessage } from 'modules/common/types';

type BookmarksReturn = ResultWithMessage<BookmarkModel>;

interface IBookmarks {
  getAll(projectId: string): Promise<BookmarkModel[]>;
  add(
    bookmark: Omit<BookmarkModel, 'id'>,
    projectId: string
  ): Promise<BookmarksReturn>;
  edit(bookmark: BookmarkModel, projectID: string): Promise<BookmarksReturn>;
  delete(
    id: BookmarkModel['id'],
    projectID: string
  ): Promise<{ id: BookmarkModel['id'] }>;
}

class BookmarksApi implements IBookmarks {
  static action = '/bookmarks';

  async getAll(projectId: string): Promise<BookmarkModel[]> {
    const res = await axiosInstance.get(BookmarksApi.action, {
      params: {
        projectId,
      },
    });
    return res.data;
  }

  async add(
    bookmark: Omit<BookmarkModel, 'id'>,
    projectId: string
  ): Promise<BookmarksReturn> {
    const res = await axiosInstance.post(BookmarksApi.action, {
      projectId,
      bookmark,
    });

    return {
      message: res.data.message,
      result: res.data.result.bookmark,
    };
  }

  async edit(
    bookmark: BookmarkModel,
    projectId: string
  ): Promise<BookmarksReturn> {
    const res = await axiosInstance.put(BookmarksApi.action, {
      projectId,
      id: bookmark.id,
      bookmark,
    });

    return {
      message: res.data.message,
      result: res.data.result.bookmark,
    };
  }

  async delete(
    id: BookmarkModel['id'],
    projectId: string
  ): Promise<{ id: BookmarkModel['id'] }> {
    const res = await axiosInstance.delete(BookmarksApi.action, {
      data: {
        projectId,
        bookmarkId: id,
      },
    });
    return res.data;
  }
}

const BookmarksApiInstance = new BookmarksApi();

export default BookmarksApiInstance;
