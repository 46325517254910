import { FC } from 'react';

import { useGetVoicesQuery } from 'redux/api/app.api';
import {
  activeProjectSelector,
  activeProjectVoiceSettingsSelector,
} from 'redux/reducers/project/selectors';
import { AudioPlayer } from 'modules/edit/AudioPlayer/AudioPlayer';
import { useAppSelector } from 'modules/common/hooks/redux';

export const Footer: FC = () => {
  const project = useAppSelector(activeProjectSelector);
  const projectVoiceSettings = useAppSelector(
    activeProjectVoiceSettingsSelector
  );
  const { data: voicesData } = useGetVoicesQuery();
  return (
    <AudioPlayer
      audio_file_type="mp3"
      voice_id={
        projectVoiceSettings?.voice ||
        voicesData?.voices?.[project?.ttsEngine || '']?.[0]?.value ||
        ''
      }
      engine_name={project?.ttsEngine}
      elevenLabsModel={projectVoiceSettings?.elevenLabsModel || ''}
      voiceSettings={projectVoiceSettings?.voiceSettings!}
      tts_engine_type="neural"
      audio_file_bit_rate="24000"
    />
  );
};
