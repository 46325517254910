import ReactDOM from 'react-dom';
import Notification, {
  NotificationType,
} from 'modules/common/components/Notify/Notification/Notification';
import NotificationsManager, {
  setNotifyType,
} from 'modules/common/components/Notify/NotificationsManager/NotificationsManager';
import createContainer from './createContainer';

export { Notification, NotificationType };

let notify: (obj: setNotifyType) => void;

ReactDOM.render(
  <NotificationsManager
    setNotify={(notifyFn) => {
      notify = notifyFn;
    }}
  />,
  createContainer()
);

type notifyFnParam = Omit<setNotifyType, 'color'>;

export function info({
  children,
  autoClose = true,
  timeToClose,
  timeToDelete,
}: notifyFnParam) {
  return notify({
    color: NotificationType.info,
    children,
    autoClose,
    timeToClose,
    timeToDelete,
  });
}

export function success({
  children,
  autoClose = true,
  timeToClose,
  timeToDelete,
}: notifyFnParam) {
  return notify({
    color: NotificationType.success,
    children,
    autoClose,
    timeToClose,
    timeToDelete,
  });
}

export function warning({
  children,
  autoClose = true,
  timeToClose,
  timeToDelete,
}: notifyFnParam) {
  return notify({
    color: NotificationType.warning,
    children,
    autoClose,
    timeToClose,
    timeToDelete,
  });
}

export function error({
  children,
  autoClose = true,
  timeToClose,
  timeToDelete,
}: notifyFnParam) {
  return notify({
    color: NotificationType.error,
    children,
    autoClose,
    timeToClose,
    timeToDelete,
  });
}

export function notification({
  children,
  autoClose = true,
  timeToClose,
  timeToDelete,
}: notifyFnParam) {
  info({
    children,
    autoClose,
    timeToClose,
    timeToDelete,
  });
}

export function SomethingWentWrong() {
  error({ children: 'Something Went Wrong' });
}
