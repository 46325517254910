import { FC, Fragment } from 'react';

import { Icon } from 'icons';
import { Stack } from 'modules/common/components/Stack/Stack';
import { useAppSelector } from 'modules/common/hooks/redux';
import {
  bookMarksIsFetchingSelector,
  selectAll,
} from 'redux/reducers/bookmarks/selectors';
import { BookmarkItem } from './BookmarkItem';

import { BookmarkModel } from 'modules/common/models/Bookmark';

interface BookBookmarksProps {
  onDelete: (bookmarkId: string) => void;
  onEdit: (bookmarkId: string, value: BookmarkModel) => void;
  onClick: (bookmarkId: string) => void;
}

export const BookBookmarks: FC<BookBookmarksProps> = ({
  onDelete,
  onEdit,
  onClick,
}) => {
  const bookmarks = useAppSelector(selectAll);
  const isBookmarkFetching = useAppSelector(bookMarksIsFetchingSelector);

  return (
    <Fragment>
      {!isBookmarkFetching && !bookmarks.length && (
        <Stack className="h-100" alignItems="center" justifyContent="center">
          <Stack.Item shrink={0}>
            <div className="ta-center">
              <Icon name="bookmarks" size={40} className="opacity-30" />
              <p className="m-t-2 fw-600 font-size-sm opacity-50">
                List of bookmarks is empty
              </p>
            </div>
          </Stack.Item>
        </Stack>
      )}
      {bookmarks.map((bookmark) => {
        return (
          <BookmarkItem
            key={bookmark.id}
            bookmark={bookmark}
            onDelete={onDelete}
            onEdit={onEdit}
            onClick={onClick}
          />
        );
      })}
    </Fragment>
  );
};
