// @ts-nocheck
export type IconNameType =
  | 'add'
  | 'alphabet'
  | 'arrow_backward'
  | 'arrow_backward_long'
  | 'arrow_downward'
  | 'arrow_drop_down'
  | 'arrow_drop_up'
  | 'arrow_forward'
  | 'arrow_left'
  | 'arrow_right'
  | 'arrow_upward'
  | 'bookmark'
  | 'bookmark_border'
  | 'bookmarks'
  | 'check_circle_outline'
  | 'close_outline'
  | 'comment'
  | 'done'
  | 'done_all'
  | 'exit_to_app'
  | 'file_download'
  | 'file_upload'
  | 'flare'
  | 'format_color_text'
  | 'forward_10'
  | 'help'
  | 'hourglass'
  | 'info'
  | 'info_outline'
  | 'language'
  | 'location_on'
  | 'lock'
  | 'more_horiz'
  | 'more_vert'
  | 'notifications'
  | 'numbers'
  | 'pause'
  | 'play_arrow'
  | 'preprocessing'
  | 'redo'
  | 'refresh'
  | 'remove_red_eye'
  | 'replay_10'
  | 'rss_feed'
  | 'rss_feed copy'
  | 'save'
  | 'settings'
  | 'speed'
  | 'star'
  | 'timelapse'
  | 'undo'
  | 'volume_high'
  | 'volume_low'
  | 'volume_mid'
  | 'volume_mute'
  | 'warning_outline';

export const IconNameArray = ['add','alphabet','arrow_backward','arrow_backward_long','arrow_downward','arrow_drop_down','arrow_drop_up','arrow_forward','arrow_left','arrow_right','arrow_upward','bookmark','bookmark_border','bookmarks','check_circle_outline','close_outline','comment','done','done_all','exit_to_app','file_download','file_upload','flare','format_color_text','forward_10','help','hourglass','info','info_outline','language','location_on','lock','more_horiz','more_vert','notifications','numbers','pause','play_arrow','preprocessing','redo','refresh','remove_red_eye','replay_10','rss_feed','rss_feed copy','save','settings','speed','star','timelapse','undo','volume_high','volume_low','volume_mid','volume_mute','warning_outline'];

interface icon {
	content: string
	viewbox: string
}

interface iconObjectInterface {
  [key: string]: icon
}




export const iconObject: iconObjectInterface = {
	'add': {
			content: '<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>',
			viewbox: '0 0 24 24'
	},
	'alphabet': {
			content: '<g clip-path="url(#clip0_323_1290)"><path d="M13.556.002H6.444c-.625 0-1.135.509-1.135 1.134v7.112c0 .629.51 1.138 1.135 1.138h7.112c.625 0 1.135-.51 1.135-1.138V1.136c0-.625-.51-1.134-1.135-1.134zM11.505 7.18l-.463-1.075H8.957L8.495 7.18h-1.18l2.148-4.974h1.074l2.149 4.974h-1.181z"/><path d="M10.003 3.686L9.377 5.13h1.245l-.619-1.444zM18.866 10.617h-7.113c-.628 0-1.137.51-1.137 1.135v7.112c0 .625.51 1.134 1.137 1.134h7.113c.625 0 1.134-.509 1.134-1.134v-7.112c0-.625-.51-1.135-1.134-1.135zm-4.322 5.805c.28.28.618.42 1.017.42.545 0 .991-.216 1.337-.648l.712.733c-.565.636-1.23.953-1.996.953s-1.397-.241-1.892-.726c-.496-.483-.744-1.094-.744-1.832 0-.737.253-1.353.758-1.846.505-.493 1.123-.74 1.853-.74.816 0 1.5.311 2.05.932l-.69.783c-.352-.436-.788-.655-1.31-.655-.417 0-.774.137-1.07.41-.297.272-.445.64-.445 1.102 0 .463.14.834.42 1.114zM5.048 13.867c-.138-.071-.37-.106-.698-.106h-.533v1.074h.526c.308 0 .538-.033.687-.1.15-.066.224-.21.224-.43s-.069-.367-.206-.438z"/><path d="M8.247 10.617H1.134c-.625 0-1.134.51-1.134 1.135v7.112c0 .625.51 1.134 1.134 1.134h7.113c.628 0 1.137-.509 1.137-1.134v-7.112c0-.625-.509-1.135-1.137-1.135zM6.32 17.332c-.275.309-.754.463-1.437.463H2.707V12.82h1.935c.337 0 .627.04.871.122.245.08.429.189.552.327.223.255.334.545.334.867 0 .39-.125.68-.377.868a1.305 1.305 0 0 1-.177.125 4.67 4.67 0 0 1-.178.082c.308.066.553.205.736.416.183.211.274.473.274.786 0 .346-.119.653-.356.918z"/><path d="M5.279 15.82c-.169-.073-.438-.11-.808-.11h-.654v1.145h.761c.318 0 .556-.039.715-.117.159-.078.238-.232.238-.462s-.084-.382-.252-.456z"/></g>',
			viewbox: '0 0 20 20'
	},
	'arrow_backward': {
			content: '<path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>',
			viewbox: '0 0 24 24'
	},
	'arrow_backward_long': {
			content: '<path d="M8.501 18.503l1.26-1.26-4.09-4.099h14.44v-1.786H5.67L9.768 7.26 8.501 6 2.25 12.251l6.251 6.252z" fill="#031E50"/>',
			viewbox: '0 0 24 24'
	},
	'arrow_downward': {
			content: '<path fill="#010101" d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"/>',
			viewbox: '0 0 24 24'
	},
	'arrow_drop_down': {
			content: '<path d="M7 10l5 5 5-5z"/>',
			viewbox: '0 0 24 24'
	},
	'arrow_drop_up': {
			content: '<path d="M7 14l5-5 5 5z"/>',
			viewbox: '0 0 24 24'
	},
	'arrow_forward': {
			content: '<path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>',
			viewbox: '0 0 24 24'
	},
	'arrow_left': {
			content: '<path d="M14 7l-5 5 5 5V7z"/><path d="M24 0v24H0V0h24z" fill="none"/>',
			viewbox: '0 0 24 24'
	},
	'arrow_right': {
			content: '<path d="M10 17l5-5-5-5v10z"/><path d="M0 24V0h24v24H0z" fill="none"/>',
			viewbox: '0 0 24 24'
	},
	'arrow_upward': {
			content: '<path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"/>',
			viewbox: '0 0 24 24'
	},
	'bookmark': {
			content: '<path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2z"/>',
			viewbox: '0 0 24 24'
	},
	'bookmark_border': {
			content: '<path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z"/>',
			viewbox: '0 0 24 24'
	},
	'bookmarks': {
			content: '<path d="M31.667 30L35 31.667V5c0-1.833-1.5-3.333-3.333-3.333H14.983c-1.833 0-3.316 1.5-3.316 3.333h16.666c1.834 0 3.334 1.5 3.334 3.333V30zM25 8.333H8.333A3.343 3.343 0 0 0 5 11.667v26.666l11.667-5 11.666 5V11.667c0-1.834-1.5-3.334-3.333-3.334z"/>',
			viewbox: '0 0 40 40'
	},
	'check_circle_outline': {
			content: '<path d="M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10A10 10 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8 8 8 0 0 0 8 8 8 8 0 0 0 8-8 8 8 0 0 0-8-8m-1 12.5L6.5 12l1.41-1.41L11 13.67l5.59-5.58L18 9.5l-7 7z"/>',
			viewbox: '0 0 24 24'
	},
	'close_outline': {
			content: '<path d="M12.59 6L10 8.59 7.41 6 6 7.41 8.59 10 6 12.59 7.41 14 10 11.41 12.59 14 14 12.59 11.41 10 14 7.41 12.59 6zM10 0C4.47 0 0 4.47 0 10s4.47 10 10 10 10-4.47 10-10S15.53 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" fill="#C01313"/>',
			viewbox: '0 0 20 20'
	},
	'comment': {
			content: '<path d="M16.667 1.667H3.333c-.916 0-1.658.75-1.658 1.666l-.008 15L5 15h11.667c.916 0 1.666-.75 1.666-1.667v-10c0-.916-.75-1.666-1.666-1.666zM5 7.5h10v1.667H5V7.5zm6.667 4.167H5V10h6.667v1.667zm3.333-5H5V5h10v1.667z"/>',
			viewbox: '0 0 20 20'
	},
	'done': {
			content: '<path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>',
			viewbox: '0 0 24 24'
	},
	'done_all': {
			content: '<path d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z"/>',
			viewbox: '0 0 24 24'
	},
	'exit_to_app': {
			content: '<path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5a2 2 0 0 0-2 2v4h2V5h14v14H5v-4H3v4a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>',
			viewbox: '0 0 24 24'
	},
	'file_download': {
			content: '<path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/>',
			viewbox: '0 0 24 24'
	},
	'file_upload': {
			content: '<path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/>',
			viewbox: '0 0 24 24'
	},
	'flare': {
			content: '<path d="M7 11H1v2h6v-2zm2.17-3.24L7.05 5.64 5.64 7.05l2.12 2.12 1.41-1.41zM13 1h-2v6h2V1zm5.36 6.05l-1.41-1.41-2.12 2.12 1.41 1.41 2.12-2.12zM17 11v2h6v-2h-6zm-5-2c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm2.83 7.24l2.12 2.12 1.41-1.41-2.12-2.12-1.41 1.41zm-9.19.71l1.41 1.41 2.12-2.12-1.41-1.41-2.12 2.12zM11 23h2v-6h-2v6z"/>',
			viewbox: '0 0 24 24'
	},
	'format_color_text': {
			content: '<g clip-path="url(#clip0_230_500)"><path d="M2 16h2.045l1.073-3.069H9.89L10.956 16H13L8.556 4H6.444L2 16zm3.734-4.809L7.45 6.23h.102l1.715 4.962H5.734z"/><circle cx="16.5" cy="14.5" r="1.5"/></g>',
			viewbox: '0 0 20 20'
	},
	'forward_10': {
			content: '<path d="M4 13c0 4.4 3.6 8 8 8s8-3.6 8-8h-2c0 3.3-2.7 6-6 6s-6-2.7-6-6 2.7-6 6-6v4l5-5-5-5v4c-4.4 0-8 3.6-8 8zm6.8 3H10v-3.3L9 13v-.7l1.8-.6h.1V16zm4.3-1.8c0 .3 0 .6-.1.8l-.3.6s-.3.3-.5.3-.4.1-.6.1-.4 0-.6-.1-.3-.2-.5-.3-.2-.3-.3-.6-.1-.5-.1-.8v-.7c0-.3 0-.6.1-.8l.3-.6s.3-.3.5-.3.4-.1.6-.1.4 0 .6.1.3.2.5.3.2.3.3.6.1.5.1.8v.7zm-.8-.8v-.5s-.1-.2-.1-.3-.1-.1-.2-.2-.2-.1-.3-.1-.2 0-.3.1l-.2.2s-.1.2-.1.3v2s.1.2.1.3.1.1.2.2.2.1.3.1.2 0 .3-.1l.2-.2s.1-.2.1-.3v-1.5z"/>',
			viewbox: '0 0 24 24'
	},
	'help': {
			content: '<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"/>',
			viewbox: '0 0 24 24'
	},
	'hourglass': {
			content: '<path d="M6 2v6h.01L6 8.01 10 12l-4 4 .01.01H6V22h12v-5.99h-.01L18 16l-4-4 4-3.99-.01-.01H18V2H6zm10 14.5V20H8v-3.5l4-4 4 4zm-4-5l-4-4V4h8v3.5l-4 4z"/>',
			viewbox: '0 0 24 24'
	},
	'info': {
			content: '<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/>',
			viewbox: '0 0 24 24'
	},
	'info_outline': {
			content: '<path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"/>',
			viewbox: '0 0 24 24'
	},
	'language': {
			content: '<path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95a15.65 15.65 0 0 0-1.38-3.56A8.03 8.03 0 0 1 18.92 8zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 0 1 5.08 16zm2.95-8H5.08a7.987 7.987 0 0 1 4.33-3.56A15.65 15.65 0 0 0 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 0 1-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"/>',
			viewbox: '0 0 24 24'
	},
	'location_on': {
			content: '<path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z"/>',
			viewbox: '0 0 24 24'
	},
	'lock': {
			content: '<path d="M11.333 14H4.666a1.333 1.333 0 0 1-1.333-1.333V7.333C3.333 6.597 3.93 6 4.666 6h6.667c.737 0 1.333.597 1.333 1.333v5.334c0 .736-.596 1.333-1.333 1.333z" fill="#fff"/><path d="M5.333 6V4.667a2.667 2.667 0 0 1 5.333 0V6" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>',
			viewbox: '0 0 16 16'
	},
	'more_horiz': {
			content: '<path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>',
			viewbox: '0 0 24 24'
	},
	'more_vert': {
			content: '<path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>',
			viewbox: '0 0 24 24'
	},
	'notifications': {
			content: '<path d="M12 22c1.1 0 2-.9 2-2h-4a2 2 0 0 0 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"/>',
			viewbox: '0 0 24 24'
	},
	'numbers': {
			content: '<path d="M3 14.666a.934.934 0 0 1-.934-.934V7.778l-.516.258a.933.933 0 1 1-.834-1.67l1.866-.934a.935.935 0 0 1 1.35.836v7.466a.93.93 0 0 1-.932.932zM11.4 14.666H6.734a.933.933 0 0 1-.66-1.594L9.808 9.34c.238-.238.368-.552.368-.886 0-.334-.13-.648-.368-.886-.474-.474-1.298-.474-1.774 0-.236.236-.366.55-.366.884a.934.934 0 1 1-1.868 0c0-.834.324-1.618.914-2.206 1.18-1.178 3.232-1.178 4.412 0 .59.588.914 1.372.914 2.206 0 .834-.324 1.618-.914 2.206l-2.14 2.14H11.4a.934.934 0 1 1 0 1.868zM18.824 9.418c.316-.426.508-.948.508-1.518a2.569 2.569 0 0 0-2.566-2.566 2.56 2.56 0 0 0-2.278 1.386.932.932 0 0 0 .4 1.258.932.932 0 0 0 1.258-.4.7.7 0 1 1 .62 1.022.934.934 0 1 0 0 1.868 1.167 1.167 0 0 1 0 2.332 1.169 1.169 0 0 1-1.166-1.166.934.934 0 1 0-1.868 0 3.037 3.037 0 0 0 3.034 3.034 3.037 3.037 0 0 0 3.034-3.034c0-.876-.378-1.66-.976-2.216z"/>',
			viewbox: '0 0 20 20'
	},
	'pause': {
			content: '<path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>',
			viewbox: '0 0 24 24'
	},
	'play_arrow': {
			content: '<path d="M8 5v14l11-7z"/>',
			viewbox: '0 0 24 24'
	},
	'preprocessing': {
			content: '<path d="M2.5 4.167h3.333M4.167 2.5v3.333M5 14.167v2.5M6.25 15.417h-2.5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.5 10l-3.158-1.27a2.5 2.5 0 0 1-1.387-1.386l-1.28-3.177-1.278 3.177A2.497 2.497 0 0 1 9.01 8.73L5.852 10h-.019l3.159 1.27a2.5 2.5 0 0 1 1.386 1.386l1.28 3.177 1.279-3.177a2.497 2.497 0 0 1 1.386-1.386L17.482 10" fill="#fff"/><path d="M17.5 10l-3.158-1.27a2.5 2.5 0 0 1-1.387-1.386l-1.28-3.177-1.278 3.177A2.497 2.497 0 0 1 9.01 8.73L5.852 10h-.019l3.159 1.27a2.5 2.5 0 0 1 1.386 1.386l1.28 3.177 1.279-3.177a2.497 2.497 0 0 1 1.386-1.386L17.482 10" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>',
			viewbox: '0 0 20 20'
	},
	'redo': {
			content: '<path d="M18.4 10.6C16.55 8.99 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16a8.002 8.002 0 0 1 7.6-5.5c1.95 0 3.73.72 5.12 1.88L13 16h9V7l-3.6 3.6z"/>',
			viewbox: '0 0 24 24'
	},
	'refresh': {
			content: '<path d="M17.65 6.35A7.958 7.958 0 0 0 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0 1 12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>',
			viewbox: '0 0 24 24'
	},
	'remove_red_eye': {
			content: '<path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>',
			viewbox: '0 0 24 24'
	},
	'replay_10': {
			content: '<path d="M12 5V1L7 6l5 5V7c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6H4c0 4.4 3.6 8 8 8s8-3.6 8-8-3.6-8-8-8zm-1.1 11H10v-3.3L9 13v-.7l1.8-.6h.1V16zm4.3-1.8c0 .3 0 .6-.1.8l-.3.6s-.3.3-.5.3-.4.1-.6.1-.4 0-.6-.1-.3-.2-.5-.3-.2-.3-.3-.6-.1-.5-.1-.8v-.7c0-.3 0-.6.1-.8l.3-.6s.3-.3.5-.3.4-.1.6-.1.4 0 .6.1c.2.1.3.2.5.3s.2.3.3.6.1.5.1.8v.7zm-.9-.8v-.5s-.1-.2-.1-.3-.1-.1-.2-.2-.2-.1-.3-.1-.2 0-.3.1l-.2.2s-.1.2-.1.3v2s.1.2.1.3.1.1.2.2.2.1.3.1.2 0 .3-.1l.2-.2s.1-.2.1-.3v-1.5z"/>',
			viewbox: '0 0 24 24'
	},
	'rss_feed': {
			content: '<g clip-path="url(#clip0_230_507)"><path d="M4.116 11.468a1.817 1.817 0 1 0 2.569-2.569 1.817 1.817 0 0 0-2.57 2.569z"/><path d="M12 1.015l-1.668 1.667c4.143 4.143 4.143 10.86 0 15.003L12 19.352c5.062-5.062 5.062-13.276 0-18.337zM8.665 4.35L6.997 6.017a5.897 5.897 0 0 1 0 8.332l1.668 1.668a8.248 8.248 0 0 0 0-11.667z"/></g>',
			viewbox: '0 0 20 20'
	},
	'rss_feed copy': {
			content: '<circle cx="6.18" cy="17.82" r="2.18"/><path d="M4 4.44v2.83c7.03 0 12.73 5.7 12.73 12.73h2.83c0-8.59-6.97-15.56-15.56-15.56zm0 5.66v2.83c3.9 0 7.07 3.17 7.07 7.07h2.83c0-5.47-4.43-9.9-9.9-9.9z"/>',
			viewbox: '0 0 24 24'
	},
	'save': {
			content: '<path d="M17 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>',
			viewbox: '0 0 24 24'
	},
	'settings': {
			content: '<path d="M5.313 12.882A7.138 7.138 0 0 1 5.25 12c0-.297.027-.594.063-.882L3.725 9.876a.898.898 0 0 1-.224-1.156l1.412-2.443a.898.898 0 0 1 1.112-.384l1.421.571a.917.917 0 0 0 .847-.098c.22-.145.448-.28.684-.4a.92.92 0 0 0 .51-.682l.217-1.514a.897.897 0 0 1 .888-.77h2.817c.446 0 .825.328.888.77l.216 1.512c.043.3.241.547.512.683.235.118.462.251.681.396a.918.918 0 0 0 .85.102l1.42-.57a.898.898 0 0 1 1.111.384L20.5 8.72a.898.898 0 0 1-.224 1.156l-1.588 1.242c.036.288.063.585.063.882 0 .297-.027.594-.063.882l1.588 1.242a.898.898 0 0 1 .224 1.156l-1.412 2.443a.899.899 0 0 1-1.112.384l-1.419-.57a.915.915 0 0 0-.85.102 6.597 6.597 0 0 1-.68.396.918.918 0 0 0-.513.683l-.217 1.512a.897.897 0 0 1-.888.77h-2.817a.897.897 0 0 1-.888-.77l-.217-1.513a.916.916 0 0 0-.509-.682 6.863 6.863 0 0 1-.684-.4.912.912 0 0 0-.846-.099l-1.422.571a.898.898 0 0 1-1.112-.384L3.501 15.28a.898.898 0 0 1 .224-1.156l1.588-1.242zm4.141 1.664a3.6 3.6 0 1 0 5.092-5.092 3.6 3.6 0 0 0-5.092 5.092z" fill="#031E50"/>',
			viewbox: '0 0 24 24'
	},
	'speed': {
			content: '<path d="M16.983 7.142l-1.025 1.541A6.667 6.667 0 0 1 15.775 15H4.225a6.667 6.667 0 0 1 8.758-9.292l1.542-1.025a8.333 8.333 0 0 0-11.733 11.15 1.667 1.667 0 0 0 1.433.834h11.542a1.667 1.667 0 0 0 1.45-.834 8.332 8.332 0 0 0-.225-8.7l-.009.009zm-8.158 5.7a1.666 1.666 0 0 0 2.358 0L15.9 5.767l-7.075 4.716a1.666 1.666 0 0 0 0 2.359z"/>',
			viewbox: '0 0 20 20'
	},
	'star': {
			content: '<path d="M0 0h24v24H0z" fill="none"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/>',
			viewbox: '0 0 24 24'
	},
	'timelapse': {
			content: '<path d="M16.24 7.76A5.974 5.974 0 0 0 12 6v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0a5.99 5.99 0 0 0-.01-8.48zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>',
			viewbox: '0 0 24 24'
	},
	'undo': {
			content: '<path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"/>',
			viewbox: '0 0 24 24'
	},
	'volume_high': {
			content: '<path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3A4.5 4.5 0 0 0 14 7.97v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"/>',
			viewbox: '0 0 24 24'
	},
	'volume_low': {
			content: '<path d="M7 9v6h4l5 5V4l-5 5H7z"/>',
			viewbox: '0 0 24 24'
	},
	'volume_mid': {
			content: '<path d="M18.5 12A4.5 4.5 0 0 0 16 7.97v8.05c1.48-.73 2.5-2.25 2.5-4.02zM5 9v6h4l5 5V4L9 9H5z"/>',
			viewbox: '0 0 24 24'
	},
	'volume_mute': {
			content: '<path d="M16.5 12A4.5 4.5 0 0 0 14 7.97v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51A8.796 8.796 0 0 0 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06a8.99 8.99 0 0 0 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z"/>',
			viewbox: '0 0 24 24'
	},
	'warning_outline': {
			content: '<path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>',
			viewbox: '0 0 24 24'
	},
	};

const noObject: icon = {
	content: null,
	viewbox: null
}

// Returns svg data in object like {content: ..., viewbox: ...}, content paths are cleaned form fill="#010100"
export const getIconByName = (name: string) => iconObject[name] || noObject;
