import { FC, useCallback, useState } from 'react';
import { useScreenSize } from 'modules/common/hooks/useScreenSize';

import Styles from './notice.module.scss';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Button } from 'modules/common/components/Button/Button';
import { useAppSelector } from 'modules/common/hooks/redux';
import { isWebSiteUpToDateSelector } from 'redux/reducers/project/selectors';

export const Notice: FC = () => {
  const { isMobile, isTabletOrMobile } = useScreenSize();
  const [showAlert, setShowAlert] = useState(true);
  const isWebSiteUpToData = useAppSelector(isWebSiteUpToDateSelector);

  let text;

  if (isWebSiteUpToData) {
    text = isMobile
      ? `It seems like you're on a mobile device — editing mode available on screen’s width >1024px only.`
      : `It seems like you're using a tablet. For full experience and enable
  editing mode please rotate the screen.`;
  } else {
    text = 'Your Project is not up to date please refresh the page';
  }

  const btnText = isMobile ? 'Ok' : 'Got It';

  const handleClick = useCallback(() => {
    setShowAlert(false);
  }, []);

  return (isTabletOrMobile && showAlert) || !isWebSiteUpToData ? (
    <div className={Styles.notice}>
      <Stack alignItems="center" spacing={3}>
        <Stack.Item grow={1}>
          <p className="font-size-sm color-white">{text}</p>
        </Stack.Item>
        <Stack.Item shrink={0}>
          <Button onClick={handleClick} secondary>
            {btnText}
          </Button>
        </Stack.Item>
      </Stack>
    </div>
  ) : null;
};
