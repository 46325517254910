import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rejectRequest } from 'utils/rejectRequest';
import { AxiosError } from 'axios';
import { ProjectApiInstance } from 'modules/edit/api/Project';
import {
  ProjectModel,
  ProjectTOCPage,
  VoiceGenerationSettings,
} from 'modules/common/models/Project';
import { ProjectStatus } from 'modules/common/types';
import bsearch from 'binary-search-bounds';

export interface ProjectsState {
  // here to keep some other stuff related to it
  project?: ProjectModel;
  projectTOCPages?: ProjectTOCPage[];
  voiceGenerationSettings?: VoiceGenerationSettings; // to optimize the rendering when the user is editing the voice settings
  projectStatus?: ProjectStatus; // to optimize the rendering
  isWebSiteUpToDate: boolean; // to optimize the rendering
}

const initialState: ProjectsState = {
  isWebSiteUpToDate: true,
};

export const fetchProject = createAsyncThunk<ProjectModel | null, string>(
  'project/fetchProject',
  async (projectId, { rejectWithValue }) => {
    try {
      return await ProjectApiInstance.getOne(projectId);
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProject(state, action: PayloadAction<ProjectModel>) {
      state.project = action.payload;
    },
    setProjectVoiceSettings(
      state,
      action: PayloadAction<VoiceGenerationSettings>
    ) {
      state.voiceGenerationSettings = {
        ...state.voiceGenerationSettings,
        ...action.payload,
      };
    },
    setProjectInactive(state) {
      state.project = undefined;
      state.projectTOCPages = undefined;
      state.projectStatus = undefined;
      state.voiceGenerationSettings = undefined;
      state.isWebSiteUpToDate = true;
    },
    changeProjectStatus(state, action: PayloadAction<ProjectStatus>) {
      if (state.project) {
        state.projectStatus = action.payload;
      }
    },
    setWebSiteIsUpToDate(state) {
      state.isWebSiteUpToDate = false;
    },
    setProjectTOCPages(state, action: PayloadAction<ProjectTOCPage[]>) {
      state.projectTOCPages = action.payload;
    },
    updateProjectTOCPage(state, action: PayloadAction<ProjectTOCPage>) {
      if (!state.projectTOCPages?.length) return;

      const pageIdToChange = bsearch.eq(
        state.projectTOCPages,
        action.payload,
        (a, b) => {
          return Number(a.id) - Number(b.id);
        }
      );
      state.projectTOCPages[pageIdToChange] = action.payload;
    },
    updateProjectTOCPageMany(state, action: PayloadAction<ProjectTOCPage[]>) {
      if (!state.projectTOCPages?.length) return;

      action.payload.forEach(page => {
        const pageIdToChange = bsearch.eq(
          state.projectTOCPages as ProjectTOCPage[],
          page,
          (a, b) => {
            return Number(a.id) - Number(b.id);
          }
        );
        if(state.projectTOCPages) {
          state.projectTOCPages[pageIdToChange] = page;
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProject.fulfilled, (state, { payload }) => {
        if (payload) {
          state.project = payload;
          state.projectStatus = payload.projectStatus;
          state.voiceGenerationSettings = {
            elevenLabsModel: payload.elevenLabsModel,
            voice: payload.voice,
            voiceSettings: payload.voiceSettings,
          };
        }
      })
      .addCase(fetchProject.pending, (state) => {
        state.project = undefined;
        state.projectStatus = undefined;
        state.voiceGenerationSettings = undefined;
        state.isWebSiteUpToDate = true;
      })
      .addCase(fetchProject.rejected, (state) => {
        state.project = undefined;
        state.projectStatus = undefined;
        state.voiceGenerationSettings = undefined;
        state.isWebSiteUpToDate = true;
      });
  },
});

export const {
  setProject,
  setProjectVoiceSettings,
  setProjectInactive,
  updateProjectTOCPage,
  updateProjectTOCPageMany,
  changeProjectStatus,
  setWebSiteIsUpToDate,
  setProjectTOCPages,
} = projectSlice.actions;

export default projectSlice.reducer;
