import { TTSEngine } from 'modules/common/types';

export const calculateTime = (secs: number): string => {
  const minutes = Math.floor(secs / 60);
  const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const seconds = Math.floor(secs % 60);
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${returnedMinutes}:${returnedSeconds}`;
};

export const DEFAULT_FULL_TEXT_LENGTH: Record<TTSEngine, number> = {
  aws: 2500,
  azure: 2500,
  google: 2500,
  wellsaid: 950,
  deepzen: 950,
  elevenlabs: 2500,
};
