import { FC } from 'react';
import { Stack } from 'modules/common/components/Stack/Stack';
import BouncingDotsLoader from 'modules/common/components/BouncingDotsLoader/BouncingDotsLoader';
import { Button } from 'modules/common/components/Button/Button';

interface Props {
  isPlaying: boolean;
  onPlayPauseClick(): void;
  isDisabled: boolean;
  isLoading?: boolean;
}

const AudioControls: FC<Props> = ({
  isPlaying,
  onPlayPauseClick,
  isDisabled,
  isLoading,
}) => {
  return (
    <Stack spacing={2} justifyContent="center" alignItems="center">
      <Stack.Item>
        {isLoading ? (
          <BouncingDotsLoader />
        ) : (
          <Button
            onClick={onPlayPauseClick}
            primary
            disabled={isDisabled}
            icon={isPlaying ? 'pause' : 'play_arrow'}
          />
        )}
      </Stack.Item>
    </Stack>
  );
};

export default AudioControls;
