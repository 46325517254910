import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { getFetchingSelector } from 'redux/reducers/loader/selectors';
import { AsyncRequestStatus } from 'redux/interfaces/loader';
import {
  addPreset,
  editPreset,
  fetchPresets,
  PresetsAdapter,
} from 'redux/reducers/presets/presetsSlice';

export const { selectAll, selectById, selectEntities } =
  PresetsAdapter.getSelectors<RootState>(({ presets }) => presets);

export const presetsIsFetchingSelector = createSelector(
  getFetchingSelector(fetchPresets.typePrefix),
  (status) => status === AsyncRequestStatus.pending
);

export const presetsFetchedSelector = createSelector(
  getFetchingSelector(fetchPresets.typePrefix),
  (status) => status === AsyncRequestStatus.fulfilled
);

export const presetIsAddingSelector = createSelector(
  getFetchingSelector(addPreset.typePrefix),
  (status) => status === AsyncRequestStatus.pending
);

export const presetIsEditingSelector = createSelector(
  getFetchingSelector(editPreset.typePrefix),
  (status) => status === AsyncRequestStatus.pending
);
