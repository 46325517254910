import { getUserEmail } from 'aws';
import { useEffect, useState } from 'react';

export function useUserEmail() {
  const [userEmail, setUserEmail] = useState<string>('');

  useEffect(() => {
    (async () => {
      try {
        const userId = await getUserEmail();
        setUserEmail(userId);
      } catch (err) {}
    })();
  }, []);

  return userEmail;
}
