import { FC, useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { batch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Actions, EditorViews } from './Actions';
import { Editor } from 'modules/edit/Editor/Editor';
import { Sidebar } from 'modules/edit/Sidebar/Sidebar';
import { Page } from 'modules/common/components/Page/Page';
import { BookmarkIconBlot } from 'modules/edit/Editor/Blots/BookmarkIconBlot';
import { Header } from 'modules/edit/Header/Header';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import { Footer } from 'modules/edit/Footer/Footer';
import {
  FoundTextBlot,
  FoundTextCurrentBlot,
} from 'modules/edit/Editor/Blots/FoundTextBlot';
import { PresetBlot } from 'modules/edit/Editor/Blots/PresetBlot';
import { KeyboardModule } from 'modules/edit/Editor/Modules/Keyboard';
import { genericSSMLBlotFactory } from 'modules/edit/Editor/Blots/genericSSMLBlotFactory';
import QuillContext from 'modules/common/context/QuillContext';
import { fetchTags, resetTags } from 'redux/reducers/tags/tagsSlice';
import {
  fetchPresets,
  resetPresets,
} from 'redux/reducers/presets/presetsSlice';
import {
  fetchBookmarks,
  resetBookmarks,
} from 'redux/reducers/bookmarks/bookmarksSlice';
import {
  isTagsFetchedSelector,
  selectAll,
  tagsIsFetchingSelector,
} from 'redux/reducers/tags/selectors';
import { SkipBlot } from './Editor/Blots/SkipBlot';
import {
  activeProjectSelector,
  activeProjectSelectorId,
  isProjectStatusIncomplete,
} from 'redux/reducers/project/selectors';
import {
  fetchProject,
  setProjectInactive,
} from 'redux/reducers/project/projectSlice';
import useAppParams from 'modules/common/hooks/useAppParams';
import {
  fetchChapters,
  fetchProjectCaches,
  resetChapters,
  setActiveChapter,
} from 'redux/reducers/chapters/chaptersSlice';
import { InsertBlot } from './Editor/Blots/InsertBlot';
import { HistoryModule } from './Editor/Modules/History';
import { Notice } from './Notice';
import { removeDownloadableIframes } from 'utils';
import { CommentBlot } from './Editor/Blots/CommentBlot';
import Loader from 'modules/common/components/Loader/Loader';
import { Stack } from 'modules/common/components/Stack/Stack';
import {
  fetchcomments,
  resetComments,
} from 'redux/reducers/comments/commentsSlice';
import { useTrackEvent, AnalyticsEvent } from 'services/amplitude';
import { IncompleteProjectActions } from './Actions/IncompleteProjectActions';
import { IncompleteProjectSidebar } from './Sidebar/IncompleteProjectSidebar';
import { PageBlot } from './Editor/Blots/PageBlot';
import { ChapterIconBlot } from './Editor/Blots/ChapterIconBlot';
import {
  FoundTextTOCBlot,
  FoundTextTOCCurrentBlot,
} from './Editor/Blots/FoundTOCTextBlot';

export const Edit: FC = () => {
  const { id } = useAppParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(activeProjectSelectorId);
  const project = useAppSelector(activeProjectSelector);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tags = useAppSelector(selectAll);
  const tagsLoading = useAppSelector(tagsIsFetchingSelector);
  const isTagsLoaded = useAppSelector(isTagsFetchedSelector);
  const isIncomplete = useAppSelector(isProjectStatusIncomplete);
  const trackEvent = useTrackEvent();

  const [quillInstance, setQuillInstance] = useState<ReactQuill | null>(null);
  const [editorActiveView, setEditorActiveView] = useState<EditorViews>(
    EditorViews.Text
  );
  const [blotsInitialized, setBlotsInitialized] = useState(false);
  const [pageLoadInitial, setPageInitialLoad] = useState(true);

  useEffect(() => {
    if (!projectId) {
      let projectId = '';
      dispatch(fetchProjectCaches(id));
      dispatch(fetchProject(id))
        .unwrap()
        .then((res) => {
          if (res && res.id) {
            projectId = res.id;
            const { apiVersion } = res;
            // if the project is V2, redirect to the V2 editor
            if (apiVersion === 'V2') {
              navigate('/editor/v2/' + projectId);
              return;
            }
            if (res.projectStatus === 'incomplete') return;
            return dispatch(fetchChapters(projectId)).unwrap();
          }
          navigate('/');
        })
        .then((res) => {
          if (res && res.length) {
            let paramId = searchParams.get('chapterId');
            let chapterActive = paramId ? paramId : res[0].id;

            if (!paramId) {
              setSearchParams({
                chapterId: chapterActive,
              });
              paramId = chapterActive;
            }

            const isParamInChapter = res.find((item) => item.id === paramId);

            if (!isParamInChapter) {
              throw new Error('Not Valid Chapter');
            }

            return dispatch(
              setActiveChapter({ chapterId: chapterActive, projectId })
            );
          }
        })
        .catch(() => {
          navigate('/');
        });
    }
  }, [id, projectId, searchParams, navigate, setSearchParams, dispatch]);

  useEffect(() => {
    if (!blotsInitialized && isTagsLoaded) {
      // Register static Blots
      Quill.register(BookmarkIconBlot, true);
      Quill.register(FoundTextTOCBlot, true);
      Quill.register(FoundTextTOCCurrentBlot, true);
      Quill.register(FoundTextBlot, true);
      Quill.register(FoundTextCurrentBlot, true);
      // Quill.register(SelectionBlot);
      Quill.register(PresetBlot, true);
      Quill.register(SkipBlot, true);
      Quill.register(PageBlot, true);
      Quill.register(InsertBlot, true);
      Quill.register(CommentBlot, true);
      Quill.register(ChapterIconBlot, true);

      // Register Modules
      Quill.register('modules/keyboard', KeyboardModule, true);
      // Quill.register('modules/selection', SelectionModule, true);
      Quill.register('modules/history', HistoryModule, true);

      // Register dynamic blots
      tags.forEach((tag) => {
        const Blot = genericSSMLBlotFactory(tag);
        Quill.register(Blot, true);
      });
      setBlotsInitialized(true);
    }
  }, [blotsInitialized, isTagsLoaded, tags]);

  useEffect(() => {
    if (project) {
      batch(() => {
        if (project.projectStatus !== 'incomplete') {
          dispatch(fetchPresets(project.id));
          dispatch(fetchBookmarks(project.id));
          dispatch(fetchcomments(project.id));
        }
        dispatch(fetchTags(project.ttsEngine));
      });
      setPageInitialLoad(false);
    }
  }, [project, dispatch]);

  useEffect(() => {
    // This useEffect stands for tracking project opening.
    // Pay attention: in case of updating the React version to the 18th or higher,
    // the tracker is going to be called twice!
    // Please use any possible workaround to avoid it!
    if (project) {
      const { chapters } = project;
      if (!chapters) return;
      const bookLength = {
        chapters: Object.values(chapters).length,
      };
      trackEvent({
        eventName: AnalyticsEvent.OpenProject,
        customProperties: {
          bookLength,
          viewType: 'visual',
        },
      });
    }
    // Project is the only dependency which should be.
    // Don't remove the es-lint ignore!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    // editor Cleanups
    return () => {
      dispatch(resetTags());
      dispatch(resetPresets());
      dispatch(resetBookmarks());
      dispatch(resetComments());
      dispatch(resetChapters());
      dispatch(setProjectInactive());
      removeDownloadableIframes();
    };
  }, [dispatch]);

  return (
    <QuillContext.Provider
      value={{
        quillInstance,
        setQuillInstance,
      }}
    >
      {tagsLoading || pageLoadInitial ? (
        <Stack className="h-100" justifyContent="center" alignItems="center">
          <Loader
            loadingPartColor="#031E50"
            loadingBackSet="#ffffff"
            size="50px"
            loaderWidth="5px"
          />
        </Stack>
      ) : (
        <>
          <Page
            hideScroll
            header={<Header />}
            actions={
              isIncomplete ? (
                <IncompleteProjectActions />
              ) : (
                <Actions
                  editorView={editorActiveView}
                  onEditorViewChange={setEditorActiveView}
                />
              )
            }
            footer={!isIncomplete && <Footer />}
            sidebar={
              isIncomplete ? (
                <IncompleteProjectSidebar />
              ) : (
                <Sidebar isSSML={editorActiveView === EditorViews.SSML} />
              )
            }
          >
            {blotsInitialized && (
              <Editor isSSML={editorActiveView === EditorViews.SSML} />
            )}
          </Page>
          <Notice />
        </>
      )}
    </QuillContext.Provider>
  );
};
