import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Styles from './auth.module.scss';
import { forgotPassword } from 'aws';
import { Button } from 'modules/common/components/Button/Button';
import { Input } from 'modules/common/components/Input/Input';
import { Stack } from 'modules/common/components/Stack/Stack';
import { getForgotPasswordFormSchema } from './validation-schemas';
import { Alert } from 'modules/common/components/Alert/Alert';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Form from 'modules/common/components/Form/Form';
import useLoading from 'modules/common/hooks/useLoading';

interface ForgotPasswordForm {
  email: string;
}

export const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const {
    isLoading,
    setLoading,
    setError,
    errorMessage: error,
    isError,
  } = useLoading();

  const methods = useForm<ForgotPasswordForm>({
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(getForgotPasswordFormSchema()),
  });

  const {
    formState: { errors },
    setValue
  } = methods;

  const onSubmit = async (values: ForgotPasswordForm) => {
    try {
      setLoading(true);
      await forgotPassword(values.email);
      setLoading(false);
      const encodedEmail = encodeURIComponent(values.email);
      navigate(`/auth/reset-password?username=${encodedEmail}`);
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <div className={Styles['auth-form']}>
      <h4 className="fw-700 font-size-lg ta-center m-b-5">Forgot Password</h4>
      {isError && (
        <Alert className="m-b-2" type="error">
          {error}
        </Alert>
      )}
      <Form<ForgotPasswordForm> onSubmit={onSubmit} methods={methods}>
        <p className="m-b-1 font-size-sm fw-600">Email</p>
        <Input
          large
          type="text"
          name="email"
          placeholder="Enter email"
          autoComplete="off"
          onInput={v => setValue('email', v)}
        />
        <p className="m-b-4 font-size-xs color-error">
          {errors.email?.message}
        </p>

        <Stack justifyContent="space-between" alignItems="center">
          <Stack.Item>
            <Button type="button" secondary>
              <Link to="/auth/login">Cancel</Link>
            </Button>
          </Stack.Item>
          <Stack.Item>
            <Button type="submit" disabled={isLoading}>
              Continue
            </Button>
          </Stack.Item>
        </Stack>
      </Form>
    </div>
  );
};
