import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import Styles from './notification.module.scss';
import createContainer from 'modules/common/components/Notify/createContainer';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Dict } from 'modules/common/types';
import { IconNameType } from 'icons/build/icons';
import { Icon } from 'icons';

export enum NotificationType {
  'info' = 'info',
  'success' = 'success',
  'warning' = 'warning',
  'error' = 'error',
}

export interface Props {
  color: NotificationType;
  onDelete: () => void;
  autoClose?: boolean;
  timeToDelete?: number;
  timeToClose?: number;
}

const container = createContainer();

const Notification: FC<Props> = ({
  children,
  onDelete,
  autoClose = true,
  color = NotificationType.info,
  timeToDelete = 300,
  timeToClose = 10000,
}) => {
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const icons: Dict<IconNameType> = {
    [NotificationType.info]: 'info_outline',
    [NotificationType.warning]: 'warning_outline',
    [NotificationType.error]: 'close_outline',
    [NotificationType.success]: 'check_circle_outline',
  };

  useEffect(() => {
    if (autoClose) {
      const timeoutId = setTimeout(() => setIsClosing(true), timeToClose);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [autoClose, timeToClose]);

  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(onDelete, timeToDelete);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isClosing, onDelete, timeToDelete]);

  return createPortal(
    <Stack
      alignItems="center"
      justifyContent="center"
      className={classnames(
        [Styles['notification'], Styles[color]],
        { [Styles['slideIn']]: !isClosing },
        { [Styles['slideOut']]: isClosing }
      )}
    >
      <Stack.Item>
        <Stack alignItems="center">
          <Icon
            className={classnames(Styles['alert-icon'], 'm-r-1')}
            name={icons[color]}
            size={20}
          />
          <div className={Styles['text']}>{children}</div>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <button
          className={Styles['close-button']}
          onClick={() => setIsClosing(true)}
        >
          <span className={Styles['close-icon']}>&#x2715;</span>
        </button>
      </Stack.Item>
    </Stack>,
    container
  );
};

export default Notification;
