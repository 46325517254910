import { cleanPercentage, DEFAULT_CIRCLE_SIZE, getCircleColour } from './lib';
import { Circle, Text } from './ui';
const ProgressCircle = ({
  percentage,
  circleSize = DEFAULT_CIRCLE_SIZE,
}: {
  percentage: string;
  circleSize?: number;
}) => {
  const pct = cleanPercentage(percentage ? Number(percentage) : 0);
  const colour = getCircleColour({ percentage: pct });
  return (
    <svg width={circleSize} height={circleSize}>
      <g transform={`rotate(-90 ${circleSize / 2} ${circleSize / 2})`}>
        <Circle colour="#D3D3D3" />
        <Circle colour={colour} percentage={pct} />
      </g>
      <Text percentage={pct} />
    </svg>
  );
};

export default ProgressCircle;
