import { useIsAdminRole } from 'modules/common/hooks/useIsAdminRole';
import { useIsEditorRole } from 'modules/common/hooks/useIsEditorRole';
import { useAppSelector } from 'modules/common/hooks/redux';
import {
  isProjectStatusDecline,
  isProjectStatusDone,
  isProjectStatusNew,
  isProjectStatusPending,
  isWebSiteUpToDateSelector,
} from 'redux/reducers/project/selectors';

export function useCanEdit(): boolean {
  const isAdminRole = useIsAdminRole();
  const isEditorRole = useIsEditorRole();

  const isNew = useAppSelector(isProjectStatusNew);
  const isPending = useAppSelector(isProjectStatusPending);
  const isDecline = useAppSelector(isProjectStatusDecline);
  const isDone = useAppSelector(isProjectStatusDone);
  const isWebSiteUptoDate = useAppSelector(isWebSiteUpToDateSelector);

  if (isAdminRole) return false;

  return (
    isEditorRole &&
    !isNew &&
    !isPending &&
    !isDone &&
    !isDecline &&
    isWebSiteUptoDate
  );
}
