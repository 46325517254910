import classNames from 'classnames';
import { Icon } from 'icons';
import { IconNameType } from 'icons/build/icons';
import { FC } from 'react';
import { Stack, StackProps } from '../Stack/Stack';

import Styles from './alert.module.scss';

interface AlertProps {
  type: 'warning' | 'error';
  icon?: IconNameType;
  className?: string;
  spacing?: StackProps['spacing'];
}

export const Alert: FC<AlertProps> = ({
  children,
  type,
  className,
  icon,
  spacing = 2,
}) => {
  return (
    <Stack
      spacing={spacing}
      alignItems="center"
      className={classNames(
        Styles.alert,
        {
          [Styles[`alert-${type}`]]: type,
        },
        className
      )}
    >
      {icon && (
        <Stack.Item shrink={0}>
          <Icon
            color={type === 'warning' ? '#EAB325' : undefined}
            name={icon}
            size={20}
          />
        </Stack.Item>
      )}
      <Stack.Item>{children}</Stack.Item>
    </Stack>
  );
};
