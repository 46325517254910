import { Stack } from 'modules/common/components/Stack/Stack';
import { useScreenSize } from 'modules/common/hooks/useScreenSize';
import { IncompleteProjectPagination } from './IncompleteProjectPagination';

export const IncompleteProjectActions = () => {
  const { isTabletOrMobile } = useScreenSize();

  return isTabletOrMobile ? null : (
    <div className="container">
      <Stack justifyContent="flex-end">
        <Stack.Item>
          <IncompleteProjectPagination />
        </Stack.Item>
      </Stack>
    </div>
  );
};
