import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import classNames from 'classnames';

import Styles from './button.module.scss';
import { IconNameType } from 'icons/build/icons';
import { Icon } from 'icons';
import { Stack } from 'modules/common/components/Stack/Stack';
import Loader from 'modules/common/components/Loader/Loader';
import classnames from 'classnames';

interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  small?: boolean;
  large?: boolean;
  primary?: boolean;
  secondary?: boolean;
  danger?: boolean;
  full?: boolean;
  fill?: 'solid' | 'subtle';
  icon?: IconNameType;
  iconSize?: number;
  inline?: boolean;
  loading?: boolean;
  flexLoader?: boolean;
}

export const Button: FC<ButtonProps> = (props) => {
  const {
    children,
    className,
    small,
    large,
    primary = true,
    secondary,
    full = false,
    fill = 'solid',
    flexLoader = false,
    icon,
    iconSize = 20,
    danger,
    inline,
    loading,
    ...rest
  } = props;

  const buttonClasses = classNames(Styles.button, className, {
    [Styles['btn-small']]: small,
    [Styles['btn-large']]: large,
    [Styles['btn-primary']]: primary,
    [Styles['btn-secondary']]: secondary,
    [Styles['btn-full']]: full,
    [Styles['btn-subtle']]: fill === 'subtle',
    [Styles['btn-icon']]: icon,
    [Styles['btn-inline']]: inline,
    [Styles['btn-danger']]: danger,
  });

  return (
    <button {...rest} className={buttonClasses}>
      {icon ? (
        <Icon size={iconSize} name={icon} />
      ) : (
        <Stack justifyContent="center" alignItems="center" spacing="1">
          {loading && (
            <Stack.Item
              className={classnames({ [Styles['flex']]: flexLoader })}
            >
              <Loader loadingBackSet="#000000" loadingPartColor="#ffffff" />
            </Stack.Item>
          )}
          <Stack.Item>{children}</Stack.Item>
        </Stack>
      )}
    </button>
  );
};
