import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import classnames from 'classnames';

import Styles from './tabs.module.scss';

export interface TabProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  active?: boolean;
}

export const Tab: FC<TabProps> = ({
  active,
  className,
  disabled,
  ...props
}) => {
  const tabClasses = classnames(
    classnames(Styles['tab'], className, {
      [Styles['tab-active']]: active,
      [Styles['tab-disabled']]: disabled,
    })
  );

  return (
    <button
      className={tabClasses}
      aria-current={active ? 'page' : false}
      aria-disabled={disabled}
      role="tab"
      tabIndex={disabled ? undefined : 0}
      {...props}
      onClick={(e) => {
        if (!!disabled) return;
        props.onClick?.(e);
      }}
    />
  );
};
