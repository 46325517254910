import { FC, SyntheticEvent } from 'react';

import { Backdrop } from '../Backdrop/Backdrop';
import { Portal } from '../Portal/Portal';

import Styles from './overlay.module.scss';

export interface OverlayProps {
  backdrop?: boolean;
  open?: boolean;
  portal?: boolean;
  onClose?: (e: SyntheticEvent<HTMLElement>) => void;
}

export const Overlay: FC<OverlayProps> = ({
  children,
  backdrop,
  open,
  portal,
  onClose,
}) => {
  if (!open) {
    return null;
  }

  return (
    <Portal conditional={portal}>
      <div className={Styles.overlay}>
        {backdrop && <Backdrop onClick={onClose} open={open} />}
        {children}
      </div>
    </Portal>
  );
};
