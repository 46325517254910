import React from 'react';
import classnames from 'classnames';
import { Icon } from 'icons';
import { Button } from 'modules/common/components/Button/Button';
import { Checkbox } from 'modules/common/components/Checkbox/Checkbox';
import { LabelValue, SelectValueBoolean } from 'modules/common/types';
import {
  ElevenLabsVoiceSettings,
  ElevenLabsVoiceSettingsParam,
} from 'modules/projects/Project/types';
import { PopoverInfo } from './VoiceSettingsItem/Description/PopoverInfo';
import { DESCRIPTIONS, VOICE_ADDITIONAL_SETTINGS } from './lib';
import { VoiceSettingsItem } from './VoiceSettingsItem';
import Styles from './voicesettings.module.scss';

interface Props {
  label: React.ReactNode;
  isSelectedModelMatchRecommended: boolean;
  modelSelected: LabelValue | null;
  values: ElevenLabsVoiceSettings;
  defaultValues: ElevenLabsVoiceSettings | undefined;
  isEnableDisclosure?: boolean;
  className?: string;
  labelClassName?: string;
  onVoiceSettingsChange(value: ElevenLabsVoiceSettings): void;
}

export const VoiceSettingsDisclosure: React.FC<Props> = ({
  label,
  isSelectedModelMatchRecommended,
  modelSelected,
  values,
  defaultValues,
  isEnableDisclosure = true,
  className,
  labelClassName,
  onVoiceSettingsChange,
}) => {
  const [isDisclosureOpen, setIsDisclosureOpen] = React.useState(false);

  const toggleDisclosureState = () =>
    isEnableDisclosure && setIsDisclosureOpen((prevState) => !prevState);

  const onVoiceSettingsChangeHandler = (value: SelectValueBoolean) => {
    if (value) onVoiceSettingsChange({ ...values, [value.label]: value.value });
  };

  const isMultilingualV2ModelSelected =
    modelSelected?.value === 'eleven_multilingual_v2';

  const styleExaggerationVoiceSettings = isSelectedModelMatchRecommended
    ? VOICE_ADDITIONAL_SETTINGS.style
    : {
        unstableRange: {
          from: '0.0',
          to: '1',
        },
      };

  return (
    <div className={classnames(Styles['Overlay'], className)}>
      <div
        className={classnames(Styles['Label'], labelClassName)}
        onClick={toggleDisclosureState}
      >
        {label}
        {isEnableDisclosure && <Icon name="arrow_drop_down" />}
      </div>
      {(!isEnableDisclosure || isDisclosureOpen) && (
        <div className={classnames(Styles['Wrapper'], className)}>
          <div className={Styles['Body']}>
            <VoiceSettingsItem
              value={values[ElevenLabsVoiceSettingsParam.stability]}
              name={ElevenLabsVoiceSettingsParam.stability}
              label="Stability"
              descriptions={DESCRIPTIONS.stability}
              voiceSettings={VOICE_ADDITIONAL_SETTINGS.stability}
              onChange={onVoiceSettingsChangeHandler}
            />
            <VoiceSettingsItem
              value={values[ElevenLabsVoiceSettingsParam.similarity_boost]}
              name={ElevenLabsVoiceSettingsParam.similarity_boost}
              label="Clarity + Similarity"
              descriptions={DESCRIPTIONS.similarity_boost}
              voiceSettings={VOICE_ADDITIONAL_SETTINGS.similarity_boost}
              onChange={onVoiceSettingsChangeHandler}
            />
            {isMultilingualV2ModelSelected && (
              <VoiceSettingsItem
                value={values[ElevenLabsVoiceSettingsParam.style]}
                name={ElevenLabsVoiceSettingsParam.style}
                label="Style Exaggeration"
                descriptions={DESCRIPTIONS.style}
                voiceSettings={styleExaggerationVoiceSettings}
                onChange={onVoiceSettingsChangeHandler}
              />
            )}
          </div>
          <div className={Styles['Footer']}>
            {isMultilingualV2ModelSelected && (
              <div className={Styles['FooterSpeakerBoost']}>
                <Checkbox
                  checked={
                    values[ElevenLabsVoiceSettingsParam.use_speaker_boost]
                  }
                  onChange={(v) =>
                    onVoiceSettingsChangeHandler({
                      label: ElevenLabsVoiceSettingsParam.use_speaker_boost,
                      value: v,
                    })
                  }
                />
                <div className={Styles['FooterSpeakerBoostLabel']}>
                  Speaker Boost
                </div>
                <PopoverInfo>
                  Boost the similarity of the synthesized speech and the voice
                  at the cost of some generation speed.
                </PopoverInfo>
              </div>
            )}
            {defaultValues && (
              <Button
                secondary
                small
                onClick={() => onVoiceSettingsChange(defaultValues)}
              >
                To Default
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
