import React from 'react';
import Styles from '../chapters.module.scss';

interface Props {
  isShow: boolean;
}

export const AddChapterTooltip: React.FC<Props> = ({ isShow }) => {
  if (!isShow) return null;

  return (
    <div className={Styles['add-chapter-button-tooltip']}>
      Put cursor in the text to define start of the chapter
    </div>
  );
};
