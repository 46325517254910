import { FC, RefCallback, useCallback, useRef } from 'react';
import { Notification } from 'modules/common/models/Notifications';
import { Stack } from 'modules/common/components/Stack/Stack';
import Loader from 'modules/common/components/Loader/Loader';
import NotificationItem from './NotificationItem';

import Styles from './notificaitons.module.scss';

interface Props {
  notifications: Notification<any>[];
  isFetching: boolean;
  setNextPage: () => void;
  hasMore: boolean;
  readANotification: (id: string) => void;
}

const NotificationList: FC<Props> = ({
  notifications,
  isFetching,
  setNextPage,
  hasMore,
  readANotification,
}) => {
  const observerRef = useRef<IntersectionObserver>();

  const refLastElement: RefCallback<HTMLDivElement> = useCallback(
    (node) => {
      if (isFetching) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setNextPage();
        }
      });

      if (node) observerRef.current?.observe(node);
    },
    [isFetching, hasMore, setNextPage, observerRef]
  );

  return (
    <div className={Styles['container']}>
      <div className={Styles['header']}>Notifications</div>
      <div className={Styles['body']}>
        {!notifications.length && <div>No Data found</div>}
        {notifications?.map((notification, index) => {
          if (index + 1 === notifications.length) {
            return (
              <div ref={refLastElement} key={notification.id + index}>
                <NotificationItem
                  notification={notification}
                  readANotification={readANotification}
                />
              </div>
            );
          }
          return (
            <NotificationItem
              notification={notification}
              key={notification.id + index}
              readANotification={readANotification}
            />
          );
        })}

        {isFetching ? (
          <Stack justifyContent="center" className={Styles['loader-container']}>
            <Loader
              loadingPartColor="#031E50"
              loadingBackSet="#ffffff"
              size="30px"
            />
          </Stack>
        ) : null}
      </div>
    </div>
  );
};

export default NotificationList;
