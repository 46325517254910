export enum SocketEventType {
  All = 'All',
  Error = 'Error',
  comment = 'comment',
  status = 'status',
}

export interface Notification<T> {
  id: string;
  projectId: string;
  projectTitle: string;
  message: T;
  isRead: boolean;
  createdAt: string;
  updatedAt: string;
  userId?: string;
}

export interface SocketNotification<T> {
  notification_type: SocketEventType;
  value: Notification<T>;
}
