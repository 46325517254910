import React, { FC, ReactNode } from 'react';
import ReactSelect, {
  ActionMeta,
  components,
  PropsValue,
  SingleValue,
} from 'react-select';

import { Icon } from 'icons';
import classNames from 'classnames';
import { Options } from 'react-select/dist/declarations/src/types';
import { DropdownIndicatorProps } from 'react-select/dist/declarations/src/components/indicators';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import { LabelValue, SelectValue } from 'modules/common/types';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

export interface SelectProps {
  size?: 'small' | 'normal' | 'large';
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  maxMenuHeight?: number;
  components?: any;
  isOptionDisabled?: (
    option: SelectValue,
    selectValue: Options<SelectValue>
  ) => boolean;
  value:
    | PropsValue<{
        value: string;
        label: string;
      }>
    | undefined;
  onChange:
    | ((
        newValue: SingleValue<{
          value: string;
          label: string;
        }>,
        actionMeta: ActionMeta<{
          value: string;
          label: string;
        }>
      ) => void)
    | undefined;
  options: LabelValue[];
  isLoading?: boolean;
  isSearchable?: boolean;
  onMenuOpen?: () => void;
  isClearable?: boolean;
  portal?: boolean;
  formatOptionLabel?(
    data: LabelValue,
    formatOptionLabelMeta: FormatOptionLabelMeta<LabelValue>
  ): ReactNode;
  filterOption?(
    option: FilterOptionOption<{ value: string; label: string }>,
    inputValue: string
  ): boolean;
}

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="arrow_drop_down" />
    </components.DropdownIndicator>
  );
};

export const Select: FC<SelectProps> = ({
  value,
  options,
  size = 'normal',
  maxMenuHeight,
  disabled,
  placeholder,
  onChange,
  isLoading,
  isSearchable = false,
  className,
  onMenuOpen,
  isOptionDisabled,
  components,
  isClearable,
  portal,
  formatOptionLabel,
  filterOption,
}) => {
  const classStyleNames = classNames('react-select-container', {
    disabled: disabled,
    [size]: size,
    [className ? className : '']: !!className,
  });

  return (
    <ReactSelect
      placeholder={placeholder}
      value={value}
      className={classStyleNames}
      classNamePrefix="react-select"
      components={{
        DropdownIndicator,
        ...components,
      }}
      maxMenuHeight={maxMenuHeight}
      onChange={onChange}
      options={options}
      isMulti={false}
      formatOptionLabel={formatOptionLabel}
      filterOption={filterOption}
      closeMenuOnSelect
      isSearchable={isSearchable}
      isLoading={isLoading}
      isDisabled={disabled}
      onMenuOpen={onMenuOpen}
      isOptionDisabled={isOptionDisabled}
      isClearable={isClearable}
      menuPortalTarget={portal ? document.body : undefined}
      menuShouldBlockScroll={portal}
    />
  );
};
