export class Normalizers {
  private static speedMinLimit = 0;
  private static speedMaxLimit = 200;
  private static volumeMinLimit = -99;
  private static volumeMaxLimit = 99;
  static initialSpeed = '100%';
  static initialVolume = '0dB';
  static initialVolumeAzure = '0';

  private static roundToTwo(num: number) {
    return +(Math.round(Number(num + 'e+2')) + 'e-2');
  }

  private static percentageToSSML(
    min: number,
    max: number,
    numberInPercent: number
  ) {
    return Normalizers.roundToTwo(((max - min) * numberInPercent) / 200 + min);
  }

  private static ssmlToPercentage(
    min: number,
    max: number,
    numberInSSML: number
  ) {
    return Normalizers.roundToTwo(((numberInSSML - min) / (max - min)) * 200);
  }

  static getSSMLSpeed(number: number): string {
    return `${Normalizers.percentageToSSML(
      Normalizers.speedMinLimit,
      Normalizers.speedMaxLimit,
      number
    )}%`;
  }

  static getSSMLVolume(number: number): string {
    const formattedValue = Normalizers.percentageToSSML(
      Normalizers.volumeMinLimit,
      Normalizers.volumeMaxLimit,
      number
    );

    if (formattedValue >= 0) {
      return `+${formattedValue}dB`;
    }

    return `${formattedValue}dB`; // sign is included
  }

  static getSSMLVolumeForAzure(number: number): string {
    const formattedValue = Normalizers.percentageToSSML(
      Normalizers.volumeMinLimit,
      Normalizers.volumeMaxLimit,
      number
    );

    if (formattedValue >= 0) {
      return `+${formattedValue}`;
    }

    return `${formattedValue}`; // sign is included
  }

  static getPercentageSpeed(number: number): number {
    return Normalizers.ssmlToPercentage(
      Normalizers.speedMinLimit,
      Normalizers.speedMaxLimit,
      number
    );
  }

  static getPercentageVolume(number: number): number {
    return Normalizers.ssmlToPercentage(
      Normalizers.volumeMinLimit,
      Normalizers.volumeMaxLimit,
      number
    );
  }
}
