import { FC } from 'react';

const WellSaidIcon: FC = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 24V14.0588C45.349 11.3137 41.051 11.3137 38.4 14.0588L33.6 19.0296L28.8 14.0588C26.149 11.3137 21.851 11.3137 19.2 14.0588L14.4 19.0296L9.6 14.0588C6.94896 11.3137 2.65104 11.3137 0 14.0588V24C2.45184 26.5387 2.45184 26.5387 0 24L9.6 33.9412C12.251 36.6863 16.549 36.6863 19.2 33.9412L24 28.9704L28.8 33.9412C31.451 36.6863 35.749 36.6863 38.4 33.9412L48 24C45.8016 26.2766 45.8016 26.2761 48 24"
        fill="#38EF7D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3698 23.9726C35.7638 21.2405 31.4227 21.2885 28.8 24.0043L24 28.9747L28.8 33.945C31.44 36.6791 35.76 36.6791 38.4 33.945L48 24.0043C45.3389 26.76 41.0184 26.7494 38.3698 23.9726"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2 14.0631L9.6 24.0043C6.94896 26.7494 2.65104 26.7494 0 24.0043L9.6 33.945C12.24 36.6791 16.56 36.6791 19.2 33.945L28.8 24.0043C31.451 21.2592 35.749 21.2592 38.4 24.0043L33.6 19.0335L28.8 14.0631C26.16 11.3295 21.84 11.3295 19.2 14.0631"
        fill="black"
        fillOpacity="0.1"
      />
    </svg>
  );
};

export default WellSaidIcon;
