import React from 'react';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { ParsedVoiceValue } from 'modules/common/types';
import LabelSelect, {
  ILabelSelect,
} from 'modules/common/components/LabeledInputs/LabelSelect';
import { VoiceSelectOptionLabel } from './OptionLabel';
import { AudioPlayerProjectProps } from 'modules/common/hooks/useAudioPlayerProject';

interface VoiceSelectProps extends ILabelSelect {
  playerProps?: AudioPlayerProjectProps;
  previewText?: string;
  options: Array<ParsedVoiceValue>;
}

export const VoiceSelect: React.FC<VoiceSelectProps> = ({
  playerProps,
  previewText,
  ...props
}) => {
  const formattedLabel = (
    data: ParsedVoiceValue,
    formatOptionLabelMeta: FormatOptionLabelMeta<ParsedVoiceValue>
  ) => (
    <VoiceSelectOptionLabel
      playerProps={playerProps}
      previewText={previewText}
      data={data}
      formatOptionLabelMeta={formatOptionLabelMeta}
    />
  );

  const filterOption = (
    option: FilterOptionOption<ParsedVoiceValue>,
    inputValue: string
  ) => {
    const lowerCaseInputValue = inputValue.toLowerCase();
    const labelsValues = option.data.voiceLabels
      ? Object.values(option.data.voiceLabels)
      : undefined;
    if (!labelsValues || !labelsValues.length) {
      return option.label.toLowerCase().includes(lowerCaseInputValue);
    }
    return [...labelsValues, option.label].some((label) =>
      label.toLowerCase().includes(lowerCaseInputValue)
    );
  };

  return (
    <LabelSelect
      formatOptionLabel={formattedLabel}
      filterOption={filterOption}
      {...props}
    />
  );
};
