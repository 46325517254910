import { createContext } from 'react';

interface ISidebarContext {
  setShowAlert: (show: boolean) => void;
  showAlert: boolean;
}

const SidebarContext = createContext({} as ISidebarContext);

export default SidebarContext;
