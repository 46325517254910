import {
  FC,
  useCallback,
  useState,
  useRef,
  KeyboardEvent,
  MouseEvent,
} from 'react';

import { Button } from 'modules/common/components/Button/Button';
import { Stack } from 'modules/common/components/Stack/Stack';
import { BookmarkModel } from 'modules/common/models/Bookmark';
import { ActionMenu } from 'modules/common/components/ActionMenu/ActionMenu';
import { Icon } from 'icons';

import Styles from './bookmarks.module.scss';
import Loader from 'modules/common/components/Loader/Loader';
import { useAppSelector } from 'modules/common/hooks/redux';
import { bookMarkIsEditingSelector } from 'redux/reducers/bookmarks/selectors';
import { Input } from 'modules/common/components/Input/Input';
import { selectById } from 'redux/reducers/chapters/selectors';
import { useActions } from 'modules/common/hooks/useActions';

export const BookmarkItem: FC<{
  bookmark: BookmarkModel;
  onDelete: (bookmarkId: string) => void;
  onEdit: (bookmarkId: string, value: BookmarkModel) => void;
  onClick: (bookmarkId: string) => void;
}> = ({ bookmark, onDelete, onEdit, onClick }) => {
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    actionMenuOpen,
    handleCloseActions,
    handleOpenActions,
    setActionMenuOpen,
    hovered,
    handleMouseEnter,
    handleMouseLeave,
  } = useActions();

  const isEditingLoading = useAppSelector(bookMarkIsEditingSelector);
  const chapter = useAppSelector((state) =>
    selectById(state, bookmark.chapter)
  );

  const handleDelete = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onDelete(bookmark.id);
      setActionMenuOpen(false);
    },
    [bookmark.id, setActionMenuOpen, onDelete]
  );

  const handleEditMode = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setActionMenuOpen(false);
      setEditMode(true);
    },
    [setActionMenuOpen]
  );

  const handleEdit = useCallback(async () => {
    if (inputRef.current?.value.length) {
      const newBookmark: BookmarkModel = {
        ...bookmark,
        title: inputRef.current.value,
      };
      await onEdit(bookmark.id, newBookmark);
    }
  }, [bookmark, onEdit]);

  const handleEditDone = useCallback(
    async (e: MouseEvent) => {
      e.stopPropagation();
      await handleEdit();
      setEditMode(false);
    },
    [handleEdit]
  );

  const handleKeyDown = useCallback(
    async (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        await handleEdit();
      }
    },
    [handleEdit]
  );

  const handleClick = useCallback(() => {
    onClick(bookmark.id);
  }, [bookmark.id, onClick]);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={Styles['bookmark-item']}
      onClick={handleClick}
    >
      <Stack alignItems="flex-start" spacing={2} justifyContent="space-between">
        <Stack.Item fill>
          {editMode ? (
            <Stack
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack.Item fill>
                <Input
                  ref={inputRef}
                  type="text"
                  autoFocus
                  defaultValue={bookmark.title}
                  onKeyDown={handleKeyDown}
                />
              </Stack.Item>
              <Stack.Item>
                <Button onClick={handleEditDone}>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing="1"
                  >
                    {isEditingLoading ? (
                      <Stack.Item>
                        <Loader
                          loadingBackSet="#000000"
                          loadingPartColor="#ffffff"
                        />
                      </Stack.Item>
                    ) : (
                      <Stack.Item>
                        <Icon name="done" size={20} />
                      </Stack.Item>
                    )}
                  </Stack>
                </Button>
              </Stack.Item>
            </Stack>
          ) : (
            <h4 className={Styles['bookmark-title']}>{bookmark.title}</h4>
          )}
          <p className={Styles['bookmark-chapter']}>{chapter?.chapterName}</p>
        </Stack.Item>
        {!editMode && hovered && (
          <Stack.Item>
            <ActionMenu
              direction="bl"
              portal
              open={actionMenuOpen}
              width="xs"
              trigger={
                <Icon onClick={handleOpenActions} name="more_horiz" size={20} />
              }
              onClickOutside={handleCloseActions}
            >
              <ActionMenu.Item onClick={handleEditMode}>Rename</ActionMenu.Item>
              <ActionMenu.Item onClick={handleDelete}>Delete</ActionMenu.Item>
            </ActionMenu>
          </Stack.Item>
        )}
      </Stack>
    </div>
  );
};
