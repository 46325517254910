import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';

import SortableInputCell from 'modules/common/components/Table/TableCells/SortableInputCell/SortableInputCell';
import {
  EditorCapacity,
  EditorStatus,
  TablesConfigParamWithFilters,
} from 'modules/common/types';
import {
  getEditorAvailabilityList,
  getEditorsStatus,
} from 'modules/common/constants/functions';
import SelectCell from 'modules/common/components/Table/TableCells/SelectCell/SelectCell';
import SortableSelectCell from 'modules/common/components/Table/TableCells/SortableSelectCell/SortableSelectCell';

export interface Editor {
  editorCapacity: string;
  editorName: string;
  id: string;
  email: string;
  editorStatus: EditorStatus | string; // TODO fix this
}

export function getEditorsTableColumnsConfig({
  changeSortBy,
  sortBy,
  sortType,
}: TablesConfigParamWithFilters) {
  const columnHelper = createColumnHelper<Editor>();

  return [
    columnHelper.accessor('editorName', {
      header: (info) => (
        <SortableInputCell
          text="Name"
          accessorName={info.header.column.id}
          onSortChange={changeSortBy}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          sort={sortBy === info.header.column.id ? sortType : undefined}
        />
      ),
    }),
    columnHelper.accessor('editorCapacity', {
      header: (info) => {
        return (
          <SelectCell
            text="Capacity"
            onChangeValue={info.column.setFilterValue}
            data={getEditorAvailabilityList()}
            value={(info.column.getFilterValue() as string) || ''}
          />
        );
      },
      cell: (info) => {
        const value = info.getValue();
        return parseInt(value) >= parseInt(EditorCapacity.Five) ? (
          <>
            <span className="color-error m-r-1">Unavailable</span> {value}/5
          </>
        ) : (
          `${value}/5`
        );
      },
    }),
    columnHelper.accessor('email', {
      header: (info) => (
        <SortableInputCell
          text="Email"
          accessorName={info.header.column.id}
          onSortChange={changeSortBy}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          sort={sortBy === info.header.column.id ? sortType : undefined}
        />
      ),
    }),
    columnHelper.accessor('editorStatus', {
      header: (info) => (
        <SortableSelectCell
          text="Status"
          accessorName={info.header.column.id}
          onSortChange={changeSortBy}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          sort={sortBy === info.header.column.id ? sortType : undefined}
          data={getEditorsStatus()}
        />
      ),
      cell: (info) => {
        const value = info.getValue();
        return (
          <div
            className={classNames({
              'color-error': value === EditorStatus.Unavailable,
            })}
          >
            {value}
          </div>
        );
      },
    }),
  ];
}
