import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { AttributeTypes, TagModel, TagType } from 'modules/common/models/Tags';
import tagsApiInstance from 'modules/edit/api/Tags';
import { rejectRequest } from 'utils/rejectRequest';
import { filterTags } from './tagsApplySlice';
import { Normalizers } from 'modules/common/Normalizers/SpeedVolumeNormalizer';
import { TTSEngine } from 'modules/common/types';

export const TagsAdapter = createEntityAdapter<TagModel>({
  selectId: (tag) => tag.id,
});

const initialState = {
  ...TagsAdapter.getInitialState(),
};

export const fetchTags = createAsyncThunk<TagModel[], TTSEngine>(
  'tags/fetchTags',
  async (engine_name, { rejectWithValue, dispatch }) => {
    try {
      let tags: TagModel[] =
        engine_name !== 'wellsaid' &&
        engine_name !== 'elevenlabs' &&
        engine_name !== 'deepzen'
          ? [
              {
                iconName: 'speed',
                iconBgColor: 'rgba(97,52,0,.2)',
                displayName: 'Speed',
                iconColor: '#613400',
                attributes: [
                  {
                    name: 'rate',
                    id: 'SPEED_ATTR_UNIQUE_SAME_ID',
                    type: AttributeTypes.Integer,
                    displayName: 'Speed',
                    min: 0,
                    max: 200,
                    prefix: '',
                    postfix: '%',
                    defaultValue: Normalizers.initialSpeed.toString(),
                    converters: {
                      uiToSSML: 'getSSMLSpeed',
                      ssmlToUi: 'getPercentageSpeed',
                    },
                  },
                ],
                description: 'Apply speed based on text',
                id: 'prosodySpeed',
                name: 'prosody',
                allowedTags: ['*'],
                tagType: TagType.WordTags,
              },
              {
                iconName: 'volume_high',
                iconBgColor: 'rgba(117,117,117,.25)',
                displayName: 'Volume',
                iconColor: '#757575',
                attributes: [
                  {
                    name: 'volume',
                    id: 'VOLUME_ATTR_UNIQUE_SAME_ID',
                    type: AttributeTypes.Integer,
                    displayName: 'Volume',
                    min: 0,
                    max: 200,
                    postfix: '%',
                    prefix: '',
                    defaultValue:
                      engine_name === 'azure'
                        ? Normalizers.initialVolumeAzure
                        : Normalizers.initialVolume,
                    converters: {
                      uiToSSML:
                        engine_name === 'azure'
                          ? 'getSSMLVolumeForAzure'
                          : 'getSSMLVolume',
                      ssmlToUi: 'getPercentageVolume',
                    },
                  },
                ],
                description: 'Apply volume based on text',
                id: 'prosodyVolume',
                name: 'prosody',
                allowedTags: ['*'],
                tagType: TagType.WordTags,
              },
            ]
          : [];

      const data = await tagsApiInstance.getAll(engine_name);
      data.sentenceTags.forEach((t) => {
        t.tagType = TagType.SentenceTags;
        tags.push(t);
      });
      data.wordTags.forEach((t) => {
        t.tagType = TagType.WordTags;
        tags.push(t);
      });
      data.numTags.forEach((t) => {
        t.tagType = TagType.NumTags;
        tags.push(t);
      });
      // UI wanted initially to be filtered so
      dispatch(
        filterTags(tags.filter((t) => t.tagType === TagType.SentenceTags))
      );
      return tags;
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

const tagsSlice = createSlice({
  name: 'Tags',
  initialState,
  reducers: {
    resetTags: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTags.fulfilled, (state, { payload }) => {
      TagsAdapter.setAll(state, payload);
    });
  },
});

export const { resetTags } = tagsSlice.actions;

export default tagsSlice.reducer;
