import { FC, useCallback, useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill, Range } from 'react-quill';

import { Modal, ModalSizes } from 'modules/common/components/Modal/Modal';
import {
  PresetsModel,
  PresetTag,
  PresetTagAttribute,
} from 'modules/common/models/Presets';
import {
  AttributeModel,
  AttributeTypes,
  TagModel,
  TagType,
} from 'modules/common/models/Tags';
import { Input } from 'modules/common/components/Input/Input';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Button } from 'modules/common/components/Button/Button';
import { selectAll, selectEntities } from 'redux/reducers/tags/selectors';
import { presetIsEditingSelector } from 'redux/reducers/presets/selectors';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import { PresetBlot } from 'modules/edit/v2/Editor/Blots/PresetBlot';
import { deletePreset, editPreset } from 'redux/reducers/presets/presetsSlice';
import { TagContainer } from 'modules/edit/Tags/TagContainer';
import { Alert } from 'modules/common/components/Alert/Alert';
import Styles from 'modules/edit/Presets/preset.module.scss';
import { activeProjectSelectorId } from 'redux/reducers/project/selectors';
import { getLastValueInMap } from 'utils/map';

function getPresetsElementsById(id: string) {
  return document.querySelectorAll(
    `${PresetBlot.tagName}[${PresetBlot.dataAttributeName}="${id}"]`
  )!;
}

interface EditPresetModalProps {
  isOpen: boolean;
  setClose(): void;
  preset: PresetsModel;
  quillInstance: ReactQuill | null;
}

export const EditPresetModal: FC<EditPresetModalProps> = ({
  isOpen,
  preset,
  quillInstance,
  setClose,
}) => {
  const reduxDispatch = useAppDispatch();
  const tags = useAppSelector(selectAll);
  const tagEntities = useAppSelector(selectEntities);
  const projectId = useAppSelector(activeProjectSelectorId);

  const [presetName, setPresetName] = useState<string>(preset.name);

  const [selectedTags, setSelectedTags] = useState(() => {
    const map = new Map<string, PresetTag>();
    preset.tags.forEach((tag) => {
      map.set(tag.id, tag);
    });
    return map;
  });
  const appliedTagNames = useRef<Set<string>>(
    new Set(preset.tags.map((t) => tagEntities[t.id]!.name))
  );
  const isLoading = useAppSelector(presetIsEditingSelector);

  const tagsToApply = useMemo(() => {
    const lastAdded = getLastValueInMap(selectedTags) as PresetTag | undefined;
    const lastAddedTag = lastAdded ? tagEntities[lastAdded.id] : undefined;
    return tags.filter((t) => {
      return (
        (t.tagType === TagType.WordTags || t.tagType === TagType.NumTags) &&
        (selectedTags.has(t.id) ||
          (lastAddedTag
            ? lastAddedTag.allowedTags.includes('*') ||
              lastAddedTag.allowedTags.includes(t.name)
            : true))
      );
    });
  }, [selectedTags, tagEntities, tags]);

  const onCloseCleanUps = useCallback(() => {
    setPresetName('');
    setClose();
  }, [setClose]);

  const handleDeletePreset = useCallback(() => {
    reduxDispatch(deletePreset({ id: preset.id, projectId }));
    const editor = quillInstance?.getEditor();
    if (editor) {
      const presetElements = getPresetsElementsById(preset.id);

      presetElements.forEach((item) => {
        const presetBlot = Quill.find(item);
        const range: Range = {
          index: presetBlot.offset(editor.scroll),
          length: presetBlot.length(),
        };
        editor.formatText(range, PresetBlot.blotName, false, 'user');
      });
    }

    onCloseCleanUps();
  }, [projectId, onCloseCleanUps, preset.id, quillInstance, reduxDispatch]);

  const handleCheckBoxChange = useCallback(
    (tag: TagModel, checked: boolean) => {
      const newMap = new Map(selectedTags);
      if (checked) {
        appliedTagNames.current.add(tag.name);
        const attributes: PresetTagAttribute[] = tag.attributes.map((a) => {
          if (a.type === AttributeTypes.Enum) {
            return {
              id: a.id,
              value: a.values![0].value,
              name: a.name,
            };
          }
          if (a.type === AttributeTypes.Integer) {
            return {
              id: a.id,
              value: a.defaultValue!,
              name: a.name,
            };
          }
          return {
            id: a.id,
            value: '',
            name: a.name,
          };
        });
        newMap.set(tag.id, {
          id: tag.id,
          name: tag.name,
          attributes: attributes,
          tagType: tag.tagType,
        });
      } else {
        appliedTagNames.current.delete(tag.name);
        newMap.delete(tag.id);
      }
      setSelectedTags(newMap);
    },
    [selectedTags]
  );

  const handleAttrValueChange = useCallback(
    (t: TagModel, attr: AttributeModel, value?: string) => {
      const tag = JSON.parse(
        JSON.stringify(selectedTags.get(t.id)!)
      ) as PresetTag;
      if (tag) {
        const existingAttr = tag.attributes.find((a) => a.id === attr.id);
        if (existingAttr) {
          existingAttr.value = value!;
          existingAttr.name = attr.name;
        } else {
          tag.attributes.push({ id: attr.id, name: attr.name, value: value! });
        }
        const newMap = new Map(selectedTags);
        newMap.set(tag.id, tag);
        setSelectedTags(newMap);
      }
    },
    [selectedTags]
  );

  const handleSubmit = useCallback(async () => {
    if (!presetName.length || !selectedTags.size) {
      return;
    }

    const tagsArr = Array.from(selectedTags.values());

    await reduxDispatch(
      editPreset({
        preset: {
          id: preset.id,
          name: presetName,
          tags: tagsArr,
        },
        projectId,
      })
    );
    const editor = quillInstance?.getEditor();
    if (editor) {
      const presetElements = getPresetsElementsById(preset.id);
      presetElements.forEach((item) => {
        const presetBlot = Quill.find(item);
        const range: Range = {
          index: presetBlot.offset(editor.scroll),
          length: presetBlot.length(),
        };
        editor.formatText(
          range,
          PresetBlot.blotName,
          JSON.stringify({
            ...preset,
            tags: tagsArr,
            name: presetName,
          }),
          'user'
        );
      });
    }

    onCloseCleanUps();
  }, [
    projectId,
    onCloseCleanUps,
    preset,
    presetName,
    quillInstance,
    reduxDispatch,
    selectedTags,
  ]);

  return (
    <Modal
      open={isOpen}
      onClose={onCloseCleanUps}
      isCloseIcon={true}
      size={ModalSizes.L}
      noPadding
    >
      <Stack className="overflow-hidden">
        <Stack.Item basis="60%">
          <div className={Styles['preset-modal-tags']}>
            {tagsToApply.map((tag) => {
              let attributeValues = new Map();
              const selectedTag = selectedTags.get(tag.id);
              if (selectedTag) {
                attributeValues = new Map(
                  selectedTag.attributes.map((a) => {
                    const attr = tag.attributes.find((at) => at.id === a.id)!;
                    return [attr.name, a.value];
                  })
                );
              }

              return (
                <div key={tag.id} className="m-b-2">
                  <TagContainer
                    tag={tag}
                    onCheckBoxChange={handleCheckBoxChange}
                    checkboxSelected={selectedTags.has(tag.id)}
                    onAttrValueChange={handleAttrValueChange}
                    attributeValues={attributeValues}
                  />
                </div>
              );
            })}
          </div>
        </Stack.Item>
        <Stack.Item basis="40%">
          <div className="p-4 h-100">
            <Stack
              direction="column"
              className="h-100"
              justifyContent="center"
              alignItems="center"
            >
              <Stack.Item className="w-100" fill>
                <h4 className="font-size-lg fw-700 m-b-3 m-t-2 ta-center">
                  Edit preset
                </h4>
                <Input
                  onChangeWithValueHandler={(value) => setPresetName(value)}
                  value={presetName}
                  className="m-b-3"
                  placeholder="Enter name"
                />
                <Alert className="m-b-3" type="warning">
                  Note that some tags cannot be combined with other tags.{' '}
                  <a href="/">Read more</a>
                </Alert>
              </Stack.Item>
              <Stack.Item className="w-100">
                <Stack justifyContent="space-between">
                  <Stack.Item>
                    <Button secondary onClick={handleDeletePreset}>
                      Delete
                    </Button>
                  </Stack.Item>
                  <Stack.Item>
                    <Button
                      disabled={
                        !presetName.length || !selectedTags.size || isLoading
                      }
                      onClick={handleSubmit}
                      loading={isLoading}
                    >
                      Save Changes
                    </Button>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            </Stack>
          </div>
        </Stack.Item>
      </Stack>
    </Modal>
  );
};
