import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  activeChapterSelector,
  dirtyChaptersSelector,
} from 'redux/reducers/chapters/selectors';
import { activeProjectSelectorId } from 'redux/reducers/project/selectors';
import { Modal } from 'modules/common/components/Modal/Modal';
import ProgressCircle from 'modules/common/components/ProgressCircle';
import { SelectValue } from 'modules/common/types';
import Loader from 'modules/common/components/Loader/Loader';
import { Stack } from 'modules/common/components/Stack/Stack';
import { useAppSelector } from 'modules/common/hooks/redux';
import ChaptersApiInstance from 'modules/edit/api/Chapter';
import {
  ChapterReport,
  PreProcessChapter,
} from 'modules/common/models/Chapter';
import { Header } from './Header';
import { PointLogo } from './ui';
import Styles from './preprocessingreportpopup.module.scss';
import { ConfirmationPlaceholder } from '../ConfirmationPlaceholder';
import {
  useSaveNextChapterChanges,
  useOpenNextChapter,
  useSaveWholeBookChanges,
} from './hooks';
import { AnalyticsEvent, useTrackEvent } from 'services/amplitude';

interface Props {
  isOpen: boolean;
  setClose(): void;
}

const PreProcessingReportPopup: FC<Props> = ({ isOpen, setClose }) => {
  const projectId = useAppSelector(activeProjectSelectorId);
  const dirtyChapters = useAppSelector(dirtyChaptersSelector);
  const currentChapter = useAppSelector(activeChapterSelector);
  const trackEvent = useTrackEvent();
  const saveNextChapterChanges = useSaveNextChapterChanges();
  const saveWholeBookChanges = useSaveWholeBookChanges();
  const openNextChapter = useOpenNextChapter();
  const [currentReport, setCurrentReport] = useState<ChapterReport | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingPending, setIsSavingPending] = useState(false);
  const [isShowConfirmationPlaceholder, setIsShowConfirmationPlaceholder] =
    useState(false);
  const [selectedChapter, setSelectedChapter] = useState<
    SelectValue | undefined
  >(
    currentChapter
      ? { value: currentChapter.id, label: currentChapter.name }
      : undefined
  );

  const popupCloseHandler = () => {
    setClose();
    setIsShowConfirmationPlaceholder(false);
    setCurrentReport(undefined);
    setSelectedChapter(undefined);
  };

  useEffect(() => {
    if (currentChapter && isOpen)
      setSelectedChapter({
        value: currentChapter.id,
        label: currentChapter.name,
      });
  }, [currentChapter, isOpen]);

  const getChapterInfo = useCallback(
    async (params: PreProcessChapter) => {
      setIsLoading(true);
      const report = await ChaptersApiInstance.getPreProcessReport(params);
      if (report instanceof Error) {
        setCurrentReport(undefined);
      } else {
        trackEvent({
          eventName: AnalyticsEvent.ShowPreProcessingReport,
          customProperties: { bookChapter: params.chapterId },
        });
        setCurrentReport(report);
      }
      setIsLoading(false);
    },
    [trackEvent]
  );

  const saveAndApplyNextChapterChanges = async () => {
    if (selectedChapter) {
      setIsSavingPending(true);
      if (selectedChapter.label === 'Full book') {
        // save a whole book changes
        await saveWholeBookChanges({
          chapterId: selectedChapter.value,
          projectId,
        });
        trackEvent({
          eventName: AnalyticsEvent.SaveProject,
        });
      } else {
        // save a next chapter changes
        await saveNextChapterChanges({
          chapterId: selectedChapter.value,
          projectId,
        });
        trackEvent({
          eventName: AnalyticsEvent.SaveChapter,
        });
      }
      setIsShowConfirmationPlaceholder(false);
      setIsSavingPending(false);
      // get a next chapter report
      getChapterInfo({
        projectId,
        chapterId:
          selectedChapter.label === 'Full book'
            ? undefined
            : selectedChapter.value,
      });
    }
  };

  const goToNextChapter = () => {
    if (selectedChapter) {
      let chapterIdToOpen = selectedChapter.value;
      if (
        selectedChapter.label === 'Full book' &&
        Object.keys(dirtyChapters).length
      )
        chapterIdToOpen = Object.keys(dirtyChapters)[0];
      openNextChapter({ chapterId: chapterIdToOpen, projectId });
      setClose();
      setIsShowConfirmationPlaceholder(false);
    }
  };

  useEffect(() => {
    // run on selected chapter changing.
    if (isOpen && selectedChapter) {
      const isSelectedFullBookAndAnyUnsavedChangesExists =
        selectedChapter.label === 'Full book' &&
        dirtyChapters &&
        !!Object.keys(dirtyChapters).length;
      const isSelectedChapterHasUnsavedChanges =
        dirtyChapters?.[selectedChapter.value];
      if (
        isSelectedChapterHasUnsavedChanges ||
        isSelectedFullBookAndAnyUnsavedChangesExists
      ) {
        // show the confirmation placeholder
        setIsShowConfirmationPlaceholder(true);
      } else {
        // show the report
        setIsShowConfirmationPlaceholder(false);
        getChapterInfo({
          projectId,
          chapterId:
            selectedChapter.label === 'Full book'
              ? undefined
              : selectedChapter.value,
        });
      }
    }
  }, [isOpen, projectId, getChapterInfo, selectedChapter, dirtyChapters]);

  return (
    <Modal
      open={isOpen}
      onClose={popupCloseHandler}
      isCloseIcon
      gray
      header={
        !isLoading && (
          <Header
            isLoading={isLoading}
            selectedChapter={selectedChapter}
            setSelectedChapter={setSelectedChapter}
          />
        )
      }
      className={Styles['container']}
      headerClassName={Styles['header-select-preview']}
    >
      {isLoading ? (
        <Stack justifyContent="center">
          <Loader
            loadingBackSet="#000000"
            loadingPartColor="#ffffff"
            size="40px"
          />
        </Stack>
      ) : isShowConfirmationPlaceholder ? (
        <ConfirmationPlaceholder
          isLoading={isSavingPending}
          selectedOption={selectedChapter?.label}
          onEdit={goToNextChapter}
          onSave={saveAndApplyNextChapterChanges}
        />
      ) : currentReport ? (
        <div className={classNames('m-t-3', Styles['confirm-body'])}>
          {currentReport.accuracy && (
            <div className={Styles['overall-accuracy']}>
              <ProgressCircle percentage={currentReport.accuracy} />
              <div>
                <div className={Styles['subtitle']}>Accuracy</div>
                <div>
                  Pre-processing was&nbsp;
                  {Math.round(Number(currentReport.accuracy))}% useful
                </div>
              </div>
            </div>
          )}
          <div className={Styles['preprocessed-tags-stats-container']}>
            <div className={Styles['title']}>
              Pre-processed tags: {currentReport.initial_ai_tag_count}
            </div>
            <div className={Styles['preprocessed-tags-stats-list']}>
              <div className={Styles['preprocessed-tags-stats-item']}>
                <PointLogo
                  className={classNames(
                    Styles['point'],
                    Styles['ai-generated']
                  )}
                />
                <p
                  className={classNames(
                    Styles['preprocessed-tags-stats-item'],
                    Styles['count']
                  )}
                >
                  {currentReport.ai_tags}&nbsp; AI-generated
                </p>
              </div>
              <div className={Styles['preprocessed-tags-stats-item']}>
                <PointLogo
                  className={classNames(Styles['point'], Styles['deleted'])}
                />
                <p
                  className={classNames(
                    Styles['preprocessed-tags-stats-item'],
                    Styles['count']
                  )}
                >
                  {currentReport.deleted_tags}&nbsp; deleted
                </p>
              </div>
              <div className={Styles['preprocessed-tags-stats-item']}>
                <PointLogo
                  className={classNames(Styles['point'], Styles['edited'])}
                />
                <p
                  className={classNames(
                    Styles['preprocessed-tags-stats-item'],
                    Styles[`count`]
                  )}
                >
                  {currentReport.ai_modified_tags}&nbsp; edited
                </p>
              </div>
            </div>
          </div>
          <div className={Styles['title']}>
            Tags added by editor: {currentReport.editor_tags}
          </div>
        </div>
      ) : (
        <div className={Styles['something-went-wrong-message-placeholder']}>
          Probably something went wrong :(
          <br /> Please check if any errors have taken place.
        </div>
      )}
    </Modal>
  );
};

export default PreProcessingReportPopup;
