import { FC } from 'react';

import Styles from './tabs.module.scss';

interface TabGroupItemProps {
  // children:
}

export const TabGroupItem: FC<TabGroupItemProps> = ({ ...props }) => {
  return (
    <li className={Styles['tab-group-item']} role="presentation" {...props} />
  );
};
