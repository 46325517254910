import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import bsearch from 'binary-search-bounds';
import { createSelector } from '@reduxjs/toolkit';
import { getFetchingSelector } from 'redux/reducers/loader/selectors';
import { AsyncRequestStatus } from 'redux/interfaces/loader';
import { ProjectStatus, TOCChapter } from 'modules/common/types';
import { useAppSelector } from 'modules/common/hooks/redux';
import { ProjectTOCPage } from 'modules/common/models/Project';
import { getTOCChapterNodes } from 'modules/common/lib';
import { fetchProject, ProjectsState } from './projectSlice';

const projectsState = ({ project }: RootState) => project;

export const isProjectLoadingSelector = createSelector(
  getFetchingSelector(fetchProject.typePrefix),
  (status) => status === AsyncRequestStatus.pending
);

export const isProjectRejectedSelector = createSelector(
  getFetchingSelector(fetchProject.typePrefix),
  (status) => status === AsyncRequestStatus.rejected
);

export const activeProjectSelector = createSelector(
  projectsState,
  (state) => state.project
);

export const getActiveProjectTTSEngineSelector = createSelector(
  activeProjectSelector,
  (state) => state?.ttsEngine
);

export const activeProjectVoiceSettingsSelector = createSelector(
  projectsState,
  (state) => state.voiceGenerationSettings
);

export const activeProjectSelectorId = createSelector(projectsState, (state) =>
  state.project ? state.project.id : ''
);

export const projectNameSelector = createSelector(
  activeProjectSelector,
  (obj) => obj?.name
);

export const projectAuthorSelector = createSelector(
  activeProjectSelector,
  (obj) => obj?.author
);

export const projectEngineSelector = createSelector(
  activeProjectSelector,
  (obj) => obj?.ttsEngine
);

export const projectVoiceSelector = createSelector(
  activeProjectVoiceSettingsSelector,
  (obj) => obj?.voice
);

export const getProjectStatus = createSelector(
  projectsState,
  (state) => state?.projectStatus
);

export const getProjectTOC = createSelector(
  projectsState,
  (state) => state.project?.toc
);

export const isProjectStatusNew = createSelector(
  getProjectStatus,
  (status) => status === ProjectStatus.new_project
);

export const isProjectStatusDone = createSelector(
  getProjectStatus,
  (status) => status === ProjectStatus.done
);

export const isProjectStatusDecline = createSelector(
  getProjectStatus,
  (status) => status === ProjectStatus.declined
);

export const isProjectStatusPending = createSelector(
  getProjectStatus,
  (status) => status === ProjectStatus.pending
);

export const isProjectStatusAssigned = createSelector(
  getProjectStatus,
  (status) => status === ProjectStatus.assigned
);

export const isProjectStatusInReview = createSelector(
  getProjectStatus,
  (status) => status === ProjectStatus.in_review
);

export const isProjectStatusInReTake = createSelector(
  getProjectStatus,
  (status) => status === ProjectStatus.re_take
);

export const isProjectStatusInProgress = createSelector(
  getProjectStatus,
  (status) => status === ProjectStatus.in_progress
);

export const isProjectStatusIncomplete = createSelector(
  getProjectStatus,
  (status) => status === ProjectStatus.incomplete
);

export const isProjectStatusDeactivated = createSelector(
  getProjectStatus,
  (status) => status === ProjectStatus.deactivated
);

export const isWebSiteUpToDateSelector = createSelector(
  projectsState,
  (state) => state.isWebSiteUpToDate
);

export const getProjectTOCPages = createSelector(
  projectsState,
  (state) => state.projectTOCPages
);

export const getProjectTOCInnerHTMLPages = createSelector(
  projectsState,
  (state) => {
    if (!state.projectTOCPages?.length) {
      return '';
    }
    return state.projectTOCPages.map(({ innerHTML }) => innerHTML).join('\n');
  }
);

export const getProjectTOCPagesWithChapters = createSelector(
  getProjectTOCPages,
  (state) =>
    !!state?.length
      ? state.reduce((acc: ProjectTOCPage[], cur) => {
          if (cur.hasChapterTag) {
            acc.push(cur);
          }
          return acc;
        }, [])
      : []
);

export const getProjectTOCPagesText = createSelector(
  getProjectTOCPages,
  (state) => {
    if (!state?.length) return '';
    return state.map((page) => page.textContent).join();
  }
);

export const getProjectTOCPagesNumber = createSelector(
  getProjectTOCPages,
  (state) => state?.length
);

export const getProjectTOCPagesChapters = createSelector(
  getProjectTOCPages,
  (state) => {
    if (!state?.length) return [] as TOCChapter[];

    return getTOCChapterNodes(state);
  }
);

export const getProjectTOCPagesNestedChapters = createSelector(
  getProjectTOCPagesChapters,
  (state): Map<number, TOCChapter[]> | null => {
    if (!state?.length) return null;

    let map = new Map();

    let lastChapterId: undefined | number = undefined;

    state.forEach((chapter) => {
      if (lastChapterId === undefined || !chapter.isSubChapter) {
        map.set(chapter.id, [chapter]);
        lastChapterId = chapter.id;
      } else {
        map.set(lastChapterId, [...map.get(lastChapterId), chapter]);
      }
    });

    return map;
  }
);

const getProjectTOCPageById = createSelector(
  (projectsState: ProjectsState) => projectsState,
  (projectsState: ProjectsState, id: string) => id,
  (state, id) => state.projectTOCPages?.find((page) => page.id === id)
);

export const useGetProjectTOCPageById = () => {
  const projectTOCPages = useAppSelector(getProjectTOCPages);

  return React.useCallback(
    (id) => {
      if (!projectTOCPages?.length) return null;
      const pageIdToChange = bsearch.eq(projectTOCPages, id, (a) => {
        return Number(a.id) - Number(id);
      });
      if (pageIdToChange === undefined) return null;
      return projectTOCPages[pageIdToChange];
    },
    [projectTOCPages]
  );
};

const getProjectTOCPageIndexById = createSelector(
  (projectsState: ProjectsState) => projectsState,
  (projectsState: ProjectsState, id: string) => id,
  (state, id) => state.projectTOCPages?.findIndex((page) => page.id === id)
);

export const useGetCurrentProjectTOCPage = (): ProjectTOCPage | undefined => {
  const [searchParams] = useSearchParams();

  const currentPageTOC = useAppSelector((state) =>
    getProjectTOCPageById(state.project, searchParams.get('pageId') ?? '0')
  );

  return React.useMemo(() => currentPageTOC, [currentPageTOC]);
};

export const useGetCurrentProjectTOCPageIndex = () => {
  const [searchParams] = useSearchParams();

  const currentTOCPageIndex = useAppSelector((state) =>
    getProjectTOCPageIndexById(state.project, searchParams.get('pageId') ?? '0')
  );

  return React.useMemo(() => currentTOCPageIndex, [currentTOCPageIndex]);
};
