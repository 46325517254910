import { FC } from 'react';

export const Logo: FC = () => {
  return (
    <svg
      width="158"
      height="32"
      viewBox="0 0 158 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7618 0H14.4116C17.3464 0.0987654 20.2517 1.10617 22.5743 2.9116C25.2938 4.9916 27.2135 8.08691 27.8455 11.4528C28.5012 14.8681 27.8574 18.5284 26.0404 21.4993C24.5414 24.0119 22.2128 25.9674 19.5505 27.1565C19.5505 26.963 19.5486 26.5758 19.5466 26.3822C22.1852 25.1299 24.4584 23.0756 25.8152 20.4721C27.5236 17.2859 27.8752 13.3906 26.7376 9.95358C25.7659 6.95506 23.6961 4.32988 21.0081 2.68444C18.6993 1.25235 15.9402 0.564938 13.2305 0.742716C10.3885 0.906667 7.61955 2.03259 5.4589 3.88346C3.18173 5.80543 1.57802 8.50765 0.985525 11.4272C0.207375 15.2059 1.13562 19.3106 3.5155 22.3546C4.82888 24.0988 6.61625 25.4321 8.56755 26.3842C8.56755 26.5778 8.56558 26.963 8.5636 27.1565C5.7986 25.922 3.39305 23.8538 1.90193 21.2049C0.67545 19.117 0.08295 16.7052 0 14.2953V13.8173C0.096775 10.0365 1.74787 6.32099 4.53657 3.76099C7.01718 1.4321 10.3648 0.0849383 13.7618 0Z"
        fill="#031E50"
      />
      <path
        d="M62.9314 7.47243C63.6148 7.47046 64.3001 7.47046 64.9854 7.47046C64.9854 8.87688 64.9874 10.2833 64.9834 11.6897C66.202 10.9766 67.8176 10.7593 69.0737 11.4981C70.0118 12.0591 70.4147 13.1929 70.4009 14.2418C70.4186 16.3218 70.4009 18.3998 70.4068 20.4798C69.7215 20.4818 69.0361 20.4818 68.3528 20.4818C68.3469 18.5243 68.3587 16.5668 68.3508 14.6112C68.3271 14.0581 68.3232 13.2858 67.6991 13.0586C66.4805 12.6734 64.8511 13.6196 64.9795 14.9984C64.9874 16.8255 64.9854 18.6527 64.9834 20.4798C64.3001 20.4818 63.6148 20.4818 62.9314 20.4798C62.9314 16.144 62.9294 11.8082 62.9314 7.47243Z"
        fill="#031E50"
      />
      <path
        d="M35.5914 20.48C37.3255 16.2667 39.0655 12.0554 40.8035 7.84399C41.1392 7.84399 41.475 7.84597 41.8107 7.84794C43.5546 12.0593 45.3084 16.2687 47.0524 20.48C46.3236 20.482 45.5928 20.482 44.8641 20.482C44.55 19.7275 44.24 18.9729 43.9279 18.2183C42.2176 18.6509 40.4124 18.6489 38.704 18.2144C38.394 18.967 38.0878 19.7235 37.7857 20.48C37.0549 20.482 36.3222 20.482 35.5914 20.48ZM39.4131 16.4544C40.6613 16.725 41.9529 16.7289 43.2031 16.4642C42.5671 14.9176 41.9312 13.3709 41.2873 11.8242C40.6613 13.3689 40.0372 14.9116 39.4131 16.4544Z"
        fill="#031E50"
      />
      <path
        d="M136.753 19.9585C136.753 15.915 136.749 11.8716 136.755 7.82812C137.089 7.82812 137.424 7.82812 137.76 7.82812C137.756 9.47553 137.774 11.1249 137.75 12.7723C138.825 11.2869 141.167 11.1348 142.502 12.3437C143.677 13.3866 144.003 15.0834 143.861 16.5866C143.731 18.0227 142.895 19.4903 141.481 19.9842C139.954 20.5017 138.299 20.2568 136.753 19.9585ZM137.794 14.4276C137.715 16.0257 137.784 17.6296 137.762 19.2276C138.929 19.3521 140.181 19.5457 141.293 19.0578C142.322 18.6015 142.8 17.4341 142.873 16.3733C142.941 15.2316 142.757 13.9516 141.91 13.1101C140.657 11.7965 137.989 12.5807 137.794 14.4276Z"
        fill="#031E50"
      />
      <path
        d="M145.427 20.1244C145.427 16.0257 145.423 11.9249 145.427 7.82617C145.761 7.82617 146.097 7.82815 146.434 7.82815C146.438 11.9289 146.436 16.0277 146.436 20.1264C146.097 20.1264 145.761 20.1264 145.427 20.1244Z"
        fill="#031E50"
      />
      <path
        d="M102.242 8.53535C103.561 8.55313 104.88 8.49782 106.198 8.56103C107.102 8.60646 107.973 9.07263 108.497 9.81732C109.62 11.3442 109.299 13.7798 107.689 14.8465C107.219 15.1902 106.616 15.1566 106.067 15.1586C106.925 15.5358 107.355 16.405 107.898 17.1141C108.382 17.7541 108.825 18.4316 109.36 19.0321C109.543 19.3146 109.891 19.2257 110.173 19.2435C110.306 19.5141 110.438 19.7847 110.57 20.0573C110.023 20.2391 109.332 20.3694 108.884 19.9032C107.742 18.72 106.996 17.205 105.828 16.0415C105.147 15.3719 104.128 15.4568 103.251 15.4391C103.249 17.0015 103.253 18.562 103.249 20.1245C102.913 20.1245 102.576 20.1265 102.242 20.1265C102.24 16.2628 102.24 12.3991 102.242 8.53535ZM103.251 9.48745C103.251 11.1586 103.249 12.8297 103.251 14.5008C104.424 14.4652 105.627 14.6292 106.772 14.3072C108.73 13.5763 108.554 10.2203 106.585 9.61189C105.491 9.37288 104.361 9.52498 103.251 9.48745Z"
        fill="#031E50"
      />
      <path
        d="M56.9037 9.1299C57.589 9.12792 58.2723 9.12792 58.9577 9.1299C58.9596 9.81731 58.9596 10.5067 58.9577 11.1941C59.8227 11.1941 60.6858 11.1941 61.5489 11.198C61.5508 11.842 61.5508 12.4859 61.5489 13.1299C60.6858 13.1279 59.8227 13.1279 58.9596 13.1299C58.9675 14.6114 58.9419 16.0929 58.9656 17.5743C58.9873 17.9457 59.009 18.4455 59.4139 18.6114C59.9689 18.8603 60.5317 18.4929 61.0373 18.2894C61.3553 18.8484 61.6713 19.4094 61.9853 19.9724C60.591 20.9976 58.2032 21.0252 57.3006 19.3482C56.9471 18.6884 56.8977 17.922 56.8997 17.1892C56.9056 14.5027 56.8977 11.8163 56.9037 9.1299Z"
        fill="#031E50"
      />
      <path
        d="M148.613 9.18127C149.381 8.78621 150.039 10.0445 149.268 10.4455C148.451 10.9867 147.716 9.54867 148.613 9.18127Z"
        fill="#031E50"
      />
      <path
        d="M74.9315 11.1843C76.3574 10.8347 77.969 11.1764 79.0454 12.1996C80.9849 14.0149 81.0402 17.4361 79.1856 19.3305C78.2258 20.3063 76.7939 20.7547 75.447 20.569C74.0032 20.4248 72.6938 19.4747 72.0618 18.1769C71.3093 16.6263 71.3627 14.6924 72.2435 13.205C72.8301 12.2134 73.8097 11.4608 74.9315 11.1843ZM75.2969 13.0806C73.5174 13.6534 73.2192 16.0179 73.9637 17.5033C74.6589 18.8939 76.8038 19.1151 77.8012 17.9477C78.3305 17.3789 78.4569 16.5729 78.4786 15.8263C78.4509 15.0697 78.3245 14.2519 77.7775 13.685C77.1751 12.9917 76.1481 12.8238 75.2969 13.0806Z"
        fill="#031E50"
      />
      <path
        d="M80.9236 12.1937C81.3857 11.7631 81.8479 11.3305 82.316 10.9038C82.6695 11.212 83.0151 11.528 83.3449 11.8638C84.3581 10.9453 85.8038 10.9117 87.0639 11.2455C87.0461 11.925 87.0283 12.6026 87.0145 13.2801C86.2324 13.0925 85.3555 12.885 84.6129 13.3097C84.1942 13.5171 83.817 13.9162 83.8308 14.408C83.8071 16.4307 83.8308 18.4554 83.8229 20.4801C83.1376 20.4821 82.4542 20.4821 81.7689 20.4821C81.7472 18.325 81.8044 16.166 81.7432 14.011C81.757 13.3038 81.447 12.6599 80.9236 12.1937Z"
        fill="#031E50"
      />
      <path
        d="M92.0409 11.4468C93.534 10.6291 95.4755 10.9708 96.7632 12.0355C96.4551 12.553 96.1489 13.0725 95.8468 13.594C95.0943 13.112 94.1344 12.6854 93.2496 13.0547C92.8843 13.1654 92.7539 13.7303 93.0877 13.9436C94.1601 14.637 95.4399 14.9589 96.4847 15.7036C97.6341 16.5333 97.6559 18.3189 96.8165 19.3757C96.0304 20.3851 94.6558 20.7505 93.4274 20.5886C92.1673 20.4938 91.089 19.757 90.2081 18.9036C90.6091 18.4256 91.0139 17.9515 91.4188 17.4775C92.1081 17.9417 92.7539 18.5915 93.6249 18.6626C94.1285 18.7002 94.7092 18.6528 95.0903 18.2814C95.434 17.9456 95.4162 17.2523 94.9462 17.037C93.8816 16.4108 92.6236 16.1402 91.6301 15.3876C90.38 14.3861 90.6742 12.2113 92.0409 11.4468Z"
        fill="#031E50"
      />
      <path
        d="M47.475 11.3342C48.1603 11.3342 48.8437 11.3342 49.529 11.3342C49.5369 13.2186 49.5349 15.1031 49.5468 16.9875C49.5705 17.5031 49.5665 18.1727 50.078 18.467C51.1426 18.9964 52.6929 18.3703 52.9655 17.1831C53.005 15.2275 52.9655 13.272 52.9813 11.3164C53.6646 11.3144 54.35 11.3144 55.0353 11.3164C55.0629 13.4656 54.9859 15.6186 55.0708 17.7658C55.055 18.4532 55.4244 19.0379 55.9201 19.4784C55.4698 19.9011 55.0215 20.3238 54.5652 20.7406C54.1663 20.3831 53.7812 20.0078 53.4257 19.6048C52.2624 20.7327 50.3782 20.8769 48.9661 20.1579C48.0023 19.6562 47.4829 18.5678 47.4849 17.507C47.4592 15.4488 47.4829 13.3925 47.475 11.3342Z"
        fill="#031E50"
      />
      <path
        d="M151.429 13.5685C152.065 12.2114 153.558 11.3917 155.037 11.4529C155.942 11.4253 156.728 11.9349 157.429 12.4485C157.202 12.6519 156.979 12.8554 156.758 13.0588C155.725 12.1858 154.085 12.1028 153.056 13.0253C151.97 13.9872 151.753 15.5872 151.97 16.9522C152.12 17.9596 152.818 18.9077 153.829 19.1764C155.107 19.5537 156.424 19.0816 157.577 18.5344C157.719 18.7734 157.86 19.0144 158 19.2593V19.2672C156.771 20.0021 155.304 20.4366 153.874 20.1462C152.701 19.9507 151.68 19.1112 151.245 18.007C150.7 16.5907 150.787 14.9374 151.429 13.5685Z"
        fill="#031E50"
      />
      <path
        d="M112.561 12.2272C114.009 11.0736 116.357 11.4193 117.412 12.9402C118.141 13.8765 118.162 15.121 118.03 16.2449C115.968 16.2489 113.906 16.241 111.846 16.2489C111.985 17.2879 112.17 18.6153 113.233 19.121C114.582 19.7037 116.1 19.283 117.329 18.6015C117.473 18.8425 117.623 19.0834 117.769 19.3244C116.422 20.16 114.722 20.6025 113.179 20.0751C111.87 19.6286 111.054 18.2993 110.906 16.9679C110.707 15.2652 111.159 13.3274 112.561 12.2272ZM113.104 12.9778C112.312 13.5012 112.117 14.4751 111.9 15.3304C113.638 15.3284 115.378 15.3383 117.116 15.3244C117.072 14.6528 116.999 13.9378 116.545 13.4044C115.771 12.3536 114.1 12.0988 113.104 12.9778Z"
        fill="#031E50"
      />
      <path
        d="M118.931 12.1619C119.15 11.9426 119.369 11.7254 119.592 11.5061C119.975 11.8893 120.285 12.3357 120.516 12.8256C121.561 11.1209 124.233 11.1584 125.517 12.553C126.517 13.6217 126.779 15.1881 126.613 16.5984C126.481 17.9259 125.744 19.2414 124.518 19.838C123.295 20.4543 121.869 20.312 120.568 20.0829C120.566 21.5644 120.568 23.0459 120.566 24.5293C120.244 24.5273 119.926 24.5273 119.608 24.5273C119.606 21.2226 119.602 17.918 119.61 14.6133C119.653 13.7461 119.503 12.8473 118.931 12.1619ZM120.595 14.5125C120.522 16.0671 120.59 17.6276 120.568 19.1841C121.597 19.3382 122.673 19.5081 123.694 19.1999C124.429 18.9826 125.031 18.4098 125.328 17.7066C125.762 16.7446 125.693 15.6523 125.497 14.6409C125.347 13.9535 124.99 13.2878 124.425 12.8552C123.064 11.8617 120.738 12.7802 120.595 14.5125Z"
        fill="#031E50"
      />
      <path
        d="M128.045 11.6563C128.381 11.6543 128.715 11.6543 129.054 11.6543C129.062 13.5032 129.045 15.354 129.06 17.2029C129.066 17.9358 129.238 18.8227 129.991 19.1447C131.371 19.7136 133.01 18.874 133.623 17.5782C133.696 15.6069 133.627 13.6296 133.652 11.6543C133.986 11.6543 134.324 11.6543 134.663 11.6543C134.667 13.5664 134.654 15.4765 134.665 17.3866C134.669 18.1886 134.812 19.0024 135.291 19.6642C135.082 19.8657 134.875 20.0671 134.667 20.2666C134.235 19.8301 133.939 19.2889 133.757 18.7042C132.848 20.1857 130.751 20.6637 129.238 19.8795C128.401 19.437 128.043 18.4592 128.051 17.5585C128.033 15.5911 128.051 13.6237 128.045 11.6563Z"
        fill="#031E50"
      />
      <path
        d="M148.427 11.6384C148.761 11.6384 149.099 11.6384 149.436 11.6384C149.436 14.4671 149.436 17.2957 149.436 20.1244C149.099 20.1263 148.761 20.1263 148.427 20.1263C148.425 17.2977 148.425 14.4671 148.427 11.6384Z"
        fill="#031E50"
      />
      <path
        d="M8.58539 17.521C10.2167 19.1111 12.5295 20.0099 14.8126 19.7472C16.5901 19.601 18.2451 18.7398 19.5289 17.523C19.8291 18.0425 20.1293 18.564 20.4275 19.0854C18.4801 20.8158 15.8376 21.7778 13.2306 21.5091C11.1707 21.363 9.23122 20.4326 7.68677 19.0874C7.98697 18.564 8.28519 18.0425 8.58539 17.521Z"
        fill="#031E50"
      />
      <path
        d="M10.2364 22.5088C10.7953 22.7043 11.3562 22.8999 11.9151 23.0955C11.9171 25.2841 11.9171 27.4727 11.9151 29.6614C11.3542 29.5527 10.7953 29.4421 10.2344 29.3335C10.2344 27.0579 10.2324 24.7824 10.2364 22.5088Z"
        fill="#031E50"
      />
      <path
        d="M16.1989 23.0955C16.7598 22.8979 17.3207 22.7004 17.8836 22.5068C17.8816 24.7824 17.8836 27.0579 17.8816 29.3335C17.3207 29.4421 16.7598 29.5508 16.1989 29.6614C16.1969 27.4728 16.1989 25.2841 16.1989 23.0955Z"
        fill="#031E50"
      />
      <path
        d="M13.2166 23.3264C13.7775 23.3343 14.3404 23.3343 14.9013 23.3284C14.8954 26.2183 14.9033 29.1101 14.8973 32H13.2186C13.2127 29.1081 13.2166 26.2183 13.2166 23.3264Z"
        fill="#031E50"
      />
      <path
        d="M8.42932 14.2696C10.2996 10.9965 12.1818 7.72936 14.0561 4.45825C15.9363 7.72541 17.8125 10.9965 19.6829 14.2696C18.9422 15.6721 17.8501 16.9639 16.3352 17.5486C14.8797 18.2183 13.1338 18.1945 11.6861 17.5111C10.2127 16.9126 9.16205 15.6424 8.42932 14.2696ZM10.4636 14.242C10.8862 14.8326 11.4017 15.3481 12.0139 15.7471C12.7111 14.5758 13.3668 13.3787 14.0581 12.2034C14.7454 13.3807 15.4129 14.5699 16.0963 15.7491C16.7105 15.3501 17.2299 14.8345 17.6466 14.2341C16.4478 12.1541 15.251 10.0681 14.0581 7.98418C12.8632 10.0701 11.6644 12.158 10.4636 14.242Z"
        fill="#FF5C35"
      />
      <path
        d="M88.0494 9.08053C88.002 8.20942 89.1337 7.59114 89.823 8.14028C90.5004 8.67559 90.4333 9.66324 90.2002 10.406C90.0225 11.0539 89.5366 11.5319 89.108 12.0218C88.8256 11.8341 88.5432 11.6465 88.2627 11.4588C88.5175 11.0815 88.7446 10.6845 88.9362 10.2716C88.4978 10.0148 88.006 9.65534 88.0494 9.08053Z"
        fill="#FF5C35"
      />
    </svg>
  );
};
