import React, { FC, useState } from 'react';
import { Modal, ModalSizes } from 'modules/common/components/Modal/Modal';
import { Button } from 'modules/common/components/Button/Button';
import Textarea from 'modules/common/components/Textarea/Textarea';

interface Props {
  isOpen: boolean;
  setClose(): void;
  projectName: string;
  handleRejection: (reason: string) => void;
  isLoading: boolean;
}

const DeclineProjectModal: FC<Props> = ({
  isOpen,
  setClose,
  projectName,
  handleRejection,
  isLoading,
}) => {
  const [textInput, setTextInput] = useState('');

  return (
    <Modal
      open={isOpen}
      onClose={setClose}
      isCloseIcon
      size={ModalSizes.M}
      gray
    >
      <h3 className="m-b-4 font-size-lg ta-center">Decline Reason</h3>
      <span className="m-b-4">
        please share with us why do you want to decline this ( {projectName} )
      </span>
      <Textarea
        name="decline_reason"
        id="decline_reason"
        className="m-b-2"
        value={textInput}
        onChangeValue={(value) => setTextInput(value)}
      />
      <Button
        disabled={!textInput || isLoading}
        loading={isLoading}
        onClick={() => handleRejection(textInput)}
      >
        Decline Request
      </Button>
    </Modal>
  );
};

export default DeclineProjectModal;
