import { useCallback } from 'react';
import Quill from 'quill';
import {
  setChapterDirty,
  setChapterWithSavedChanges,
  unsetChapterDirty,
} from 'redux/reducers/chapters/chaptersSlice';
import { setProjectTOCPages } from 'redux/reducers/project/projectSlice';
import { useSplitBookIntoChapters } from 'modules/common/api';
import { error } from 'modules/common/components/Notify';
import ChaptersApiInstance from 'modules/edit/api/Chapter';
import { ProjectApiInstance } from 'modules/edit/api/Project';
import { useAppDispatch } from 'modules/common/hooks/redux';
import { EditorSave } from 'utils/EditorSave';
import { useSearchParams } from 'react-router-dom';
import { getTOCPages } from './lib';

export const useSetTextAndHistory = () => {
  const editorSaveInstance = EditorSave.getInstance();
  const dispatch = useAppDispatch();

  return useCallback(
    async ({
      projectId,
      currentChapterId,
      getUrl,
      editor,
    }: {
      projectId: string;
      currentChapterId: string;
      getUrl: string;
      editor: Quill;
    }) => {
      await editorSaveInstance.startSaving(projectId, currentChapterId);

      const res = await Promise.all([
        ChaptersApiInstance.getChapterText(getUrl),
        editorSaveInstance.getSaveHistoryStack(),
      ]);

      const text = res[0];
      const currentChapterHistoryStack = res[1];
      editor.history.clear();
      editor.setContents(editor.clipboard.convert(text), 'api');
      const isChangesWereAppliedToTheTextBefore =
        text.includes('<p>', 0) && text.includes('</p>', text.length - 4);
      if (isChangesWereAppliedToTheTextBefore) {
        dispatch(setChapterWithSavedChanges(currentChapterId));
      }

      if (currentChapterHistoryStack) {
        editor.history.loadHistoryStack(currentChapterHistoryStack);
        dispatch(setChapterDirty(currentChapterId));
      } else {
        dispatch(unsetChapterDirty(currentChapterId));
        editor.history.setStackHistoryValue(true);
      }
    },
    [dispatch, editorSaveInstance]
  );
};

export const useSetTOCText = () => {
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();
  const splitBookIntoChapters = useSplitBookIntoChapters();

  return useCallback(
    async ({
      projectId,
      editor,
      setSavingTOCCompleted,
    }: {
      projectId: string;
      editor: Quill;
      setSavingTOCCompleted: () => void;
    }) => {
      try {
        const { url } = await ProjectApiInstance.getProjectTOCData(projectId);
        if (!url) {
          await splitBookIntoChapters({ projectId, setSavingTOCCompleted });
        } else {
          const text = await ProjectApiInstance.getTOCText(url);
          editor.history.clear();
          const delta = editor.clipboard.convert(text);
          const pages = getTOCPages(delta);
          if (!pages.length) return;
          dispatch(setProjectTOCPages(pages));
          const pageIdSearchParam = new URLSearchParams(
            window.location.search
          ).get('pageId');
          if (
            pageIdSearchParam === null ||
            Number(pageIdSearchParam) < 0 ||
            Number(pageIdSearchParam) + 1 > pages.length
          ) {
            setSearchParams({
              pageId: pages[0].id,
            });
          }
          setSavingTOCCompleted();
        }
      } catch (e) {
        setSavingTOCCompleted();
        error({
          children: 'Something went wrong with the book TOC',
        });
      }
    },
    [dispatch, setSearchParams, splitBookIntoChapters]
  );
};
