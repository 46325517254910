import Quill, { StringMap } from 'quill';
import QuillUtil from 'utils/QuillUtil';
import { AnalyticsEvent, useTrackEvent } from 'services/amplitude';
import { AttributeTypes, TagType, TagModel } from 'modules/common/models/Tags';
import { tagBlotModelFactory } from 'modules/edit/Editor/Blots/tagBlotModelFactory';
import { ALIAS_DEFAULT_VALUE } from 'modules/common/constants';
import useQuill from 'modules/common/hooks/useQuill';
import { generateId } from 'modules/common/lib';
import { SentenceBlot } from '../Editor/Blots/SentenceBlot';

type Props = {
  checked?: boolean;
  setAttributeValues?(
    value: React.SetStateAction<Map<string, string | undefined>>
  ): void;
  setSelected?(value: boolean): void;
};

export const useHandleCheckboxChange = ({
  setAttributeValues,
  setSelected,
}: Props) => {
  const trackEvent = useTrackEvent();
  const quillInstance = useQuill();

  return (tag: TagModel, checked: boolean) => {
    const quill = quillInstance!.getEditor();
    const scrollPosition = quill.root.scrollTop;
    const selection = quill.getSelection(true);
    quill.root.scrollTo({
      top: scrollPosition,
      behavior: 'auto'
    });
    if (selection) {
      // Cursor tags
      if (tag.tagType === TagType.SentenceTags) {
        if (selection.length) {
          return;
        }

        if (checked) {
          const attrs = tag.attributes.map((a) => {
            if (a.type === AttributeTypes.Enum) {
              return [a.name, `${a.prefix}${a.values![0].value}${a.postfix}`];
            }
            if (a.type === AttributeTypes.Integer) {
              return [a.name, `${a.prefix}${a.defaultValue}${a.postfix}`];
            }

            return [a.name, ''];
          });
          const tagForApply = tagBlotModelFactory(
            tag,
            attrs as [string, string][]
          );
          quill.insertText(
            selection.index,
            '\u00A0',
            tag.id,
            JSON.stringify(tagForApply),
            'user'
          );
          trackEvent({
            eventName: AnalyticsEvent.AddTag,
            customProperties: {
              tagType: { type: tag.tagType, displayName: tag.displayName },
            },
          });
          const newMap = new Map<string, string>(attrs as [string, string][]);
          if (setAttributeValues) setAttributeValues(newMap);
        } else {
          // default before the tag
          let index = selection.index;
          if (tag.id in quill.getFormat(selection)) {
            // after tag
            index = selection.index - 1;
          }
          quill.deleteText(index, 1, 'user');
          trackEvent({
            eventName: AnalyticsEvent.DeleteTag,
            customProperties: {
              tagType: {
                type: tag.tagType,
                displayName: tag.displayName,
              },
            },
          });
        }
        if (setSelected) setSelected(checked);
        return;
      }

      // Selection tags
      if (selection.length) {
        if (setSelected) setSelected(checked);
        if (checked) {
          const attrs = tag.attributes.map((a) => {
            if (a.type === AttributeTypes.Enum) {
              return [a.name, a.values![0].value];
            }

            if (a.type === AttributeTypes.Integer && a.defaultValue) {
              return [a.name, a.defaultValue];
            }

            if (a.defaultValue === ALIAS_DEFAULT_VALUE) {
              return [a.name, quill.getText(selection.index, selection.length)];
            }
            return [a.name, ''];
          });

          const tagForApply = tagBlotModelFactory(
            tag,
            attrs as [string, string][]
          );
          quill.formatText(
            selection,
            tagForApply.id,
            JSON.stringify(tagForApply),
            'user'
          );
          const newMap = new Map<string, string>(attrs as [string, string][]);
          if (setAttributeValues) setAttributeValues(newMap);
        } else {
          quill.formatText(selection, tag.id, false, 'user');
          trackEvent({
            eventName: AnalyticsEvent.DeleteTag,
            customProperties: {
              tagType: {
                type: tag.tagType,
                displayName: tag.displayName,
              },
            },
          });
          if (setAttributeValues) setAttributeValues(new Map());
        }

        quill.blur();
        quill.focus();
      }
    }
    quill.root.scrollTo({
      top: scrollPosition,
      behavior: 'auto'
    });
  };
};

const isSentenceTagExists = (format: StringMap) =>
  format.hasOwnProperty('sentence');

export const areMultipleSentencesSelected = (
  editor: Quill,
  range: { index: number; length: number }
) => {
  const format = editor.getFormat(range.index, range.length);
  if (!isSentenceTagExists(format)) return false;

  // Get ids within a selected/highlighted area.
  const ids: Record<any, any>[] | string | undefined = format['sentence'];

  return ids && Array.isArray(ids) && ids.length > 1;
};

export const isSelectedSentenceTagAreaFitsTheSkipTagUsage = (
  editor: Quill,
  range: { index: number; length: number }
) => {
  const format = editor.getFormat(range.index, range.length);
  const selectedText = editor.getText(range.index, range.length);

  // If no sentence tag exists.
  if (!isSentenceTagExists(format)) return true;

  // If the only sentence tag (i.e., only one sentence) was selected.
  if (!areMultipleSentencesSelected(editor, range)) return true;

  const sentenceBlots = QuillUtil.getSentenceChildren(editor, range);
  const sentencesFullText = sentenceBlots.reduce(
    (acc, item) => acc + item.domNode.textContent,
    ''
  );

  // If the selected text and text of whole sentences are equal, let the Skip tag usage be appropriate.
  return (
    !!selectedText.length &&
    !!sentencesFullText.length &&
    selectedText === sentencesFullText
  );
};

export const isPartOfASkippedSentence = (
  editor: Quill,
  range: { index: number; length: number }
) => {
  const sentenceBlots = QuillUtil.getSentenceChildren(editor, range);
  return sentenceBlots.some((item) => JSON.parse(item.domNode.dataset.skip));
};

export const isFullSentencesSelected = (
  editor: Quill,
  range: { index: number; length: number }
) => {
  const format = editor.getFormat(range.index, range.length);
  const selectedText = editor.getText(range.index, range.length);
  // If no sentence tag exists.
  if (!isSentenceTagExists(format)) return false;

  const sentenceBlots = QuillUtil.getSentenceChildren(editor, range);

  const sentencesFullText = sentenceBlots.reduce(
    (acc, item) => acc + item.domNode.textContent,
    ''
  );

  return (
    !!selectedText.length &&
    !!sentencesFullText.length &&
    selectedText === sentencesFullText
  );
};

export const isFullySelectedSentenceSkipped = (
  editor: Quill,
  range: { index: number; length: number }
) => {
  if (!isFullSentencesSelected(editor, range)) return false;
  const format = editor.getFormat(range.index, range.length);
  const sentenceFormats: string | string[] = format[SentenceBlot.blotName];
  return Array.isArray(sentenceFormats)
    ? sentenceFormats.some((item) => JSON.parse(item).skip)
    : JSON.parse(sentenceFormats).skip;
};

// This regex pattern should be exactly the same as the one in the backend.
const sentencePattern =
  /(?<!\bDr\.)(?<!\bMr\.)(?<!\bMrs\.)(?<!\bMs\.)(?<!\bProf\.)(?<!\bSt\.)(?<=\.|\?|!)/g;

export const hasFullSentence = (text: string) => {
  const result = sentencePattern.test(text);
  // Reset the lastIndex to 0 to avoid side effects when using the same regex pattern multiple times.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/lastIndex
  sentencePattern.lastIndex = 0;
  return result;
};

export const getSentences = (text: string) => {
  return text.split(sentencePattern);
};

export const generateMiddleId = (
  sentenceBlot: SentenceBlot,
  prevSentenceBlot: SentenceBlot | null
) => {
  const prevId = prevSentenceBlot?.domNode
    ? parseFloat(prevSentenceBlot.domNode.id)
    : 0;
  const currentId = parseFloat(sentenceBlot.domNode.id);
  const id = generateId(prevId, currentId);
  return id;
};
