import { createContext } from 'react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

interface IUserContext {
  setUserGroups: (userGroups: string[]) => void;
  userGroups: string[];
  userSession: CognitoUserSession | null;
  setUserSession: (userGroups: CognitoUserSession | null) => void;
}

const UserContext = createContext({} as IUserContext);

export default UserContext;
