import React from 'react';
import ReactQuill from 'react-quill';
import useEventListener from '../useEventListener';
import {
  MemoizedRange,
  moveToTheNextTagHotkeyHandler,
  moveToThePreviousTagHotkeyHandler,
  updateRangesWithDelta,
} from './lib';

export function useMoveBetweenTagsHotKeyListener({
  quillInstance,
}: {
  quillInstance: ReactQuill | null;
}) {
  const memoizedRanges = React.useRef<MemoizedRange[]>([]);
  const editor = quillInstance?.getEditor();

  React.useEffect(() => {
    // update memoizedRanges collection in case of adding/removing tags or loading a new text
    quillInstance?.editor?.on('text-change', (delta) => {
      updateRangesWithDelta(delta, memoizedRanges);
    });
  }, [quillInstance?.editor]);

  const onKeydown = (event: globalThis.KeyboardEvent) => {
    if (!editor?.hasFocus() || event.metaKey || event.ctrlKey) return;
    const keyToTheNextTag = event.code === 'KeyS' || event.code === 'KeyD';
    const keyToThePreviousTag = event.code === 'KeyW' || event.code === 'KeyA';
    let handler;
    if (keyToTheNextTag) {
      handler = moveToTheNextTagHotkeyHandler;
    } else if (keyToThePreviousTag) {
      handler = moveToThePreviousTagHotkeyHandler;
    }

    if (handler) {
      event.preventDefault();
      handler({
        quillEditor: editor,
        memoizedRanges: memoizedRanges.current || [],
      });
    }
  };

  useEventListener('keydown', onKeydown);
}
