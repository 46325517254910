import { FC, MouseEventHandler, SyntheticEvent } from 'react';

import Styles from './backdrop.module.scss';

interface BackdropProps {
  onClick?: (e: SyntheticEvent<HTMLElement>) => void;
  open?: boolean;
}

export const Backdrop: FC<BackdropProps> = ({ open, onClick }) => {
  const handleClick: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    if (e.target !== e.currentTarget) {
      return;
    }
    onClick?.(e);
  };

  return open ? (
    <div className={Styles.backdrop} onClick={handleClick} />
  ) : null;
};
