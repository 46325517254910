import { combineReducers } from '@reduxjs/toolkit';
import loaderReducer from 'redux/reducers/loader/loaderSlice';
import BookmarksReducer from 'redux/reducers/bookmarks/bookmarksSlice';
import PresetsReducer from 'redux/reducers/presets/presetsSlice';
import TagsReducer from 'redux/reducers/tags/tagsSlice';
import projectReducer from 'redux/reducers/project/projectSlice';
import chaptersReducer from 'redux/reducers/chapters/chaptersSlice';
import TagsApply from 'redux/reducers/tags/tagsApplySlice';
import CommentsReducer from 'redux/reducers/comments/commentsSlice';
import FocusReducer from 'redux/reducers/focus/focusSlice';
import { apiSlice } from 'redux/api/app.api';

export default combineReducers({
  loader: loaderReducer,
  bookmarks: BookmarksReducer,
  presets: PresetsReducer,
  tags: TagsReducer,
  tagsApply: TagsApply,
  project: projectReducer,
  chapters: chaptersReducer,
  comments: CommentsReducer,
  focus: FocusReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});
