import {
  CanvasParameters,
  DOCUMENT_LINE_HEIGHT,
  DOCUMENT_SIDE_PADDINGS,
  UNDO_REDO_ACTION_DELAY,
} from './config';
import Quill from 'quill';

let preservedAnimation: Animation | null = null;

const restartAnimations = (element: Element): void => {
  if (document.getAnimations().length) {
    for (const animation of document.getAnimations()) {
      if (
        animation.effect instanceof KeyframeEffect &&
        element.contains(animation.effect.target)
      ) {
        if (!preservedAnimation) {
          preservedAnimation = animation;
        }
        animation.play();
      }
    }
  } else if (preservedAnimation) {
    preservedAnimation.play();
  }
};
export const initialiseAnimation = (
  editor: Quill,
  canvasRef: React.MutableRefObject<HTMLCanvasElement | undefined>,
  top: number,
  left?: number,
  width?: number,
  height?: number
) => {
  if (canvasRef.current) {
    canvasRef.current.style.display = 'block';
    restartAnimations(canvasRef.current);
    let canvasParameters: Partial<CanvasParameters>;
    if (!!left && !!width && !!height) {
      canvasParameters = {
        height,
        width,
        left,
      };
    } else {
      canvasParameters = {
        height: DOCUMENT_LINE_HEIGHT,
        width:
          (editor.scroll.domNode as HTMLDivElement).clientWidth -
          DOCUMENT_SIDE_PADDINGS,
        left: 34,
      };
    }
    canvasRef.current.style.top = `${top}px`;
    canvasRef.current.style.left = `${canvasParameters.left}px`;
    canvasRef.current.height = canvasParameters.height || 0;
    canvasRef.current.width = canvasParameters.width || 0;
  }
};

export const invokeActionOnScrollEnd = (
  element: HTMLElement,
  resolve: () => void,
  timeout: number | undefined = UNDO_REDO_ACTION_DELAY
) => {
  element.addEventListener(
    'scrollend',
    () => {
      setTimeout(() => {
        resolve();
      }, timeout);
    },
    { once: true }
  );
};
