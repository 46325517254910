import { Quill } from 'react-quill';
import { optimizeMerge } from './genericSSMLBlotFactory';

const Inline = Quill.import('blots/inline');

export interface BookmarkIconBlotValue {
  bookmarkId: string;
  projectId: string;
}

export class BookmarkIconBlot extends Inline {
  static blotName = 'bookmark-icon';
  static tagName = 'bookmark-icon';
  static dataBookmarkIdName = 'data-bookmark-id';
  static dataProjectIdName = 'data-project-id';

  static create(value: string) {
    let node: HTMLSpanElement = super.create('bookmark-icon');
    const bookmark = JSON.parse(value) as BookmarkIconBlotValue;
    if (value) {
      node.setAttribute('class', 'bookmark-icon');
      node.setAttribute(
        BookmarkIconBlot.dataBookmarkIdName,
        bookmark.bookmarkId
      );
      node.setAttribute(BookmarkIconBlot.dataProjectIdName, bookmark.projectId);
      node.setAttribute('id', bookmark.bookmarkId);
      node.tabIndex = -1;
      node.setAttribute('data-blot', BookmarkIconBlot.blotName);
    }
    return node;
  }

  static formats(domNode: HTMLElement) {
    const formats = {
      bookmarkId: domNode.dataset.bookmarkId,
      projectId: domNode.dataset.projectId,
    };
    return JSON.stringify(formats);
  }

  optimize(): void {
    // Don't call with super otherwise
    // the order will be implemented and your optimize will be lost

    let formats = this.formats();

    if (Object.keys(formats).length === 0) {
      return this.unwrap(); // un-formatted span
    }

    // this.domNode.parentNode maybe use this if you fell lag because of the API

    // to not optimize un-shown deleted Refs
    if (this.domNode && document.contains(this.domNode)) {
      optimizeMerge.call(this);
      return;
    }
  }
}
