import { FC, useMemo, ReactElement } from 'react';
import { TTSEngine } from 'modules/common/types';
import GcpIcon from 'modules/projects/ProjectItem/CloudIcon/GcpIcon';
import AwsIcon from 'modules/projects/ProjectItem/CloudIcon/AwsIcon';
import AzureIcon from 'modules/projects/ProjectItem/CloudIcon/AzureIcon';
import WellSaidIcon from 'modules/projects/ProjectItem/CloudIcon/WellSaidIcon';
import DeepZenIcon from 'modules/projects/ProjectItem/CloudIcon/DeepZenIcon';
import ElevenLabsIcon from 'modules/projects/ProjectItem/CloudIcon/ElevenLabsIcon';

interface Props {
  ttsEngine: TTSEngine;
}

export const ProjectIcon: FC<Props> = ({ ttsEngine }) => {
  const components: Record<TTSEngine, ReactElement> = useMemo(() => {
    return {
      google: <GcpIcon />,
      aws: <AwsIcon />,
      azure: <AzureIcon />,
      wellsaid: <WellSaidIcon />,
      deepzen: <DeepZenIcon />,
      elevenlabs: <ElevenLabsIcon />,
    };
  }, []);

  return components[ttsEngine] ? components[ttsEngine] : null;
};
