import * as amplitude from '@amplitude/analytics-browser';

export enum AnalyticsEvent {
  LogIn = '[RB] User logged in',
  LogOut = '[RB] User logged out',
  CreateProject = '[RB] Project has been created',
  OpenProject = '[RB] Project has been opened',
  CloseProject = '[RB] Project has been closed',
  SaveChapter = '[RB] Chapter has been saved',
  SaveProject = '[RB] Project has been saved',
  SendProjectToReview = '[RB] Project has been sent to review',
  ApproveProject = '[RB] Project has been approved',
  ApplyPreProcess = '[RB] User applied pre-processing',
  ShowPreProcessingReport = '[RB] Pre-processing tag has been shown',
  AddTag = '[RB] Tag has been added',
  DeleteTag = '[RB] Tag has been deleted',
  EditTag = '[RB] Tag has been edited',
  InsertText = '[RB] Text has been inserted',
  AudioPlayedHighlighted = '[RB] Highlighted audio has been played',
  AudioPlayedPosition = '[RB] Cursor location audio has been played',
  CreatePreset = '[RB] Preset has been created',
  FindAndApply = '[RB] Find and apply function has been used',
  AddBookmark = '[RB] Bookmark has been added',
  DeleteBookmark = '[RB] Bookmark has been deleted',
  ClearAll = '[RB] The clear all button has been clicked',
  PushNotifications = '[RB][System] Push notification has been received',
  TimeToPlay = '[RB][System] Time to play has been measured',
  TimeToPreprocess = '[RB][System] Time to preprocess has been measured',
  ErrorMessage = '[RB][System] An error message has been thrown',
}

// Disable tracking while in the development mode.
const apiKey =
  process.env.NODE_ENV === 'development'
    ? ''
    : window.location.origin === process.env.REACT_APP_QA_ENV_URL
    ? process.env.REACT_APP_VITE_AMPLITUDE_QA_API_KEY || ''
    : window.location.origin === process.env.REACT_APP_PROD_ENV_URL
    ? process.env.REACT_APP_VITE_AMPLITUDE_PROD_API_KEY || ''
    : '';

// amplitude config
amplitude.init(apiKey, {
  defaultTracking: false,
});

export { amplitude };
