import { Icon } from 'icons';
import Styles from './changingmodelwarning.module.scss';

export const ChangingModelWarning: React.FC = () => {
  return (
    <div className={Styles['Container']}>
      <Icon
        name="warning_outline"
        color="#C01313"
        size={20}
        className="m-r-1"
      />
      <div className="font-size-xs">
        Changing the model will delete all tags
      </div>
    </div>
  );
};
