import { useCallback, useEffect, useRef, useState } from 'react';

export default function useStopwatch() {
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const time = useRef(0);

  useEffect(() => {
    let interval: NodeJS.Timer | undefined = undefined;

    if (isActive && !isPaused) {
      interval = setInterval(() => {
        time.current = time.current + 1;
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isActive, isPaused]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
  };

  const getTime = useCallback(() => time.current, []);

  const handlePauseResume = () => setIsPaused(!isPaused);

  const handleReset = useCallback(() => {
    setIsActive(false);
    setIsPaused(true);
    time.current = 0;
  }, []);

  return {
    getTime,
    handleStart,
    handlePauseResume,
    handleReset,
  };
}
