import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ReactElement,
  ReactNode,
} from 'react';
import classnames from 'classnames';

import Styles from './page.module.scss';

interface PageProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  footer?: ReactNode;
  sidebar?: ReactNode;
  actions?: ReactElement;
  header?: ReactElement;
  sidebarPosition?: 'left' | 'right';
  hideScroll?: boolean;
  classNameContent?: string;
  classNameFooter?: string;
}

export const Page: FC<PageProps> = ({
  children,
  className,
  classNameContent,
  classNameFooter,
  footer,
  sidebar,
  sidebarPosition = 'right',
  actions,
  header,
  hideScroll = false,
  ...props
}) => {
  return (
    <div className={Styles.page} {...props}>
      {header && <header className={Styles['page-header']}>{header}</header>}
      <div className={Styles['page-wrapper']}>
        {sidebar && sidebarPosition === 'left' && (
          <aside
            className={classnames(
              Styles['page-sidebar'],
              Styles['page-sidebar-left']
            )}
          >
            {sidebar}
          </aside>
        )}
        <div className={Styles['page-inner']}>
          {actions && <div className={Styles['page-actions']}>{actions}</div>}
          <div
            className={classnames(
              Styles['page-content'],
              { [Styles['page-content-scroll']]: !hideScroll },
              className,
              classNameContent
            )}
          >
            {children}
          </div>
          {footer && (
            <footer
              className={classnames(classNameFooter, Styles['page-footer'])}
            >
              {footer}
            </footer>
          )}
        </div>
        {sidebar && sidebarPosition === 'right' && (
          <aside
            className={classnames(
              Styles['page-sidebar'],
              Styles['page-sidebar-right']
            )}
          >
            {sidebar}
          </aside>
        )}
      </div>
    </div>
  );
};
