import { FC, Fragment, useMemo } from 'react';

import { Icon } from 'icons';
import { Stack } from 'modules/common/components/Stack/Stack';
import { useAppSelector } from 'modules/common/hooks/redux';
import { activeChapterSelector } from 'redux/reducers/chapters/selectors';
import {
  commentsIsFetchingSelector,
  selectAll,
} from 'redux/reducers/comments/selectors';
import { CommentItem } from './CommentItem';

interface ChapterCommentsProps {
  onDelete: (commentId: string, chapterId: string) => void;
  onEdit: (id: string, commentId: string, comment: string) => void;
  onClick: (commentId: string) => void;
}

export const ChapterComments: FC<ChapterCommentsProps> = ({
  onClick,
  onDelete,
  onEdit,
}) => {
  const comments = useAppSelector(selectAll);
  const isCommentFetching = useAppSelector(commentsIsFetchingSelector);
  const chapter = useAppSelector(activeChapterSelector);

  const chapterComments = useMemo(() => {
    return comments.filter((b) => b.chapterId === chapter?.id);
  }, [chapter?.id, comments]);

  return (
    <Fragment>
      {!isCommentFetching && !chapterComments.length && (
        <Stack className="h-100" alignItems="center" justifyContent="center">
          <Stack.Item shrink={0}>
            <div className="ta-center">
              <Icon name="comment" size={40} className="opacity-30" />
              <p className="m-t-2 fw-600 font-size-sm opacity-50">
                List of comments is empty
              </p>
            </div>
          </Stack.Item>
        </Stack>
      )}
      {chapterComments.map((comment) => (
        <CommentItem
          key={comment.id}
          comment={comment}
          onDelete={onDelete}
          onEdit={onEdit}
          onClick={onClick}
        />
      ))}
    </Fragment>
  );
};
