import { saveChapters } from 'redux/reducers/chapters/chaptersSlice';
import useQuill from 'modules/common/hooks/useQuill';
import { useAppDispatch } from 'modules/common/hooks/redux';
import ChaptersApiInstance from 'modules/edit/api/Chapter';
import { EditorSave } from 'utils/EditorSave';

export const useSaveWholeBookChanges = () => {
  const quillInstance = useQuill();
  const dispatch = useAppDispatch();

  return async ({
    chapterId,
    projectId,
  }: {
    chapterId: string;
    projectId: string;
  }) => {
    const editorInstanceSave = EditorSave.getInstance();
    const editor = quillInstance?.editor!;
    const { postUrl } = await ChaptersApiInstance.getChapterInfo(
      chapterId,
      projectId
    );
    if (projectId && chapterId && postUrl) {
      editorInstanceSave.saveText('');
      await dispatch(
        saveChapters({
          chapterId,
          projectId,
          text: editor.root?.innerHTML,
          uploadUrl: postUrl,
        })
      );
      editor.history.clear();
    }
  };
};
