import { DetailedHTMLProps, FC, HTMLAttributes, ReactElement } from 'react';
import classnames from 'classnames';

import { wrapChildren } from 'utils/wrap-children';
import { TabProps } from './Tab';
import { TabGroupItem } from './TabGroupItem';
import Styles from './tabs.module.scss';

interface TabGroupProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLUListElement>,
    HTMLUListElement
  > {
  className?: string;
  underline?: boolean;
  children: ReactElement<TabProps> | ReactElement<TabProps>[];
}

export const TabGroup: FC<TabGroupProps> = ({
  children,
  underline = false,
  className,
  ...props
}) => {
  const tabGroupClasses = classnames(Styles['tab-group'], className, {
    [Styles['tab-group-underline']]: underline,
  });

  return (
    <ul className={tabGroupClasses} role="tablist" {...props}>
      {wrapChildren(children, TabGroupItem)}
    </ul>
  );
};
