import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { batch } from 'react-redux';

import { Icon } from 'icons';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Button } from 'modules/common/components/Button/Button';
import useModal from 'modules/common/hooks/useModal';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import {
  activeChapterSelector,
  chapterDetailSelector,
  isAllChaptersPendingSelector,
  isAnyChapterIsDirtySelector,
  isChapterActiveLoading,
  isChaptersLoadingSelector,
  isCurrentChapterDirtySelector,
  isCurrentChapterPendingSelector,
  selectAll,
  selectEntities,
} from 'redux/reducers/chapters/selectors';
import {
  activeProjectSelectorId,
  getProjectStatus,
  getProjectTOCPagesChapters,
  isProjectStatusIncomplete,
  isWebSiteUpToDateSelector,
  projectNameSelector,
} from 'redux/reducers/project/selectors';
import {
  saveChapter,
  saveChapters,
  setActiveChapter,
  setChapterInactive,
} from 'redux/reducers/chapters/chaptersSlice';
import BouncingDotsLoader from 'modules/common/components/BouncingDotsLoader/BouncingDotsLoader';

import {
  changeProjectStatus,
  setProjectInactive,
  setWebSiteIsUpToDate,
} from 'redux/reducers/project/projectSlice';
import { resetLoaderSliceState } from 'redux/reducers/loader/loaderSlice';
import { Select } from 'modules/common/components/Select/Select';
import { ProjectStatus, SelectValue } from 'modules/common/types';
import { EditorSave } from 'utils/EditorSave';
import useQuill from 'modules/common/hooks/useQuill';
import Loader from 'modules/common/components/Loader/Loader';
import { components } from 'react-select';
import ExportPopup from 'modules/edit/ExportPopup/ExportPopup';
import { useScreenSize } from 'modules/common/hooks/useScreenSize';
import ProjectActions from 'modules/edit/Header/ProjectActions';
import StatusCell from 'modules/common/components/Table/TableCells/StatusCell/StatusCell';
import { useCanEdit } from 'modules/common/hooks/can-permission/useCanEdit';
import { useCanSeeNotification } from 'modules/common/hooks/can-permission/useCanSeeNotification';
import NotificationsView from 'modules/common/components/NotificationsView/NotificationsView';
import { useWebSocket } from 'modules/common/hooks/useWebSocket';
import { SocketEventType } from 'modules/common/models/Notifications';
import { useLogout } from 'modules/common/hooks/useLogout';
import { info } from 'modules/common/components/Notify';
import useEventListener from 'modules/common/hooks/useEventListener';
import { cleanUpWSService } from 'services/WebSocketService';
import { getStatusHash } from 'modules/common/constants/functions';
import { useTrackEvent, AnalyticsEvent } from 'services/amplitude';
import QuillUtil from 'utils/QuillUtil';
import TOCBackWarningPopup from 'modules/edit/Header/TOCBackWarningPopup';

type saveChapterOption = {
  value: 'SaveCurrentChapter' | 'SaveAll';
  label: string;
};

const saveChapterOptions: saveChapterOption[] = [
  {
    value: 'SaveAll',
    label: 'Full Book',
  },
  {
    value: 'SaveCurrentChapter',
    label: 'Current Chapter',
  },
];

const Control = (props: any): any => {
  return (
    <components.Control {...props}>
      <Icon className="m-l-1" name="save" size={16} />
      {props.children}
    </components.Control>
  );
};

export const Header: FC = () => {
  const { isOpen, setClose, setOpen } = useModal();
  const dispatch = useAppDispatch();
  const quillInstance = useQuill();
  const currentChapter = useAppSelector(activeChapterSelector);
  const isCurrentChapterDirty = useAppSelector(isCurrentChapterDirtySelector);
  const [, setSearchParams] = useSearchParams();
  const isCurrentChapterPending = useAppSelector(
    isCurrentChapterPendingSelector
  );
  const isAnyChapterDirty = useAppSelector(isAnyChapterIsDirtySelector);
  const isAllChaptersPending = useAppSelector(isAllChaptersPendingSelector);
  const trackEvent = useTrackEvent();
  const { isTabletOrMobile } = useScreenSize();

  const chapterUploadDetails = useAppSelector(chapterDetailSelector);
  const isActiveChapterLoading = useAppSelector(isChapterActiveLoading);
  const chapters = useAppSelector(selectAll);
  const chapterEntities = useAppSelector(selectEntities);
  const navigate = useNavigate();
  const projectName = useAppSelector(projectNameSelector);
  const projectStatus = useAppSelector(getProjectStatus);
  const projectId = useAppSelector(activeProjectSelectorId);
  const editorInstanceSave = EditorSave.getInstance();
  const isChaptersLoading = useAppSelector(isChaptersLoadingSelector);
  const isWebSiteUptoDate = useAppSelector(isWebSiteUpToDateSelector);
  const isIncompleteStatus = useAppSelector(isProjectStatusIncomplete);
  const projectTOCChapters = useAppSelector(getProjectTOCPagesChapters);
  const [warningModalShown, setWarningModalShown] = useState(false);

  const [saveDropdownState, setSaveDropdownState] = useState<SelectValue>();
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const canUserEdit = useCanEdit();
  const canSeeNotifications = useCanSeeNotification();
  const handleLogout = useLogout();

  // this socket logic is only related when the user is logged in the editor view

  useWebSocket<ProjectStatus>(SocketEventType.status, function (socketInfo) {
    const sentStatus = socketInfo.value.message;
    const message = `Status is changed to ${getStatusHash()[sentStatus]}`;
    trackEvent({
      eventName: AnalyticsEvent.PushNotifications,
      customProperties: {
        message,
        projectId: socketInfo.value.projectId,
        projectTitle: socketInfo.value.projectTitle,
      },
    });
    if (socketInfo.value.projectId !== projectId) return;

    // change the status to avoid
    if (sentStatus !== projectStatus) {
      info({ children: message });
      dispatch(changeProjectStatus(sentStatus));
    }
  });

  useWebSocket<string>(SocketEventType.comment, function (socketInfo) {
    const message = `( ${socketInfo.value.projectTitle} ) someone made a comment "${socketInfo.value.message}"`;
    trackEvent({
      eventName: AnalyticsEvent.PushNotifications,
      customProperties: {
        message,
        projectId: socketInfo.value.projectId,
        projectTitle: socketInfo.value.projectTitle,
      },
    });
    if (socketInfo.value.projectId !== projectId) return;

    // refresh the page notification dispatch
    info({
      children: message,
    });
    if (isWebSiteUptoDate) {
      dispatch(setWebSiteIsUpToDate());
    }
  });

  useEventListener('beforeunload', function () {
    cleanUpWSService();
  });

  const chaptersDropdown: {
    value: string;
    label: string;
  }[] = useMemo(() => {
    if (!chapters) {
      return [];
    }

    return chapters.map((item) => {
      return { value: item.id, label: item.chapterName };
    });
  }, [chapters]);

  const [selectedChapterValue, setSelectedChapterValue] =
    useState<SelectValue>();

  useEffect(() => {
    if (currentChapter) {
      setSelectedChapterValue({
        value: currentChapter.id,
        label: currentChapter.name,
      });
    }
  }, [currentChapter]);

  const nextChapterClick = async () => {
    if (currentChapter?.next) {
      if (!!editorInstanceSave._lastUnSavedState) {
        trackEvent({
          eventName: AnalyticsEvent.SaveChapter,
        });
      }
      await editorInstanceSave.saveCacheImmediately();
      dispatch(setActiveChapter({ chapterId: currentChapter.next, projectId }));
      let chapterName = chapterEntities[currentChapter.next]?.chapterName;
      if (chapterEntities && chapterName) {
        setSelectedChapterValue({
          value: currentChapter.next,
          label: chapterName,
        });
        setSearchParams({ chapterId: currentChapter.next });
      }
      editorInstanceSave.deleteEditorSaveStates();
    }
  };

  const changeChapter = async (value: SelectValue) => {
    if (value) {
      if (!!editorInstanceSave._lastUnSavedState) {
        trackEvent({
          eventName: AnalyticsEvent.SaveChapter,
        });
      }
      await editorInstanceSave.saveCacheImmediately();
      setSelectedChapterValue(value);
      dispatch(setActiveChapter({ chapterId: value.value, projectId }));
      setSearchParams({ chapterId: value.value });
      editorInstanceSave.deleteEditorSaveStates();
    }
  };

  const prevChapterClick = async () => {
    if (currentChapter?.prev) {
      if (!!editorInstanceSave._lastUnSavedState) {
        trackEvent({
          eventName: AnalyticsEvent.SaveChapter,
        });
      }
      await editorInstanceSave.saveCacheImmediately();
      dispatch(setActiveChapter({ chapterId: currentChapter.prev, projectId }));
      let chapterName = chapterEntities[currentChapter.prev]?.chapterName;
      if (chapterEntities && chapterName) {
        setSelectedChapterValue({
          value: currentChapter.prev,
          label: chapterName,
        });
        setSearchParams({ chapterId: currentChapter.prev });
      }
      editorInstanceSave.deleteEditorSaveStates();
    }
  };

  const goBack = useCallback(async () => {
    editorInstanceSave.deleteEditorSaveStates();
    batch(() => {
      dispatch(setChapterInactive());
      dispatch(setProjectInactive());
      dispatch(resetLoaderSliceState());
      navigate('/');
    });
    trackEvent({
      eventName: AnalyticsEvent.CloseProject,
    });
  }, [dispatch, editorInstanceSave, navigate, trackEvent]);

  const handleProjectBack = useCallback(() => {
    if (isIncompleteStatus && projectTOCChapters.length > 1) {
      setWarningModalShown(true);
    } else {
      goBack();
    }
  }, [goBack, isIncompleteStatus, projectTOCChapters.length]);

  const onSaveChapter = async () => {
    const editor = quillInstance?.editor!;
    if (
      currentChapter?.id &&
      projectId &&
      chapterUploadDetails?.postUrl &&
      editor
    ) {
      editorInstanceSave.saveText('');
      trackEvent({
        eventName: AnalyticsEvent.SaveChapter,
      });
      return dispatch(
        saveChapter({
          projectId,
          chapterId: currentChapter.id,
          uploadUrl: chapterUploadDetails.postUrl,
          text: editor.root?.innerHTML,
        })
      );
    }
  };

  const onSaveAllChapters = async () => {
    const editor = quillInstance?.editor!;
    if (projectId && currentChapter?.id && chapterUploadDetails?.postUrl) {
      editorInstanceSave.saveText('');
      trackEvent({
        eventName: AnalyticsEvent.SaveProject,
      });
      return dispatch(
        saveChapters({
          chapterId: currentChapter?.id,
          projectId,
          text: editor.root?.innerHTML,
          uploadUrl: chapterUploadDetails.postUrl,
        })
      );
    }
  };

  const onSaveSelectChange = async (value: SelectValue) => {
    if (!value) {
      return;
    }
    const editor = quillInstance?.editor!;

    setSaveDropdownState(value);
    try {
      QuillUtil.removeFindAndApplyStylesV2(quillInstance);
      if (value.value === 'SaveAll') {
        await onSaveAllChapters();
      } else {
        await onSaveChapter();
      }
      editor.history.clear();
      // After saving reset the value of the dropdown
      setSaveDropdownState({ value: '', label: 'Save' });
      dispatch(changeProjectStatus(ProjectStatus.in_progress));
    } catch (err) {}
  };

  return (
    <div className="container">
      <Stack
        justifyContent="space-between"
        alignItems="center"
        className="fw-600 font-size-sm"
      >
        <Stack.Item>
          <Icon
            className="m-r-2"
            hoverable
            size={20}
            name="arrow_backward"
            onClick={handleProjectBack}
          />
          {isIncompleteStatus ? 'Back' : projectName}
        </Stack.Item>
        <Stack.Item className="m-x-auto">
          {!isIncompleteStatus && (
            <Stack>
              <Icon
                size={24}
                name="arrow_left"
                hoverable
                onClick={prevChapterClick}
                disabled={!currentChapter?.prev || isActiveChapterLoading}
              />
              {isActiveChapterLoading ? (
                <BouncingDotsLoader />
              ) : (
                selectedChapterValue && (
                  <Select
                    value={selectedChapterValue}
                    onChange={changeChapter}
                    options={chaptersDropdown}
                    className="headerDropdown without-arrow"
                    isLoading={isActiveChapterLoading}
                    disabled={isActiveChapterLoading}
                    isSearchable={false}
                  />
                )
              )}
              <Icon
                size={24}
                name="arrow_right"
                hoverable
                onClick={nextChapterClick}
                disabled={!currentChapter?.next || isActiveChapterLoading}
              />
            </Stack>
          )}
        </Stack.Item>
        {canUserEdit && !isTabletOrMobile && (
          <Stack.Item>
            <Stack justifyContent="center" alignItems="center">
              {isAllChaptersPending || isCurrentChapterPending ? (
                <Loader size="14px" />
              ) : null}
              <Select
                value={saveDropdownState}
                onChange={onSaveSelectChange}
                options={saveChapterOptions}
                placeholder={'Save'}
                components={{ Control }}
                className="headerDropdown small"
                disabled={
                  isCurrentChapterPending ||
                  isAllChaptersPending ||
                  !isAnyChapterDirty
                }
                isOptionDisabled={(option) => {
                  if (!option) return false;

                  if (option.value === 'SaveAll') {
                    return !isAnyChapterDirty;
                  }

                  return !isCurrentChapterDirty;
                }}
              />
            </Stack>
          </Stack.Item>
        )}
        {projectId && !isTabletOrMobile && (
          <Stack.Item>
            {projectStatus && !isIncompleteStatus && (
              <div className="m-x-1 " style={{ display: 'inline' }}>
                <StatusCell type={projectStatus} />
              </div>
            )}
            <ProjectActions projectId={projectId} />
          </Stack.Item>
        )}
        {!isTabletOrMobile && !isIncompleteStatus && (
          <Stack.Item>
            <Button
              small
              fill="subtle"
              onClick={setOpen}
              disabled={isChaptersLoading || exportLoading}
              loading={exportLoading}
            >
              <Icon size={18} name="file_upload" />
              <span className="m-l-1">Export</span>
            </Button>
          </Stack.Item>
        )}
        {canSeeNotifications && (
          <Stack.Item>
            <NotificationsView />
          </Stack.Item>
        )}
        <Stack.Item>
          <Button
            onClick={handleLogout}
            icon="exit_to_app"
            small
            secondary
            fill="subtle"
          />
        </Stack.Item>
      </Stack>
      <ExportPopup
        setClose={setClose}
        isOpen={isOpen}
        exportLoading={exportLoading}
        setExportLoading={setExportLoading}
        saveAllChapters={onSaveAllChapters}
        isAnyChapterDirty={isAnyChapterDirty}
      />
      <TOCBackWarningPopup
        isOpen={warningModalShown}
        onAction={goBack}
        onClose={() => setWarningModalShown(false)}
      />
    </div>
  );
};
