import { useContext, useEffect, useMemo, useRef } from 'react';
import { getWSService, messageHandlerFn } from 'services/WebSocketService';
import { SocketEventType } from 'modules/common/models/Notifications';
import UserContext from 'modules/common/context/UserContext';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

export function useWebSocket<T = any>(
  type: SocketEventType,
  handler: messageHandlerFn<T>
) {
  const { userSession } = useContext(UserContext);

  const ws = useMemo(() => {
    const accessToken = userSession?.getAccessToken().getJwtToken();

    if (accessToken) {
      return getWSService(accessToken);
    }

    return null;
  }, [userSession]);

  const savedHandler = useRef(handler);

  useIsomorphicLayoutEffect(() => {
    // Remember the latest callback if it changes.
    savedHandler.current = handler;
  }, [handler, type]);

  useEffect(() => {
    if (!ws) return;

    const messageSocketListener: typeof handler = (socketInfo) => {
      return savedHandler.current(socketInfo);
    };

    ws.addMessageListener(type, messageSocketListener);

    return () => {
      ws.removeMessageListener(type, messageSocketListener);
    };
  }, [savedHandler, type, ws]);
}
