import React, { useCallback, useContext, useMemo } from 'react';
import { getProjectTOCPagesNestedChapters } from 'redux/reducers/project/selectors';
import { useAppSelector } from 'modules/common/hooks/redux';
import useQuill from 'modules/common/hooks/useQuill';
import { TOCChapter } from 'modules/common/types';
import Loader from 'modules/common/components/Loader/Loader';
import { Stack } from 'modules/common/components/Stack/Stack';
import { useDeleteTOCChapter, useEditTOCChapter } from '../lib';
import { EmptyChapterList } from './EmptyChapterList';
import { ChaptersGroup } from './ChaptersGroup';
import Styles from './tocChapters.module.scss';
import { Checkbox } from 'modules/common/components/Checkbox/Checkbox';
import ToCChaptersContext from 'modules/edit/TOCChapters/context';

export const ChaptersList: React.FC<{
  isContentLoading: boolean;
  setIsContentLoading(value: boolean): void;
}> = ({ isContentLoading, setIsContentLoading }) => {
  const projectTOCPagesNestedChapters = useAppSelector(
    getProjectTOCPagesNestedChapters
  );
  const deleteTOCChapter = useDeleteTOCChapter();
  const editTOCChapter = useEditTOCChapter();
  const quillInstance = useQuill();
  const editor = quillInstance?.getEditor();
  const context = useContext(ToCChaptersContext);

  const deleteChapterHandler = (chapterToDelete: TOCChapter) => {
    if (!editor) return;

    deleteTOCChapter({ editor, chapterToDelete });
  };

  const saveChapterChangesHandler = (
    value: string,
    chapterToEdit: TOCChapter
  ) => {
    if (!editor) return;

    editTOCChapter({ value, editor, chapterToEdit });
  };

  React.useEffect(() => {
    if (editor) {
      const selectionChangeHandler = () => {
        if (isContentLoading) setIsContentLoading(false);
      };

      editor.once('text-change', selectionChangeHandler);
      return () => {
        editor.off('text-change', selectionChangeHandler);
      };
    }
  }, [editor, isContentLoading, setIsContentLoading]);

  const parentChapters = React.useMemo(
    () =>
      projectTOCPagesNestedChapters
        ? Array.from(projectTOCPagesNestedChapters.keys())
        : null,
    [projectTOCPagesNestedChapters]
  );

  const isAllChaptersSelected = useMemo(() =>
    context.selectedChapterIds.length === context.chapters.length, [context])

  const handleAllChaptersSelect = useCallback(() => {

    if(!isAllChaptersSelected) {
      let idsToAdd: number[] = []
      context.chapters.forEach(chapter => {
        if(!context.selectedChapterIds.includes(chapter.id)) {
          idsToAdd.push(chapter.id);
        }
      });

      if(idsToAdd.length) {
        context.setSelectedChapterIds([...context.selectedChapterIds, ...idsToAdd])
      }
    }
    else {
      context.setSelectedChapterIds([]);
    }
  }, [context, isAllChaptersSelected]);

  if (isContentLoading) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <Loader
          loadingPartColor="#031E50"
          loadingBackSet="#E5E5E5"
          size="30px"
        />
      </Stack>
    );
  }

  if (!parentChapters?.length || !projectTOCPagesNestedChapters) {
    return <EmptyChapterList />;
  }

  return (
    <div className={Styles['toc-chapters-list']}>
      <Stack className={Styles['chapters-header']} alignItems="center" justifyContent="space-between">
        Chapters
        <Stack.Item shrink={0} className="m-l-auto" flex>
          <Checkbox checked={isAllChaptersSelected} onChange={handleAllChaptersSelect} />
        </Stack.Item>
      </Stack>
      {parentChapters.map((chapterId) => (
        <ChaptersGroup
          key={chapterId}
          chapters={projectTOCPagesNestedChapters.get(chapterId)}
          onDelete={deleteChapterHandler}
          onSaveChangedValue={saveChapterChangesHandler}
        />
      ))}
    </div>
  );
};
