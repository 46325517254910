import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Range } from 'react-quill';

import { Button } from 'modules/common/components/Button/Button';
import Styles from './insert-text.module.scss';
import useQuill from 'modules/common/hooks/useQuill';
import { Divider } from 'modules/common/components/Divider/Divider';
import TabsFocusContext from 'modules/common/context/TabsFocusContext';
import { InsertBlot } from 'modules/edit/Editor/Blots/InsertBlot';
import { useTrackEvent, AnalyticsEvent } from 'services/amplitude';

export const InsertText: FC = () => {
  const trackEvent = useTrackEvent();
  const [value, setValue] = useState('');
  const quillInstance = useQuill();
  const [showInsert, setShowInsert] = useState(false);
  const tabsFocus = useContext(TabsFocusContext);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleInsert = useCallback(() => {
    const editor = quillInstance?.getEditor();
    if (editor) {
      const selection = editor.getSelection(true);
      if (selection && value.length) {
        editor.insertText(
          selection.index,
          value,
          InsertBlot.blotName,
          true,
          'user'
        );
        trackEvent({
          eventName: AnalyticsEvent.InsertText,
          customProperties: {
            text: value,
          },
        });
        setValue('');
      }
    }
  }, [quillInstance, trackEvent, value]);

  useEffect(() => {
    const editor = quillInstance?.getEditor();
    if (editor) {
      const selectionChangeHandler = (range: Range, oldRange: Range) => {
        range = range || oldRange;
        setShowInsert(!range || !range.length);
      };

      editor.on('selection-change', selectionChangeHandler);
      return () => {
        editor.off('selection-change', selectionChangeHandler);
      };
    }
  }, [quillInstance, tabsFocus]);

  return showInsert ? (
    <>
      <Divider spacing={3} />
      <div className="p-x-3">
        <p className="font-size-sm fw-600 m-b-2">Insert text</p>
        <textarea
          value={value}
          onChange={handleChange}
          // This one needs to prevent the start/pause payback functionality.
          onKeyDown={(e) => e.stopPropagation()}
          className={Styles.textarea}
          rows={7}
          placeholder="Enter text"
        />
        <Button
          full
          secondary
          disabled={!value.length}
          onClick={handleInsert}
          className="m-t-2"
        >
          Insert fragment
        </Button>
      </div>
    </>
  ) : null;
};
