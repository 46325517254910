import { v4 as uuid } from 'uuid';

import { TagBlotModel, TagModel, TagType } from 'modules/common/models/Tags';

export function tagBlotModelFactory(
  tag: TagModel,
  attributeValues?: [string, string][]
): TagBlotModel {
  const blotTag: TagBlotModel = {
    id: tag.id,
    blotId: uuid(),
    iconBgColor: tag.iconBorderColor ? '' : tag.iconBgColor,
    iconBorderColor: tag.iconBorderColor,
    iconColor: tag.iconColor,
    iconName: tag.iconName,
    name: tag.name,
    attributeValues: [],
    allowedTags: tag.allowedTags,
  };
  if (attributeValues && attributeValues.length) {
    blotTag.attributeValues = attributeValues;
  } else {
    tag.attributes.forEach((attr) => {
      const values = attr.values!;
      if (tag.tagType === TagType.WordTags && values.length === 1) {
        blotTag.attributeValues.push([attr.name, values[0].value]);
      }
    });
  }

  return blotTag;
}
