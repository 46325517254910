export enum TOCMessageResponse {
  noTOC = 'The file does not have a table of content. Generated presign URL',
  tocInProgress = 'Get draft file to continue creating a TOC',
  tocPresignUrlToSaveDocIsReady = 'Generated presign url to save a document with a TOC.',
  tocIsReady = 'The document includes a table of contents.',
}

export interface TOCResponse {
  message: TOCMessageResponse;
  url: string;
}
