import React from 'react';
import Slider from 'modules/common/components/Slider/Slider';
import Styles from './voisesettingsslider.module.scss';
import { VoiceAdditionalSetting } from '../../types';

interface Props extends VoiceAdditionalSetting {
  defaultValue?: string;
  step?: string;
  onChange(value: string): void;
}

const SLIDER_MAX_VALUE = '1';
const SLIDER_MIN_VALUE = '0';

export const VoiceSettingsSlider: React.FC<Props> = ({
  unstableRange,
  defaultValue = '0.5',
  step = '0.01',
  onChange,
}) => {
  const onChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) =>
    onChange(evt.target.value);

  // This one is needed for displaying an unstable range. Don't remove it!
  const styles = unstableRange
    ? {
        '--seek-before-width-custom': `${
          (parseFloat(unstableRange.to) - parseFloat(unstableRange.from)) * 100
        }%`,
        '--seek-before-start': `${parseFloat(unstableRange.from) * 100}%`,
      }
    : undefined;

  return (
    <Slider
      max={SLIDER_MAX_VALUE}
      min={SLIDER_MIN_VALUE}
      step={step}
      defaultValue={defaultValue}
      styles={styles}
      onChange={onChangeHandler}
      classname={Styles['VoiceSettingsSlider']}
    />
  );
};
