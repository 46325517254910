import { Quill } from 'react-quill';

import { TagType } from 'modules/common/models/Tags';
import QUtil from 'utils/QuillUtil';
import {
  optimizeMerge,
  ifEmbeddingNonEmbeddedTagCleanse,
} from './genericSSMLBlotFactory';

const Inline = Quill.import('blots/inline');

export interface CommentBlotValue {
  id: string;
}

export class CommentBlot extends Inline {
  static blotName = 'rb-comment';
  static tagName = 'rb-comment';
  static isSSML = true;

  static create(value: string) {
    let node: HTMLSpanElement = super.create('rb-comment');
    const comment = JSON.parse(value) as CommentBlotValue;
    if (value) {
      node.setAttribute('class', 'rb-comment');
      node.setAttribute('id', comment.id);
      node.setAttribute('data-ssml-tag-type', TagType.SentenceTags);
      node.setAttribute('data-blot', CommentBlot.blotName);
      node.setAttribute(
        'style',
        `--bg-image: url(${process.env.PUBLIC_URL}/svg/comment.svg);`
      );
    }
    return node;
  }

  static formats(domNode: HTMLElement) {
    return JSON.stringify({
      id: domNode.id,
    });
  }

  formatAt(index: number, length: number, name: string, value: any): void {
    // close case for embedded tags
    if (this.statics.blotName && this.statics.blotName !== name && !value) {
      QUtil.forEachRecursive(this.children, function (child) {
        if (QUtil.isTagNameEqual(child?.statics.blotName, name)) {
          child.unwrap();
        }
      });
      return;
    }
    super.formatAt(index, length, name, value);
  }

  optimize(): void {
    if (this.domNode['__blot'] != null) {
      delete this.domNode['__blot'].mutations;
    }

    if (this.children.length === 0) {
      if (this.statics.defaultChild == null) {
        this.remove();
      }
    }

    let formats = this.formats();

    if (Object.keys(formats).length === 0) {
      return this.unwrap();
    }

    if (this.domNode && document.contains(this.domNode)) {
      optimizeMerge.call(this);
      ifEmbeddingNonEmbeddedTagCleanse.call(this);
      return;
    }
  }
}
