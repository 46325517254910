import { Dispatch, FC, SetStateAction } from 'react';
import { useGetUnassignedProjectsQuery } from 'redux/api/app.api';
import { ProjectItem } from './ProjectItem';

import ProjectsView from 'modules/dashboard/ProjectsView/ProjectsView';
import { AdminProjects } from 'modules/dashboard/ProjectsTableConfig';

interface Props {
  viewAllUnassignedProjects: boolean;
  setViewAllUnassignedProjects: Dispatch<SetStateAction<boolean>>;
}

export const UnassignedProjects: FC<Props> = ({
  viewAllUnassignedProjects,
  setViewAllUnassignedProjects,
}) => {
  const { data, isFetching } = useGetUnassignedProjectsQuery();

  return (
    <ProjectsView<AdminProjects>
      viewProjects={viewAllUnassignedProjects}
      setViewProjects={setViewAllUnassignedProjects}
      data={data?.data}
      isFetching={isFetching}
      render={(project, isFetching) => (
        <ProjectItem data={project} isFetching={isFetching} />
      )}
      header="unassigned project(s)"
    />
  );
};
