import { FC } from 'react';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Checkbox } from 'modules/common/components/Checkbox/Checkbox';

interface Props {
  listOfItems: { id: string; chapterName: string; [i: string]: any }[];
  onChangeCheckbox: (id: string, value: boolean) => void;
  checkedIds: Set<string>;
}

const CheckboxList: FC<Props> = ({
  listOfItems,
  onChangeCheckbox,
  checkedIds,
}) => {
  return (
    <>
      {listOfItems.map((item) => (
        <Stack
          key={item.id}
          alignItems="center"
          className="m-b-1"
          spacing="2"
          justifyContent="space-between"
        >
          <Stack.Item>{item.chapterName}</Stack.Item>
          <Stack.Item>
            <Checkbox
              onChange={(checked) => onChangeCheckbox(item.id, checked)}
              checked={checkedIds.has(item.id)}
            />
          </Stack.Item>
        </Stack>
      ))}
    </>
  );
};

export default CheckboxList;
