import {
  FC,
  useCallback,
  useState,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useMemo,
} from 'react';

import { Button } from 'modules/common/components/Button/Button';
import { Stack } from 'modules/common/components/Stack/Stack';
import { ActionMenu } from 'modules/common/components/ActionMenu/ActionMenu';
import { Icon } from 'icons';
import { useAppSelector } from 'modules/common/hooks/redux';
import { bookMarkIsEditingSelector } from 'redux/reducers/bookmarks/selectors';
import { selectById } from 'redux/reducers/chapters/selectors';
import { useActions } from 'modules/common/hooks/useActions';
import { CommentModel } from 'modules/common/models/Comment';
import { getTimeSince } from 'utils';
import Textarea from 'modules/common/components/Textarea/Textarea';
import Styles from './comments.module.scss';
import { useUserId } from 'modules/common/hooks/useUserId';

export const CommentItem: FC<{
  comment: CommentModel;
  onDelete: (commentId: string, chapterId: string) => void;
  onEdit: (id: string, commentId: string, comment: string) => void;
  onClick: (commentId: string) => void;
}> = ({ comment, onDelete, onEdit, onClick }) => {
  const [editMode, setEditMode] = useState(false);
  const {
    actionMenuOpen,
    handleCloseActions,
    handleOpenActions,
    setActionMenuOpen,
    hovered,
    handleMouseEnter,
    handleMouseLeave,
  } = useActions();
  const [commentText, setCommentText] = useState('');

  const isEditingLoading = useAppSelector(bookMarkIsEditingSelector);
  const chapter = useAppSelector((state) =>
    selectById(state, comment.chapterId)
  );

  const userId = useUserId();

  const firstComment = useMemo(() => {
    return comment.comments[0];
  }, [comment.comments]);

  const repliesLength = useMemo(() => {
    return Object.keys(comment.comments).length - 1;
  }, [comment.comments]);

  useEffect(() => {
    setCommentText(firstComment.comment);
  }, [comment.comments, firstComment]);

  const handleDelete = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onDelete(comment.id, comment.chapterId);
      setActionMenuOpen(false);
    },
    [onDelete, comment.id, comment.chapterId, setActionMenuOpen]
  );

  const handleEditMode = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setActionMenuOpen(false);
      setEditMode(true);
    },
    [setActionMenuOpen]
  );

  const handleEdit = useCallback(async () => {
    if (commentText.length) {
      onEdit(comment.id, firstComment.commentId, commentText);
    }
  }, [comment.id, commentText, firstComment.commentId, onEdit]);

  const handleEditDone = useCallback(
    async (e: MouseEvent) => {
      e.stopPropagation();
      await handleEdit();
      setEditMode(false);
    },
    [handleEdit]
  );

  const handleKeyDown = useCallback(
    async (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        await handleEdit();
        setEditMode(false);
      }

      if (e.key === 'Escape') {
        setEditMode(false);
        setCommentText(firstComment.comment);
      }
    },
    [firstComment.comment, handleEdit]
  );

  const handleClick = useCallback(() => {
    onClick(comment.id);
  }, [comment.id, onClick]);

  return (
    <div
      onMouseEnter={
        userId && userId === firstComment.authorId
          ? handleMouseEnter
          : undefined
      }
      onMouseLeave={
        userId && userId === firstComment.authorId
          ? handleMouseLeave
          : undefined
      }
      className={Styles['comment-item']}
      onClick={handleClick}
    >
      <Stack alignItems="flex-start" spacing={2} justifyContent="space-between">
        <Stack.Item fill>
          <p className={Styles['comment-chapter']}>{chapter?.chapterName}</p>
          {editMode ? (
            <Stack
              spacing={2}
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <Stack.Item fill>
                <Textarea
                  value={commentText}
                  onChangeValue={(v) => setCommentText(v)}
                  autoFocus
                  onKeyDown={handleKeyDown}
                />
              </Stack.Item>
              <Stack.Item shrink={0}>
                <Button
                  className="m-b-1"
                  loading={isEditingLoading}
                  onClick={handleEditDone}
                >
                  <Icon name="done" size={20} />
                </Button>
              </Stack.Item>
            </Stack>
          ) : (
            <p className={Styles['comment-text']}>{firstComment.comment}</p>
          )}
        </Stack.Item>
        {!editMode && hovered && userId && userId === firstComment.authorId && (
          <Stack.Item>
            <ActionMenu
              direction="bl"
              portal
              open={actionMenuOpen}
              width="xs"
              trigger={
                <Icon onClick={handleOpenActions} name="more_horiz" size={20} />
              }
              onClickOutside={handleCloseActions}
            >
              <ActionMenu.Item onClick={handleEditMode}>Edit</ActionMenu.Item>
              <ActionMenu.Item onClick={handleDelete}>Delete</ActionMenu.Item>
            </ActionMenu>
          </Stack.Item>
        )}
      </Stack>
      <Stack className="m-t-1" spacing={2}>
        {!!repliesLength && <Stack.Item>{repliesLength} comment(s)</Stack.Item>}
        <Stack.Item>
          <span className={Styles['comment-date']}>
            {getTimeSince(firstComment.dateAdded)}
          </span>
        </Stack.Item>
      </Stack>
    </div>
  );
};
