import axiosInstance from 'utils/axios';
import { PresetsModel, PresetTag } from 'modules/common/models/Presets';
import { ResultWithMessage } from 'modules/common/types';

type PresetReturn = ResultWithMessage<PresetsModel>;

interface IPresetApi {
  getAll(projectId: string): Promise<PresetsModel[]>;
  add(preset: PresetsModel, projectId: string): Promise<PresetReturn>;
  edit(preset: PresetsModel, projectId: string): Promise<PresetReturn>;
  delete(
    id: PresetsModel['id'],
    projectId: string
  ): Promise<{ id: PresetsModel['id'] }>;
}

class PresetApi implements IPresetApi {
  static action = '/ssml_tags_presets';

  async getAll(projectId: string): Promise<PresetsModel[]> {
    const res = await axiosInstance.get<Array<PresetsModel>>(PresetApi.action, {
      params: {
        projectId,
      },
    });

    return res.data.map((item) => ({
      ...item,
      tags: item.tags.map((tag) => ({
        ...tag,
        attributes: tag.attributes.map((attr) => ({
          ...attr,
          name: attr.name || attr.label,
        })),
      })),
    }));
  }

  async add(
    preset: Omit<PresetsModel, 'id'>,
    projectId: string
  ): Promise<PresetReturn> {
    let tagsSend = preset.tags?.map((item) => {
      let obj: PresetTag = { ...item };
      obj.tagName = item.name;
      obj.attributes = obj.attributes.map((a) => ({
        label: a.name,
        id: a.id,
        value: a.value,
      }));
      return obj;
    });

    const res = await axiosInstance.post(PresetApi.action, {
      projectId,
      name: preset.name,
      tags: tagsSend,
    });
    return res.data;
  }

  async edit(preset: PresetsModel, projectId: string): Promise<PresetReturn> {
    const res = await axiosInstance.put(PresetApi.action, {
      ...preset,
      projectId,
    });
    return res.data;
  }

  async delete(
    id: PresetsModel['id'],
    projectId: string
  ): Promise<{ id: PresetsModel['id'] }> {
    const res = await axiosInstance.delete(PresetApi.action, {
      data: {
        id,
        projectId,
      },
    });
    return res.data;
  }
}

const PresetApiInstance = new PresetApi();

export default PresetApiInstance;
