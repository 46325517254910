import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';

import useModal from 'modules/common/hooks/useModal';
import { Stack } from 'modules/common/components/Stack/Stack';
import { useAppSelector } from 'modules/common/hooks/redux';
import {
  presetsIsFetchingSelector,
  selectAll,
} from 'redux/reducers/presets/selectors';
import { Preset } from './Preset';
import { CreatePresetModal } from 'modules/edit/Presets/PresetCreateModal';
import { PresetsModel } from 'modules/common/models/Presets';
import { EditPresetModal } from './PresetEditModal';
import BouncingDotsLoader from 'modules/common/components/BouncingDotsLoader/BouncingDotsLoader';
import { Icon } from 'icons';
import { Divider } from 'modules/common/components/Divider/Divider';
import { useSelectionWithLength } from 'modules/common/hooks/useSelectionWithLength';
import { SelectValue } from 'modules/common/types';

interface PresetsProps {
  quillInstance: ReactQuill | null;
  presetsAreVisible?: boolean;
  title?: string;
  hideAddIcon?: boolean;
  onSelect?: (value: SelectValue) => void;
}

export const Presets: FC<PresetsProps> = ({
  quillInstance,
  presetsAreVisible,
  title,
  hideAddIcon = false,
  onSelect,
}) => {
  const editor = quillInstance?.editor;
  const selection = editor?.getSelection();
  const { isOpen, setOpen, setClose } = useModal();
  const {
    isOpen: isOpenEdit,
    setOpen: setEditOpen,
    setClose: setEditClose,
  } = useModal();
  const [editPreset, setEditPreset] = useState<PresetsModel | undefined>();
  const presets = useAppSelector(selectAll);
  const arePresetsFetching = useAppSelector(presetsIsFetchingSelector);
  const showPresets = useSelectionWithLength();

  const presetsToRender = useMemo(() => {
    if (selection?.length) {
      const text = editor?.getText(selection?.index, selection?.length).trim();
      if (text?.length) {
        return presets.filter((preset) =>
          preset.tags.find((tag) => tag.tagName !== 'customPause')
        );
      }
      return presets;
    }
    return presets;
  }, [editor, presets, selection?.index, selection?.length]);

  const openCreatePopup = useCallback(() => {
    setEditPreset(undefined);
    setOpen();
  }, [setOpen]);

  const openEditPopup = useCallback(
    (preset: PresetsModel, e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setEditPreset(preset);
      setEditOpen();
    },
    [setEditOpen]
  );

  return showPresets || presetsAreVisible ? (
    <div className="p-x-3">
      <Divider spacing={3} />
      <Stack justifyContent="space-between">
        <p className="fw-600 font-size-sm">{title || 'Presets'}</p>
        {!hideAddIcon && (
          <>
            {arePresetsFetching ? (
              <BouncingDotsLoader />
            ) : (
              <Icon hoverable onClick={openCreatePopup} name="add" />
            )}
          </>
        )}
      </Stack>
      <div className="m-y-2">
        {presetsToRender.map((item) => (
          <Preset
            preset={item}
            key={item.id}
            onEdit={openEditPopup}
            onSelect={onSelect}
          />
        ))}
      </div>
      {isOpen && <CreatePresetModal isOpen={isOpen} setClose={setClose} />}
      {isOpenEdit && editPreset && (
        <EditPresetModal
          preset={editPreset}
          isOpen={isOpenEdit}
          setClose={setEditClose}
          quillInstance={quillInstance}
        />
      )}
    </div>
  ) : null;
};
