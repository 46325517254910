import { FC } from 'react';
import Styles from './label.module.scss';

export interface ILabelContainer {
  labelText: string;
  className?: string;
}

const LabelContainer: FC<ILabelContainer> = ({
  labelText,
  className,
  children,
}) => {
  return (
    <div className={className}>
      <div className={Styles['text-label']}>{labelText}</div>
      {children}
    </div>
  );
};

export default LabelContainer;
