import { TTS_ENGINES } from 'modules/common/types';
import { ElevenLabsVoiceSettings, ResultForm } from './types';

export const DEFAULT_RESULT_FORM_DATA: ResultForm = {
  name: '',
  voice: '',
  elevenLabsModel: '',
  ttsEngine: TTS_ENGINES.aws,
  reviewers: {},
  elevenLabsVoiceSettings: undefined,
};

// Use it in case of applying voice settings while project creation for generating audio or creating a project itself.
export const adjustElevenLabsSettingsDataForNoneMultilingualV2Model = (
  data: ElevenLabsVoiceSettings
): ElevenLabsVoiceSettings => ({
  ...data,
  style: '0.0',
  use_speaker_boost: true,
});
