import { FC } from 'react';
import { EditorRequestedProjects } from 'modules/dashboard/ProjectsTableConfig';
import Styles from '../unassigned-projects/unassigned-projects.module.scss';
import { prettifyDate } from 'utils';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Button } from 'modules/common/components/Button/Button';
import { Card } from 'modules/common/components/Card/Card';
import { useAcceptBookMutation } from 'redux/api/app.api';
import { RequestProjectStatus } from 'modules/common/types';
import useModal from 'modules/common/hooks/useModal';
import DeclineProjectModal from './DeclineProjectModal';
import { SomethingWentWrong } from 'modules/common/components/Notify';
import { Icon } from 'icons';
import { Link } from 'react-router-dom';

interface Props {
  project: EditorRequestedProjects;
  isFetching?: boolean;
}

const ProjectItem: FC<Props> = ({ project, isFetching }) => {
  const [assignBook, { isLoading }] = useAcceptBookMutation();
  const { isOpen, setOpen, setClose } = useModal();

  const handleRejection = async (reason: string) => {
    if (!reason) return;
    try {
      await assignBook({
        editorId: project.editorId,
        projectId: project.id,
        decision: RequestProjectStatus.Decline,
        declinedReason: reason,
      }).unwrap();
      setClose();
    } catch (err) {
      SomethingWentWrong();
    }
  };

  return (
    <>
      <Card className={Styles.card}>
        <Stack
          direction="column"
          justifyContent="space-between"
          className="h-100"
        >
          <Stack.Item>
            <p className="fw-500 font-size-xs m-b-1-5">
              {prettifyDate(project.dateAdded)}
            </p>
          </Stack.Item>
          <Stack.Item>
            <p className="m-b-half">
              {project.author} uploaded project ( {project.name} )
            </p>
            <Stack justifyContent="space-between" spacing={2} className="m-t-3">
              <Stack.Item>
                <Button
                  disabled={isLoading || isFetching}
                  onClick={() =>
                    assignBook({
                      editorId: project.editorId,
                      projectId: project.id,
                      decision: RequestProjectStatus.Accept,
                    })
                  }
                >
                  Accept Project
                </Button>
              </Stack.Item>
              <Stack.Item>
                <Button secondary disabled={isLoading || isFetching}>
                  <Link to={`/editor/${project.id}`} className="h-100 w-100">
                    <Icon name="remove_red_eye" />
                  </Link>
                </Button>
              </Stack.Item>
              <Stack.Item>
                <Button
                  danger
                  disabled={isLoading || isFetching}
                  onClick={setOpen}
                >
                  &#x2715;
                </Button>
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </Card>
      {isOpen && (
        <DeclineProjectModal
          isOpen={isOpen}
          projectName={project.name}
          setClose={setClose}
          handleRejection={handleRejection}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default ProjectItem;
