import React from 'react';
import { ColumnFiltersState, Row } from '@tanstack/react-table';

import Table from 'modules/common/components/Table/Table';
import { Modal, ModalSizes } from 'modules/common/components/Modal/Modal';
import useSorting from 'modules/common/hooks/useSorting';
import { Editor } from 'modules/dashboard/EditorsTableConfig';
import { useAssignEditorMutation, useGetEditorsQuery } from 'redux/api/app.api';
import { Checkbox } from 'modules/common/components/Checkbox/Checkbox';
import { Stack } from 'modules/common/components/Stack/Stack';
import { EditorCapacity, EditorStatus } from 'modules/common/types';
import { Button } from 'modules/common/components/Button/Button';
import { getFiltersToSend } from 'modules/common/lib';
import { getEditorsTableColumnsConfigForUnassigned } from './EditorsTableConfig';

interface SelectEditorModalProps {
  isOpen: boolean;
  setClose(): void;
  projectId: string;
}

export const SelectEditorModal: React.FC<SelectEditorModalProps> = ({
  isOpen,
  setClose,
  projectId,
}) => {
  const { sortBy, sortType, changeSortBy } = useSorting();
  const [filters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [selectedRowId, setSelectedRowId] = React.useState<string>();
  const [assignEditor, { isLoading }] = useAssignEditorMutation();

  const filtersToSend = React.useMemo(
    () => getFiltersToSend(filters),
    [filters]
  );

  const { data, isFetching } = useGetEditorsQuery({
    sortBy,
    sortType,
    ...filtersToSend,
  });

  const columns = React.useMemo(() => {
    return getEditorsTableColumnsConfigForUnassigned({
      sortBy,
      sortType,
      changeSortBy,
    });
  }, [sortBy, sortType, changeSortBy]);

  const handleRowClick = React.useCallback(
    (row: Row<Editor>) => {
      if (+row.original.editorCapacity >= +EditorCapacity.Five) {
        return;
      }
      if (row.original.id === selectedRowId) {
        setSelectedRowId(undefined);
      } else {
        setSelectedRowId(row.original.id);
      }
    },
    [selectedRowId]
  );

  const handleStatusFilterChange = React.useCallback((checked: boolean) => {
    setColumnFilters((filtersPrev) => {
      const statusFilterIndex = filtersPrev.findIndex(
        (filter) => filter.id === 'editorStatus'
      );
      const valueToSet = checked
        ? { id: 'editorStatus', value: EditorStatus.Unavailable }
        : { id: 'editorStatus', value: EditorStatus.Available };
      if (statusFilterIndex === -1) {
        return [...filtersPrev, valueToSet];
      }
      filtersPrev[statusFilterIndex] = valueToSet;
      return filtersPrev;
    });
  }, []);

  const handleAssignEditor = React.useCallback(async () => {
    try {
      if (!selectedRowId) {
        return;
      }
      await assignEditor({ projectId, editorId: selectedRowId });
      setClose();
    } catch {}
  }, [assignEditor, projectId, selectedRowId, setClose]);

  const getRowId = React.useCallback((originalRow: Editor) => {
    return originalRow.id;
  }, []);

  const getRowDisabled = React.useCallback((originalRow: Editor) => {
    return (
      +originalRow.editorCapacity >= +EditorCapacity.Five ||
      originalRow.editorStatus === EditorStatus.Unavailable
    );
  }, []);

  return (
    <Modal open={isOpen} onClose={setClose} isCloseIcon size={ModalSizes.M}>
      <h3 className="m-b-4 font-size-lg ta-center">Select editor</h3>
      <Table
        data={data && data.data ? data.data : []}
        columns={columns}
        isLoading={isFetching}
        enableMultiRowSelection={false}
        onRowClick={handleRowClick}
        selectedRowId={selectedRowId}
        getRowId={getRowId}
        getRowDisabled={getRowDisabled}
        onColumnFiltersChange={setColumnFilters}
      />

      <Stack className="m-t-2 m-b-4" spacing={2}>
        <Stack.Item>
          <Checkbox
            checked={
              filters.find((filter) => filter.id === 'editorStatus') ===
              undefined
            }
            onChange={handleStatusFilterChange}
          />
        </Stack.Item>
        <Stack.Item>Show unavailable editors</Stack.Item>
      </Stack>

      <Stack justifyContent="center">
        <Stack.Item>
          <Button
            primary
            disabled={!selectedRowId}
            onClick={handleAssignEditor}
            loading={isLoading}
          >
            Assign editor
          </Button>
        </Stack.Item>
      </Stack>
    </Modal>
  );
};
