import { isInTheViewport } from 'utils';
import Quill, {
  DeltaStatic,
  DeltaOperation,
  OptionalAttributes,
  StringMap,
} from 'quill';
import { RangeStatic } from 'quill';
import bsearch from 'binary-search-bounds';

export type MemoizedRange = {
  isPauseTag: boolean;
} & RangeStatic;

const irrelevantAttributes = [
  'found-text',
  'found-text-current',
  'found-toc-text',
  'found-toc-text-current',
  'skip',
  'insert',
  'sentence',
];

const isRemovesAttributes = (operation: DeltaOperation) => {
  return (
    operation.hasOwnProperty('attributes') &&
    Object.values(operation.attributes || {}).some((v) => !v)
  );
};

const isPauseTag = (operation: DeltaOperation): boolean => {
  const attributes = operation.attributes || {};
  const name = Object.values(attributes)[0]
    ? JSON.parse(Object.values(attributes)[0]).name
    : '';
  return (name && name === 'customPause') || name === 'break';
};

const rangeComparator = (a: MemoizedRange, b: MemoizedRange) => {
  return a.index - b.index;
};

const updateIndexes = (
  range: MemoizedRange[],
  tagIndex: number,
  newTagLength: number,
  type: 'increase' | 'decrease'
) => {
  const indexToStartFrom = bsearch.lt(
    range,
    { index: tagIndex, length: 0, isPauseTag: false },
    rangeComparator
  );

  for (let i = Math.max(indexToStartFrom, 0); i < range.length; i++) {
    if (range[i].index >= tagIndex) {
      range[i] = {
        ...range[i],
        index:
          type === 'increase'
            ? range[i].index + newTagLength
            : range[i].index - newTagLength,
      };
    }
  }
};

const setSelectionOnTag = (quillEditor: Quill, tag: MemoizedRange) => {
  const { isPauseTag, index, length } = tag;
  const selection = {
    index: isPauseTag ? index + 1 : index,
    length: isPauseTag ? 0 : length,
  };
  quillEditor.setSelection(selection);
};

const scrollToTagIfNeeded = (quill: Quill, tag: MemoizedRange) => {
  const { top, bottom } = quill.getBounds(tag.index, tag.length);
  const { offsetHeight, scrollTop } = quill.root;
  if (isInTheViewport({ top, bottom, offsetHeight })) return;
  quill.root.scrollBy({
    top:
      (top < 0 || scrollTop < top || top > bottom ? top : bottom) -
      offsetHeight / 2,
  });
};

export const moveToThePreviousTagHotkeyHandler = ({
  quillEditor,
  memoizedRanges,
}: {
  quillEditor: Quill;
  memoizedRanges: MemoizedRange[];
}) => {
  const selection = quillEditor.getSelection();
  if (
    !selection ||
    !memoizedRanges.length ||
    memoizedRanges[0].index >= selection.index
  )
    return;

  const selectionIndex =
    selection.length > 0 ? selection.index : selection.index - 1;
  let indexToSwitchTo: number | undefined = undefined;

  // If a cursor placed after the last's tag index.
  if (selectionIndex > memoizedRanges[memoizedRanges.length - 1].index) {
    indexToSwitchTo = memoizedRanges.length - 1;
  } else {
    for (let i = 1; i < memoizedRanges.length; i++) {
      const prev = memoizedRanges[i - 1].index;
      const next = memoizedRanges[i].index;
      if (prev < selectionIndex && selectionIndex <= next) {
        indexToSwitchTo = i - 1;
        break;
      }
    }
  }

  if (indexToSwitchTo === undefined) return;

  const previousTag = memoizedRanges[indexToSwitchTo];

  setSelectionOnTag(quillEditor, previousTag);
  scrollToTagIfNeeded(quillEditor, previousTag);
};

export const moveToTheNextTagHotkeyHandler = ({
  quillEditor,
  memoizedRanges,
}: {
  quillEditor: Quill;
  memoizedRanges: MemoizedRange[];
}) => {
  const selection = quillEditor.getSelection();
  if (!selection || !memoizedRanges.length) return;

  const nextTag = memoizedRanges.find(
    (tag) => tag && tag.index >= selection.index + selection.length
  );

  if (!nextTag) return;

  setSelectionOnTag(quillEditor, nextTag);
  scrollToTagIfNeeded(quillEditor, nextTag);
};

const isDeleteOp = (op: DeltaOperation): op is { delete: number } => {
  return 'delete' in op;
};

const isRetainOp = (
  op: DeltaOperation
): op is { retain: number } & OptionalAttributes => {
  return 'retain' in op;
};

const isInsertOp = (
  op: DeltaOperation
): op is { insert: string } & OptionalAttributes => {
  return 'insert' in op;
};

const isInsertsTagAttributes = <T extends { attributes?: StringMap }>(
  op: T
): op is T & { attributes: StringMap } => {
  const attributesWithoutIrrelevant = Object.fromEntries(
    Object.entries(op.attributes || {}).filter(
      ([key]) => irrelevantAttributes.indexOf(key) === -1
    )
  );
  return Object.keys(attributesWithoutIrrelevant).length !== 0;
};

const findInsertIndex = (range: MemoizedRange[], cursorPosition: number) => {
  const firstBiggerThanCursor = bsearch.gt(
    range,
    { index: cursorPosition, length: 0, isPauseTag: false },
    rangeComparator
  );
  return firstBiggerThanCursor;
};

const filterRanges = (ranges: MemoizedRange[], start: number, end: number) => {
  return ranges.filter((range) => {
    const isWithin = range.index >= start && range.index + range.length <= end;
    return isWithin ? false : true;
  });
};

export const updateRangesWithDelta = (
  delta: DeltaStatic,
  memoizedRanges: React.MutableRefObject<MemoizedRange[]>
) => {
  // no operations
  if (!delta.ops?.length) return;

  let ranges = [...memoizedRanges.current];
  let cursorPos = 0;

  delta.ops.forEach((op) => {
    // insert operation
    // always moves existing ranges to the right after the cursor
    // when inserting new tags, adds new range at the cursor position
    // need to ignore irrelevant tags
    if (isInsertOp(op)) {
      updateIndexes(ranges, cursorPos, op.insert.length, 'increase');
      if (isInsertsTagAttributes(op)) {
        const insertIndex = findInsertIndex(ranges, cursorPos);
        ranges.splice(insertIndex, 0, {
          index: cursorPos,
          length: op.insert.length,
          isPauseTag: isPauseTag(op),
        });
      }
      cursorPos += op.insert.length;
    } else if (isDeleteOp(op)) {
      // delete operation
      // removes ranges that are within the deleted range
      // moves ranges to the left if they are after the deleted range
      const filtered = filterRanges(ranges, cursorPos, cursorPos + op.delete);
      updateIndexes(filtered, cursorPos, op.delete, 'decrease');
      ranges = filtered;
      cursorPos += op.delete;
    } else if (isRetainOp(op)) {
      // retain operation
      // does not move ranges
      // adds new ranges if new tags are inserted
      // removes ranges if tags are removed
      // need to ignore irrelevant tags
      if (isInsertsTagAttributes(op)) {
        if (isRemovesAttributes(op)) {
          ranges = filterRanges(ranges, cursorPos, cursorPos + op.retain);
        } else {
          const insertIndex = findInsertIndex(ranges, cursorPos);
          ranges.splice(insertIndex, 0, {
            index: cursorPos,
            length: op.retain,
            isPauseTag: isPauseTag(op),
          });
        }
      }
      cursorPos += op.retain;
    } else {
      console.info('unknown operation', op);
    }
  });
  memoizedRanges.current = ranges;
};
