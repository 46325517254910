import { PresetTag } from 'modules/common/models/Presets';
import { Dictionary } from '@reduxjs/toolkit';
import { TagType } from 'modules/common/models/Tags';
import Quill, { DeltaStatic, RangeStatic } from 'quill';
import QuillUtil from './QuillUtil';

let blotAttrBlackListDict: Dictionary<boolean> = {
  'ssml-blot-name': true,
  class: true,
  style: true,
  'data-id': true,
  'data-selected-attributes': true,
  'data-before-text': true,
  'data-after-text': true,
  'ssml-tag-name': true,
  'ssml-tag-type': true,
  'ssml-tag-id': true,
  ai_generated_tag: true,
};

export function removeBlotAttributesAndChangeTag(
  root: Element,
  replaceSet: Set<string>
) {
  let ssmlTagName = root.getAttribute('ssml-tag-name');

  if (ssmlTagName) {
    const tagType = root.getAttribute('ssml-tag-type');

    if (tagType === TagType.SentenceTags) {
      replaceSet.add(`> </${ssmlTagName}>`);
    }

    let range = document.createRange();
    let element = document.createElement(ssmlTagName);

    let attrs = root.getAttributeNames();
    // during copy, it will erase all the attr

    for (let i = 0; i < attrs.length; i++) {
      let curr = attrs[i];
      if (!blotAttrBlackListDict[curr]) {
        // if not found in blackList then copy
        let val = root.getAttribute(curr);
        element.setAttribute(curr, val ? val : '');
      }
    }

    range.selectNodeContents(root);
    element.appendChild(range.extractContents());
    if (root.parentNode) {
      root.parentNode.replaceChild(element, root);
    }
    root = element;
  } else if (root.getAttributeNames().length) {
    // normal tag
    for (let attr in blotAttrBlackListDict) {
      root.removeAttribute(attr);
    }
  }

  for (let child of root.children) {
    removeBlotAttributesAndChangeTag(child, replaceSet);
  }
}

export function getQuillHtml(delta: DeltaStatic) {
  let tempContainer = document.createElement('div');

  // to make sure optimizations works
  tempContainer.style.display = 'none';
  document.body.appendChild(tempContainer);

  const tempQuill = new Quill(tempContainer);
  tempQuill.setContents(delta, 'api');

  (tempQuill.scroll as any).optimize();

  document.body.removeChild(tempContainer);
  return tempQuill.root;
}

export function prepareSSMLText(el: HTMLElement) {
  let replaceSet = new Set<string>();
  for (let child of el.children) {
    removeBlotAttributesAndChangeTag(child, replaceSet);
  }
  // const selectionTags = el.getElementsByTagName('selection');
  const presetTags = Array.from(
    el.getElementsByTagName('preset') as HTMLCollectionOf<HTMLElement>
  );

  for (let preset of presetTags) {
    if (preset.dataset.tags) {
      const tags = JSON.parse(preset.dataset.tags) as PresetTag[];
      let fragment = document.createDocumentFragment();
      let parent: any = fragment;
      tags.forEach((tag) => {
        const tagEl = document.createElement(tag.name!);
        tag.attributes.forEach((a) => {
          tagEl.setAttribute(a.name!, a.value);
        });
        if (tag.fixedAttributes) {
          Object.keys(tag.fixedAttributes).forEach((key) => {
            const attribute = String(tag?.fixedAttributes?.[key]);
            tagEl.setAttribute(key, attribute);
          });
        }
        parent.appendChild(tagEl);
        parent = tagEl;
      });
      parent.innerHTML = preset.innerHTML;
      const presetParent = preset.parentNode!;
      presetParent.insertBefore(fragment, preset);
      presetParent.removeChild(preset);
    }
  }

  // while (selectionTags.length) {
  //   const parent = selectionTags[0].parentNode!;
  //   while (selectionTags[0].firstChild) {
  //     parent.insertBefore(selectionTags[0].firstChild, selectionTags[0]);
  //   }
  //   parent.removeChild(selectionTags[0]);
  // }

  let textStr = el.children[0].innerHTML
    .replaceAll('&nbsp;', ' ')
    .replace(/<div[^>]*>/g, '')
    .replace(/<\/div>/g, '');
  replaceSet.forEach((r) => {
    textStr = textStr.replaceAll(r, ' />');
  });

  return textStr;
}

export function prepareSSMLTextV2(el: HTMLElement) {
  let replaceSet = new Set<string>();
  for (let child of el.children) {
    removeBlotAttributesAndChangeTag(child, replaceSet);
  }
  const presetTags = Array.from(
    el.getElementsByTagName('preset') as HTMLCollectionOf<HTMLElement>
  );

  for (let preset of presetTags) {
    if (preset.dataset.tags) {
      const tags = JSON.parse(preset.dataset.tags) as PresetTag[];
      let fragment = document.createDocumentFragment();
      let parent: any = fragment;
      tags.forEach((tag) => {
        const tagEl = document.createElement(tag.name!);
        tag.attributes.forEach((a) => {
          tagEl.setAttribute(a.name!, a.value);
        });
        if (tag.fixedAttributes) {
          Object.keys(tag.fixedAttributes).forEach((key) => {
            const attribute = String(tag?.fixedAttributes?.[key]);
            tagEl.setAttribute(key, attribute);
          });
        }
        parent.appendChild(tagEl);
        parent = tagEl;
      });
      parent.innerHTML = preset.innerHTML;
      const presetParent = preset.parentNode!;
      presetParent.insertBefore(fragment, preset);
      presetParent.removeChild(preset);
    }
  }

  let textStr = el.innerHTML
    .replaceAll(/<p>|<\/p>/g, '')
    .replaceAll('&nbsp;', ' ')
    .replace(/<sentence[^>]*>/g, '')
    .replace(/<\/sentence>/g, '');
  replaceSet.forEach((r) => {
    textStr = textStr.replaceAll(r, ' />');
  });

  return textStr;
}

export function sanitizeSSMLText(delta: DeltaStatic) {
  const currentElement = getQuillHtml(delta);

  let elements = currentElement.getElementsByTagName('skip');

  for (let i = 0; i < elements.length; i++) {
    let item = elements[i];
    item?.parentNode?.removeChild(item);
  }

  return currentElement.innerText;
}

export function getSSMLText(editor: Quill, range: RangeStatic) {
  let text = editor.getText(range.index, range.length);
  let isThereRBBLot = !!QuillUtil.getRBChildren(editor, {
    index: range.index,
    length: range.length,
  }).length;

  if (isThereRBBLot) {
    const contents = editor.getContents(range.index, range.length);

    const tempQuill = getQuillHtml(contents);

    text = prepareSSMLText(tempQuill);
  }
  return text;
}
