import { useAppSelector } from 'modules/common/hooks/redux';
import { selectAll } from 'redux/reducers/tags/selectors';
import Styles from './helpcenter.module.scss';
import { Icon } from 'icons';

const TagsCustomize = () => {
  const tags = useAppSelector(selectAll);

  return (
    <>
      <p className={Styles['main-header']} id="tags_customize">
        Tags to customise speech ddd
      </p>
      <p className={Styles['sub-header']} id="tags_customize_enable">
        How to enable tags
      </p>
      <ol className={Styles['list']}>
        <li>
          On the right panel, select the <b>Edit</b> tab
        </li>
        <li>Result: tag options appear in the Tags list.</li>
      </ol>

      <p className={Styles['sub-header']} id="tags_customize_apply">
        How to apply tags{' '}
      </p>

      <ol className={Styles['list']}>
        <li>Select word or phrase</li>
        <li>
          Go to the <b>Edit</b> tab and select one or several tags.{' '}
        </li>
        <li>
          At the upper right, click on <b>Save</b>.
        </li>
      </ol>
      <p className={Styles['text']}>
        To unselect the tag, on the right panel clear the checkbox. Don’t forget
        to save every change you do.
      </p>
      <p className={Styles['sub-header']}>List of Tags</p>
      {tags && (
        <table className={Styles['table']}>
          <thead>
            <tr>
              <th>Tag</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {tags.map((tag) => (
              <tr key={tag.id}>
                <td>
                  <div>
                    <Icon
                      style={{ backgroundColor: tag.iconBgColor }}
                      color={tag.iconColor}
                      size={20}
                      name={tag.iconName}
                      className={Styles['tag-icon']}
                    />
                    {tag.displayName}
                  </div>
                </td>
                <td>{tag.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <p className={Styles['sub-header']} id="tags_customize_emphasis">
        Emphasize a phrase{' '}
      </p>
      <p className={Styles['text']}>
        To add a pause to your text, place the cursor between words or syllables
        of a word, select the <b>Pause</b> tag and then add a duration.
      </p>
      <p className={Styles['text']}>
        <b>Speed</b> and <b>Volume</b> tags help to change speaking rate and
        volume.
      </p>
      <p className={Styles['alert']}>
        To increase emphasizing make a phrase slower and louder
      </p>

      <p className={Styles['sub-header']} id="tags_customize_adjust">
        Adjust pronunciation{' '}
      </p>
      <p className={Styles['text']}>
        The Part of speech specifies the following parts of speech:
      </p>
      <ul className={Styles['list']}>
        <li>Present simple verb</li>
        <li>Past simple verb</li>
        <li>Determiner</li>
        <li>Preposition</li>
        <li>Adjective</li>
        <li>Noun</li>
      </ul>
      <p className={Styles['text']}>
        The Language tag generates voices with specific accents and makes
        foreign language words and phrases sound according to pronunciation
        rules.
      </p>
      <p className={Styles['text']}>
        You may apply the following pronunciations: American or British English,
        French, German, German (Austrian), Spanish (US), Spanish (Mexican).
      </p>
      <p className={Styles['text']}>
        To add a sound according to a specific transcription, use the Phoneme.
        To apply this tag, add a correct transcription.
      </p>
      <ul className={Styles['list']}>
        <li>
          IPA — Indicates that the International Phonetic Alphabet (IPA) will be
          used.
        </li>
        <li>
          x-sampa— Indicates that the Extended Speech Assessment Methods
          Phonetic Alphabet (X-SAMPA) will be used.
        </li>
      </ul>
      <p className={Styles['text']}>Example: kɔʃəsli for the word cautiously</p>
      <p className={Styles['alert']}>
        To convert words to phonetic transcriptions, we recommend toPhonetics
      </p>
      <p className={Styles['text']}>
        To specify pronunciation of numbers, use <b>Numerical</b>. There are
        following options:
      </p>
      <ul className={Styles['list']}>
        <li>Cardinal</li>
        <li>Number</li>
        <li>Digits</li>
        <li>Fraction</li>
        <li>Unit</li>
      </ul>

      <p className={Styles['sub-header']} id="tags_customize_date">
        Date, time and abbreviations{' '}
      </p>
      <p className={Styles['text']}>
        <b>Date and time</b> helps to interpret a text as a date or time.
      </p>
      <p className={Styles['text']}>
        <b>Spell</b> out is applied to pronounce abbreviations.{' '}
      </p>
      <p className={Styles['text']}>
        <b>Alias</b> is used to turn abbreviations into full names. By default,
        when you select word or phrase and then apply this tag, it automatically
        duplicates copied word or phrase to the Alias input.
      </p>
      <p className={Styles['sub-header']} id="tags_customize_censored">
        Censored effects
      </p>
      <p className={Styles['text']}>
        The <b>Expletive</b> tag replaces a voice with a beep sound.
      </p>
      <p className={Styles['text']}>
        To remove word or phrase, select it and then on the right panel, select
        the <b>Skip selected fragment</b>.
      </p>
      <p className={Styles['sub-header']} id="tags_customize_compatibility">
        Tag compatibility
      </p>
      <p className={Styles['text']}>
        When you apply a tag, some other tags are disabled. Here is a table of
        tags that cannot be applied to a word or phrase simultaneously:
      </p>
    </>
  );
};

export default TagsCustomize;
