import React from 'react';
import { getUserEmail } from 'aws';
import {
  useGetIsFeatureEnabledQuery,
  useLazyGetIsFeatureEnabledQuery,
} from 'redux/api/app.api';
import { getActiveProjectTTSEngineSelector } from 'redux/reducers/project/selectors';
import { setProjectInactive } from 'redux/reducers/project/projectSlice';
import {
  ProjectApiInstance as ProjectApiInstanceV2,
  ProjectApiV2,
} from 'modules/edit/v2/api/Project';
import {
  ProjectApi,
  ProjectApiInstance as ProjectApiInstanceV1,
  SplitBookStatus,
} from 'modules/edit/api/Project';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { useUserEmail } from './hooks/useUserEmail';
import { error, SomethingWentWrong, success } from './components/Notify';
import { useNavigate } from 'react-router-dom';

export const useSaveTOCAndSplitBookIntoChapters = () => {
  const navigate = useNavigate();
  const activeProjectTTSEngineSelector = useAppSelector(
    getActiveProjectTTSEngineSelector
  );
  const dispatch = useAppDispatch();
  const userEmail = useUserEmail();
  const intervalId = React.useRef<ReturnType<typeof setInterval> | null>(null);
  const { data: isUseCachingEnabled } = useGetIsFeatureEnabledQuery(
    {
      featureName: 'use-caching',
      context: {
        tts_engine: activeProjectTTSEngineSelector || '',
        user_email: userEmail,
      },
    },
    { skip: !activeProjectTTSEngineSelector || !userEmail }
  );
  const ProjectApiInstance = !!isUseCachingEnabled
    ? ProjectApiInstanceV2
    : ProjectApiInstanceV1;

  const runningLoop = ({
    projectId,
    setIsSavingTOCPending,
  }: {
    projectId: string;
    setIsSavingTOCPending: Function;
  }) => {
    intervalId.current = setInterval(async () => {
      const { processingStatus, logException } =
        await ProjectApiInstance.getStatusOfSplitBooks(projectId);
      if (processingStatus !== SplitBookStatus.RUNNING) {
        if (intervalId.current) {
          clearInterval(intervalId.current);
        }

        if (processingStatus === SplitBookStatus.FAILED) {
          setIsSavingTOCPending(false);
          error({
            children: logException
              ? logException
              : 'Something went wrong while splitting the book.',
          });
        }

        if (processingStatus === SplitBookStatus.COMPLETED) {
          success({ children: 'Project is saved successfully' });
          navigate('/dashboard');
          dispatch(setProjectInactive());
        }
      }
    }, 5000);
  };

  return async ({
    projectId,
    setIsSavingTOCPending,
    projectTOCInnerHTMLPages,
  }: {
    projectId: string;
    projectTOCInnerHTMLPages: string;
    setIsSavingTOCPending: Function;
  }) => {
    try {
      const { url } = await ProjectApiInstanceV1.getProjectTOCPresignUrl(
        projectId
      );
      await ProjectApiInstanceV1.saveProjectTOC(url, projectTOCInnerHTMLPages);
      await ProjectApiInstance.initializeBookToChapters(projectId);
      runningLoop({ projectId, setIsSavingTOCPending });
    } catch (err) {
      setIsSavingTOCPending(false);
      SomethingWentWrong();
    }
  };
};

export const useSplitBookIntoChapters = () => {
  const activeProjectTTSEngineSelector = useAppSelector(
    getActiveProjectTTSEngineSelector
  );
  const dispatch = useAppDispatch();
  const intervalId = React.useRef<ReturnType<typeof setInterval> | null>(null);
  const [getIsCachingEnabled] = useLazyGetIsFeatureEnabledQuery();

  const runningLoop = React.useCallback(
    ({
      projectId,
      setSavingTOCCompleted,
      projectApiInstance,
    }: {
      projectId: string;
      setSavingTOCCompleted?: () => void;
      projectApiInstance: ProjectApiV2 | ProjectApi;
    }) => {
      intervalId.current = setInterval(async () => {
        const { processingStatus, logException } =
          await projectApiInstance.getStatusOfSplitBooks(projectId);
        if (processingStatus !== SplitBookStatus.RUNNING) {
          if (intervalId.current) {
            clearInterval(intervalId.current);
          }

          if (processingStatus === SplitBookStatus.FAILED) {
            setSavingTOCCompleted?.();
            error({
              children: logException
                ? logException
                : 'Something went wrong while splitting the book.',
            });
          }

          if (processingStatus === SplitBookStatus.COMPLETED) {
            setSavingTOCCompleted?.();
            success({ children: 'Project is saved successfully' });
            dispatch(setProjectInactive());
          }
        }
      }, 5000);
    },
    [dispatch]
  );

  return React.useCallback(
    async ({
      projectId,
      setSavingTOCCompleted,
    }: {
      projectId: string;
      setSavingTOCCompleted?: () => void;
    }) => {
      const userEmail = await getUserEmail();
      const isCachingEnabled = await getIsCachingEnabled({
        featureName: 'use-caching',
        context: {
          tts_engine: activeProjectTTSEngineSelector || '',
          user_email: userEmail,
        },
      }).unwrap();

      const ProjectApiInstance = !!isCachingEnabled
        ? ProjectApiInstanceV2
        : ProjectApiInstanceV1;

      try {
        success({
          children:
            'The splitting book process has started. Please wait until it is finished.',
        });
        await ProjectApiInstance.initializeBookToChapters(projectId);
        runningLoop({
          projectId,
          setSavingTOCCompleted,
          projectApiInstance: ProjectApiInstance,
        });
      } catch (err) {
        setSavingTOCCompleted?.();
        error({ children: 'Something went wrong with the book splitting.' });
      }
    },
    [activeProjectTTSEngineSelector, getIsCachingEnabled, runningLoop]
  );
};
