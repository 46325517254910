import ReactDOM from 'react-dom';
import { createElementContainer } from 'utils';
import ConfirmationPopup, {
  ConfirmationSetup,
} from './ConfirmationPopup/ConfirmationPopup';

let confirmFn: (obj: ConfirmationSetup) => void;

ReactDOM.render(
  <ConfirmationPopup
    setConfirm={(fn) => {
      confirmFn = fn;
    }}
  />,
  createElementContainer('confirmationPortal')
);

export function ConfirmPopup(confirmationSetup: ConfirmationSetup) {
  return confirmFn(confirmationSetup);
}
