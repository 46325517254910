export const UNDO_REDO_ACTION_DELAY = 500;
export const DOCUMENT_SIDE_PADDINGS = 96;
export const DOCUMENT_LINE_HEIGHT = 42;

export interface CanvasParameters {
  left: number;
  height: number;
  width: number;
}

export const UNDO_REDO_CANVAS_ID = 'undo-redo-animation-canvas';
