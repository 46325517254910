export interface LoaderState {
  [key: string]: 'notFetched' | 'fetching' | 'fetched' | 'errorFetching';
}

export enum AsyncRequestStatus {
  initial = 'initial',
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

export interface LoaderSliceState {
  [key: string]: AsyncRequestStatus;
}
