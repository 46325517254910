import { FC, useEffect, useMemo, useState } from 'react';
import { SuperAdminTabs } from './index';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Tab } from 'modules/common/components/Tab/Tab';
import { TabGroup } from 'modules/common/components/Tab/TabGroup';
import { useIsAdminRole } from 'modules/common/hooks/useIsAdminRole';
import { useIsEditorRole } from 'modules/common/hooks/useIsEditorRole';
import { Select } from 'modules/common/components/Select/Select';
import { getEditorsStatus } from 'modules/common/constants/functions';
import { EditorStatus, SelectValue } from 'modules/common/types';
import {
  useChangeEditorAvailabilityMutation,
  useGetCurrentUserStatusQuery,
} from 'redux/api/app.api';
import { error, SomethingWentWrong } from 'modules/common/components/Notify';

interface Props {
  tabView: SuperAdminTabs;
  onTabChange: (tab: SuperAdminTabs) => void;
}

const Actions: FC<Props> = ({ tabView, onTabChange }) => {
  const isAdminRole = useIsAdminRole();
  const isEditorRole = useIsEditorRole();
  const [availableState, setAvailableState] = useState<SelectValue>();
  const editorStatus = useMemo(() => getEditorsStatus(), []);

  const [changeStatus, { isLoading }] = useChangeEditorAvailabilityMutation();
  const { data, isFetching } = useGetCurrentUserStatusQuery(undefined, {
    skip: isAdminRole,
  });

  const onChangeSelect = async (value: SelectValue) => {
    if (!value || (availableState && value.value === availableState.value))
      return;

    try {
      let res = await changeStatus(value.value as EditorStatus).unwrap();
      if (res.editorCapacity >= 5) {
        error({
          children:
            'Editor status could not be changed due to the value of capacity: 5.',
        });
      } else {
        setAvailableState(value);
      }
    } catch (err) {
      SomethingWentWrong();
    }
  };

  useEffect(() => {
    if (data && isEditorRole) {
      let val = editorStatus.find((item) => item.value === data.editorStatus);
      if (val) {
        setAvailableState(val);
      }
    }
  }, [data, isEditorRole, editorStatus]);

  const AdminTabs = useMemo(
    () => [
      <Tab
        onClick={() => onTabChange(SuperAdminTabs.Projects)}
        active={tabView === SuperAdminTabs.Projects}
        key={SuperAdminTabs.Projects}
      >
        Projects
      </Tab>,
      <Tab
        onClick={() => onTabChange(SuperAdminTabs.Editors)}
        active={tabView === SuperAdminTabs.Editors}
        key={SuperAdminTabs.Editors}
      >
        Editors
      </Tab>,
    ],
    [tabView, onTabChange]
  );

  const EditorsTabs = useMemo(
    () => [
      <Tab
        onClick={() => onTabChange(SuperAdminTabs.Projects)}
        active={tabView === SuperAdminTabs.Projects}
        key={SuperAdminTabs.Projects}
      >
        Projects
      </Tab>,
    ],
    [tabView, onTabChange]
  );

  return (
    <div className="container">
      <Stack justifyContent="space-between" alignItems="center">
        <Stack.Item>
          <TabGroup>{isAdminRole ? AdminTabs : EditorsTabs}</TabGroup>
        </Stack.Item>
        {isEditorRole && (
          <Stack.Item>
            <Stack alignItems="center">
              <Stack.Item>
                <span className="m-r-1 font-size-xs">Status :</span>
              </Stack.Item>
              <Stack.Item>
                <Select
                  value={availableState}
                  onChange={onChangeSelect}
                  options={editorStatus}
                  isLoading={isLoading || isFetching}
                  disabled={
                    isLoading ||
                    isFetching ||
                    (data && data.editorCapacity >= 5)
                  }
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        )}
      </Stack>
    </div>
  );
};

export default Actions;
