import { ChangeEvent, FC, useCallback, useState } from 'react';
import { IconNameType } from 'icons/build/icons';

import Slider from 'modules/common/components/Slider/Slider';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Icon } from 'icons';

interface AudioPlayerVolumeProps {
  audioPlayerRef: React.MutableRefObject<HTMLAudioElement>;
}

enum VolumeTypes {
  mute = 0,
  low = 1,
  mid = 2,
  high = 3,
}

const volumeIcons = new Map<VolumeTypes, IconNameType>([
  [VolumeTypes.high, 'volume_high'],
  [VolumeTypes.mid, 'volume_mid'],
  [VolumeTypes.low, 'volume_low'],
  [VolumeTypes.mute, 'volume_mute'],
]);

const AudioPlayerVolume: FC<AudioPlayerVolumeProps> = ({ audioPlayerRef }) => {
  const [volumeState, setVolumeState] = useState<VolumeTypes>(VolumeTypes.high);

  const manageVolume = useCallback(
    (target): number => {
      audioPlayerRef.current.volume = target.value;
      return audioPlayerRef.current.volume;
    },
    [audioPlayerRef]
  );

  const onChangeHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = manageVolume(evt.target);

    if (value === 0) {
      setVolumeState(VolumeTypes.mute);
    } else if (value < 0.4) {
      setVolumeState(VolumeTypes.low);
    } else if (0.4 <= value && value < 0.8) {
      setVolumeState(VolumeTypes.mid);
    } else {
      setVolumeState(VolumeTypes.high);
    }
  };

  return (
    <Stack spacing={1} alignItems="center">
      <Stack.Item>
        <Icon size={24} name={volumeIcons.get(volumeState)!} />
      </Stack.Item>
      <Stack.Item flex>
        <Slider
          max="1"
          min="0"
          step="0.1"
          defaultValue="1"
          onChange={onChangeHandler}
        />
      </Stack.Item>
    </Stack>
  );
};

export default AudioPlayerVolume;
