import { RBBlot } from './RBBlot';

export class SkipBlot extends RBBlot {
  static blotName = 'skip';
  static tagName = 'skip';
  static classNameInternal = 'rb-skip-tag';
  static allowedTags = [];

  static create(value: string) {
    let node = super.create();
    if (value) {
      node.setAttribute('class', this.classNameInternal);
      node.setAttribute('ssml-blot-name', this.blotName);
      let beforeText = `<${SkipBlot.tagName.toLowerCase()}>`;
      let afterText = `</${SkipBlot.tagName.toLowerCase()}>`;
      node.setAttribute('data-before-text', beforeText);
      node.setAttribute('data-after-text', afterText);
    }
    return node;
  }
}
