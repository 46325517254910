import { FC } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { AdminProjects } from 'modules/dashboard/ProjectsTableConfig';
import { prettifyDate } from 'utils';
import { ProjectStatus } from 'modules/common/types';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Icon } from 'icons';
import { Button } from 'modules/common/components/Button/Button';

import Styles from './unassigned-projects.module.scss';

interface Props {
  setDeclineReasonView: (value: boolean) => void;
  setOpen: () => void;
  isFetching?: boolean;
  data: AdminProjects;
}

const NormalProjectView: FC<Props> = ({
  data,
  setDeclineReasonView,
  setOpen,
  isFetching,
}) => {
  return (
    <Stack direction="column" justifyContent="space-between" className="h-100">
      <Stack.Item>
        <span
          className={classnames(Styles['status'], Styles[data.projectStatus])}
        />
        <p className="fw-500 font-size-xs m-b-1-5">
          {prettifyDate(data.dateAdded)}
        </p>
      </Stack.Item>
      <Stack.Item>
        <p className="m-b-half font-size-sm">
          {data.author} uploaded project ( {data.name} )
        </p>
        {data?.projectStatus === ProjectStatus.pending ? (
          <Stack
            justifyContent="space-between"
            className="color-primary opacity-80 font-size-sm m-t-2"
          >
            <Stack.Item>
              <Icon size="14" name="hourglass" className="m-r-1" />
              Pending...
            </Stack.Item>
            <Stack.Item>
              <span>{data.editor}</span>
            </Stack.Item>
          </Stack>
        ) : (
          <Stack justifyContent="space-between" spacing={2} className="m-t-2">
            <Stack.Item>
              <Button onClick={setOpen} disabled={isFetching}>
                Select editor
              </Button>
            </Stack.Item>
            <Stack.Item>
              {data?.projectStatus === ProjectStatus.new_project && (
                <Button secondary disabled={isFetching}>
                  <Link to={`/editor/${data.id}`}>View book</Link>
                </Button>
              )}
              {data?.projectStatus === ProjectStatus.declined && (
                <Button
                  secondary
                  onClick={() => setDeclineReasonView(true)}
                  disabled={isFetching}
                >
                  View reason
                </Button>
              )}
            </Stack.Item>
          </Stack>
        )}
      </Stack.Item>
    </Stack>
  );
};

export default NormalProjectView;
