import { ComponentType, ReactNode } from 'react';
import { isFragment } from 'react-is';

export const wrapWithComponent = (
  element: ReactNode,
  Component: ComponentType,
  props?: any
) => {
  const childArray = isFragment(element) ? element.props.children : element;
  return <Component {...props}>{childArray}</Component>;
};
