import { createContext } from 'react';
import { TOCChapter } from 'modules/common/types';

export interface ToCChaptersContextType {
  chapters: TOCChapter[],
  selectedChapterIds: number[],
  setSelectedChapterIds(value: number[]): void
}

const ToCChaptersContext = createContext<ToCChaptersContextType>({
  chapters: [],
  selectedChapterIds: [],
  setSelectedChapterIds(value: number[]) {
    console.log(value)
  }
})
export default ToCChaptersContext;
