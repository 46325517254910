import { Keys } from 'utils/keyCodes';
import useEventListener from 'modules/common/hooks/useEventListener';

type KeyEvent = 'keydown' | 'keyup';

export interface KeyPressListenerProps {
  code: Keys;
  handler(event: KeyboardEvent): void;
  keyEvent?: KeyEvent;
  disabled?: boolean;
}

export function useKeyPressListener({
  code,
  handler,
  keyEvent = 'keyup',
  disabled = false,
}: KeyPressListenerProps) {
  const handleKeyEvent = (event: KeyboardEvent) => {
    if (disabled) {
      event.preventDefault();
      return;
    }

    if (event.code === code) {
      handler(event);
    }
  };
  useEventListener(keyEvent, handleKeyEvent);
}
