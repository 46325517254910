import React, {
  ChangeEvent,
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
} from 'react';
import Styles from './slider.module.scss';
import classnames from 'classnames';

interface SliderProps {
  max: number | string;
  min: number | string;
  defaultValue: number | string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  classname?: string;
  styles?: React.CSSProperties & { '--seek-before-width-custom': string };
  step?: number | string;
}

const Slider = forwardRef<HTMLInputElement, SliderProps>(
  (
    { classname, styles, max, min, defaultValue = 0, onChange, step },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
      let target = evt.target;

      const min = parseFloat(target.min);
      const max = parseFloat(target.max);
      const val = parseFloat(target.value);

      target.style.setProperty(
        '--seek-before-width',
        `${((val - min) * 100) / (max - min)}%`
      );

      onChange?.(evt);
    };

    // works only in re-renders foe initial value
    const style = {
      ...(styles || {}),
      '--seek-before-width': `${
        ((parseFloat(defaultValue.toString()) - parseFloat(min.toString())) *
          100) /
        (parseFloat(max.toString()) - parseFloat(min.toString()))
      }%`,
    } as React.CSSProperties;

    return (
      <input
        type="range"
        step={step}
        min={min}
        defaultValue={defaultValue}
        max={max}
        className={classnames(Styles['progress'], classname)}
        ref={ref}
        onChange={handleOnChange}
        style={style}
      />
    );
  }
);

Slider.displayName = 'Slider';

export default Slider;
