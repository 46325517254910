import { Button } from 'modules/common/components/Button/Button';
import Styles from './generateaudio.module.scss';
import { LabelTextarea } from 'modules/common/components/LabeledInputs/LabelTextarea';
import { ResultForm } from '../../types';
import { AudioPlayerProjectProps } from 'modules/common/hooks/useAudioPlayerProject';

interface GenerateAudioProps {
  previewText: string;
  setPreviewText: React.Dispatch<React.SetStateAction<string>>;
  formData: ResultForm;
  playerProps: AudioPlayerProjectProps;
}

export const GenerateAudio: React.FC<GenerateAudioProps> = ({
  previewText,
  setPreviewText,
  formData,
  playerProps,
}) => {
  const count = previewText.length;
  const maxCount = 500;
  const isOverLimit = count > maxCount;

  const isLoading = playerProps.isLoading;
  const isDisabled = isLoading || playerProps.isPlaying || isOverLimit;

  const countClassName = isOverLimit ? 'color-error opacity-100' : 'opacity-50';

  const onPreviewTextChange = (value: string) => {
    setPreviewText(value);
  };

  const onGenerateAudio = () => {
    playerProps.voiceIdRef.current = formData.voice;
    playerProps.handlePlayPause();
  };

  return (
    <div>
      <LabelTextarea
        placeholder="Enter text"
        labelText="Sample text"
        value={previewText}
        onChangeValue={onPreviewTextChange}
        maxLength={maxCount + 100}
      />
      <p className="m-y-2 fw-600 font-size-xs">
        <span className={countClassName}>{count}</span>
        <span className="opacity-50">/{maxCount}</span>
      </p>
      <Button
        secondary
        onClick={onGenerateAudio}
        className={Styles['GenerateButton']}
        disabled={isDisabled}
        loading={isLoading}
        flexLoader
      >
        Generate audio
      </Button>
    </div>
  );
};
