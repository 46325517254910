import React from 'react';
import { Modal } from 'modules/common/components/Modal/Modal';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Button } from 'modules/common/components/Button/Button';

const TOCBackWarningPopup: React.FC<{
  isOpen: boolean;
  onClose(): void;
  onAction(): void;
}> = ({ isOpen, onClose, onAction }) => (
  <Modal
    portal
    open={isOpen}
    isCloseIcon={true}
    onClose={onClose}
    gray
    isClosableOnClickOutside={false}
  >
    <Stack
      className="m-t-2"
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Stack.Item className="m-b-4 font-size-lg fw-700">
        Return to projects list?
      </Stack.Item>
      <Stack.Item className="m-b-4 font-size-sm ta-center">
        All created chapters and sub-chapters will not be saved
      </Stack.Item>
      <Stack.Item className="font-size-sm ta-center">
        <Button secondary style={{width: 209}} onClick={onAction}>Go back to projects list</Button>
        <Button className="m-l-2" style={{width: 209}} onClick={onClose}>Continue chapter creation</Button>
      </Stack.Item>
    </Stack>
  </Modal>
);

export default TOCBackWarningPopup;
