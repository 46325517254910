import { convertArrayToStrWithCommas, StatusType } from 'utils';
import axiosInstance from 'utils/axios';
import { Dict, TTSEngine } from 'modules/common/types';

export type preAssignedUrlValue = {
  audioId: string;
  path: string;
  getUrl: string;
  chapterName: string;
};

type audioStatusType = {
  audio_id: string;
  path: string;
  status: StatusType;
};

type preAssignedAudioUrls = Dict<preAssignedUrlValue>;

interface IExport {
  startTheProcess(
    projectId: string,
    projectEngine: TTSEngine | undefined,
    chapterIds: string[],
    volumeNormalization?: boolean
  ): Promise<string>;
  getTheProcessStatus(
    projectId: string,
    projectEngine: TTSEngine | undefined
  ): Promise<audioStatusType[]>;
  getPreAssignedAudio(
    projectId: string,
    chapterIds: string[]
  ): Promise<preAssignedAudioUrls>;
  getAudioOfPreAssignedUrls(getUrl: string): Promise<Blob>;
}

class Export implements IExport {
  static exportProject = '/project/export';
  static exportElevenLabsProject = '/project/elevenlabs_projects_api_export';
  static exportedAudios = '/project/audios';

  async startTheProcess(
    projectId: string,
    projectEngine: TTSEngine | undefined,
    chapterIdsArr: string[],
    volumeNormalization?: boolean
  ): Promise<string> {
    const isElevenLabsEngineInUse =
      projectEngine && projectEngine === 'elevenlabs';
    const chapterId = convertArrayToStrWithCommas(chapterIdsArr);

    const res = await axiosInstance.post(
      isElevenLabsEngineInUse
        ? Export.exportElevenLabsProject
        : Export.exportProject,
      {
        projectId,
        chapterId,
        ...(isElevenLabsEngineInUse && { volumeNormalization }),
      }
    );

    return res.data;
  }

  async getTheProcessStatus(
    projectId: string,
    projectEngine: TTSEngine | undefined
  ): Promise<audioStatusType[]> {
    const isElevenLabsEngineInUse =
      projectEngine && projectEngine === 'elevenlabs';
    const res = await axiosInstance.get(
      isElevenLabsEngineInUse
        ? Export.exportElevenLabsProject
        : Export.exportProject,
      {
        params: {
          projectId,
        },
      }
    );

    return Object.values(res.data);
  }

  async getPreAssignedAudio(
    projectId: string,
    chapterIdsArr?: string[]
  ): Promise<preAssignedAudioUrls> {
    const chapterId = chapterIdsArr
      ? convertArrayToStrWithCommas(chapterIdsArr)
      : undefined;

    const res = await axiosInstance.get(Export.exportedAudios, {
      params: {
        projectId,
        chapterId,
      },
    });

    return res.data;
  }

  async getAudioOfPreAssignedUrls(getUrl: string): Promise<Blob> {
    const res = await axiosInstance.get(getUrl, {
      responseType: 'blob',
      baseURL: getUrl,
    });

    return res.data;
  }
}

const ExportApiInstance = new Export();

export default ExportApiInstance;
