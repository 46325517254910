import { Stack } from 'modules/common/components/Stack/Stack';

export const EmptyChapterList = () => (
  <Stack alignItems="center" justifyContent="center">
    <Stack.Item shrink={0}>
      <div className="ta-center">
        <p className="m-t-2 fw-600 font-size-sm opacity-50">
          List of TOC chapters is empty
        </p>
      </div>
    </Stack.Item>
  </Stack>
);
