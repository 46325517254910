import { FC, useCallback, useMemo } from 'react';

import Styles from './tags.module.scss';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Checkbox } from 'modules/common/components/Checkbox/Checkbox';
import { Icon } from 'icons';
import {
  AttributeModel,
  AttributeTypes,
  TagModel,
  TagType,
} from 'modules/common/models/Tags';
import { Attribute } from './Attribute';
import classNames from 'classnames';

interface TagContainerProps {
  tag: TagModel;
  onCheckBoxChange: (tag: TagModel, checked: boolean) => void;
  checkboxSelected?: boolean;
  onAttrValueChange: (
    tag: TagModel,
    attr: AttributeModel,
    value?: string
  ) => void;
  attributeValues: Map<string, string | undefined>;
  className?: string;
}

export const TagContainer: FC<TagContainerProps> = ({
  tag,
  checkboxSelected,
  attributeValues,
  className,
  onCheckBoxChange,
  onAttrValueChange,
}) => {
  const handleCheckBoxChange = useCallback(
    (checked: boolean) => {
      onCheckBoxChange(tag, checked);
    },
    [onCheckBoxChange, tag]
  );

  const handleChange = useCallback(
    (attr: AttributeModel, value?: string) => {
      onAttrValueChange(tag, attr, value);
    },
    [onAttrValueChange, tag]
  );

  const attributes = useMemo(
    () =>
      [...tag.attributes].sort((a, b) =>
        a.type === AttributeTypes.Integer
          ? -1
          : b.type === AttributeTypes.Integer
          ? 1
          : 0
      ),
    [tag.attributes]
  );

  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      spacing={3}
      wrap="wrap"
      className={className}
    >
      <Stack.Item shrink={0} className="m-b-1">
        <Stack justifyContent="space-around" alignItems="center">
          <Checkbox
            onChange={handleCheckBoxChange}
            checked={checkboxSelected}
          />
          <Icon
            style={{ backgroundColor: tag.iconBgColor }}
            color={tag.iconColor}
            size={20}
            name={tag.iconName}
            className={Styles['tag-icon']}
          />
          <span className="font-size-sm fw-600">{tag.displayName}</span>
        </Stack>
      </Stack.Item>
      {checkboxSelected &&
        !!tag.attributes.length &&
        attributes.map((attribute) => {
          return (
            <Stack.Item
              className={classNames({
                [Styles.numericAttr]:
                  attribute.type === AttributeTypes.Integer &&
                  tag.tagType === TagType.SentenceTags,
                [Styles['tag-attr']]: attribute.type !== AttributeTypes.Integer,
                'w-100': attribute.type === AttributeTypes.Integer,
              })}
              key={attribute.id}
            >
              <Attribute
                onChange={handleChange}
                value={attributeValues.get(attribute.name)}
                attribute={attribute}
              />
            </Stack.Item>
          );
        })}
    </Stack>
  );
};
