import { useIsAdminRole } from 'modules/common/hooks/useIsAdminRole';

import ProjectsTabAdmin from './ProjectsTabAdmin';
import ProjectsTabEditor from './ProjectsTabEditor';

const ProjectsTab = () => {
  const isAdminRole = useIsAdminRole();

  return (
    <div className="p-t-3">
      {isAdminRole ? <ProjectsTabAdmin /> : <ProjectsTabEditor />}
    </div>
  );
};

export default ProjectsTab;
