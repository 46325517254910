import React from 'react';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Select } from 'modules/common/components/Select/Select';
import { LabelValue, SelectValue } from 'modules/common/types';

interface Props {
  onChangeValue: (value: string | undefined) => void;
  value?: string;
  data: LabelValue[];
  text: string;
  isPortal?: boolean;
  width?: string;
}

const SelectCell: React.FC<Props> = ({
  text,
  onChangeValue,
  value,
  data,
  width,
  isPortal = true,
}) => {
  const onChangeSelect = (value: SelectValue) => {
    onChangeValue(value?.value);
  };

  const selectValue = data.find((item) => item.value === value);

  return (
    <Stack direction="column" justifyContent="flex-end" style={{ width }}>
      <Stack.Item className="m-b-1">{text}</Stack.Item>
      <Stack.Item>
        <Select
          value={selectValue}
          onChange={onChangeSelect}
          options={data}
          isClearable
          portal={isPortal}
        />
      </Stack.Item>
    </Stack>
  );
};

export default SelectCell;
