import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { rejectRequest } from 'utils/rejectRequest';
import { AxiosError } from 'axios';
import {
  ChapterDetailedModel,
  ChapterModel,
} from 'modules/common/models/Chapter';
import ChaptersApiInstance from 'modules/edit/api/Chapter';
import { RootState } from 'redux/store';
import { Dict } from 'modules/common/types';
import { ProjectModel } from 'modules/common/models/Project';
import SaveCacheApiInstance from 'modules/edit/api/SaveCache';
import QuillUtil from 'utils/QuillUtil';
import { SomethingWentWrong, success } from 'modules/common/components/Notify';

export const ChapterAdapter = createEntityAdapter<ChapterModel>({
  selectId: (chapter) => chapter.id,
});

export interface ChapterState extends EntityState<ChapterModel> {
  currentChapter?: {
    name: string;
    id: string;
    next?: string;
    prev?: string;
    task_arn?: string;
  };
  details?: ChapterDetailedModel;
  dirtyChapters: Dict<boolean>;
  chaptersWithSavedChanges: Dict<boolean>;
  pendingSaveChapters: Dict<boolean>;
  allChaptersSaving: boolean;
}

const initialState: ChapterState = {
  ...ChapterAdapter.getInitialState(),
  dirtyChapters: {},
  chaptersWithSavedChanges: {},
  pendingSaveChapters: {},
  allChaptersSaving: false,
};

export const fetchChapters = createAsyncThunk<ChapterModel[], string>(
  'chapters/fetchChapters',
  async (projectId, { rejectWithValue }) => {
    try {
      return ChaptersApiInstance.getAll(projectId);
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

export const fetchProjectCaches = createAsyncThunk<void, string>(
  'chapters/fetchProjectCaches',
  async (projectId, { rejectWithValue, dispatch }) => {
    try {
      const projectCache = await SaveCacheApiInstance.getAll(projectId);
      const dict: Dict<boolean> = {};
      projectCache.forEach((cache) => (dict[cache.chapterId] = true));
      dispatch(setChapterDirtyBatch(dict));
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

export const setActiveChapter = createAsyncThunk<
  {
    details: ChapterDetailedModel;
    id: string;
    name: string;
    next?: string;
    prev?: string;
  },
  { chapterId: string; projectId: string }
>(
  'chapters/getChapterInfo',
  async ({ chapterId, projectId }, { rejectWithValue, getState }) => {
    try {
      let res = await ChaptersApiInstance.getChapterInfo(chapterId, projectId);

      const state = getState() as RootState;
      let chaptersIds = state.chapters.ids;
      const chapter = state.chapters.entities[chapterId] as ChapterModel;

      const index = chaptersIds.indexOf(chapterId);
      const name = chapter.chapterName ? chapter.chapterName : '??';
      const next =
        index === chaptersIds.length - 1
          ? undefined
          : chaptersIds[index + 1].toString();

      // uncomment this when the project model has this field
      // const isUsingAudioCaching = state.project.project?.isUsingAudioCaching;
      const isUsingAudioCaching = true;
      if (isUsingAudioCaching && next) {
        // no need to wait for this
        ChaptersApiInstance.cacheChapterTrigger(projectId, next);
      }

      const prev = index === 0 ? undefined : chaptersIds[index - 1].toString();

      return {
        id: chapterId,
        name,
        next,
        prev,
        details: res,
      };
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

export const saveChapter = createAsyncThunk<
  { chapterId: string; projectId: string },
  {
    chapterId: string;
    projectId: string;
    uploadUrl: ProjectModel['uploadUrl'];
    text: string;
  }
>(
  'chapters/saveChapter',
  async ({ chapterId, projectId, uploadUrl, text }, { rejectWithValue }) => {
    try {
      await ChaptersApiInstance.saveChapterInS3(uploadUrl, text);
      await SaveCacheApiInstance.delete(projectId, chapterId);
      return { chapterId, projectId };
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

export const saveChapters = createAsyncThunk<
  {},
  {
    chapterId: string;
    projectId: string;
    text: string;
    uploadUrl: ProjectModel['uploadUrl'];
  }
>(
  'chapters/saveChapters',
  async (
    { chapterId, projectId, text, uploadUrl },
    { rejectWithValue, dispatch }
  ) => {
    try {
      // get from cache the chapters
      const projectCaches = await SaveCacheApiInstance.getAll(projectId);
      const savePromises: Promise<any>[] = [];
      let chapterIds: string[] = [];

      // if there exists cache in DB
      if (projectCaches.length) {
        chapterIds = projectCaches.map((item) => item.chapterId);

        dispatch(setPendingChapters(chapterIds));

        // get there preassigned Url
        const preAssignedUrls = await ChaptersApiInstance.getAllPreAssignedUrls(
          projectId,
          chapterIds
        );

        // this should be mapped not in an array to keep the chapter Ids
        const promiseStack: Promise<string>[] = [];
        const ids: string[] = [];

        // get all chapter Texts
        for (const preAssignedUrlsKey in preAssignedUrls) {
          if (preAssignedUrlsKey !== chapterId) {
            const p = ChaptersApiInstance.getChapterText(
              preAssignedUrls[preAssignedUrlsKey].getUrl
            );
            promiseStack.push(p);
            ids.push(preAssignedUrlsKey);
          }
        }

        const results: string[] = await Promise.all(promiseStack);

        results.forEach((item, index) => {
          let chapterId = ids[index];

          const history = projectCaches.find(
            (item) => item.chapterId === chapterId
          )?.value;

          if (history) {
            const text = QuillUtil.getQuillValueOptimizedWithHistory(
              item,
              JSON.parse(history)
            );

            const p = ChaptersApiInstance.saveChapterInS3(
              preAssignedUrls[chapterId].postUrl,
              text
            );

            savePromises.push(p);
          }
        });
      }

      // current Chapter
      // cause the user may save or save all before the saving loop start -> this way we keep the changes :)
      const p = ChaptersApiInstance.saveChapterInS3(uploadUrl, text);

      savePromises.push(p);

      // after this we can delete all the project Dynamo caches
      await Promise.all(savePromises);

      // delete all the caches
      return SaveCacheApiInstance.deleteMany(projectId, chapterIds);
    } catch (err) {
      return rejectWithValue(rejectRequest(err as AxiosError));
    }
  }
);

const chapterSlice = createSlice({
  name: 'chapters',
  initialState,
  reducers: {
    resetChapters: () => initialState,
    setChapterInactive(state) {
      // details
      state.currentChapter = undefined;
      state.ids = [];
      state.entities = {};
      state.details = undefined;

      // loading
      state.dirtyChapters = {};
      state.pendingSaveChapters = {};
      state.allChaptersSaving = false;
    },
    setChapterDirty(state, action: PayloadAction<string>) {
      state.dirtyChapters[action.payload] = true;
    },
    setChapterWithSavedChanges(state, action: PayloadAction<string>) {
      state.chaptersWithSavedChanges[action.payload] = true;
    },
    setChapterDirtyBatch(state, action: PayloadAction<Dict<boolean>>) {
      state.dirtyChapters = action.payload;
    },
    unsetChapterDirty(state, action: PayloadAction<string>) {
      delete state.dirtyChapters[action.payload];
    },
    setPendingChapters(state, action: PayloadAction<string[]>) {
      state.pendingSaveChapters = action.payload.reduce((acc, curr) => {
        return {
          [curr]: true,
          ...acc,
        };
      }, {});
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChapters.fulfilled, (state, { payload }) => {
        ChapterAdapter.setAll(state, payload);
      })
      .addCase(setActiveChapter.fulfilled, (state, { payload }) => {
        state.details = payload.details;
        state.currentChapter = {
          id: payload.id,
          task_arn: state?.entities?.[payload.id]?.['task_arn'],
          name: payload.name,
          next: payload.next,
          prev: payload.prev,
        };
      })
      .addCase(saveChapter.pending, (state, payload) => {
        state.pendingSaveChapters[payload.meta.arg.chapterId] = true;
      })
      .addCase(saveChapter.fulfilled, (state, { payload }) => {
        delete state.pendingSaveChapters[payload.chapterId];
        delete state.dirtyChapters[payload.chapterId];
        success({ children: 'Chapter is Saved Successfully' });
      })
      .addCase(saveChapter.rejected, (state, payload) => {
        delete state.pendingSaveChapters[payload.meta.arg.chapterId];
        SomethingWentWrong();
      })
      .addCase(saveChapters.pending, (state) => {
        state.allChaptersSaving = true;
      })
      .addCase(saveChapters.fulfilled, (state) => {
        state.allChaptersSaving = false;
        state.pendingSaveChapters = {};
        state.dirtyChapters = {};
        success({ children: 'Chapters are Saved Successfully' });
      })
      .addCase(saveChapters.rejected, () => {
        SomethingWentWrong();
      });
  },
});

export const {
  setChapterInactive,
  setChapterDirty,
  setChapterDirtyBatch,
  setChapterWithSavedChanges,
  unsetChapterDirty,
  setPendingChapters,
  resetChapters,
} = chapterSlice.actions;

export default chapterSlice.reducer;
