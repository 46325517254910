import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnFiltersState } from '@tanstack/react-table';
import {
  useGetSuperAdminProjectsQuery,
  useGetVoicesQuery,
} from 'redux/api/app.api';
import { Icon } from 'icons';
import useSorting from 'modules/common/hooks/useSorting';
import Table from 'modules/common/components/Table/Table';
import { Button } from 'modules/common/components/Button/Button';
import { SORT } from 'modules/common/types';
import { getFiltersToSend } from 'modules/common/lib';
import { UnassignedProjects } from './unassigned-projects/UnassignedProjects';
import { getAdminProjectsTableColumnsConfig } from './ProjectsTableConfig';

const ProjectsTabAdmin = () => {
  const [page, setPage] = React.useState(1);
  const { sortBy, sortType, changeSortBy } = useSorting(
    SORT.ASC,
    'lastUpdated'
  );
  const [filters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [viewAllProjects, setViewAllProjects] = React.useState(false);

  const filtersToSend = React.useMemo(
    () => getFiltersToSend(filters),
    [filters]
  );

  const { data, isFetching } = useGetSuperAdminProjectsQuery({
    index: page * 10,
    sortBy,
    sortType,
    ...filtersToSend,
  });
  const { data: voicesData } = useGetVoicesQuery();

  const columns = React.useMemo(() => {
    return getAdminProjectsTableColumnsConfig({
      sortBy,
      sortType,
      changeSortBy,
      voicesWithPreprocessing: voicesData?.voicesWithPreprocessing,
    });
  }, [sortBy, sortType, changeSortBy, voicesData?.voicesWithPreprocessing]);
  return (
    <>
      <UnassignedProjects
        viewAllUnassignedProjects={viewAllProjects}
        setViewAllUnassignedProjects={setViewAllProjects}
      />
      <div className="t-align-right m-b-1">
        <Button fill="subtle">
          <Link to={'/projects/create'}>
            <Icon name="add" className="m-r-1" />
            Create Project
          </Link>
        </Button>
      </div>
      {!viewAllProjects && (
        <Table
          data={data && data.data ? data.data : []}
          columns={columns}
          isLoading={isFetching}
          getPaginationRowModel={undefined}
          totalDataCount={data ? data.totalCount : 0}
          page={setPage}
          onColumnFiltersChange={setColumnFilters}
          paginated
        />
      )}
    </>
  );
};

export default ProjectsTabAdmin;
