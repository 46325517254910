import React, { FC } from 'react';
import { components } from 'react-select';
import { ValueContainerProps } from 'react-select/dist/declarations/src/components/containers';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import { Select, SelectProps } from 'modules/common/components/Select/Select';
import { ParsedVoiceValue } from 'modules/common/types';
import { Icon } from 'icons';
import LabelContainer, { ILabelContainer } from './LabelContainer';
import Styles from './label.module.scss';

export interface ILabelSelect extends ILabelContainer, SelectProps {
  labelText: string;
}

const ValueContainer = (props: ValueContainerProps) => {
  const selectedValue = props.selectProps.value as ParsedVoiceValue;
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        <div className={Styles['SelectInput']}>
          {selectedValue?.pre_process && (
            <Icon
              className={Styles['preprocessing-icon']}
              name="preprocessing"
              style={{ color: '#FF5C35' }}
            />
          )}
          {props.children}
        </div>
      </components.ValueContainer>
    )
  );
};

const Option = (props: OptionProps) => {
  const option = props.data as ParsedVoiceValue;
  return (
    components.Option && (
      <components.Option {...props}>
        <div className={Styles['SelectInput']}>
          {props.children}
          {option?.pre_process && (
            <Icon
              className={Styles['preprocessing-icon']}
              name="preprocessing"
              style={{ color: '#FF5C35' }}
            />
          )}
        </div>
      </components.Option>
    )
  );
};

const LabelSelect: FC<ILabelSelect> = ({ labelText, className, ...rest }) => {
  return (
    <LabelContainer labelText={labelText} className={className}>
      <Select components={{ ValueContainer, Option }} {...rest} />
    </LabelContainer>
  );
};

export default LabelSelect;
