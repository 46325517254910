import { useEffect, useMemo, useState } from 'react';

export function useScreenSize() {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = useMemo(() => {
    return windowSize.width! < 768;
  }, [windowSize.width]);

  const isTabletOrMobile = useMemo(() => {
    return windowSize.width! <= 1024;
  }, [windowSize.width]);

  return {
    screenSize: windowSize,
    isMobile,
    isTabletOrMobile,
  };
}
