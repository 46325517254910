import { RootState } from 'redux/store';
import { getFetchingSelector } from 'redux/reducers/loader/selectors';
import { createSelector } from '@reduxjs/toolkit';
import { fetchTags, TagsAdapter } from 'redux/reducers/tags/tagsSlice';
import { AsyncRequestStatus } from 'redux/interfaces/loader';
import { TagsApplyAdapter } from './tagsApplySlice';

export const { selectAll, selectEntities } =
  TagsAdapter.getSelectors<RootState>(({ tags }) => tags);

export const {
  selectAll: selectTagsApplyAll,
  selectEntities: selectTagApplyEntities,
} = TagsApplyAdapter.getSelectors<RootState>(({ tagsApply }) => tagsApply);

export const tagsIsFetchingSelector = createSelector(
  getFetchingSelector(fetchTags.typePrefix),
  (status) => status === AsyncRequestStatus.pending
);

export const isTagsFetchedSelector = createSelector(
  getFetchingSelector(fetchTags.typePrefix),
  (status) => status === AsyncRequestStatus.fulfilled
);

export const isTagsAvailable = createSelector(
  selectAll,
  (tags) => !!tags?.length
);
