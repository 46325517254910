import { useCallback, useRef, useState } from 'react';
import pLimit from 'p-limit';
import {
  useLazyGetCachedAudioFromUrlQuery,
  useLazyTextToTtsV2Query,
} from 'redux/api/app.api';
import { TextToTtsRequest, TextToTtsV2Request } from 'redux/api/types';
import { activeProjectSelectorId } from 'redux/reducers/project/selectors';
import { activeChapterIdSelector } from 'redux/reducers/chapters/selectors';
import { useAppSelector } from 'modules/common/hooks/redux';
import { AudioToPlayback, SentenceToProcess } from './types';
import { MAX_SENTENCE_AMOUNT_TO_BE_PROCESSED_IN_ONE_TIME } from './lib';

export const useDownloadAndPlaySentencesOneByOne = ({
  abortSignal,
}: {
  abortSignal: AbortSignal;
}) => {
  const projectId = useAppSelector(activeProjectSelectorId);
  const chapterId = useAppSelector(activeChapterIdSelector);
  const [textToTtsQueryTrigger] = useLazyTextToTtsV2Query();
  const [getAudioFromUrl] = useLazyGetCachedAudioFromUrlQuery();
  const cachedCurrentAudioToPlayRef = useRef<AudioToPlayback>();
  const cachedSettingsRef = useRef<TextToTtsRequest>();
  const postponedToDownloadAudiosRef = useRef<SentenceToProcess[]>();
  const [audiosToPlayQueue, setAudiosToPlayQueue] =
    useState<Promise<AudioToPlayback>[]>();

  const startProcessing = useCallback(
    async (props: {
      settings: TextToTtsRequest;
      requests: SentenceToProcess[];
    }) => {
      // The pLimit callback usage necessary to ensure the sequence order for requests within the Promise statement.
      const limit = pLimit(1);
      let { requests, settings } = props;
      // If the request contains more sentences than accepted, pass the acceptable
      // amount to the download queue and put the rest of them to the postponed cache.
      if (requests.length > MAX_SENTENCE_AMOUNT_TO_BE_PROCESSED_IN_ONE_TIME) {
        const sentenceToPostpone = requests.slice(
          MAX_SENTENCE_AMOUNT_TO_BE_PROCESSED_IN_ONE_TIME
        );
        requests = requests.slice(
          0,
          MAX_SENTENCE_AMOUNT_TO_BE_PROCESSED_IN_ONE_TIME
        );
        postponedToDownloadAudiosRef.current = sentenceToPostpone;
        // Otherwise, clean the postponed cache if exists.
      } else if (!!postponedToDownloadAudiosRef.current?.length) {
        postponedToDownloadAudiosRef.current = undefined;
      }
      if (!cachedSettingsRef.current) cachedSettingsRef.current = settings;
      for (let request of requests) {
        const textToTtsRequest: TextToTtsV2Request = {
          ...settings,
          text: request.ssmlText,
          project_id: projectId,
          chapter_id: chapterId || '',
        };
        const audioToPlay = limit(
          async () =>
            await new Promise<AudioToPlayback>(async (resolve, reject) => {
              try {
                const textToTTsRequest = textToTtsQueryTrigger(
                  textToTtsRequest,
                  true
                );
                abortSignal.onabort = () => textToTTsRequest.abort();
                const response = await textToTTsRequest.unwrap();
                // For the preload(!) purpose.
                const audioRequest = getAudioFromUrl(response.audio_url, true);

                const audio = audioRequest.unwrap();
                abortSignal.onabort = () => audioRequest.abort();
                resolve({
                  meta: { ...response, text: request.text, audio_url: audio },
                  range: request.range,
                });
              } catch (e: any) {
                if (e.message === 'Aborted') return;
                reject(e);
              }
            })
        );
        setAudiosToPlayQueue((prev) => [...(prev || []), audioToPlay]);
      }
    },
    [abortSignal, chapterId, getAudioFromUrl, projectId, textToTtsQueryTrigger]
  );

  // Don't remove the useCallback memoization as it prevents from excessive renders.
  const reset = useCallback(() => {
    setAudiosToPlayQueue(undefined);
    cachedCurrentAudioToPlayRef.current = undefined;
    cachedSettingsRef.current = undefined;
    postponedToDownloadAudiosRef.current = undefined;
  }, []);

  const modifyAudiosToPlayQueue = async () => {
    if (!audiosToPlayQueue?.length) return;

    cachedCurrentAudioToPlayRef.current = await audiosToPlayQueue[0];
    // If there are still postponed audios to download and add them to the queue,
    // and two(!) more audios in the queue left to play,
    // download the next ten (or what is the maximum equal to at the moment)
    if (
      cachedSettingsRef.current &&
      postponedToDownloadAudiosRef.current &&
      audiosToPlayQueue?.length === 2
    ) {
      startProcessing({
        settings: cachedSettingsRef.current,
        requests: postponedToDownloadAudiosRef.current,
      });
    }
    setAudiosToPlayQueue((prev) => prev?.slice(1));
  };

  const setCachedCurrentAudioToPlay = (meta: AudioToPlayback) =>
    (cachedCurrentAudioToPlayRef.current = meta);

  return {
    audiosToPlayQueue,
    cachedCurrentAudioToPlay: cachedCurrentAudioToPlayRef.current,
    setAudiosToPlayQueue,
    setCachedCurrentAudioToPlay,
    startProcessing,
    modifyAudiosToPlayQueue,
    reset,
  };
};
