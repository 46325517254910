import { createSlice } from '@reduxjs/toolkit';
import { AsyncRequestStatus, LoaderSliceState } from 'redux/interfaces/loader';
import {
  UnknownAsyncThunkFulfilledAction,
  UnknownAsyncThunkPendingAction,
  UnknownAsyncThunkRejectedAction,
} from '@reduxjs/toolkit/dist/matchers';

const initialState: LoaderSliceState = {};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    resetLoaderSliceState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action): action is UnknownAsyncThunkPendingAction =>
          action.type.endsWith(`/${AsyncRequestStatus.pending}`),
        (state, { type }) => {
          state[type.replace(`/${AsyncRequestStatus.pending}`, '')] =
            AsyncRequestStatus.pending;
        }
      )
      .addMatcher(
        (action): action is UnknownAsyncThunkFulfilledAction =>
          action.type.endsWith(`/${AsyncRequestStatus.fulfilled}`),
        (state, { type }) => {
          state[type.replace(`/${AsyncRequestStatus.fulfilled}`, '')] =
            AsyncRequestStatus.fulfilled;
        }
      )
      .addMatcher(
        (action): action is UnknownAsyncThunkRejectedAction =>
          action.type.endsWith(`/${AsyncRequestStatus.rejected}`),
        (state, { type }) => {
          state[type.replace(`/${AsyncRequestStatus.rejected}`, '')] =
            AsyncRequestStatus.rejected;
        }
      );
  },
});
export const { resetLoaderSliceState } = loaderSlice.actions;

export default loaderSlice.reducer;
