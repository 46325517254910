import { FC } from 'react';
import SortableCell, {
  SortableCellProps,
} from 'modules/common/components/Table/TableCells/SortableCell/SortableCell';
import DebouncedInput from 'modules/common/components/DebouncedInput/DebouncedInput';

interface Props extends SortableCellProps {
  onChangeValue: (value: string | undefined) => void;
  value?: string;
}

const SortableInputCell: FC<Props> = ({
  accessorName,
  onSortChange,
  sort,
  text,
  onChangeValue,
  value,
  width,
}) => {
  return (
    <SortableCell
      accessorName={accessorName}
      onSortChange={onSortChange}
      text={text}
      sort={sort}
      width={width}
    >
      <DebouncedInput
        name={accessorName}
        placeholder="Search"
        onChangeWithValueHandler={onChangeValue}
        initialValue={value}
      />
    </SortableCell>
  );
};

export default SortableInputCell;
