import React, { useState } from 'react';

import { Tab } from 'modules/common/components/Tab/Tab';
import { TabGroup } from 'modules/common/components/Tab/TabGroup';
import { TOCChapters } from 'modules/edit/TOCChapters';
import { Search } from 'modules/edit/Search';
import Styles from './sidebar.module.scss';
import classNames from 'classnames';

type Tabs = 'chapters' | 'search';

export const IncompleteProjectSidebar = () => {
  const [activeTab, setActiveTab] = useState<Tabs>('chapters');
  const [isContentLoading, setIsContentLoading] = React.useState<boolean>(true);

  return (
    <>
      <div className={Styles['sidebar-header']}>
        <TabGroup underline className="p-l-3">
          <Tab
            disabled={isContentLoading}
            active={activeTab === 'chapters'}
            onClick={() => setActiveTab('chapters')}
          >
            Chapters
          </Tab>
          <Tab
            disabled={isContentLoading}
            active={activeTab === 'search'}
            onClick={() => setActiveTab('search')}
          >
            Search
          </Tab>
        </TabGroup>
      </div>

      <div
        className={classNames(Styles['sidebar-content'], Styles['toc-sidebar'])}
      >
        {activeTab === 'chapters' && (
          <TOCChapters
            isContentLoading={isContentLoading}
            setIsContentLoading={setIsContentLoading}
          />
        )}
        {activeTab === 'search' && <Search />}
      </div>
    </>
  );
};
