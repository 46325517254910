import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Keys, putClickOnTagsV2 } from 'utils';
import { getModulesConfig } from 'modules/edit/editor.config';
import { useKeyPressListener } from 'modules/common/hooks/useKeyPressListener';
import { useAppSelector } from 'modules/common/hooks/redux';
import { selectAll } from 'redux/reducers/tags/selectors';
import { selectEntities } from 'redux/reducers/presets/selectors';
import {
  commentsFetchedSelector,
  selectEntities as commentsSelectEntities,
} from 'redux/reducers/comments/selectors';
import {
  bookmarksFetchedSelector,
  selectEntities as bookmarksSelectEntities,
} from 'redux/reducers/bookmarks/selectors';
import {
  FoundTextBlot,
  FoundTextCurrentBlot,
} from 'modules/edit/v2/Editor/Blots/FoundTextBlot';
import {
  BookmarkIconBlot,
  BookmarkIconBlotValue,
} from 'modules/edit/v2/Editor/Blots/BookmarkIconBlot';
import { PresetBlot } from 'modules/edit/v2/Editor/Blots/PresetBlot';
import QuillContext from 'modules/common/context/QuillContext';
import { SkipBlot } from 'modules/edit/v2/Editor/Blots/SkipBlot';
import {
  activeChapterIdSelector,
  chapterDetailSelector,
  isCurrentChapterPendingSelector,
} from 'redux/reducers/chapters/selectors';
import Loader from 'modules/common/components/Loader/Loader';
import { Stack } from 'modules/common/components/Stack/Stack';
import { InsertBlot } from 'modules/edit/Editor/Blots/InsertBlot';
import { EditorSave } from 'utils/EditorSave';
import {
  activeProjectSelectorId,
  getProjectTOC,
  isProjectStatusIncomplete,
  useGetCurrentProjectTOCPage,
} from 'redux/reducers/project/selectors';
import QuillUtil from 'utils/QuillUtil';
import { presetsFetchedSelector } from 'redux/reducers/presets/selectors';
import { PresetsModel } from 'modules/common/models/Presets';
import { useMoveBetweenTagsHotKeyListener } from 'modules/common/hooks/useMoveBetweenTagsHotKeyListener';
import Styles from 'modules/edit/Editor/editor.module.scss';
import {
  CommentBlot,
  CommentBlotValue,
} from 'modules/edit/v2/Editor/Blots/CommentBlot';
import { CommentsTooltip } from 'modules/edit/v2/CommentsTooltip/CommentsTooltip';
import {
  useSetTOCText,
  useSetTextAndHistory,
} from 'modules/edit/Editor/module';
import { SentenceBlot } from 'modules/edit/v2/Editor/Blots/SentenceBlot';
import {
  FoundTextTOCBlot,
  FoundTextTOCCurrentBlot,
} from 'modules/edit/Editor/Blots/FoundTOCTextBlot';
import { PageBlot } from '../../Editor/Blots/PageBlot';
import { ChapterIconBlot } from './Blots/ChapterIconBlot';

interface EditorProps {
  isSSML?: boolean;
}

export const Editor: FC<EditorProps> = ({ isSSML }) => {
  const { quillInstance, setQuillInstance } = useContext(QuillContext);
  const modules = useMemo(getModulesConfig, []);
  const tags = useAppSelector(selectAll);
  const presetEntities = useAppSelector(selectEntities);
  const bookmarksEntities = useAppSelector(bookmarksSelectEntities);
  const commentsEntities = useAppSelector(commentsSelectEntities);
  const presetsFetched = useAppSelector(presetsFetchedSelector);
  const bookmarksFetched = useAppSelector(bookmarksFetchedSelector);
  const commentsFetched = useAppSelector(commentsFetchedSelector);
  const currentChapterDetail = useAppSelector(chapterDetailSelector);
  const currentChapterId = useAppSelector(activeChapterIdSelector);
  const editorSaveInstance = EditorSave.getInstance();
  const setTextAndHistory = useSetTextAndHistory();
  const isTextStartedLoadingRef = useRef(false);
  const setTOCText = useSetTOCText();
  const [loading, setLoading] = useState<boolean>(true);
  const projectId = useAppSelector(activeProjectSelectorId);
  const isIncomplete = useAppSelector(isProjectStatusIncomplete);
  const projectTOC = useAppSelector(getProjectTOC);
  useMoveBetweenTagsHotKeyListener({ quillInstance });
  const isCurrentChapterPending = useAppSelector(
    isCurrentChapterPendingSelector
  );
  const [tooltipComment, setTooltipComment] = useState<{
    commentId: string;
    blot: CommentBlot;
  }>();

  const currentProjectTOCPage = useGetCurrentProjectTOCPage();

  const formats = useMemo(() => {
    const formats = [
      ChapterIconBlot.blotName,
      BookmarkIconBlot.blotName,
      FoundTextTOCBlot.blotName,
      FoundTextTOCCurrentBlot.blotName,
      FoundTextBlot.blotName,
      FoundTextCurrentBlot.blotName,
      // SelectionBlot.blotName,
      PresetBlot.blotName,
      SkipBlot.blotName,
      SentenceBlot.blotName,
      PageBlot.blotName,
      InsertBlot.blotName,
      CommentBlot.blotName,
      'bold',
      'header',
    ];
    tags.forEach((tag) => {
      formats.push(tag.id);
    });
    return formats;
  }, [tags]);

  const handleESC = useCallback(() => {
    const editor = quillInstance?.editor;
    if (editor) {
      editor.setSelection(0, 0);
    }
  }, [quillInstance]);

  useKeyPressListener({
    code: Keys.Esc,
    handler: handleESC,
  });

  useEffect(() => {
    const editor = quillInstance?.editor;
    if (!editor || !projectId || isTextStartedLoadingRef.current) return;
    setLoading(true);

    const setDefaultSelectionAfterLoading = () => {
      // set default selection and focus on editor after loading
      editor.setSelection(0, 0);
      editor.focus();
      setLoading(false);
      isTextStartedLoadingRef.current = false;
    };

    if (isIncomplete) {
      isTextStartedLoadingRef.current = true;
      (async function () {
        await setTOCText({
          projectId,
          editor,
          setSavingTOCCompleted: setDefaultSelectionAfterLoading,
        });
      })();
    } else if (currentChapterDetail?.getUrl && currentChapterId) {
      isTextStartedLoadingRef.current = true;
      (async function () {
        await setTextAndHistory({
          projectId,
          currentChapterId,
          editor,
          getUrl: currentChapterDetail.getUrl,
        });
        setDefaultSelectionAfterLoading();
      })();
    }

    return () => {
      (function () {
        editorSaveInstance.deleteEditorSaveStates();
      })();
    };
  }, [
    currentChapterDetail?.getUrl,
    currentChapterId,
    editorSaveInstance,
    isIncomplete,
    projectId,
    projectTOC,
    quillInstance?.editor,
    setTOCText,
    setTextAndHistory,
  ]);

  useEffect(() => {
    const editor = quillInstance?.editor;
    if (!editor || !currentProjectTOCPage) return;

    const pageDelta = editor.clipboard.convert(currentProjectTOCPage.innerHTML);
    editor.setContents(pageDelta, 'api');
  }, [currentProjectTOCPage, quillInstance?.editor]);

  useEffect(() => {
    const editor = quillInstance?.editor;
    if (
      editor &&
      !loading &&
      presetsFetched &&
      bookmarksFetched &&
      commentsFetched
    ) {
      const presetBlots = QuillUtil.getPresetChildrenV2(editor, {
        index: 0,
        length: editor.getLength(),
      });
      presetBlots.forEach((presetBlot) => {
        const formatParsed = JSON.parse(
          presetBlot.statics.formats(presetBlot.domNode)
        ) as PresetsModel;
        const range = {
          // we need the offset from the start of the document, or else!
          index: presetBlot.offset(presetBlot.scroll),
          length: presetBlot.length(),
        };
        if (!presetEntities.hasOwnProperty(formatParsed.id)) {
          editor.formatText(range, PresetBlot.blotName, false, 'silent');
        } else {
          const preset = presetEntities[formatParsed.id]!;
          editor.formatText(
            range,
            PresetBlot.blotName,
            JSON.stringify({
              ...preset,
              tags: preset.tags,
              name: preset.name,
            }),
            'silent'
          );
        }
      });

      const bookmarkFormats = QuillUtil.getBookmarkChildrenV2(editor, {
        index: 0,
        length: editor.getLength(),
      });
      bookmarkFormats.forEach((bookmarkFormat) => {
        const formatParsed = JSON.parse(
          bookmarkFormat.statics.formats(bookmarkFormat.domNode)
        ) as BookmarkIconBlotValue;
        const range = {
          index: bookmarkFormat.offset(),
          length: bookmarkFormat.length(),
        };

        if (!bookmarksEntities.hasOwnProperty(formatParsed.bookmarkId)) {
          editor.formatText(range, BookmarkIconBlot.blotName, false, 'silent');
        }
      });

      // That one is not needed for now.
      // const chapterFormats = QuillUtil.getChapterChildren(editor, {
      //   index: 0,
      //   length: editor.getLength(),
      // });
      // chapterFormats.forEach((chapterFormat) => {
      //   const range = {
      //     index: chapterFormat.offset(),
      //     length: chapterFormat.length(),
      //   };
      //   editor.formatText(range, ChapterIconBlot.blotName, false, 'silent');
      // });

      const commentFormats = QuillUtil.getCommentChildrenV2(editor, {
        index: 0,
        length: editor.getLength(),
      });
      commentFormats.forEach((commentFormat) => {
        const formatParsed = JSON.parse(
          commentFormat.statics.formats(commentFormat.domNode)
        ) as CommentBlotValue;
        const range = {
          index: commentFormat.offset(),
          length: commentFormat.length(),
        };

        if (!commentsEntities.hasOwnProperty(formatParsed.id)) {
          editor.formatText(range, CommentBlot.blotName, false, 'silent');
        }
      });
      putClickOnTagsV2(editor);
    }
  }, [
    bookmarksEntities,
    bookmarksFetched,
    commentsEntities,
    commentsFetched,
    loading,
    presetEntities,
    presetsFetched,
    quillInstance?.editor,
  ]);

  useEffect(() => {
    const editor = quillInstance?.editor;
    if (editor) {
      const editorChangeHandler = () => {
        document.querySelectorAll(CommentBlot.tagName).forEach((node) => {
          const blot = Quill.find(node);
          if (blot) {
            (node as HTMLElement).addEventListener('click', () => {
              if (commentsEntities[node.id]) {
                setTooltipComment({ commentId: node.id, blot });
              }
            });
          }
        });
      };
      editor.on('text-change', editorChangeHandler);
      return () => {
        editor.off('text-change', editorChangeHandler);
      };
    }
  }, [commentsEntities, quillInstance]);

  return (
    <div className={Styles['editor-wrapper']} spellCheck={false}>
      {loading && (
        <Stack className="h-100" justifyContent="center" alignItems="center">
          <Loader
            loadingPartColor="#031E50"
            loadingBackSet="#ffffff"
            size="50px"
            loaderWidth="5px"
          />
        </Stack>
      )}
      <ReactQuill
        ref={(newRef) => setQuillInstance(newRef)}
        formats={formats}
        modules={modules}
        // The scrollingContainer property equal to html prevents auto-scroll on undo-redo action.
        scrollingContainer="html"
        theme="snow"
        className={isSSML ? 'ssml-editor' : ''}
        readOnly={isCurrentChapterPending}
      />
      {tooltipComment && (
        <CommentsTooltip
          tooltipComment={tooltipComment}
          onExit={() => setTooltipComment(undefined)}
        />
      )}
    </div>
  );
};
