import React from 'react';
import { Icon } from 'icons';
import { TOCChapter } from 'modules/common/types';
import { ChaptersListItem } from './ChaptersListItem';
import Styles from './tocChapters.module.scss';

type Props = {
  chapters: TOCChapter[] | undefined;
  onDelete: (tocChapter: TOCChapter) => void;
  onSaveChangedValue: (value: string, tocChapter: TOCChapter) => void;
};

export const ChaptersGroup: React.FC<Props> = ({
  chapters,
  onDelete,
  onSaveChangedValue,
}) => {
  const [isUnwrapList, setIsUnwrapList] = React.useState(false);

  if (!chapters?.length) return null;

  const parentChapter = chapters[0];
  const subChapters = chapters.slice(1);

  return (
    <div className={Styles['toc-chapter-group']}>
      <div className={Styles['toc-chapter-group-parent-element']}>
        {subChapters.length ? (
          <Icon
            name={isUnwrapList ? 'arrow_drop_up' : 'arrow_drop_down'}
            className={Styles['arrow-icon']}
            onClick={() => setIsUnwrapList((prev) => !prev)}
          />
        ) : null}
        <ChaptersListItem
          key={parentChapter.id}
          tocChapter={parentChapter}
          onDelete={onDelete}
          onSaveChangedValue={onSaveChangedValue}
        />
      </div>
      <div>
        {isUnwrapList &&
          subChapters.map((TOCChapter, index) => (
            <ChaptersListItem
              key={TOCChapter.id}
              tocChapter={TOCChapter}
              onDelete={onDelete}
              onSaveChangedValue={onSaveChangedValue}
            />
          ))}
      </div>
    </div>
  );
};
