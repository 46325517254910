import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
} from 'react';
import classnames from 'classnames';

import Styles from './input.module.scss';

interface InputPropsStrict {
  label?: string;
  placeholder?: string;
  onChangeWithValueHandler?: (value: string) => void;
  onInput?: (value: string) => void;
  onBlur?: () => void;
  onChangeWithEventHandler?: (e: ChangeEvent<HTMLInputElement>) => void;
  large?: boolean;
  small?: boolean;
  xs?: boolean;
  value?: any;
  className?: string;
}

export interface InputProps extends InputPropsStrict {
  [propName: string]: any;
}

export const Input: ForwardRefExoticComponent<InputProps> = forwardRef(
  (
    {
      type = 'text',
      value,
      register,
      className,
      label,
      large,
      small,
      xs,
      onChangeWithEventHandler,
      onChangeWithValueHandler,
      onInput,
      onBlur,
      name,
      placeholder,
      ...props
    },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const handleOnInput = (e: ChangeEvent<HTMLInputElement>) => {
      onInput?.(e.target.value);
    };

    let registerProp = null;
    if (register && name) {
      registerProp = register(name);
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      switch (true) {
        case !!onChangeWithEventHandler:
          return onChangeWithEventHandler?.(e);
        case !!onChangeWithValueHandler:
          return onChangeWithValueHandler?.(e.target.value);
        default:
          return null;
      }
    };

    return (
      <div
        className={classnames(Styles.input, className, {
          [Styles['large']]: large,
          [Styles['small']]: small,
          [Styles['xs']]: xs,
        })}
      >
        <input
          placeholder={placeholder}
          ref={ref}
          {...registerProp}
          value={value}
          type={type}
          onChange={onChange}
          onInput={handleOnInput}
          name={name === 'alias' ? undefined : name} // fix for [DN-21]
          onBlur={onBlur}
          {...props}
        />
      </div>
    );
  }
);
