import { FC, useState } from 'react';
import ProjectsTab from './ProjectsTab';
import { useIsAdminRole } from 'modules/common/hooks/useIsAdminRole';
import { Page } from 'modules/common/components/Page/Page';
import HeaderWithLogo from 'modules/common/components/HeaderWithLogo/HeaderWithLogo';
import Actions from './Actions';
import EditorsTab from './EditorsTab';

export enum SuperAdminTabs {
  Projects,
  Editors,
}

const Dashboard: FC = () => {
  const [tab, setTab] = useState<SuperAdminTabs>(SuperAdminTabs.Projects);
  const isAdminRole = useIsAdminRole();

  return (
    <Page
      header={<HeaderWithLogo />}
      actions={<Actions tabView={tab} onTabChange={setTab} />}
    >
      <div className="container fixed">
        {tab === SuperAdminTabs.Projects && <ProjectsTab />}
        {tab === SuperAdminTabs.Editors && isAdminRole && <EditorsTab />}
      </div>
    </Page>
  );
};

export default Dashboard;
