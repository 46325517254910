import { FC } from 'react';
import { flexRender, HeaderGroup } from '@tanstack/react-table';
import Styles from './table.module.scss';

interface Props {
  data: HeaderGroup<any>[];
}

const TableHeader: FC<Props> = ({ data }) => {
  return (
    <thead className={Styles['thead']}>
      {data.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th key={header.id}>
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeader;
