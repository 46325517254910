import React, { FC } from 'react';
import { Input, InputProps } from 'modules/common/components/Input/Input';
import LabelContainer, { ILabelContainer } from './LabelContainer';

interface ILabelInput extends ILabelContainer, InputProps {}

const LabelInput: FC<ILabelInput> = ({ labelText, className, ...rest }) => {
  return (
    <LabelContainer labelText={labelText} className={className}>
      <Input {...rest} />
    </LabelContainer>
  );
};

export default LabelInput;
