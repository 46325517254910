import React, { useCallback } from 'react';
import { Range } from 'react-quill';
import { Input } from 'modules/common/components/Input/Input';
import { Button } from 'modules/common/components/Button/Button';
import { Checkbox } from 'modules/common/components/Checkbox/Checkbox';
import useQuill from 'modules/common/hooks/useQuill';
import { useAddChapterTag } from '../module';
import { AddChapterTooltip } from './AddChapterTooltip';
import Styles from '../chapters.module.scss';

export const Actions = () => {
  const quillInstance = useQuill();
  const editor = quillInstance?.getEditor();
  const addChapterTag = useAddChapterTag({ editor });
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [isAddChapterFlowStarted, setIsAddChapterFlowStarted] =
    React.useState(false);
  const [cachedRangeToAddANewChapter, setCachedRangeToAddANewChapter] =
    React.useState<Range>();
  const [isSubChapterShouldBeCreated, setIsSubChapterShouldBeCreated] =
    React.useState(false);
  const formRef = React.useRef<HTMLFormElement>(null);
  const isMouseOverForm = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (editor) {
      const selectionChangeHandler = (range: Range) => {
        //
        if (
          document.activeElement === formRef.current?.['chapterName'] ||
          isMouseOverForm.current
        ) {
          return;
        }

        if (range && !range.length) {
          setCachedRangeToAddANewChapter(range);
        } else if (!!cachedRangeToAddANewChapter) {
          setCachedRangeToAddANewChapter(undefined);
          setIsAddChapterFlowStarted(false);
          setIsSubChapterShouldBeCreated(false);
        }
      };

      editor.on('selection-change', selectionChangeHandler);
      return () => {
        editor.off('selection-change', selectionChangeHandler);
      };
    }
  }, [cachedRangeToAddANewChapter, editor]);

  const addChapterHandler = useCallback(() => {
    const chapterName = formRef.current?.['chapterName'].value;
    addChapterTag({
      cachedRange: cachedRangeToAddANewChapter,
      newChapterName: chapterName || '',
      isSubChapterShouldBeCreated,
    });
    formRef.current?.reset();
  }, [addChapterTag, cachedRangeToAddANewChapter, isSubChapterShouldBeCreated]);

  const mouseEnterHandler = () => {
    isMouseOverForm.current = true;
    if (!cachedRangeToAddANewChapter) {
      setShowTooltip(true);
    }
  };

  const mouseLeaveHandler = () => {
    isMouseOverForm.current = false;
    if (!cachedRangeToAddANewChapter) {
      setShowTooltip(false);
    }
  };

  const handleInputKeyDown = useCallback((e: KeyboardEvent) => {
    if(e.key === 'Enter') {
      e.preventDefault();
      addChapterHandler();
    }
  }, [addChapterHandler]);

  return (
    <div
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      className={Styles['add-chapter-button-wrapper']}
    >
      <form ref={formRef}>
        {isAddChapterFlowStarted && (
          <div>
            <Input name={'chapterName'} onKeyDown={handleInputKeyDown}/>
            <div
              className={Styles['make-sub-chapter-checkbox']}
              onClick={() => setIsSubChapterShouldBeCreated((prev) => !prev)}
            >
              <Checkbox
                onChange={() => setIsSubChapterShouldBeCreated((prev) => !prev)}
                checked={isSubChapterShouldBeCreated}
              />
              <div>Make a sub-chapter</div>
            </div>
          </div>
        )}
      </form>
      {!isAddChapterFlowStarted ? (
        <Button
          className={Styles['add-chapter-button']}
          disabled={!cachedRangeToAddANewChapter}
          onClick={() => setIsAddChapterFlowStarted(true)}
        >
          Add chapter / sub-chapter
        </Button>
      ) : (
        <Button
          className={Styles['add-chapter-button']}
          disabled={!cachedRangeToAddANewChapter}
          onClick={addChapterHandler}
        >
          Add {isSubChapterShouldBeCreated ? 'sub-chapter' : 'chapter'}
        </Button>
      )}
      <AddChapterTooltip isShow={showTooltip && !cachedRangeToAddANewChapter} />
    </div>
  );
};
