import React, { FC, useEffect } from 'react';
import Notification, {
  NotificationType,
  Props as NotificationProps,
} from 'modules/common/components/Notify/Notification/Notification';
import { v4 as uuid } from 'uuid';
import { AnalyticsEvent, logAmplitudeEvent } from 'services/amplitude';

export interface PropsWithChildren extends Omit<NotificationProps, 'onDelete'> {
  children: React.ReactNode;
  id: string;
}

export interface setNotifyType {
  color: NotificationType;
  autoClose?: boolean;
  children: React.ReactNode;
  timeToDelete?: number;
  timeToClose?: number;
}

interface Props {
  setNotify: (func: (obj: setNotifyType) => void) => void;
}

const NotificationsManager: FC<Props> = ({ setNotify }) => {
  const [notifications, setNotifications] = React.useState<PropsWithChildren[]>(
    []
  );

  const createNotification = (obj: setNotifyType) => {
    if (obj.color === 'error') {
      logAmplitudeEvent({
        eventName: AnalyticsEvent.ErrorMessage,
        properties: {
          error: obj.children,
        },
      });
    }
    setNotifications((prevNotifications) => {
      return [
        ...prevNotifications,
        {
          id: uuid(),
          ...obj,
        },
      ];
    });
  };

  const deleteNotification = (id: string) => {
    const filteredNotifications = notifications.filter(
      (item) => item.id !== id,
      []
    );
    setNotifications(filteredNotifications);
  };

  useEffect(() => {
    setNotify((obj: setNotifyType) => createNotification(obj));
  }, [setNotify]);

  return (
    <>
      {notifications.map(({ id, ...props }) => (
        <Notification
          {...props}
          key={id}
          onDelete={() => deleteNotification(id)}
        />
      ))}
    </>
  );
};

export default NotificationsManager;
