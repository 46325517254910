import { useCallback, useEffect, useRef } from 'react';
import useQuill from '../useQuill';
import { Range } from 'react-quill';

const LINE_HEIGHT = 48;

interface UseKaraokeProps {
  wordsRef: React.MutableRefObject<
    | {
        words: string[];
        wordStartTimes: number[];
      }
    | undefined
  >;
  audioPlayer: React.MutableRefObject<HTMLAudioElement>;
}

export const useKaraoke = ({ wordsRef, audioPlayer }: UseKaraokeProps) => {
  const quillInstance = useQuill();
  const canvasRef = useRef<HTMLCanvasElement>();
  const firstLineTopRef = useRef(0);
  const playingWordIndexRef = useRef<number>();
  const playingLineRef = useRef<{
    line: number;
    wordIndex: number;
    rect?: [number, number, number, number];
  }>();

  // create canvas and add it to the editor
  useEffect(() => {
    const editor = quillInstance?.editor;
    if (!editor) return;

    const canvas = document.createElement('canvas');
    canvas.id = 'highlight-canvas';
    canvasRef.current = canvas;
    editor.addContainer(canvas);

    editor.root.addEventListener('scroll', () => {
      if (canvasRef.current) {
        canvasRef.current.style.marginTop = -1 * editor.root.scrollTop + 'px';
      }
    });
  }, [quillInstance?.editor]);

  // Set up the canvas, firstLineTop, and reset playingWordIndex
  const setupKaraoke = useCallback(
    (selection?: Range) => {
      const editor = quillInstance?.editor!;
      if (!selection) return;
      const { top: firstLineTop } = editor.getBounds(selection.index);
      const { top: lastLineTop } = editor.getBounds(
        selection.index + selection.length
      );
      firstLineTopRef.current = firstLineTop + editor.root.scrollTop;
      playingWordIndexRef.current = undefined;
      const linesCount = (lastLineTop - firstLineTop) / LINE_HEIGHT + 1;
      if (canvasRef.current) {
        canvasRef.current.style.top = `${
          firstLineTop - 12 + editor.root.scrollTop
        }px`;
        canvasRef.current.height = linesCount * LINE_HEIGHT;
        canvasRef.current.width =
          (editor.scroll.domNode as HTMLDivElement).clientWidth - 96;
        canvasRef.current.style.left = `${LINE_HEIGHT}px`;
      }
    },
    [quillInstance?.editor]
  );

  const drawKaraokeFrame = useCallback(
    (selection: Range | undefined) => {
      if (!canvasRef.current) return;

      const editor = quillInstance?.editor!;
      if (!selection) return;
      const { left } = editor.getBounds(selection.index);
      const ctx = canvasRef.current.getContext('2d');
      const { currentTime } = audioPlayer.current;

      const playingWordIndex = wordsRef.current?.wordStartTimes.findIndex(
        (item, index, arr) => {
          const nextItem = arr[index + 1] || Number.MAX_VALUE;
          return currentTime >= item && currentTime < nextItem;
        }
      )!;

      if (playingWordIndexRef.current !== playingWordIndex) {
        playingWordIndexRef.current = playingWordIndex;
        const length =
          wordsRef.current?.words.slice(0, playingWordIndex + 1).join(' ')
            ?.length || 0;

        const playingWord = wordsRef.current!.words[playingWordIndex];

        // sometimes playingWord is undefined
        if (!playingWord) return;

        const { top: wordTop } = editor.getBounds(
          selection.index + length - playingWord.length,
          playingWord.length
        );

        const playingLine =
          (wordTop + editor.root.scrollTop - firstLineTopRef.current) / 48;

        if (playingLineRef.current?.line !== playingLine) {
          playingLineRef.current = {
            line: playingLine,
            wordIndex: playingWordIndex,
          };
        }

        const start =
          wordsRef
            .current!.words.slice(0, playingLineRef.current.wordIndex)
            .join(' ').length +
          selection.index +
          playingLine;

        const vh = editor.root.clientHeight;

        if (wordTop >= vh || wordTop <= 0) {
          const topCalculated = editor.root.scrollTop + wordTop - vh + 48;
          editor.root.scrollTo({
            behavior: 'smooth',
            top: topCalculated,
          });
        }

        const { width } = editor.getBounds(
          start,
          wordsRef
            .current!.words.slice(
              playingLineRef.current.wordIndex,
              playingWordIndexRef.current + 1
            )
            .join(' ').length
        );

        if (ctx) {
          if (playingLineRef.current.rect) {
            ctx.clearRect(...playingLineRef.current.rect);
          }

          playingLineRef.current.rect = [
            playingLine > 0 ? 0 : left - 48,
            playingLine * 48,
            width,
            48,
          ];

          ctx.fillStyle = 'rgba(234, 179, 37, .2)';
          ctx.fillRect(...playingLineRef.current.rect);
        }
      }
    },
    [audioPlayer, quillInstance?.editor, wordsRef]
  );

  return {
    setupKaraoke,
    drawKaraokeFrame,
  };
};
