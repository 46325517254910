import React, { FC } from 'react';
import LabelContainer, { ILabelContainer } from './LabelContainer';
import FileUploader, {
  IFileUploader,
} from 'modules/common/components/FileUploader/FileUploader';

interface ILabelInput extends ILabelContainer, IFileUploader {}

const LabelInput: FC<ILabelInput> = ({ labelText, className, ...rest }) => {
  return (
    <LabelContainer labelText={labelText} className={className}>
      <FileUploader {...rest} />
    </LabelContainer>
  );
};

export default LabelInput;
