import Quill, { RangeStatic } from 'quill';
import ReactQuill, { Range } from 'react-quill';
import QuillUtil from 'utils/QuillUtil';
import { getQuillHtml, prepareSSMLTextV2 } from 'utils/SsmlUtils';
import { SentenceToProcess, AudioToPlayback } from './types';

export const MAX_SENTENCE_AMOUNT_TO_BE_PROCESSED_IN_ONE_TIME = 10;

const isSentenceSkipped = (sentenceDomNode: any) => {
  if (sentenceDomNode.dataset.skip === 'true') return true;

  const nodeInnerHTML = sentenceDomNode.innerHTML.trim();

  return nodeInnerHTML.startsWith('<skip') && nodeInnerHTML.endsWith('</skip>');
};

export const getSentencesToPlay = ({
  editor,
  quillInstance,
  range,
}: {
  editor: Quill;
  quillInstance: ReactQuill | null;
  range: RangeStatic;
}) => {
  // If the playback starts from the cursor point in the text, get all the sentences after its position.
  const length = range.length || editor.getLength() - range.index;
  const sentencesBlot = QuillUtil.getSentenceChildren(editor, {
    ...range,
    length,
  });

  const sentencesToPlay: SentenceToProcess[] = sentencesBlot.reduce(
    (acc: SentenceToProcess[], sentence) => {
      const range: Range =
        QuillUtil.getNodeRange(quillInstance, sentence.domNode) || null;
      if (!range) return [];
      const contents = editor.getContents(range.index, range.length);
      const tempQuill = getQuillHtml(contents);
      const ssmlText = prepareSSMLTextV2(tempQuill);

      const isSkipped = isSentenceSkipped(sentence.domNode);
      if (isSkipped) return acc;

      acc.push({
        ssmlText,
        range,
        text: sentence.domNode.textContent,
      });
      return acc;
    },
    []
  );

  return sentencesToPlay;
};

export const setupValuesForTheNextAudioToPlay = async ({
  animationRef,
  audioPlayer,
  audioToPlay,
  wordsRef,
  modifyAudiosToPlayQueue,
  setupKaraoke,
  whilePlaying,
}: {
  animationRef: React.MutableRefObject<number | undefined>;
  audioToPlay: Promise<AudioToPlayback>;
  audioPlayer: React.MutableRefObject<HTMLAudioElement>;
  wordsRef: React.MutableRefObject<
    { words: string[]; wordStartTimes: number[] } | undefined
  >;
  modifyAudiosToPlayQueue: () => void;
  setupKaraoke: (selectionProp?: Range | undefined) => void;
  whilePlaying: (range?: Range) => Promise<void>;
}) => {
  // That one needs to complete switching between audios.
  audioPlayer.current.currentTime = 0;
  const audioData = await audioToPlay;
  audioPlayer.current.src = await audioData.meta.audio_url;
  wordsRef.current = {
    words: audioData.meta.time_intervals.words ?? [],
    wordStartTimes: audioData.meta.time_intervals.wordStartTimes ?? [],
  };
  setupKaraoke(audioData.range);
  if (animationRef.current) {
    cancelAnimationFrame(animationRef.current);
  }
  animationRef.current = requestAnimationFrame(() =>
    whilePlaying(audioData.range)
  );
  audioPlayer.current.play();
  modifyAudiosToPlayQueue();
};
