import { FC } from 'react';

const GcpIcon: FC = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.9195 16.3626H31.2616L35.0865 12.6902L35.2744 11.131C33.0832 9.27415 30.4352 7.98425 27.5805 7.38316C24.7258 6.78207 21.7586 6.88965 18.9592 7.69572C16.1598 8.5018 13.6207 9.97976 11.5816 11.99C9.54254 14.0002 8.07089 16.4764 7.30566 19.1845C7.73177 19.0168 8.2038 18.9897 8.64773 19.1072L16.2975 17.896C16.2975 17.896 16.6867 17.2775 16.888 17.3161C18.5271 15.5877 20.7923 14.5263 23.2199 14.349C25.6475 14.1717 28.0539 14.8921 29.9464 16.3626H29.9195Z"
        fill="#EA4335"
      />
      <path
        d="M40.535 19.1843C39.6558 16.0758 37.8507 13.2813 35.3412 11.1437L29.9729 16.2979C31.0917 17.1756 31.9883 18.2858 32.5951 19.5448C33.2018 20.8037 33.503 22.1785 33.4757 23.5654V24.4803C34.1031 24.4803 34.7244 24.5989 35.304 24.8295C35.8837 25.06 36.4104 25.3979 36.8541 25.8239C37.2977 26.2498 37.6496 26.7555 37.8898 27.3121C38.1299 27.8686 38.2534 28.4652 38.2534 29.0676C38.2534 29.67 38.1299 30.2665 37.8898 30.823C37.6496 31.3796 37.2977 31.8853 36.8541 32.3113C36.4104 32.7372 35.8837 33.0751 35.304 33.3057C34.7244 33.5362 34.1031 33.6548 33.4757 33.6548H23.9202L22.9673 34.5826V40.0848L23.9202 40.9996H33.4757C36.144 41.0196 38.7482 40.2144 40.903 38.7031C43.0577 37.1919 44.6486 35.0549 45.4404 32.6082C46.2321 30.1615 46.1826 27.5351 45.2991 25.1176C44.4157 22.7 42.7453 20.6197 40.535 19.1843V19.1843Z"
        fill="#4285F4"
      />
      <path
        d="M14.3537 40.9484H23.9092V33.6036H14.3537C13.6729 33.6035 13.0001 33.4629 12.3809 33.1913L11.0388 33.5907L7.18708 37.2631L6.85156 38.5517C9.01153 40.1177 11.6471 40.9597 14.3537 40.9484V40.9484Z"
        fill="#34A853"
      />
      <path
        d="M14.3533 17.1229C11.7642 17.1378 9.24461 17.9287 7.1464 19.3851C5.04819 20.8416 3.47615 22.8909 2.64986 25.2468C1.82357 27.6027 1.78429 30.1476 2.5375 32.526C3.29072 34.9044 4.79881 36.9975 6.85115 38.5131L12.3939 33.1913C11.6895 32.8858 11.0729 32.4207 10.5969 31.836C10.1209 31.2514 9.79968 30.5645 9.6609 29.8345C9.52213 29.1045 9.56989 28.353 9.80011 27.6446C10.0303 26.9361 10.4361 26.2919 10.9826 25.7672C11.5292 25.2424 12.2001 24.8528 12.938 24.6318C13.6758 24.4108 14.4585 24.3649 15.2189 24.4981C15.9792 24.6314 16.6945 24.9398 17.3035 25.3968C17.9124 25.8538 18.3968 26.4459 18.715 27.1222L24.2578 21.8004C23.0944 20.3402 21.595 19.1581 19.8768 18.3467C18.1585 17.5352 16.2681 17.1164 14.3533 17.1229V17.1229Z"
        fill="#FBBC05"
      />
    </svg>
  );
};

export default GcpIcon;
