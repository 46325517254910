import React, { useMemo } from 'react';
import { Stack } from 'modules/common/components/Stack/Stack';
import { Button } from 'modules/common/components/Button/Button';
import { Icon } from 'icons';
import {
  usePreprocessChapterMutation,
  useGetPreprocessedChapterQuery,
  useGetVoicesQuery,
} from 'redux/api/app.api';
import {
  activeChapterIdSelector,
  activeChapterTaskArnSelector,
  chapterPostUrlSelector,
  isCurrentChapterDirtySelector,
  isCurrentChapterHasSavedChangesSelector,
} from 'redux/reducers/chapters/selectors';
import {
  activeProjectSelectorId,
  projectEngineSelector,
  projectVoiceSelector,
} from 'redux/reducers/project/selectors';
import { saveChapter } from 'redux/reducers/chapters/chaptersSlice';
import { useAppDispatch, useAppSelector } from 'modules/common/hooks/redux';
import { useSelectionWithLength } from 'modules/common/hooks/useSelectionWithLength';
import {
  error,
  error as errorNotify,
  success,
} from 'modules/common/components/Notify';
import { useTrackEvent, AnalyticsEvent } from 'services/amplitude';
import useModal from 'modules/common/hooks/useModal';
import PreProcessingReportPopup from 'modules/common/components/PreProcessingReport/PreProcessingReportPopup';
import useQuill from 'modules/common/hooks/useQuill';
import PreProcessingConfirmationPopup from 'modules/common/components/PreProcessingReport/PreProcessingConfirmationPopup';
import { EditorSave } from 'utils/EditorSave';
import { POOLING_INTERVAL, WINDOW_RELOAD_TIMEOUT } from './config';
import Styles from './tags.module.scss';

export const PreProcessButton = () => {
  const {
    isOpen: isPreProcessingConfirmation,
    setOpen: setPreProcessingConfirmationOpen,
    setClose: setPreProcessingConfirmationClose,
  } = useModal();
  const {
    isOpen: isPreProcessingReportOpen,
    setOpen: setPreProcessingReportOpen,
    setClose: setPreProcessingReportClose,
  } = useModal();
  const quillInstance = useQuill();
  const dispatch = useAppDispatch();
  const chapterTaskArn = useAppSelector(activeChapterTaskArnSelector);
  const chapterPostUrl = useAppSelector(chapterPostUrlSelector);
  const chapterId = useAppSelector(activeChapterIdSelector);
  const projectId = useAppSelector(activeProjectSelectorId);
  const projectEngine = useAppSelector(projectEngineSelector);
  const projectVoice = useAppSelector(projectVoiceSelector);
  const isCurrentChapterDirty = useAppSelector(isCurrentChapterDirtySelector);
  const isCurrentChapterHasSavedChanges = useAppSelector(
    isCurrentChapterHasSavedChangesSelector
  );
  const { data: voicesData } = useGetVoicesQuery();
  const isProjectAbleToBePreProcessed = useMemo(() => {
    if (voicesData?.voices && projectEngine && projectVoice) {
      return voicesData?.voices[projectEngine].find(
        (voice) => voice?.value === projectVoice
      )?.pre_process;
    }
    if (voicesData && projectEngine)
      return voicesData?.voices[projectEngine][0]?.pre_process;
    return false;
  }, [projectEngine, projectVoice, voicesData]);
  const editorInstanceSave = EditorSave.getInstance();
  const trackEvent = useTrackEvent();
  const [preprocessStartTime, setPreprocessStartTime] = React.useState<
    number | undefined
  >();
  const [isPooling, setIsPooling] = React.useState<boolean>(false);
  // This record (a task arn) fills on chapter's preprocessing starting.
  const isPreprocessingStarted = !!chapterTaskArn;
  const [preprocessChapterMutation, preprocessChapterMutationMeta] =
    usePreprocessChapterMutation();
  const { data: processedChapterData, error: preprocessingError } =
    useGetPreprocessedChapterQuery(
      {
        projectId,
        chapterId,
      },
      {
        skip: !isPooling || isPreprocessingStarted,
        pollingInterval: POOLING_INTERVAL,
      }
    );

  React.useEffect(() => {
    if (
      preprocessChapterMutationMeta.isSuccess &&
      preprocessChapterMutationMeta.data
    ) {
      success({ children: preprocessChapterMutationMeta.data });
    }
  }, [
    preprocessChapterMutationMeta.data,
    preprocessChapterMutationMeta.isSuccess,
  ]);

  React.useEffect(() => {
    if (preprocessingError) {
      const error = preprocessingError as { error?: string };
      setIsPooling(false);
      if (error.error) errorNotify({ children: error.error });
    }
  }, [preprocessingError]);

  React.useEffect(() => {
    if (processedChapterData?.jobStatus === 'STOPPED') {
      setIsPooling(false);
    }
  }, [processedChapterData?.jobStatus]);

  const isPreProcessingInProgress =
    preprocessChapterMutationMeta.isLoading ||
    preprocessChapterMutationMeta.isSuccess ||
    processedChapterData?.jobStatus === 'IN PROGRESS';

  React.useEffect(() => {
    if (preprocessChapterMutationMeta.isSuccess) setIsPooling(true);
  }, [preprocessChapterMutationMeta.isSuccess]);

  const isSelectionActive = useSelectionWithLength();
  const preprocessChapterHandler = React.useCallback(() => {
    const editor = quillInstance?.editor;
    const innerHTML = editor?.root?.innerHTML || '';
    const isChapterEdited = innerHTML.includes('ssml-tag-id');

    if (isChapterEdited) {
      error({
        children: 'The chapter has changes. Preprocessing is prohibited',
      });
      return;
    }

    if (chapterId) {
      trackEvent({ eventName: AnalyticsEvent.ApplyPreProcess });
      setPreprocessStartTime(Date.now());
      preprocessChapterMutation({ projectId, chapterId });
    }
  }, [
    chapterId,
    preprocessChapterMutation,
    projectId,
    quillInstance,
    trackEvent,
  ]);

  const saveChanges = React.useCallback(async () => {
    const editor = quillInstance?.editor;

    if (!chapterId || !chapterPostUrl || !editor) return;
    editorInstanceSave.saveText('');
    trackEvent({
      eventName: AnalyticsEvent.SaveChapter,
    });
    await dispatch(
      saveChapter({
        projectId,
        chapterId,
        uploadUrl: chapterPostUrl,
        text: editor.root.innerHTML,
      })
    );
    setPreProcessingConfirmationClose();
    setPreProcessingReportOpen();
  }, [
    chapterId,
    chapterPostUrl,
    dispatch,
    editorInstanceSave,
    projectId,
    quillInstance?.editor,
    setPreProcessingConfirmationClose,
    setPreProcessingReportOpen,
    trackEvent,
  ]);

  const getPreProcessingReport = React.useCallback(async () => {
    if (isCurrentChapterDirty) {
      setPreProcessingConfirmationOpen();
    } else {
      setPreProcessingReportOpen();
    }
  }, [
    isCurrentChapterDirty,
    setPreProcessingConfirmationOpen,
    setPreProcessingReportOpen,
  ]);

  const isPreProcessingFinished = processedChapterData?.jobStatus === 'STOPPED';

  React.useEffect(() => {
    if (isPreProcessingFinished) {
      if (preprocessStartTime) {
        const processingTime = (
          (Date.now() - preprocessStartTime) /
          1000
        ).toFixed(2);
        trackEvent({
          eventName: AnalyticsEvent.TimeToPreprocess,
          customProperties: {
            processingTime,
            projectId,
            chapterId,
          },
        });
      }
      success({
        children:
          'Preprocessing was successfully finished! The page is going to be reloaded in 3 secs.',
      });
      setTimeout(() => {
        window.location.reload();
      }, WINDOW_RELOAD_TIMEOUT);
    }
  }, [
    chapterId,
    isPreProcessingFinished,
    preprocessStartTime,
    projectId,
    trackEvent,
  ]);

  const button = React.useMemo(() => {
    if (isPreProcessingFinished || isPreprocessingStarted) {
      return (
        <>
          <PreProcessingConfirmationPopup
            isOpen={isPreProcessingConfirmation}
            setClose={setPreProcessingConfirmationClose}
            onSaveChanges={saveChanges}
          />
          <PreProcessingReportPopup
            isOpen={isPreProcessingReportOpen}
            setClose={setPreProcessingReportClose}
          />
          <Button
            flexLoader
            // Temporary check for having a chapterTaskArn value.
            disabled={!chapterTaskArn}
            className="w-100"
            onClick={getPreProcessingReport}
            secondary
          >
            <div className={Styles['pre-processing-button']}>
              Pre-processing report
            </div>
          </Button>
        </>
      );
    }
    return (
      <Button
        flexLoader
        // If changes have been made previously, pre-processing must be restricted!
        disabled={
          isCurrentChapterDirty ||
          isCurrentChapterHasSavedChanges ||
          isPreProcessingInProgress
        }
        loading={isPreProcessingInProgress}
        className="w-100"
        onClick={preprocessChapterHandler}
      >
        <div className={Styles['pre-processing-button']}>
          {!isPreProcessingInProgress ? (
            <Icon size={20} name="preprocessing" />
          ) : null}
          Pre-process the chapter
        </div>
      </Button>
    );
  }, [
    chapterTaskArn,
    getPreProcessingReport,
    isCurrentChapterDirty,
    isCurrentChapterHasSavedChanges,
    isPreProcessingConfirmation,
    isPreProcessingFinished,
    isPreProcessingInProgress,
    isPreProcessingReportOpen,
    isPreprocessingStarted,
    preprocessChapterHandler,
    saveChanges,
    setPreProcessingConfirmationClose,
    setPreProcessingReportClose,
  ]);

  if (isSelectionActive || !isProjectAbleToBePreProcessed) return null;

  return (
    <Stack className={Styles['pre-processing-button-wrapper']}>
      <Stack.Item className="w-100">{button}</Stack.Item>
    </Stack>
  );
};
