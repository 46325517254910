import React, {useState} from 'react';
import {Icon} from 'icons';
import {
  getProjectTOCInnerHTMLPages,
  getProjectTOCPagesChapters,
  isProjectStatusIncomplete,
  isProjectStatusInProgress,
  isProjectStatusInReTake,
  isProjectStatusInReview,
} from 'redux/reducers/project/selectors';
import {changeProjectStatus} from 'redux/reducers/project/projectSlice';
import {useChangeProjectStatusMutation} from 'redux/api/app.api';
import {AnalyticsEvent, useTrackEvent} from 'services/amplitude';
import {useIsAdminRole} from 'modules/common/hooks/useIsAdminRole';
import {useIsEditorRole} from 'modules/common/hooks/useIsEditorRole';
import {useAppDispatch, useAppSelector} from 'modules/common/hooks/redux';
import {Button} from 'modules/common/components/Button/Button';
import {ProjectStatus, TOCChapter} from 'modules/common/types';
import useLoading from 'modules/common/hooks/useLoading';
import {SomethingWentWrong} from 'modules/common/components/Notify';
import {useSaveTOCAndSplitBookIntoChapters} from 'modules/common/api';
import ChaptersTooLongPopup from 'modules/edit/Header/ChaptersTooLongPopup';

interface Props {
  projectId: string;
}

/**
 * Philosophy behind this component
 * Since this component will and can have a lot of cases just adhere to the written form
 * where add your desired condition with an if statement , since two cases will not happen
 * simultaneously, so we will not have any problem there
 * */

const CHAPTER_MAXIMUM_CHARACTER_COUNT = 65000;

const ProjectActions: React.FC<Props> = ({ projectId }) => {
  const isAdminRole = useIsAdminRole();
  const isEditorRole = useIsEditorRole();
  const [changeStatus, { isLoading }] = useChangeProjectStatusMutation();
  const { isLoading: isSavingTOCPending, setLoading: setIsSavingTOCPending } =
    useLoading();
  const isProgress = useAppSelector(isProjectStatusInProgress);
  const isReview = useAppSelector(isProjectStatusInReview);
  const isRetake = useAppSelector(isProjectStatusInReTake);
  const isIncomplete = useAppSelector(isProjectStatusIncomplete);
  const projectTOCInnerHTMLPages = useAppSelector(getProjectTOCInnerHTMLPages);
  const projectTOCChapters = useAppSelector(getProjectTOCPagesChapters);
  const dispatch = useAppDispatch();
  const trackEvent = useTrackEvent();
  const saveTOCAndSplitBookIntoChapters = useSaveTOCAndSplitBookIntoChapters();
  const [chaptersTooLong, setChaptersTooLong] = useState<TOCChapter[] | null>(null);

  const changeProjStatusHandler = async ({
    id,
    projectStatus,
  }: {
    id: string;
    projectStatus: ProjectStatus;
  }) => {
    try {
      await changeStatus({ id, projectStatus }).unwrap();
      if (ProjectStatus.in_review) {
        trackEvent({ eventName: AnalyticsEvent.SendProjectToReview });
      }
      if (ProjectStatus.done) {
        trackEvent({ eventName: AnalyticsEvent.ApproveProject });
      }
      dispatch(changeProjectStatus(projectStatus));
    } catch (err) {
      SomethingWentWrong();
    }
  };

  const saveTOCText = async () => {
    const chaptersTooLong = projectTOCChapters.filter(chapter => chapter.characterCount > CHAPTER_MAXIMUM_CHARACTER_COUNT);
    if(chaptersTooLong.length) {
      setChaptersTooLong(chaptersTooLong);
      return;
    }

    try {
      setIsSavingTOCPending(true);
      await saveTOCAndSplitBookIntoChapters({
        projectId,
        setIsSavingTOCPending,
        projectTOCInnerHTMLPages,
      });
    } catch (err) {
      setIsSavingTOCPending(false);
      SomethingWentWrong();
    }
  };

  let component = <></>;

  if (isEditorRole && (isProgress || isRetake)) {
    component = (
      <Button
        small
        fill="subtle"
        className="m-l-1"
        onClick={() =>
          changeProjStatusHandler({
            id: projectId,
            projectStatus: ProjectStatus.in_review,
          })
        }
        disabled={isLoading}
        loading={isLoading}
      >
        <Icon size={18} name="file_upload" />
        <span className="m-l-1">Send for Review</span>
      </Button>
    );
  }

  // this can be replaced with the author later on
  if (isAdminRole && isReview) {
    component = (
      <Button
        small
        fill="subtle"
        className="m-l-1"
        onClick={() =>
          changeProjStatusHandler({
            id: projectId,
            projectStatus: ProjectStatus.re_take,
          })
        }
        disabled={isLoading}
        loading={isLoading}
      >
        <Icon name="refresh" size={18} />
        <span className="m-l-1">Send for Retake</span>
      </Button>
    );
  }

  if (isAdminRole && isIncomplete) {
    component = (
      <Button
        small
        fill="subtle"
        className="m-l-1"
        onClick={saveTOCText}
        disabled={isSavingTOCPending}
        loading={isSavingTOCPending}
      >
        <Icon name="file_upload" size={18} />
        <span className="m-l-1">Save & Continue</span>
      </Button>
    );
  }

  // here we will have the generic default behavior and added anyone of those actions
  return (
    <>
      {component}
      {isAdminRole && !isIncomplete && (
        <Button
          small
          fill="subtle"
          className="m-l-1"
          onClick={() =>
            changeProjStatusHandler({
              id: projectId,
              projectStatus: ProjectStatus.done,
            })
          }
          disabled={isLoading}
          loading={isLoading}
        >
          <Icon name="file_upload" size={18} />
          <span className="m-l-1">Publish</span>
        </Button>
      )}

      <ChaptersTooLongPopup
        allChapters={projectTOCChapters}
        chaptersTooLong={chaptersTooLong}
        onClose={() => setChaptersTooLong(null)}
      />
    </>
  );
};

export default ProjectActions;
