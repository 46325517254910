import { FC } from 'react';
import classnames from 'classnames';
import { Icon } from 'icons';
import { SORT } from 'modules/common/types';
import { Stack } from 'modules/common/components/Stack/Stack';

export interface SortableCellProps {
  text: string;
  accessorName: string;
  onSortChange: (accessorName: string, value: SORT) => void;
  sort?: SORT;
  width?: string;
}

const SortableCell: FC<SortableCellProps> = ({
  text,
  accessorName,
  onSortChange,
  sort,
  children,
  width,
}) => {
  return (
    <Stack direction="column" justifyContent="flex-end" style={{ width }}>
      <Stack.Item>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          className={classnames('m-b-1')}
        >
          <Stack.Item>{text}</Stack.Item>
          <Stack.Item>
            <Stack direction="column">
              <Icon
                name="arrow_drop_up"
                className={classnames({
                  'color-primary-i': sort === SORT.ASC,
                  'color-primary-opacity-i': sort !== SORT.ASC,
                })}
                onClick={() => onSortChange(accessorName, SORT.ASC)}
              />
              <Icon
                name="arrow_drop_down"
                className={classnames('m-t--1', {
                  'color-primary-i': sort === SORT.DESC,
                  'color-primary-opacity-i': sort !== SORT.DESC,
                })}
                onClick={() => onSortChange(accessorName, SORT.DESC)}
              />
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>{children}</Stack.Item>
    </Stack>
  );
};

export default SortableCell;
