import { getUserId } from 'aws';
import { useEffect, useState } from 'react';

export function useUserId() {
  const [userId, setUserId] = useState<string>();

  useEffect(() => {
    (async () => {
      try {
        const userId = await getUserId();
        setUserId(userId);
      } catch (err) {}
    })();
  }, []);

  return userId;
}
