import Quill from 'quill';
import QuillUtil from './QuillUtil';
import { InsertBlot } from '../modules/edit/Editor/Blots/InsertBlot';
import { TagType } from 'modules/common/models/Tags';
import { SentenceBlot } from 'modules/edit/v2/Editor/Blots/SentenceBlot';

export * from './isScrolledIntoView';
export * from './axios';
export * from './find-indices';
export * from './get-OS-Name';
export * from './keyCodes';
export * from './wrap-children';
export * from './wrap-with-component';

export function isNumeric(str: any) {
  return !isNaN(str) && !isNaN(parseFloat(str));
}

export function convertArrayToStrWithCommas(arr: string[]): string {
  return arr.join(',');
}

export function downloadAudioFile(
  blob: Blob,
  audioInfo: { name: string; ext: string }
) {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${audioInfo.name}.${audioInfo.ext}`); //or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadAudioByUrl(url: string, name?: string) {
  const link = document.createElement('a');
  link.href = url;
  if (name) {
    link.download = name;
  }
  link.click();
  link.remove();
}

export function downloadAudioByIframe(url: string) {
  const iframe = document.createElement('iframe');
  iframe.setAttribute('sandbox', 'allow-downloads allow-scripts');
  iframe.src = url;
  iframe.setAttribute('style', 'display: none');

  iframe.className = 'downloadableIframe';

  document.body.appendChild(iframe);
}

export function removeDownloadableIframes() {
  document
    .querySelectorAll('.downloadableIframe')
    .forEach((e) => e?.parentNode?.removeChild(e));
}

export enum StatusType {
  'RUNNING' = 'RUNNING',
  'FAILED' = 'FAILED',
  'SUCCEEDED' = 'SUCCEEDED',
  'TIMED_OUT' = 'TIMED_OUT',
  'ABORTED' = 'ABORTED',
}

export function listStatusChecker(
  result: { status: StatusType; [i: string]: any }[]
): { isRunning: boolean; isFinished: boolean; hasError: boolean } {
  let isRunning = true;
  let hasError = false;
  let isFinished = false;
  let successCount = 0;

  for (let i = 0; i < result.length; i++) {
    let status = result[i].status;
    if (
      status === StatusType.TIMED_OUT ||
      status === StatusType.FAILED ||
      status === StatusType.ABORTED
    ) {
      hasError = true;
      isRunning = false;
      break;
    }

    if (status === StatusType.SUCCEEDED) {
      successCount++;
    }
  }

  if (successCount === result.length) {
    isFinished = true;
    isRunning = false;
  }

  return { hasError, isFinished, isRunning };
}

export function createElementContainer(id: string, Styles?: string) {
  let element = document.getElementById(id);

  if (element) {
    return element;
  }

  element = document.createElement('div');
  element.setAttribute('id', id);
  if (Styles) {
    element.className = Styles;
  }
  document.body.appendChild(element);
  return element;
}

function clickHandler(event: MouseEvent) {
  if (event.target) {
    const element = event.target as HTMLElement;
    const tagType = element.getAttribute('ssml-tag-type');
    const selectedText = element.textContent?.trim();
    let range = document.createRange();
    range.selectNodeContents(event.target as Node);
    let sel = window.getSelection();
    // Disable the range adding if no text content was selected
    if (
      sel &&
      (!!selectedText?.length ||
        element.className === 'rb-ssml-preset' ||
        tagType === TagType.WordTags)
    ) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }
}

export function putClickOnTags(editor: Quill) {
  const elements = QuillUtil.getRBChildren(editor, {
    index: 0,
    length: editor.getLength(),
  });

  elements.forEach((item) => {
    if (item.statics.blotName === InsertBlot.blotName) {
      return;
    }
    const domNode: HTMLElement = item.domNode;

    domNode.onclick = clickHandler;
  });
}

export function putClickOnTagsV2(editor: Quill) {
  const elements = QuillUtil.getInlineChildren(editor, {
    index: 0,
    length: editor.getLength(),
  });

  elements.forEach((item) => {
    if (item.statics.blotName === InsertBlot.blotName) return;
    if (
      item.statics.blotName === SentenceBlot.blotName &&
      item.domNode.getAttribute(SentenceBlot.attrSkip) !== 'true'
    )
      return;
    const domNode: HTMLElement = item.domNode;

    domNode.onclick = clickHandler;
  });
}

export function range(start: number, end: number) {
  const length = end - start + 1;
  /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
}

export function prettifyDate(str: string) {
  const date = new Date(str);
  return Intl.DateTimeFormat('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(date);
}

export function prettifyDateShort(str: string) {
  const date = new Date(str);
  return Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(date);
}

export function getTimeSince(date: string) {
  type Unit = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second';
  const units = {
    year: 24 * 60 * 60 * 1000 * 365,
    month: (24 * 60 * 60 * 1000 * 365) / 12,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000,
  };
  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  const now = new Date();
  const elapsed = +new Date(date) - +now;

  for (let u in units)
    if (Math.abs(elapsed) > units[u as Unit] || u === 'second')
      return rtf.format(Math.round(elapsed / units[u as Unit]), u as Unit);
}

export function debounce(callback: Function, wait = 300) {
  let timeoutId: number | undefined;

  function debounceFn(...args: any) {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  }

  debounceFn.cancel = function () {
    clearTimeout(timeoutId);
    timeoutId = undefined;
  };

  return debounceFn;
}

export const isInTheViewport = ({
  top,
  bottom,
  offsetHeight,
}: {
  top: number;
  bottom: number;
  offsetHeight: number;
}) => top >= 0 && bottom <= offsetHeight;
