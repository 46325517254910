import Quill, { DeltaStatic } from 'quill';
import { ProjectTOCPage } from 'modules/common/models/Project';

export const getTOCPages = (delta: DeltaStatic): ProjectTOCPage[] => {
  let tempContainer = document.createElement('div');
  tempContainer.style.display = 'none';
  document.body.appendChild(tempContainer);

  const tempQuill = new Quill(tempContainer);
  tempQuill.setContents(delta, 'api');

  (tempQuill.scroll as any).optimize();

  document.body.removeChild(tempContainer);
  const pages = [...tempQuill.root.childNodes[0]?.childNodes].reduce(
    (acc: {}[], cur: any) => {
      if (cur && cur.nodeName === 'PAGE') {
        acc.push({
          id: cur.id,
          innerHTML: cur.innerHTML,
          textContent: cur.textContent.trim(),
          hasChapterTag: cur.innerHTML.includes('<chapter '),
        });
      }
      return acc;
    },
    []
  );
  return pages as ProjectTOCPage[];
};
