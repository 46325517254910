import { createSlice } from '@reduxjs/toolkit';

export interface FocusState {
  shouldFocusAliasInput: boolean;
}

const initialState: FocusState = {
  shouldFocusAliasInput: false,
};

const focusSlice = createSlice({
  name: 'focus',
  initialState,
  reducers: {
    setFocusAliasInput(state, action) {
      state.shouldFocusAliasInput = action.payload;
    },
    resetFocus(state) {
      state.shouldFocusAliasInput = false;
    },
  },
});

export const { setFocusAliasInput, resetFocus } = focusSlice.actions;
export default focusSlice.reducer;
