import { FC, MouseEvent } from 'react';
import classnames from 'classnames';

import { getIconByName, IconNameType } from './build/icons';
import Styles from './index.module.scss';

interface IconPropsStrict {
  className?: string;
  color?: string;
  el?: any;
  name: IconNameType;
  size?: string | number;
  hoverable?: boolean;
  onClick?: (e: MouseEvent) => void;
}

interface IconProps extends IconPropsStrict {
  [propName: string]: any;
}

export const Icon: FC<IconProps> = ({
  name,
  color = 'currentColor',
  className,
  el,
  size = '16px',
  hoverable,
  ...props
}) => {
  const { viewbox, content } = getIconByName(name);

  if (!content) {
    console.warn('Icon does not exists', name);
    return null;
  }

  const IconElement =
    el ?? (typeof props.onClick !== 'undefined' ? 'button' : 'i');

  return (
    <IconElement
      className={classnames(
        Styles.icon,
        `icon-${name}`,
        hoverable && Styles.hoverable,
        className
      )}
      width={size}
      height={size}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
        height={size}
        width={size}
        viewBox={viewbox}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </IconElement>
  );
};

Icon.displayName = 'Icon';
