import { PresetsModel } from 'modules/common/models/Presets';
import { Quill } from 'react-quill';
import { RBBlot } from 'modules/edit/v2/Editor/Blots/RBBlot';

const Parchment = Quill.import('parchment');

export class PresetBlot extends RBBlot {
  static blotName = 'preset';
  static classNameInternal = 'rb-ssml-preset';
  static tagName = 'preset';
  static scope = Parchment.Scope.INLINE_BLOT;
  static dataAttributeName = 'data-id';

  static create(value: string) {
    let node = super.create();
    if (value) {
      const preset: PresetsModel = JSON.parse(value);
      node.setAttribute('class', this.classNameInternal);
      node.setAttribute('data-blot', this.blotName);
      node.setAttribute(PresetBlot.dataAttributeName, preset.id);
      node.setAttribute('data-name', preset.name);
      let beforeText = '';
      let afterText = '';
      node.setAttribute('data-tags', JSON.stringify(preset.tags));
      preset.tags.forEach((tag) => {
        let attributes = '';
        if (tag.attributes.length) {
          tag.attributes.forEach((attr) => {
            attributes += ` ${attr.name || attr.label}="${attr.value}"`;
          });
        }

        if (tag.fixedAttributes) {
          Object.keys(tag.fixedAttributes).forEach((key) => {
            const attribute = String(tag?.fixedAttributes?.[key]);
            attributes += ` ${key}="${attribute}"`;
          });
        }
        beforeText += `<${tag.name}`;
        if (attributes.length) {
          beforeText += ` ${attributes}`;
        }
        if (tag.name === 'break') {
          beforeText += ' />';
        } else {
          beforeText += '>';
        }

        if (tag.name !== 'break') {
          afterText = `</${tag.name}>` + afterText;
        }
      });
      node.setAttribute('data-before-text', beforeText);
      node.setAttribute('data-after-text', afterText);
    }
    return node;
  }

  static formats(domNode: HTMLElement) {
    const format = {
      name: domNode.dataset.name,
      id: domNode.dataset.id,
      tags: JSON.parse(domNode.dataset.tags!),
    } as PresetsModel;
    return JSON.stringify(format);
  }
}
