import { Quill } from 'react-quill';
import { QuillUtil } from 'utils/QuillUtil';
import { SkipBlot } from './SkipBlot';

const Inline = Quill.import('blots/inline');

Inline.order = [
  'cursor',
  'inline',
  'underline',
  'strike',
  'italic',
  'bold',
  'script',
  'link',
  'code',

  // added these two so that skip at the start of the sentence or at the end of the sentence
  // does not destroy the sentence tag
  'skip',
  'sentence',
];

export interface SentenceBlotValue {
  id: string;
  skip?: boolean;
}

export class SentenceBlot extends Inline {
  static blotName = 'sentence';
  static tagName = 'sentence';
  static classNameInternal = 'rb-sentence-tag';
  static allowedTags = [];
  static attrSkip = 'data-skip';

  static create(value: string) {
    let node: HTMLSpanElement = super.create(
      SentenceBlot.tagName.toLowerCase()
    );
    const sentence = JSON.parse(value) as SentenceBlotValue;
    if (value) {
      node.setAttribute('id', sentence.id);
      node.setAttribute('class', this.classNameInternal);
      node.setAttribute('ssml-blot-name', this.blotName);
      let beforeText = `<${SentenceBlot.tagName.toLowerCase()}>`;
      let afterText = `</${SentenceBlot.tagName.toLowerCase()}>`;
      node.setAttribute('data-before-text', beforeText);
      node.setAttribute('data-after-text', afterText);
      if (sentence.hasOwnProperty('skip')) {
        node.setAttribute(SentenceBlot.attrSkip, sentence.skip!.toString());
      } else {
        node.setAttribute(SentenceBlot.attrSkip, 'false');
      }
    }
    return node;
  }

  static formats(domNode: HTMLElement) {
    return JSON.stringify({
      id: domNode.id,
      skip: domNode.dataset.skip === 'true',
    });
  }

  formatAt(index: number, length: number, name: string, value: any): void {
    // removing child tag inside a sentence tag
    if (this.statics.blotName && this.statics.blotName !== name && !value) {
      QuillUtil.forEachRecursive(this.children, function (child) {
        if (
          QuillUtil.isTagNameEqual(child?.statics.blotName, name) &&
          QuillUtil.isFullyInRange(
            { index: child.offset(child.parent), length: child.length() },
            { index, length }
          )
        ) {
          child.unwrap();
        }
      });
      return;
    }
    super.formatAt(index, length, name, value);
  }

  optimize(): void {
    // Don't call with super otherwise
    // the order will be implemented and your optimize will be lost

    if (this.domNode && document.contains(this.domNode)) {
      // combine two sentence nodes if they include the same IDs (removing sentence tag duplication)
      if (
        this.statics.blotName === SentenceBlot.blotName &&
        this.prev?.statics.blotName === SentenceBlot.blotName &&
        this.domNode.id === this.prev?.domNode.id
      ) {
        this.prev.appendChild(this);
        this.unwrap();
      }
      // change/replace pause tags position if placed at the beginning of a sentence:
      // place them at the end of a previous sentence (if exists)
      if (!!this.prev?.domNode && this.domNode && this.domNode.firstChild) {
        const isInsertingPauseTagInTheBeginningOfTheSentence =
          QuillUtil.isInsertingPauseTagInTheBeginningOfTheSentence(
            this.domNode.firstChild
          );
        if (isInsertingPauseTagInTheBeginningOfTheSentence.isPauseTag) {
          this.prev.appendChild(
            isInsertingPauseTagInTheBeginningOfTheSentence.blot
          );
        }
      }
      // Unwrap skip tags in a sentence if every part of the sentence is covered by them
      if (this.domNode.id !== this.domNode.nextSibling?.id) {
        const isEveryChildNodeWrappedWithSkipTag = Array.from(
          this.domNode.childNodes.values()
        ).every((node: any) =>
          QuillUtil.isTagNameEqual(
            node.__blot.blot.statics.blotName,
            SkipBlot.blotName
          )
        );
        if (isEveryChildNodeWrappedWithSkipTag) {
          QuillUtil.unwrapChildrenSkipTags(this.children);
          this.domNode.setAttribute(SentenceBlot.attrSkip, 'true');
        }
      }
      // remove skip tags if the sentence is skipped
      if (this.domNode.dataset.skip === 'true') {
        QuillUtil.unwrapChildrenSkipTags(this.children);
      }
      return;
    }
  }
}
