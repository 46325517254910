import { createColumnHelper } from '@tanstack/react-table';

import SortableInputCell from 'modules/common/components/Table/TableCells/SortableInputCell/SortableInputCell';
import {
  EditorCapacity,
  EditorStatus,
  TablesConfigParamWithFilters,
} from 'modules/common/types';
import { getEditorAvailabilityList } from 'modules/common/constants/functions';
import SelectCell from 'modules/common/components/Table/TableCells/SelectCell/SelectCell';
import { Editor } from '../EditorsTableConfig';

export function getEditorsTableColumnsConfigForUnassigned({
  changeSortBy,
  sortBy,
  sortType,
}: TablesConfigParamWithFilters) {
  const columnHelper = createColumnHelper<Editor>();

  return [
    columnHelper.accessor('editorName', {
      header: (info) => (
        <SortableInputCell
          text="Name"
          accessorName={info.header.column.id}
          onSortChange={changeSortBy}
          onChangeValue={info.column.setFilterValue}
          value={(info.column.getFilterValue() as string) || ''}
          sort={sortBy === info.header.column.id ? sortType : undefined}
        />
      ),
    }),
    columnHelper.accessor('editorCapacity', {
      header: (info) => {
        return (
          <SelectCell
            text="Capacity"
            data={getEditorAvailabilityList()}
            onChangeValue={info.column.setFilterValue}
            value={(info.column.getFilterValue() as string) || ''}
            width="120px"
            isPortal={false}
          />
        );
      },
      cell: (info) => {
        const value = info.getValue();
        return +value >= +EditorCapacity.Five ||
          info.row.original.editorStatus === EditorStatus.Unavailable ? (
          <span className="color-error">Unavailable</span>
        ) : (
          `${value}/5`
        );
      },
    }),
  ];
}
